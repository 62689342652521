import React, { useContext } from "react";
import { GlobalContext } from "./GlobalContext";
import { SiteWideNotification } from "./SiteWideNotification";
import { Button } from "./core/views/Button";
import Typography from "@mui/material/Typography";

export const OutOfDate = () => {
  const { api } = useContext(GlobalContext);
  return (
    <SiteWideNotification
      onDismiss={api.dismissUpgrade}
      notificationAction={
        <Button type="primary" medium onClick={() => window.location.reload(true)}>
          Upgrade
        </Button>
      }
    >
      <Typography variant="h6">A new version is available! Please click on upgrade to refresh</Typography>
    </SiteWideNotification>
  );
};
