import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useMemo } from "react";
import keyBy from "lodash/keyBy";
import { action } from "mobx";
import { USelect } from "../Select";
import { decorateOnChange, FormSelectInputProps } from "../FormInputTypes";
import FormItemDecorator from "./FormItemDecorator";
import { FormFieldRegistry } from "../../FormFieldRegistry";
import { Field as MField } from "../../type-definitions/mobx-react-form";
import { SimpleMetadata } from "../../SimpleMetadata";
import { defaultClazz } from "./defaultClusterDeploymentMethod";

export const ClusterDeploymentMethodInput: React.FC<FormSelectInputProps> = observer(({ options, input }) => {
  const metadata: { [key: string]: SimpleMetadata } = useMemo(() => keyBy(options, "clazz"), [options]);
  useEffect(() => {
    if (metadata) {
      const container = input.container();
      for (const key in metadata) {
        metadata[key].properties
          .map((p) => container.$(p.name))
          .forEach((propField) => {
            propField.metadata.originalRules = propField.metadata.originalRules || propField.get("rules");
            propField.set("rules", "");
            propField.metadata.hidden = true;
          });
      }
    }
  }, [input, metadata]);

  const setIntegrationClazz = useCallback(
    action<any>((v: string, clazzInput: MField) => {
      const container = input.container();
      const current = input.$value && metadata[input.$value];
      if (current) {
        current.properties
          .map((p) => container.$(p.name))
          .forEach((propField) => {
            propField.set("rules", "");
            propField.metadata.hidden = true;
          });
      }
      const next = metadata[v];
      next.properties
        .map((p) => container.$(p.name))
        .forEach((propField) => {
          if (propField.metadata.originalRules) {
            propField.set("rules", propField.metadata.originalRules);
          }
          propField.metadata.hidden = false;
        });
      decorateOnChange(clazzInput, clazzInput.onChange)(v);
    }),
    [input]
  );

  useEffect(() => setIntegrationClazz(input.$value || defaultClazz, input), [input, setIntegrationClazz]);

  return (
    <FormItemDecorator input={input}>
      <USelect
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          const v = e.currentTarget.value;
          setIntegrationClazz(v, input);
        }}
        value={input.$value}
      >
        {options.map((x) => (
          <option key={x.clazz} value={x.clazz}>
            {x.displayName}
          </option>
        ))}
      </USelect>
    </FormItemDecorator>
  );
});
export const register = (formFieldRegistry: FormFieldRegistry) =>
  formFieldRegistry.register(
    "cluster-deployment-method",
    (props) => <ClusterDeploymentMethodInput {...props} options={props.input.metadata.options} />,
    { decorate: false }
  );
