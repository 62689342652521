import React from "react";
import { Api } from "../core/api/Api";
import { setTimeoutVisible } from "../core/PageVisibilityStore";
import { action, observable } from "mobx";
import { Auth } from "../auth/Auth";
import sortBy from "lodash/sortBy";
import identity from "lodash/identity";
import classNames from "classnames";
import globalApiLocation from "../core/api/globalApiLocation";

export type NotificationsCount = { unread: number };

export type NotificationRelatedObject = { id: string; itemType: { clazz: string } };

export const MarkedAsRead = (markedAt: string) => ({ clazz: "MarkedAsRead", markedAt });

export type UserMarking = { clazz: string };

export type NotificationLevel =
  | "OkNotification"
  | "InfoNotification"
  | "WarningNotification"
  | "ErrorNotification"
  | "NewFeatureNotification";

const statusOrdering = {
  OkNotification: 0,
  InfoNotification: 1,
  WarningNotification: 2,
  NewFeatureNotification: 3,
  ErrorNotification: 4,
};

export const levelClazzClassName = (level: { clazz: NotificationLevel }) =>
  level.clazz.replace("Notification", "").toLowerCase();

export const sortByLevel = (x: UserNotification[]): UserNotification[] =>
  sortBy(x, (x) => 0 - statusOrdering[x.level.clazz]);

export const NotificationLevelView = ({
  level,
  hideText,
}: {
  level: { clazz: NotificationLevel };
  hideText?: boolean;
}) => <span className={classNames("status-type", levelClazzClassName(level))}>{!hideText && level}</span>;

export type UserNotification = {
  organizationId: string;
  id: string;
  level: { clazz: NotificationLevel };
  createdAt: string;
  createdBy: string;
  relatedObject?: NotificationRelatedObject;
  value: string;
  userMarkings: UserMarking[];
};

export class NotificationsStore {
  _api: Api;
  _fetching: boolean;
  _auth: Auth;
  _close: any;

  static defaultSampleRate = 30 * 1000;

  constructor(api: Api, auth: Auth) {
    this._api = api;
    this._auth = auth;
    // this._startChecking(true);
  }

  @observable unread: UserNotification[] = [];

  _op = (name: string) => `user-notifications/${name}`;

  _startChecking = action((runImmediately: boolean = false) => {
    if (this._close) {
      this._close();
    }
    this._fetching = false;
    this._close = setTimeoutVisible(this._refreshUnread, runImmediately ? 0 : NotificationsStore.defaultSampleRate);
  });

  _refreshUnread = action(() => {
    if (!this._fetching) {
      this._fetching = true;
      this.getUnreadNotifications()
        .then(
          action((x: UserNotification[]) => {
            this.unread = x;
            this._startChecking();
          })
        )
        .catch(() => {
          this._startChecking();
        });
    }
  });

  getUnreadNotifications = (): Promise<UserNotification[]> => {
    if (!this._auth?.currentOrganization?.id) {
      return Promise.resolve([]);
    }
    return this._api._requestByLocationObj({
      location: globalApiLocation(),
      method: "GET",
      op: this._op("unread"),
      errorHandlers: { connectionError: identity },
    });
  };

  mark = (notificationId: string, marking: UserMarking): Promise<UserNotification> => {
    return this._api
      .patch(this._op(`mark/${notificationId}`), Object.assign({}, marking, { userId: this._auth.user.email }))
      .then((x) => {
        this._startChecking(true);
        return x;
      });
  };

  markAll = (notifications: UserNotification[], marking: UserMarking): Promise<UserNotification[]> => {
    return this._api
      .patch(this._op("mark-multi/"), {
        ids: notifications.map((x) => x.id),
        marking: Object.assign(marking, { userId: this._auth.user.email }),
      })
      .then((x) => {
        this._startChecking(true);
        return x;
      });
  };

  notify = (
    not: UserNotification,
    options: { all: boolean } = { all: false }
  ): Promise<UserNotification | UserNotification[]> => {
    return this._api.post(this._op(options.all ? "all/ " : ""), not);
  };
}
