import React, { PropsWithChildren } from "react";
import styles from "../../styles/step-view.module.scss";
import ViewAgendaIcon from "@mui/icons-material/ViewAgenda";
import ReactMarkdown from "react-markdown";
import { markdownProps } from "./MarkdownRenderers";

export const DEFAULT_STEP = "DEFAULT_STEP";
export interface Step {
  description?: string;
  fields: any[];
}

export type Steps = Record<string, Step>;

function StepView({ name, description, children }: PropsWithChildren<{ name: string; description?: string }>) {
  if (name === DEFAULT_STEP) {
    return <>{children}</>;
  }

  return (
    <div className={styles.StepView}>
      <h1>
        <ViewAgendaIcon />
        {name}
      </h1>
      {description && (
        <ReactMarkdown className="description" {...markdownProps}>
          {description}
        </ReactMarkdown>
      )}
      {children}
    </div>
  );
}

export default StepView;
