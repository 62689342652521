import { BaseFormWithArrayFields } from "../../BaseForm";
import { Item } from "../SuperSelectItem";

export const hiveMetastoresValidator = (
  passes: (passed?: boolean, err?: string) => void,
  value: BaseFormWithArrayFields[]
) => {
  if (!value.length) {
    passes();
  }
  Promise.all(value.map((x) => x.form.validate({ showErrors: true }))).then((x) => {
    if (x.some((y) => !y.isValid)) {
      passes(false, "");
    } else {
      passes();
    }
  });
};

const isAutoCompleteItem = (item: any): item is Item => item?.hasOwnProperty("key") && item?.hasOwnProperty("title");
export const deserializeHiveMetastoresValue = (
  value: Array<BaseFormWithArrayFields | { form: BaseFormWithArrayFields }>
): any[] => {
  if (!value) {
    return [];
  }
  const results = value.map((x) => Object.assign((x.form || x).values(), { clazz: (x.form || x).clazz }));
  return results.map((result) =>
    Object.fromEntries(Object.entries(result).map(([k, v]) => [k, isAutoCompleteItem(v) ? v.key : v]))
  );
};
