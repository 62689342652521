import React from "react";
import { FormInputProps } from "../FormInputTypes";
import { FormFieldRegistry } from "../../FormFieldRegistry";
import { FormTextInput } from "./TextInputs";

export function PhoneFormInput(props: FormInputProps) {
  return <FormTextInput {...props} />;
}
export const register = (formFieldRegistry: FormFieldRegistry) =>
  formFieldRegistry.register("phone", (props) => <PhoneFormInput {...props} />);
