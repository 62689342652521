import React, { useCallback, useEffect, useRef, useState } from "react";
import { fuzzyFilter } from "../Fuzzy";
import classNames from "classnames";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { FixedSizeList } from "react-window";
import CircularProgress from "@mui/material/CircularProgress";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { ItemView, SimpleItemView } from "./SelectItemViews";
import { comparer } from "mobx";
import { Item, itemToString } from "./SuperSelectItem";

export type SelectProps = {
  options: any[];
  getFilteredOptions?: (item: any) => Item[];
  onChange: (value: Item | any) => void;
  value: string | Item | Item[];
  placeholder?: string;
  disabled?: boolean;
  loading?: boolean;
  automationName?: string;
  clearable?: boolean;
  selectValue?: boolean;
  onFocus?: React.FocusEventHandler;
  onBlur?: React.FocusEventHandler;
  children?: (item: any) => any;
  allowCreate?: boolean;
};

export type SuperSelectProps = SelectProps & {
  multi?: boolean;
  defaultRenderer?: any;
  renderItem?: (item: Item | any) => any;
  children?: (item: Item | any) => any;
  renderValue?: ({ item }: { item: Item | any }) => any;
  clearAfter?: boolean;
  noPadding?: boolean;
};

function structureCompare<T>(a: T, b: T) {
  return a === b || comparer.structural(a, b);
}

function getValueFromProps(props: Partial<SelectProps>): Item | string {
  if (!(props.value && props.options)) {
    return "";
  }
  if (props.selectValue) {
    return props.value || ("" as any);
  } else {
    const index = props.options.findIndex((x) => structureCompare(x.key, props.value));
    return props.options[index] || (props.allowCreate && { key: props.value, title: props.value });
  }
}

function itemInputValue(props: Partial<SelectProps>): string {
  const valueFromProps: any = getValueFromProps(props);
  const value = valueFromProps && Array.isArray(valueFromProps) ? valueFromProps[0] : valueFromProps;
  const nilTitle = value && (value.title === undefined || value.title === null);
  if (value && value.icon && value.icon === "copy") {
    return nilTitle || value.title === "" ? "Empty String" : value.title;
  }
  return (!nilTitle ? itemToString(value) : value) || "";
}

function useSelectOptions(props: SuperSelectProps): [any[], any[]] {
  const [createOptions, setCreateOptions] = useState(null);
  useEffect(() => {
    setCreateOptions(props.options ? props.options.filter((x) => x.type === "create") : []);
  }, [props.options]);

  const [itemOptions, setItemOptions] = useState(null);
  useEffect(() => {
    setItemOptions(props.options ? props.options.filter((x) => x.type !== "create") : []);
  }, [props.options]);

  return [createOptions, itemOptions];
}
const renderRow = ({ data, index, style }: any) => React.cloneElement(data[index], { style });
const ListboxComponent: React.ComponentType<React.HTMLAttributes<HTMLElement>> = React.forwardRef(
  function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemCount = Array.isArray(children) ? children.length : 0;

    const outerElementType = React.useMemo(
      () => React.forwardRef((props2, ref2) => <div ref={ref2 as any} {...props2} {...other} />),
      []
    );

    return (
      <div ref={ref as any}>
        <FixedSizeList
          height={itemCount < 5 ? itemCount * 55 : 275}
          itemData={children}
          width="100%"
          outerElementType={outerElementType}
          innerElementType="ul"
          overscanCount={10}
          itemSize={53}
          itemCount={itemCount}
        >
          {renderRow}
        </FixedSizeList>
      </div>
    );
  }
);

function getChangeValue(value: any, props: SuperSelectProps) {
  if (props.multi) {
    return (value && props.selectValue ? value : value.map((x: any) => x.key)) || value;
  }
  if (
    value &&
    props.selectValue &&
    props.allowCreate &&
    (value.userCreated || !props.options.find((x) => x === value))
  ) {
    return value.userCreated ? value : { userCreated: true, key: value, title: value };
  }
  return props.selectValue ? value : value && value.key;
}
const useStyles: any = makeStyles((theme: Theme) => {
  return createStyles({
    root: {},
    paper: {
      borderColor: theme.palette.grey.A100,
      borderWidth: 1,
      border: "solid",
    },
  });
});

function optionIsItem(option: unknown): option is Item {
  if (!option || typeof option !== "object") {
    return false;
  }
  return Reflect.has(option, "key") || Reflect.has(option, "title");
}

export function getOptionLabel(options: any[], option: Item | unknown | string): string {
  if (options.length === 0) {
    return "";
  }
  const isString = typeof option === "string";
  if (!isString && optionIsItem(option)) {
    return itemToString(option as Item) as string;
  }
  const isObject = typeof option === "object";
  if (isObject || isString) {
    const title = options.find((x) => structureCompare(x.key, option))?.title;
    return title ?? (isString ? option : "");
  }
}

export function Select(props: SuperSelectProps) {
  const classes = useStyles();
  const [createOptions, itemOptions] = useSelectOptions(props);
  const [open, setOpen] = useState(false);
  const [clear, setClear] = useState(false);
  const displayedOptions = useRef<any[]>();
  const { placeholder, loading, onBlur, onFocus, disabled, children, allowCreate, multi, clearAfter } = props;
  const ItemRender = props.renderItem || props.defaultRenderer || SimpleItemView;
  const closePopper = () => setOpen(false);
  const onChange = (e: React.ChangeEvent<{}>, value: any, reason: string) => {
    if (props.clearAfter) {
      setClear(true);
    }
    props.onChange(getChangeValue(value, props));
    if (allowCreate || reason === "create" || reason === "blur") {
      closePopper();
    }
  };

  const shouldPropagateManualOptions = (value: any) =>
    !props.value ||
    (props.value !== value &&
      !(Reflect.has(props, "value") && Reflect.get(props, "value") === value) &&
      !(Reflect.has(props, "title") && Reflect.get(props, "title") === value));

  const optionLabel = useCallback((option: Item | unknown | string) => getOptionLabel(props.options, option), [
    props.options,
  ]);

  return (
    <ClickAwayListener onClickAway={closePopper}>
      <span className="super-select">
        <Autocomplete
          classes={classes}
          open={open}
          onClose={closePopper}
          clearOnEscape
          onOpen={() => setOpen(true)}
          multiple={multi}
          autoComplete
          autoHighlight={!allowCreate}
          autoSelect={allowCreate === false}
          filterSelectedOptions
          filterOptions={(options, state) => {
            const filtered = props.getFilteredOptions
              ? props.getFilteredOptions(state.inputValue)
              : fuzzyFilter(itemOptions, ["key", "title"], state.inputValue).concat(createOptions);
            const displayOptions =
              multi && props.value && Array.isArray(props.value)
                ? filtered.filter((x) => !(props.value as any).find((y: any) => structureCompare(x.key, y.key)))
                : filtered;
            displayedOptions.current = displayOptions;
            if (allowCreate) {
              displayOptions.push({ key: state.inputValue, title: state.inputValue, userCreated: true });
            }
            return displayOptions;
          }}
          disabled={disabled || loading}
          loading={loading}
          value={props.value || (multi && []) || null}
          options={props.options}
          ListboxComponent={ListboxComponent}
          onChange={onChange}
          getOptionLabel={optionLabel}
          renderOption={(props, option) => {
            if (option?.userCreated && !option?.key) {
              return <i {...props}>Start typing to create your own option</i>;
            }
            return <span {...props}>{children ? children(option) : <ItemRender item={option} />}</span>;
          }}
          isOptionEqualToValue={(option, value) => {
            const item = option?.key ?? option;
            return structureCompare(item, value);
          }}
          renderInput={(params) => {
            const inputProps = params.inputProps as any;
            if (!inputProps["value"] && props.value && !props.multi) {
              inputProps["value"] = itemInputValue(props);
            }
            if (props.clearAfter && clear && !props.value) {
              inputProps["value"] = "";
              setClear(false);
            }
            inputProps.className = classNames(inputProps.className, props.noPadding && "no-padding-select");

            const inputBlur = inputProps["onBlur"];
            inputProps["onBlur"] = (e: React.FocusEvent<HTMLInputElement>) => {
              const value = e.currentTarget.value;
              if (allowCreate && e && shouldPropagateManualOptions(value)) {
                onChange(e, value, "create");
              }
              if (inputBlur) {
                inputBlur();
              }
              if (onBlur) {
                onBlur(e);
              }
            };
            const inputFocus = inputProps["onFocus"];
            inputProps["onFocus"] = (e: React.FocusEvent<HTMLInputElement>) => {
              if (inputFocus) {
                inputFocus();
              }
              if (onFocus) {
                onFocus(e);
              }
            };
            inputProps["onKeyPress"] = (e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === "Enter") {
                e.preventDefault();
                if (allowCreate) {
                  const value = e.currentTarget.value;
                  if (allowCreate && e && shouldPropagateManualOptions(value)) {
                    onChange(e, value, "create");
                  }
                }
              }
            };
            inputProps["data-testid"] = `superselect-${props.automationName}`;

            return (
              <TextField
                className={classNames("select-input", { "has-value": !!props.value })}
                {...params}
                fullWidth
                placeholder={placeholder}
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? <CircularProgress color="primary" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            );
          }}
        />
      </span>
    </ClickAwayListener>
  );
}

export const SuperSelect: React.FC<SuperSelectProps> = (props) => <Select defaultRenderer={ItemView} {...props} />;
export const MultiSuperSelect: React.FC<SuperSelectProps> = (props) => <Select {...props} multi />;
