import React, { PropsWithChildren, useContext, useState } from "react";
import { UFormFieldProps } from "../views/UFormField";

type Context = { hiddenPaths: Record<string, boolean>; setHiddenPaths: (path: string, value: boolean) => void };
const HideFormFieldContext = React.createContext<Context>({
  hiddenPaths: {},
  setHiddenPaths: () => {},
});

export function HideFormFieldProvider({ children }: PropsWithChildren<{}>) {
  const [hiddenPaths, setHidden] = useState<Record<string, boolean>>({});
  const setHiddenPaths = (path: string, value: boolean) => setHidden({ ...hiddenPaths, [path]: value });
  return (
    <HideFormFieldContext.Provider value={{ hiddenPaths, setHiddenPaths }}>{children}</HideFormFieldContext.Provider>
  );
}

export function HideFormFieldConsumer(props: { children: (context: Context) => JSX.Element }) {
  const context = useContext(HideFormFieldContext);
  return props.children(context);
}

/**
 * Allow hiding a form field using context - this is for hooks that want to hide themselves
 */
function Hide({ children, input }: PropsWithChildren<UFormFieldProps>) {
  const { hiddenPaths } = useContext(HideFormFieldContext);
  if (hiddenPaths[input.path] === true) {
    return <span hidden={true}>{children}</span>;
  } else {
    return children;
  }
}

export default Hide;
