import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../routes/AppContext";
import { Observer } from "mobx-react-lite";
import { SimpleMetadata } from "../SimpleMetadata";

function CustomIconMetadataLogo(props: { metadata: SimpleMetadata; large?: boolean }) {
  const { customDataSources, api } = useContext(AppContext);
  const [image, setImage] = useState<any>(null);

  useEffect(() => {
    setImage(
      `${customDataSources.api.location}/${customDataSources.op}icon/${encodeURIComponent(props.metadata.iconOverride)}`
    );
  }, [props.metadata, customDataSources]);

  if (!image) {
    return null;
  }
  return (
    <Observer>
      {() => (
        <div className="logo">
          <span
            style={
              props.large
                ? { height: 88, width: 96, backgroundSize: "90px 90px" }
                : { height: 50, width: 50, backgroundSize: "50px 50px" }
            }
          >
            {/* can't send impersonate header with img */}
            <img src={api.impersonateOrganizationId ? "" : image} alt={props.metadata.displayName} />
          </span>
        </div>
      )}
    </Observer>
  );
}

export const MetadataLogo: React.FC<{
  metadata: SimpleMetadata;
  clazz?: string;
  logoClazz?: string;
  large?: boolean;
}> = ({ metadata, logoClazz, clazz, large }) => {
  if (metadata?.iconOverride) {
    return <CustomIconMetadataLogo metadata={metadata} large={large} />;
  }
  return (
    <div className="logo">
      <span
        className={`logo-${logoClazz || metadata?.clazz || clazz}`}
        style={large && { height: 88, width: 96, backgroundSize: "90px 90px" }}
      />
    </div>
  );
};
