import { observer } from "mobx-react";
import React from "react";
import { FormSelectInputProps, inputOnChange } from "../FormInputTypes";
import { action, computed, get, keys } from "mobx";
import { Button } from "../Button";
import FormSelectInput from "./FormSelectInput";
import { FormFieldRegistry } from "../../FormFieldRegistry";
import { Field as MField } from "../../type-definitions/mobx-react-form";

@observer
export class MultiSelectComponent extends React.Component<FormSelectInputProps> {
  componentDidMount() {
    const input = this.props.input;
    if (!(input.$value && input.$value.length)) {
      input.$value = [null];
    }

    if (input.$value && input.$value.length && input.fields.size === 0) {
      input.$value.forEach((x: unknown) => {
        input.add();
        input.$(input.fields.size - 1).set(x);
      });
    }
  }

  @action.bound
  _onAdd(e?: React.MouseEvent) {
    if (e) {
      e.stopPropagation();
    }
    const { input, options } = this.props;
    if (input.fields.size < options.length) {
      input.add({});

      // Set the new value to null, this is because add with null or undefined
      // does nothing.
      const keys = Object.keys(input.get());
      const last = keys[keys.length - 1];
      input.select(last).set(null);
    }
  }

  @action.bound
  _onDel(id: string, idx: string | number) {
    this.props.input.del(idx);
    const currentIndex = this.props.input.$value.findIndex((x: unknown) => x === id);
    this.props.input.$value[currentIndex] = null;
  }

  @computed
  get _unusedOptions() {
    const usedOptions = this.props.input.values();
    return this.props.options.filter((x) => !usedOptions.includes(x.key || x));
  }

  render() {
    const mainInput = this.props.input;
    const required = mainInput.$rules && mainInput.$rules.includes("required");
    return (
      <div className="fields-autocomplete-array">
        {keys(mainInput.fields).map((key: any) => {
          const childInput: MField = get(mainInput.fields, key);
          if (key === "0" && required) {
            childInput.set("rules", "required");
          }

          const childInputKey = childInput.name;
          const setValue = action((v: unknown) => {
            inputOnChange(childInput)(v);
            if (mainInput.metadata.changeHandler) {
              mainInput.metadata.changeHandler(mainInput.values());
            }
          });

          const filteredOptions = childInput.$value
            ? this.props.options
                .filter((x) => x.key === childInput.$value || x === childInput.$value)
                .concat(this._unusedOptions)
            : this._unusedOptions;

          return (
            <div key={key} className="flex-two-columns mapped-column">
              <div className="properties">
                {this.props.children({
                  ...this.props,
                  childInput,
                  unusedOptions: this._unusedOptions,
                  key: childInputKey,
                  filteredOptions,
                  setValue,
                })}
                {childInput.error && this.props.hideLabel !== false && (
                  <span className="description error-text">{childInput.error}</span>
                )}
              </div>
              <div className="buttons">
                {Number(key) > 0 && (
                  <span
                    className="icon-trash clickable"
                    onClick={action(() => this._onDel(childInput.$value, childInputKey))}
                  />
                )}
              </div>
            </div>
          );
        })}
        <Button
          type="secondary"
          automationName="nary-add-input"
          disabled={this.props.input.fields.size >= this.props.options.length}
          small
          onClick={this._onAdd}
        >
          Add
        </Button>
        {this.props.hideLabel !== false && !this.props.hideDescription && this.props.input.metadata.description && (
          <div className="description">{this.props.input.metadata.description}</div>
        )}
      </div>
    );
  }
}

export class MultiSelect extends React.Component<FormSelectInputProps> {
  render() {
    return (
      <MultiSelectComponent {...this.props}>
        {(props: any) => {
          const { childInput, filteredOptions, setValue } = props;
          return <FormSelectInput {...props} options={filteredOptions} input={childInput} onChange={setValue} />;
        }}
      </MultiSelectComponent>
    );
  }
}
export const register = (formFieldRegistry: FormFieldRegistry) =>
  formFieldRegistry.register("multi-select", (props) => (
    <MultiSelect {...props} hideDescription options={props.input.metadata.options} />
  ));
