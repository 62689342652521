export const UpsolverLocalStorage = {
  get: (): { [key: string]: any } => JSON.parse(window.localStorage.getItem("upsolver")) || {},
  upsertKey: (key: string, value: any): { [key: string]: any } => {
    const data = UpsolverLocalStorage.get();
    data[key] = value;
    UpsolverLocalStorage.set(data);
    return data;
  },
  removeKey: (key: string): { [key: string]: any } => {
    const data = UpsolverLocalStorage.get();
    delete data[key];
    UpsolverLocalStorage.set(data);
    return data;
  },
  set: (data: any): any => {
    window.localStorage.setItem("upsolver", JSON.stringify(data));
    return UpsolverLocalStorage.get();
  },
};
