import React from "react";
import { observer } from "mobx-react";
import { FormInputProps, inputOnChange } from "../FormInputTypes";
import { FormFieldRegistry } from "../../FormFieldRegistry";

import "../../../styles/time-range-picker.scss";
import { DateTimeMaskedInput } from "./FormDateTimeWithDefaults";

export const FormDateTimeInput: React.FC<FormInputProps> = observer(({ input, onChange, disabled }) => {
  const { readonly = undefined, label = undefined } = input.metadata || {};

  const handleDateChange = (newTime: string) => {
    inputOnChange(input, !!onChange)(newTime);
  };

  return (
    <DateTimeMaskedInput input={input} onChange={handleDateChange} onFocus={input.onFocus} onBlur={input.onBlur} />
  );
});
export const register = (formFieldRegistry: FormFieldRegistry) => {
  formFieldRegistry.register("date", (props) => {
    return <FormDateTimeInput {...props} />;
  });

  formFieldRegistry.register("datetime", (props) => {
    return <FormDateTimeInput {...props} />;
  });
};
