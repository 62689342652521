/* eslint-disable */
import { observer } from "mobx-react";
import React, { PropsWithChildren, useContext, useMemo, useState } from "react";
import { SettingsLinks, SettingsLinksProps, UserMenuLinks } from "./SettingsLinks";
import { UserPic } from "./UserPic";
import { GlobalContext } from "./GlobalContext";
import { AccountBar } from "./AccountBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "@mui/material/Menu";
import { useLoginStateProvider } from "./LoginStateProvider";
import { organizationExpired } from "./core/api/contracts/Organization";

export const SettingsButton = ({ user, org, showLinks }: SettingsLinksProps) => {
  const [el, setEl] = useState<SVGSVGElement>();
  if (!user) {
    return null;
  }
  return (
    <span style={{ cursor: "pointer" }}>
      <MenuIcon style={{ color: "white" }} fontSize="large" onClick={(e) => setEl(e.currentTarget)} />
      <Menu anchorEl={el} keepMounted open={Boolean(el)} onClick={() => setEl(null)} onClose={() => setEl(null)}>
        <span>
          <SettingsLinks org={org} user={user} showLinks={showLinks} />
        </span>
      </Menu>
    </span>
  );
};

type UserSettingsButtonProps = { showImpersonate?: React.MouseEventHandler };
export const UserSettingsButton = ({ showImpersonate }: UserSettingsButtonProps) => {
  const { auth } = useContext(GlobalContext);
  const { logout, clearImpersonate } = useLoginStateProvider();
  const user = auth.user || { name: "" };
  const [el, setEl] = useState<HTMLButtonElement>();
  const unimpersonate = () => {
    clearImpersonate();
    window.location.reload();
  };
  return (
    <Box mt={1}>
      <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={(e) => setEl(e.currentTarget)} size="large">
        <UserPic userName={user.name} title={user.name} />
      </IconButton>
      <Menu anchorEl={el} keepMounted open={Boolean(el)} onClick={() => setEl(null)} onClose={() => setEl(null)}>
        <span>
          <UserMenuLinks
            user={user}
            onImpersonate={showImpersonate}
            onClearImpersonate={unimpersonate}
            onLogout={logout}
          />
        </span>
      </Menu>
    </Box>
  );
};

export interface UserMenuProps {
  showLinks: boolean;
  logout: React.MouseEventHandler;
  user: any;
  org: any;
  showImpersonate: React.MouseEventHandler;
  clearImpersonate: React.MouseEventHandler;
  userTitle: string;
  impersonationText: any;
}

export const TopBar = observer((props: PropsWithChildren<any>) => {
  const context = useContext(GlobalContext);
  const loginState = useLoginStateProvider();

  const userTitle = useMemo(() => loginState?.status?.loginReply?.user?.name ?? "", [loginState.status]);
  const trialOver = useMemo(
    () =>
      loginState?.status?.selectedOrganization ? organizationExpired(loginState.status.selectedOrganization) : false,
    [loginState.status]
  );

  const { auth } = context;
  const { user, outOfDate, currentOrganization } = auth;
  const org = currentOrganization;

  if (!user || !org) {
    return null;
  }

  return (
    <AccountBar
      {...props}
      outOfDate={outOfDate}
      auth={auth}
      user={user}
      org={org}
      userTitle={userTitle}
      showLinks={!trialOver}
      onLogout={loginState.logout}
      title={props.children}
    />
  );
});
