import { TreeEntry } from "./views/Tree";
import { FlattenedTree, TreeEntryInfo } from "./TreeContext";
import { TreeCheckBoxState } from "./TreeCheckboxState";
import { UField } from "./api/contracts/UField";
import { treeFeaturesWord } from "./Utils";
import { isLookupFeature } from "./api/contracts/isLookupFeature";

export function treeEntryInfo<TEntry, TEntryKey>(
  entry: TreeEntry<TEntry>,
  entryChecked: (entry: TreeEntry<TEntry>) => boolean,
  descendantFilter: (entry: TreeEntry<TEntry>) => boolean,
  countOnlyCheckedDescendants: boolean
): TreeEntryInfo {
  let checked: TreeCheckBoxState = "unchecked";
  let descendantsCount = 0;
  let checkedCount =
    entryChecked(entry) && (!entry.children || entry.children.every((x) => x.children && x.children.length > 0))
      ? 1
      : 0;
  traverseItemTree([entry], (item) => {
    if (item.children) {
      const descendantItems = item.children.filter(descendantFilter);
      descendantsCount += descendantItems.length;
      if (countOnlyCheckedDescendants) {
        checkedCount += descendantItems.filter(entryChecked).length;
      }
    }
  });

  if (checkedCount > 0) {
    checked = checkedCount >= descendantsCount ? "checked" : "partial";
  }

  return { checked, descendantsCount };
}

export function traverseItemTree<T>(items: TreeEntry<T>[], f: (entry: TreeEntry<T>) => void) {
  if (items) {
    items.forEach((item) => {
      f(item);
      if (item.children) {
        traverseItemTree(item.children, f);
      }
    });
  }
}

export function escapableTraverseItemTree<T>(items: TreeEntry<T>[], f: (entry: TreeEntry<T>) => boolean): void {
  if (items?.length > 0) {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      const keepGoing = f(item);
      if (!keepGoing) {
        break;
      }
      if (item.children) {
        escapableTraverseItemTree(item.children, f);
      }
    }
  }
}

export const iterateAllItemsWithParents = <T extends { children?: T[] }>(
  items: T[],
  f: (parents: T[], item: T) => void
): void => {
  const iterateAllItems = (items: T[], parents: T[], f: (parents: T[], item: T) => void): void => {
    items.forEach((item) => {
      f(parents, item);

      if (item.children) {
        parents.push(item);
        iterateAllItems(item.children, parents, f);
        parents.pop();
      }
    });
  };

  if (items) {
    iterateAllItems(items, [], f);
  }
};

export function findTreeContextEntry<T>(
  tree: TreeEntry<T>[],
  key: string,
  onTraversedItem?: (entry: TreeEntry<T>) => void
) {
  let result: T = null;
  let i = 0;
  while (i < tree.length && result === null) {
    const rootEntry = tree[i];
    escapableTraverseItemTree([rootEntry], (entry) => {
      if (onTraversedItem) {
        onTraversedItem(entry);
      }
      if (entry.key === key) {
        result = entry;
        return false;
      } else {
        return true;
      }
    });
    i++;
  }
  return result;
}

export const getUnfilteredFlattenedTreePaths = (
  nodes: TreeEntry[],
  expandedKeys: { [key: string]: boolean },
  parents: string[] = []
): FlattenedTree => {
  let children: { [key: string]: any[] } = {};
  const paths = [];

  for (const node of nodes) {
    if (node.children) {
      children[node.key] = node.children;
    }
    if (!node.children || !node.children.length || !expandedKeys[node.key]) {
      paths.push(parents.concat(node.key));
    } else {
      paths.push(parents.concat(node.key));
      const flattenedTreePaths = getUnfilteredFlattenedTreePaths(node.children, expandedKeys, [...parents, node.key]);
      paths.push(...flattenedTreePaths.paths);
      children = { ...children, ...flattenedTreePaths.children };
    }
  }

  return { paths, children };
};
export const getFlattenedTreePaths = (
  nodes: TreeEntry[],
  expandedKeys: { [key: string]: boolean },
  filteredKeys: Set<string>,
  parents: string[] = []
): { paths: string[][]; children: { [key: string]: any[] } } => {
  let children: { [key: string]: any[] } = {};
  const paths = [];

  for (const node of nodes) {
    if (filteredKeys.has(node.key)) {
      if (node.children) {
        children[node.key] = node.children;
      }
      if (!node.children || !node.children.length || !expandedKeys[node.key]) {
        paths.push(parents.concat(node.key));
      } else {
        paths.push(parents.concat(node.key));
        const flattenedTreePaths = getFlattenedTreePaths(node.children, expandedKeys, filteredKeys, [
          ...parents,
          node.key,
        ]);
        paths.push(...flattenedTreePaths.paths);
        children = { ...children, ...flattenedTreePaths.children };
      }
    }
  }

  return { paths, children };
};

export function getFieldsTreeFeaturePrefix(field: UField) {
  return isLookupFeature(field.feature.feature) ? "Lookups" : treeFeaturesWord;
}
