import { UpsolverRemoteStorage } from "./core/UpsolverRemoteStorage";
import { Api } from "./core/api/Api";
import { User } from "./core/api/contracts/User";
import { stubZendeskWidget, ZendeskWidget } from "./core/zendesk/zendesk";

export type SupportSystem = "zendesk" | "intercom";

interface OrgPreferences {
  supportSystem: SupportSystem;
}

export class SupportChat {
  private readonly upsolverRemoteStorage: UpsolverRemoteStorage<OrgPreferences>;

  constructor(private api: Api) {
    this.upsolverRemoteStorage = new UpsolverRemoteStorage<OrgPreferences>(this.api, "organization");
  }

  async setSupportSystem(system: SupportSystem): Promise<void> {
    await this.upsolverRemoteStorage.upsertKey("supportSystem", system);
  }

  private get zendesk(): ZendeskWidget {
    return window.zE ?? stubZendeskWidget;
  }

  authorize() {
    this.zendesk(() => {
      this.zendesk("webWidget", "helpCenter:reauthenticate");
    });
  }

  logout() {
    this.zendesk(() => {
      this.zendesk("webWidget", "logout");
    });
  }

  open() {
    this.zendesk("webWidget", "open");
  }

  sendMessage(message: string) {
    this.zendesk("webWidget", "open");
    const display = this.zendesk("webWidget:get", "display");
    if (display === "contactForm") {
      // there's no way of filling the content of the contactForm message because zendesk
    } else if (display === "chat") {
      // this.zendesk("webWidget", "chat:send", message);
    }
  }

  boot(user: User & { revision: string }) {
    this.zendesk("webWidget", "prefill", {
      name: {
        value: user.name,
        readOnly: true, // optional
      },
      email: {
        value: user.email?.trim(),
        readOnly: true, // optional
      },
      phone: {
        value: user.phone,
        readOnly: true // optional
      },
      organization: {
        value: user.organization,
        readOnly: true
      }
    });
    this.zendesk("webWidget", "identify", user);
    this.zendesk("webWidget", "updateSettings", {
      contactForm: {
        fields: [{ id: 360033791532, prefill: { "*": user.organization } }],
        ticketForms:  [{ id: 360001016152 }],
      },
    });

    console.log(window.zESettings);
  }

  show() {
    this.zendesk("webWidget", "show");
  }

  hide() {
    this.zendesk("webWidget", "hide");
  }
}
