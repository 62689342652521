import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import { ExternalIntegrationState, integrationStates, methodsOrDefault } from "./integrations-store";
import { createFormFields, deserializeFormValues } from "../core/views/MetadataForm";
import { FormView } from "../core/views/UForm";
import AWSLogo from "../animations/amazon-logo.png";
import UpsolverLogo from "../animations/upsolver-logo.png";
import { AppContext } from "../routes/AppContext";
import { Button } from "../core/views/Button";
import { UDocumentTitle } from "../core/UDocumentTitle";
import { Header, Main, Page, Sidebar } from "../core/views/layout/layouts";
import Modal from "../core/views/Modal";
import { CopyButton } from "../core/views/CopyText";
import { Spin } from "../core/views/Spin";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { TopBarActions, TopBarContent, TopBarStandaloneTitle } from "../core/views/TopBarContent";
import Box from "@mui/material/Box";
import RadioButtonChecked from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUnchecked from "@mui/icons-material/RadioButtonUnchecked";
import Typography from "@mui/material/Typography";
import { SupportedFeatureSwitch } from "../core/views/SupportedFeatureSwitch";
import { GlobalTitle } from "../core/views/GlobalTitle";
import { useHistory, useParams } from "react-router";
import { useLoginStateProvider } from "../LoginStateProvider";
import UserTrackingEvent from "../analytics/UserTrackingEvent";
import UserTrackingFormValueChange from "../analytics/UserTrackingFormValueChange";

import { blue, grey } from '@mui/material/colors';

const azureLogo = (
  <svg width="78" height="78" viewBox="0 0 122 97" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M66.6559 0L30.6905 31.0776L0 85.9691H27.672L66.6559 0ZM71.4402 7.27124L56.0912 50.3798L85.5215 87.2248L28.4267 97H122L71.4402 7.27124Z"
      fill="#0072C6"
    />
  </svg>
);

type DoneProps = { done: boolean };
export const AwsCloudIntegrationAnimation = ({ done }: DoneProps) => (
  <CloudIntegrationAnimation done={done} cloudLogo={<img src={AWSLogo} alt="AWS" />} />
);

export const AzureCloudIntegrationAnimation = ({ done }: DoneProps) => (
  <CloudIntegrationAnimation done={done} cloudLogo={azureLogo} />
);

interface CloudIntegrationAnimationProps extends DoneProps {
  cloudLogo: React.ReactNode;
  className?: string;
}

function CloudIntegrationAnimation(props: CloudIntegrationAnimationProps) {
  return (
    <div className={classNames("aws-integration-animation", props.className)}>
      <div className={classNames("integration-done", { love: props.done })}>
        <img src={UpsolverLogo} alt="Upsolver" className="upsolver-logo" />

        <div className="lines">
          <span className="line" />
          <span className="line line1" />
          <span className="line line2" />
          <svg id="heart" xmlns="http://www.w3.org/2000/svg" width="53px" height="47px">
            <path
              fillRule="evenodd"
              fill="rgb(255, 55, 55)"
              d="M16.332,8.821 C12.350,8.821 9.111,12.036 9.111,15.989 C9.111,16.900 8.368,17.637 7.450,17.637 C6.532,17.637 5.789,16.900 5.789,15.989 C5.789,10.218 10.519,5.524 16.332,5.524 C17.250,5.524 17.993,6.261 17.993,7.172 C17.993,8.083 17.250,8.821 16.332,8.821 ZM48.754,4.297 C46.023,1.533 42.364,0.009 38.449,0.009 C34.448,0.009 30.685,1.589 27.853,4.456 L26.501,5.826 L25.148,4.456 C22.316,1.589 18.552,0.009 14.553,0.009 C10.637,0.009 6.976,1.534 4.245,4.297 C-1.475,10.087 -1.406,19.581 4.401,25.456 L25.119,46.432 C25.501,46.817 26.000,47.009 26.502,47.009 C27.001,47.009 27.501,46.816 27.884,46.432 L48.602,25.456 C54.406,19.577 54.474,10.087 48.754,4.297 Z"
            />
          </svg>
          <span className="circle" />
        </div>
        <span className="integration-logo">{props.cloudLogo}</span>
      </div>
    </div>
  );
}

export type IntegrationLaunchProps = {
  title: React.ReactNode;
  blurb?: any;
  checking?: boolean;
  completed?: boolean;
  children?: React.ReactNode;
  actions?: React.ReactNode;
};

export class IntegrationLaunch extends React.Component<IntegrationLaunchProps> {
  render() {
    const { title, children, actions, checking, completed } = this.props;
    return (
      <div className="integration-form create-feature-form">
        <div className="main">
          <div>
            {title}
            <p>{this.props.blurb}</p>
            {checking || completed ? (
              <div>
                <AwsCloudIntegrationAnimation done={completed} />
                <div className={classNames("text", { completed })}>
                  <p className="running-blurb">
                    In order to continue, please create the CloudFormation stack. Once the integration is complete and
                    the CloudFormation script finishes successfully, this message will automatically disappear.
                  </p>
                  <p>You can click launch to open a {checking && "new "} CloudFormation tab.</p>
                </div>
              </div>
            ) : (
              <ul className="details">{children}</ul>
            )}
          </div>
          <div className="actions">{actions}</div>
        </div>
      </div>
    );
  }
}

/*
 * This component gets the parent form that the user fills (with region and some flags) and wraps it in an
 * integration with bucket and kinesis streams (filled from local form) to CloudFormation.
 *
 * Pops the copy modal whenever we have the url.
 */
const SendToDevops: React.FC<{
  getIntegrationPayload: () => any;
  onClose: () => void;
  currentIntegration: ExternalIntegrationState;
  refreshIntegration: () => void;
}> = (props) => {
  const context = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [currentIntegration, setCurrentIntegration] = useState(props.currentIntegration);
  const [generatedLink, setLink] = useState("");
  const bucketAndStreamsMetadata = context.metadataStore
    .Metadata()
    .integrations.find((f) => f.clazz === "AwsSpecificIntegrationRequest");

  const form = createFormFields(bucketAndStreamsMetadata, {
    values: currentIntegration ? currentIntegration.externalIntegration : undefined,
    formMetadata: context.metadataStore.getFormMetadata(),
    getDefaultValue: (x) => context.metadataStore.defaultValue(x),
  }).createForm();

  const generateLink = () => {
    setLoading(true);

    const payload = {
      ...deserializeFormValues(bucketAndStreamsMetadata, context.metadataStore.getFormMetadata(), form.values()),
      original: {
        ...props.getIntegrationPayload(),
        id: Math.floor(Math.random() * Date.now()),
      },
    };

    setCurrentIntegration({ url: "", externalIntegration: payload });

    context.integrations
      .startIntegration(payload, "basic")
      .then((url) => {
        setLoading(false);
        setLink(url);
        props.refreshIntegration();
      })
      .catch((err) => {
        if (err && err.response && err.response.body) {
          form.onValidateError(err.response.body);
        }
      });
  };

  return generatedLink.length > 0 ? (
    <div className="integration-copy-link">
      <h2>Send this link to someone on your team</h2>
      <CopyLink generatedLink={generatedLink} />
    </div>
  ) : (
    <div>
      <h1>Send to Team Member</h1>
      <p>Send to to your team member who is responsible for your AWS account</p>
      <hr />
      <FormView onSuccess={generateLink} submitText="Generate Link" loading={loading} form={form} />
    </div>
  );
};

const CopyLink = ({ generatedLink }: { generatedLink: string }) => {
  return (
    <div>
      <TextField disabled value={generatedLink} />
      <CopyButton copyText="COPY" value={generatedLink} />
    </div>
  );
};

function ChoiceBox(props: { link: string; selected: boolean; text: React.ReactNode; title: React.ReactNode }) {
  const borderColor = props.selected ? blue["300"] : grey["300"];
  return (
    <Link to={props.link} role="radio" aria-checked={props.selected}>
      <Box style={{ border: `1px solid ${borderColor}` }} p={2}>
        <Box display="flex" alignItems="center">
          <Box pr={0.5} pt={0.5}>
            {props.selected ? <RadioButtonChecked /> : <RadioButtonUnchecked color="disabled" />}
          </Box>
          <Box fontWeight="bold" color={props.selected ? "primary" : "text.primary"}>
            {props.title}
          </Box>
        </Box>
        <Box color="text.primary">{props.text}</Box>
      </Box>
    </Link>
  );
}

const DeploymentTypeText = ({ multi }: { multi?: boolean }) => (
  <>
    Deploy servers to <b>{multi ? "your" : "our"}</b> AWS account for processing and running jobs.
  </>
);

const IntegrationExplain = () => (
  <p>
    Data will never be saved on our AWS account, we will create an S3 bucket in your AWS account to store all data
    connected or created by Upsolver.
  </p>
);

const integrateText = "Integrate Upsolver with your AWS account";
type AwsIntegrationLaunchProps = { hideTitle?: React.ReactNode };
export const AwsIntegrationLaunch: React.FC<AwsIntegrationLaunchProps> = observer((props) => {
  const { hideTitle } = props;
  const history = useHistory();
  const { kind } = useParams<{ kind: "basic" | "advanced" }>();
  const advancedMode = kind === "advanced";
  const context = useContext(AppContext);
  const metadata = useRef(
    context.metadataStore.Metadata().integrations.find((f) => f.clazz === "AwsIntegrationRequest")
  );
  const methods = methodsOrDefault(metadata.current.editorInformation["methods"]);
  const checking = context.integrations.state.current === integrationStates.STARTED;
  const completed = context.integrations.state.current === integrationStates.COMPLETED;
  const id: number = Math.floor(Math.random() * Date.now());
  const [modalOpen, setModalOpen] = useState(false);
  const [currentIntegration, setCurrentIntegration] = useState<ExternalIntegrationState>(null);
  const [loading, setLoading] = useState(true);
  const [canceling, setCanceling] = useState(false);
  const [launched, setLaunched] = useState(false);
  const [waitingForDone, setDone] = useState(false);
  const [startingIntegration, setStartingIntegration] = useState(false);
  const [canSendToDevops, setCanSendToDevops] = useState(true);
  const { reloadUser } = useLoginStateProvider();
  const form = useMemo(
    () =>
      createFormFields(metadata.current, {
        hiddenFields: { clusterDeploymentMethod: !advancedMode },
        formMetadata: context.metadataStore.getFormMetadata(),
        getDefaultValue: (x) => context.metadataStore.defaultValue(x),
      }).createForm(),
    [advancedMode, context.metadataStore]
  );

  const cancelCallback = async () => {
    setCanceling(true);
    try {
      if (await context.integrations.cancel(currentIntegration, awsIntegrationClazz)) {
        setCurrentIntegration(null);
        setDone(false);
        setStartingIntegration(false);
        setLaunched(false);
      }
    } finally {
      setCanceling(false);
    }
  };
  const doneCallback = async () => {
    context.integrations.done();
    await reloadUser();
    setDone(true);
  };

  const deserializeForm = () => {
    const formValues = form.values();
    const payload = advancedMode
      ? formValues
      : Object.assign({}, formValues, { clusterDeploymentMethod: { clazz: "CloudDeploymentMethod" } });
    return Object.assign(deserializeFormValues(metadata.current, context.metadataStore.getFormMetadata(), payload), {
      id,
    });
  };
  const [link, setLink] = useState<string>();

  const launch = () => {
    setStartingIntegration(true);
    const payload = deserializeForm();
    context.integrations
      .startIntegration(payload, methods[0])
      .then((url) => {
        setLink(url);
        setCurrentIntegration(payload);
        setStartingIntegration(false);
      })
      .catch((err) => {
        setStartingIntegration(false);
        if (err && err.response && err.response.body) {
          form.onValidateError(err.response.body);
        }
      });
  };

  useEffect(() => {
    context.integrations
      .setRequired({ payload: { clazz: "AwsIntegrationRequest" }, title: "aws", description: "" })
      .then(() => {
        return context.api
          .getUserOrganizations()
          .then((orgs) => context.auth.setLoginOrg(orgs.find((x) => x.id === context.auth.currentOrganization.id)));
      })
      .then(() => {
        const locationState = history.location?.state;
        const pushback = locationState?.postIntegrationPushback;
        if (pushback) {
          const pushbackState = Object.assign({}, locationState);
          delete pushbackState["postIntegrationPushback"];
          history.replace(pushback, pushbackState);
        } else {
          history.replace("/inputs/create");
        }
      });

    context.integrations.pendingIntegration().then((pendingIntegration) => {
      setCurrentIntegration(pendingIntegration);
      setLoading(false);
      if (pendingIntegration) {
        setLaunched(true);
      }
    });
  }, [context.integrations, history]);

  useEffect(() => {
    if (form) {
      const field = form.tryGetField("clusterDeploymentMethod");
      if (field && field.metadata.hidden === advancedMode) {
        field.metadata.hidden = !advancedMode;
      }
    }
  }, [advancedMode, form]);

  const tryShowDevopsModal = () => {
    context.integrations
      .validateIntegration(deserializeForm(), methods[0])
      .then(() => {
        setCanSendToDevops(true);
        setModalOpen(true);
      })
      .catch((err) => {
        if (err && err.response && err.response.body) {
          form.onValidateError(err.response.body);
          setCanSendToDevops(false);
        }
      });
  };

  return (
    <Page reversed padded>
      {!hideTitle && (
        <Header>
          <div className="flex-two-columns">
            <h2>{integrateText}</h2>
            <div className="logo">
              <span className="logo-AWS" />
            </div>
          </div>
        </Header>
      )}
      <Main className="aws-integration-form">
        {hideTitle && <br />}
        <Spin spinning={loading}>
          {launched || checking || completed ? (
            <AwsIntegrationWaiter
              onCancel={cancelCallback}
              canceling={canceling}
              onDone={doneCallback}
              onLaunch={() => {
                setLaunched(true);
              }}
              launchLink={currentIntegration?.url || link}
              completed={completed}
              checking={checking || launched}
              doneing={waitingForDone}
            />
          ) : (
            <React.Fragment>
              <IntegrationExplain />
              <FormView
                form={form}
                disabled={startingIntegration}
                loading={startingIntegration}
                onSuccess={launch}
                submitText="Continue"
              />
            </React.Fragment>
          )}
        </Spin>
      </Main>
      <Sidebar>
        <Typography variant="h5">Send to Team Member</Typography>
        <Box py={1}>
          If you don’t have access to your AWS account, you can easily send this page to someone else on your team.
        </Box>
        <Button onClick={tryShowDevopsModal} type="secondary">
          Send this page
        </Button>
        {!canSendToDevops && (
          <div className="devops-error">Please fix the errors in the fields to the left and try again</div>
        )}
        <Modal onRequestClose={() => setModalOpen(false)} isOpen={modalOpen}>
          <SendToDevops
            currentIntegration={currentIntegration}
            getIntegrationPayload={deserializeForm}
            onClose={() => {
              setCurrentIntegration(null);
              setModalOpen(false);
            }}
            refreshIntegration={() => {}}
          />
        </Modal>
      </Sidebar>
    </Page>
  );
});

const AwsIntegrationWaiter = ({
  onDone,
  onLaunch,
  launchLink,
  onCancel,
  canceling,
  checking,
  completed,
  doneing,
}: any) => {
  const primaryButton = completed ? (
    <Button onClick={onDone} loading={doneing} type="primary">
      Done
    </Button>
  ) : (
    <a href={launchLink} target="_blank" onClick={onLaunch} className="link-button">
      Launch
    </a>
  );

  const actions = (
    <React.Fragment>
      {primaryButton}
      {!completed && (
        <SupportedFeatureSwitch feature={"cancel-integrations"} fallback={null}>
          <Button onClick={onCancel} type="cancel" loading={canceling}>
            Cancel
          </Button>
        </SupportedFeatureSwitch>
      )}
    </React.Fragment>
  );

  const title = completed ? "completed" : "in progress";
  return (
    <IntegrationLaunch
      completed={completed}
      checking={checking}
      title={<h1>Integration {title}</h1>}
      actions={actions}
    />
  );
};

const AwsIntegPageTitle = () => (
  <TopBarContent>
    <TopBarStandaloneTitle title={integrateText} />
    <TopBarActions>
      <div className="logo">
        <span className="logo-AWS" />
      </div>
    </TopBarActions>
  </TopBarContent>
);

export const awsIntegrationClazz = "AwsIntegration";
export function AwsIntegrationPage() {
  return (
    <UserTrackingEvent event="integration-started">
      <UDocumentTitle title="💕 AWS">
        <GlobalTitle title={<AwsIntegPageTitle />}>
          <AwsIntegrationLaunch hideTitle />
        </GlobalTitle>
      </UDocumentTitle>
    </UserTrackingEvent>
  );
}
