import React, { useEffect } from "react";
import { decorateOnChange, FormSelectInputProps } from "../../FormInputTypes";
import { observer } from "mobx-react-lite";
import FormItemDecorator from "../FormItemDecorator";
import { FormChoicesInputProps } from "../FormChoicesInput";
import { FormFieldRegistry } from "../../../FormFieldRegistry";
import styles from "./input.module.scss";

export const FormRadioInput: React.FC<FormSelectInputProps & FormChoicesInputProps> = observer((props) => {
  const input = props.input;
  useEffect(() => {
    if (props.options.length > 0) {
      decorateOnChange(input, props.onChange, true)(props.input.$value || props.options[0].id);
    }
  }, [input, props.options]);
  return (
    <FormItemDecorator input={input} hideLabel={props.hideLabel ?? true}>
      <div>
        {props.options.map((o, i) => {
          const choose = () => decorateOnChange(input, props.onChange, true)(o.id);
          const checked = input.$value === o.id;
          const id = `${input.path}-${o.id}`;
          return (
            <div className={styles.item} key={id}>
              <input type="radio" checked={checked} onClick={choose} id={id} />
              <label className={styles.label} htmlFor={id}>
                {o.label}
              </label>
            </div>
          );
        })}
      </div>
    </FormItemDecorator>
  );
});

FormRadioInput.displayName = "FormRadioInput";

export const register = (formFieldRegistry: FormFieldRegistry) =>
  formFieldRegistry.register("radio", (props) => <FormRadioInput {...props} options={props.input.metadata.options} />);
