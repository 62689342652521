import moment, { Moment } from "moment";

export const dateFormat = "YYYY-MM-DD";
export const shortTimeFormat = "HH:mm";
export const timeWithSeconds = `${shortTimeFormat}:ss`;
export const timeWithMillis = `${timeWithSeconds}.SSS`;
export const shortDateTimeFormat = `${dateFormat} ${shortTimeFormat}`;
export const dateTimeFormat = `${dateFormat} ${timeWithSeconds}`;
export const fullDateTimeWithZoneFormat = `${dateFormat} ${timeWithMillis}Z`;
export const fullDateTimeFormat = `${dateFormat} ${timeWithMillis}`;
export const shortDateTimeZoneFormat = `${shortDateTimeFormat} Z`;
export const dateFormatPicker = "yyyy-MM-dd";
export const shortDateTimeFormatPicker = `${dateFormatPicker} ${shortTimeFormat}`;
export const dateTimeFormatPicker = `${dateFormatPicker} ${timeWithSeconds}`;

export const formatTime = (date: Moment) => {
  // check in user's timezone instead of utc
  return moment(date).startOf("day").isSame(date) ? date.format(shortDateTimeFormat) : date.format(dateTimeFormat);
};
