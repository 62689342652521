import { Theme } from "@mui/material/styles";

import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

export const roundedInputStyle = (theme: Theme) => ({
  "&:not(:last-child)": {
    marginRight: theme.spacing(2),
  },
  paddingLeft: theme.spacing(1),
  borderRadius: "22.5px",
  border: "1px solid",
  borderColor: "#d5d5d5",
  backgroundColor: theme.palette.background.paper,
  width: 150,
  fontSize: theme.typography.body1.fontSize,
  [theme.breakpoints.up("lg")]: {
    borderColorColor: theme.palette.primary.light,
    width: 250,
  },
});

const useSharedStyles: any = makeStyles((theme) =>
  createStyles({
    clickable: {
      cursor: "pointer",
    },
    disabled: {
      cursor: "not-allowed",
    },
    roundedInput: roundedInputStyle(theme),
  })
);

export default useSharedStyles;
