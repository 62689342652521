import { observable, toJS } from "mobx";
import { UField } from "./UField";

export class TypeName {
  @observable name: string;
  @observable isArray: boolean;
  label: string;
  subFields: UField[];

  constructor(name: string, isArray: boolean, subFields: UField[]) {
    this.name = name;
    this.isArray = isArray;
    this.label = name;
    this.subFields = subFields ? toJS(subFields) : [];
  }

  static equals(a: TypeName, b: TypeName): boolean {
    return a.name === b.name && a.isArray === b.isArray && a.subFields.length === b.subFields.length;
  }

  static untypedEquals(a: TypeName, b: TypeName, matchArrays: boolean): boolean {
    return a.name === b.name && (!matchArrays || a.isArray === b.isArray);
  }

  static fromJSON(json: any) {
    return new TypeName(json.name, json.isArray, json.subFields);
  }

  static get Instant(): TypeName {
    return new TypeName("instant", false, []);
  }

  static get Number(): TypeName {
    return new TypeName("number", false, []);
  }

  static get String(): TypeName {
    return new TypeName("string", false, []);
  }

  static get Boolean(): TypeName {
    return new TypeName("boolean", false, []);
  }

  static get Date(): TypeName {
    return new TypeName("date", false, []);
  }
}

/**
 * Checks assignable types
 *
 * @param t My Type
 */
const typeFilter = (t: TypeName) => {
  if (t.isArray) {
    return (tt: TypeName) => tt.name === "object" || tt.name === "any" || tt.name === t.name;
  } else {
    return (tt: TypeName) => tt.name === t.name || tt.name === "any";
  }
};
export { typeFilter };
