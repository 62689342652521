import { GridData } from "./GridData";
import { FieldPresentation } from "./FieldPresentation";

export const enrichSamplesGrid = (x: TableSampleResponse, extraFields: FieldPresentation[]): TableSampleResponse => {
  x.fields = extraFields.map((x) => x.field).slice();
  const mapping = new Map();
  let dupCount = 0;
  x.columns.forEach((y, i) => {
    if (!mapping.has(y.name)) {
      mapping.set(y.name, i - dupCount);
    } else {
      dupCount++;
    }
    y.index = mapping.get(y.name);
  });
  return x;
};
export type TableSampleResponse = GridData & { filtersEnabled: boolean };
