import React, { HTMLAttributes, useCallback, useMemo, useRef, useState } from "react";

/**
 * @param {(files: File[]) => void} onFiles - callback to call with files
 * @param {boolean} fireOnSameFile - if users re-selects files with the sames name still call onFiles
 * @param {boolean} multiple - accept multiple files
 * @param {string[]} accept - allowed file extenstions
 * @returns {{inputProps: React.HTMLAttributes<HTMLInputElement> & {ref: React.MutableRefObject<HTMLInputElement | undefined>}, loading: boolean, fileNames: string[]}}
 */
export function useFileUpload(
  onFiles: (files: File[]) => void | Promise<void>,
  fireOnSameFile: boolean,
  multiple?: boolean,
  accept?: string[]
) {
  const [fileNames, setFileNames] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const uploadRef = useRef<HTMLInputElement>();

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setLoading(true);
      const files = Array.from(e.currentTarget.files).filter((f) => f.size > 0);
      if (files.length === 0) {
        alert("All of the selected files are empty, please choose files with content.");
        e.target.value = null;
      } else {
        setFileNames(files.map((x) => x.name));
        if (fireOnSameFile) {
          uploadRef.current.value = null;
        }
        onFiles(files);
      }
      setLoading(false);
    },
    [onFiles, fireOnSameFile]
  );

  const inputProps: HTMLAttributes<HTMLInputElement> & {
    ref: React.MutableRefObject<HTMLInputElement | undefined>;
  } = useMemo(
    () => ({
      onChange,
      ref: uploadRef,
      accept: accept ? accept.map((f) => "." + f).join(",") + ", *" : "*",
      type: "file",
      title: "file upload",
      multiple,
    }),
    [onChange, accept, multiple]
  );

  return { fileNames, loading, inputProps };
}
