import React, { PropsWithChildren, useContext, useMemo } from "react";
import getPath from "lodash/get";

export type EntityFieldValueProvider = (fieldPath: string) => unknown | null;
const FromEntityContext = React.createContext<{ getFieldValue: EntityFieldValueProvider }>({
  getFieldValue: () => null,
});

function fieldValueGetter(target: any) {
  return function (fieldName: string) {
    return getPath(target, fieldName);
  };
}

export function FormEntityValueProvider(props: PropsWithChildren<{ entity: any }>) {
  const provider = useMemo(() => ({ getFieldValue: fieldValueGetter(props.entity) }), [props.entity]);
  return <FromEntityContext.Provider value={provider}>{props.children}</FromEntityContext.Provider>;
}

export function useFormEntityValueProvider(): EntityFieldValueProvider {
  const context = useContext(FromEntityContext);
  return context.getFieldValue;
}
