import React, { useEffect, useMemo, useState } from "react";
import { Toggle } from "../../core/views/Toggle";
import { USelect } from "../../core/views/Select";
import useSharedStyles from "../../styles/useSharedStyles";
import Box from "@mui/material/Box";
import Input, { InputProps } from "@mui/material/Input";
import { SupportedFeatureSwitch } from "../../core/views/SupportedFeatureSwitch";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import { Button } from "../../core/views/Button";

export type ItemsListFilterProps = {
  onSelect?: React.ChangeEventHandler<HTMLSelectElement>;
  selectValue?: string;
  options?: Array<{ id: string; label: string }>;
  onUpdateFilter: (value: string) => void;
  filterValue: string;
  archive?: boolean;
  word: string;
  onShowArchived?: (show: string) => any;
  disabled?: boolean;
  inputProps?: InputProps;
};
export const ItemsListFilter: React.FC<ItemsListFilterProps> = ({
  filterValue,
  options,
  selectValue,
  disabled,
  archive,
  onShowArchived,
  word,
  onSelect,
  inputProps,
  onUpdateFilter,
}) => {
  const [filterState, setFilterState] = useState(filterValue || "");
  const classes = useSharedStyles();
  const inputStyle = useMemo(() => Object.assign({ height: 38 }, inputProps), [inputProps]);

  useEffect(() => {
    if (filterState !== filterValue) {
      const timer = setTimeout(() => onUpdateFilter(filterState), 200);
      return () => clearTimeout(timer);
    }
  }, [filterState, filterValue, onUpdateFilter]);

  const inProps = inputProps ? inputProps : {};
  return (
    <Box display="flex">
      {onSelect && (
        <USelect
          onChange={onSelect}
          className={classes.roundedInput}
          value={selectValue}
          disabled={disabled}
          Input={<Input disableUnderline style={inputStyle} />}
        >
          <option value="">All</option>
          {options.map((i) => (
            <option key={i.id} value={i.id}>
              {i.label}
            </option>
          ))}
        </USelect>
      )}
      <Input
        style={inputStyle}
        className={classes.roundedInput}
        disableUnderline
        placeholder={`Search ${word}`}
        onChange={(e) => setFilterState(e.target.value)}
        value={filterState}
        {...inProps}
      />
      {onShowArchived && (
        <SupportedFeatureSwitch
          feature="new-deletion"
          fallback={
            <Toggle
              options={["Active", "Archived"]}
              value={archive ? "Archived" : "Active"}
              onChange={onShowArchived}
            />
          }
        >
          <Button
            onClick={() => onShowArchived(archive ? "Active" : "Deleted")}
            type="secondary"
            icon={archive ? <CheckBoxOutlinedIcon /> : <CheckBoxOutlineBlankOutlinedIcon />}
            medium
          >
            Trash
          </Button>
        </SupportedFeatureSwitch>
      )}
    </Box>
  );
};
