export const UpdateRetention = (retention?: number) => ({
  clazz: "UpdateRetention",
  retention,
});

export const UpdateEndExecutionAt = (endExecutionAt?: string) => ({
  clazz: "UpdateEndExecutionAt",
  endExecutionAt,
});

export const StopInput = () => ({
  clazz: "Stop",
});

export const Rename = (name: string) => ({
  clazz: "Rename",
  name,
});

export const AttachWorkspace = (workspace: string) => ({
  clazz: "AttachWorkspace",
  workspace,
});

export const DetachWorkspace = (workspace: string) => ({
  clazz: "DetachWorkspace",
  workspace,
});

export type InputChangeset = any;

export const InputChangesets: { [key: string]: (args: any) => InputChangeset } = {
  UpdateRetention: UpdateRetention,
  UpdateEndExecutionAt: UpdateEndExecutionAt,
  StopInput: StopInput,
  Rename: Rename,
};

//keyBy([UpdateRetention, UpdateEndExecutionAt, SetComputeEnvironment, StopInput, Rename], 'name');
