import React, { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import classNames from "classnames";
import { FlatButton } from "./Button";
import Tooltip from "@mui/material/Tooltip";

type CopyButtonProps = {
  value: string;
  copyText?: string;
  className?: string;
  title?: string;
};

export const CopyButton: React.FC<CopyButtonProps> = ({ value, copyText, className, title }) => {
  const [visible, setVisible] = useState(false);

  function onCopy() {
    setVisible(true);
  }

  function disableTooltip() {
    setVisible(false);
  }

  useEffect(() => {
    if (visible) {
      const timer = setTimeout(disableTooltip, 1000);
      return () => clearTimeout(timer);
    }
  }, [visible]);

  return (
    <Tooltip title="Copied!" open={visible} placement="top" onClose={disableTooltip}>
      <span>
        <FlatButton title={title} className={classNames(className, "copy")} onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}>
          <CopyToClipboard text={value || ""} onCopy={onCopy}>
            <span>
              <span className={classNames("icon-copy")} />
              {copyText}
            </span>
          </CopyToClipboard>
        </FlatButton>
      </span>
    </Tooltip>
  );
};

CopyButton.defaultProps = {
  copyText: "Copy",
};
