import { observer } from "mobx-react";
import React from "react";
import { FormInputProps, secretClassName } from "../FormInputTypes";
import { action, computed, observable, values } from "mobx";
import FormItemDecorator from "./FormItemDecorator";
import { USelect } from "../Select";
import classNames from "classnames";
import TextField from "@mui/material/TextField";
import { FormOptionsItem } from "../../FormOptionsItem";
import { FormFieldRegistry } from "../../FormFieldRegistry";
import { FileMatcherType, FileMatcherTypes } from "../../api/contracts/FileNameMatcher";

@observer
export class FileNameMatcherInputView extends React.Component<FormInputProps> {
  typeInput: any;
  valueInput: any;
  options: any[];
  noInputOptions: any;

  @observable _disableInput = false;

  componentWillMount() {
    const childFields = values(this.props.input.fields);
    this.typeInput = childFields.find((f) => f.name === "clazz");
    this.valueInput = childFields.find((f) => f.name === "value");

    const options =
      this.props.input.metadata.options?.length > 0 ? this.props.input.metadata.options : FileMatcherTypes;
    this.options = options.map((opt: FormOptionsItem | string, i: number) =>
      typeof opt === "string" ? (
        <option key={opt + i} value={opt}>
          {opt}
        </option>
      ) : (
        <option key={opt.id + i} value={opt.id}>
          {opt.label}
        </option>
      )
    );

    this.noInputOptions = (this.typeInput.metadata.options as FileMatcherType[])
      .filter((opt) => opt.noValue)
      .map((opt) => opt.id);
    this._disableInput = this.noInputOptions.indexOf(this.typeInput.value) !== -1;
  }

  @action.bound
  _syncTypeInput(e: React.FormEvent) {
    this.typeInput.sync(e);
    this._disableInput = this.noInputOptions.indexOf(this.typeInput.value) !== -1;

    const onChange = this.typeInput.metadata.changeHandler;
    if (onChange) {
      onChange(e);
    }
  }

  @computed
  get validating(): boolean | null {
    return this.typeInput.validating || this.valueInput.validating;
  }

  @computed
  get error(): string | null {
    return this.typeInput.error || this.valueInput.error;
  }

  @action.bound
  _syncValueInput(e: React.FormEvent) {
    this.valueInput.sync(e);

    const onChange = this.valueInput.metadata.changeHandler;
    if (onChange) {
      onChange(e);
    }
  }

  render() {
    return (
      <FormItemDecorator input={this.props.input} hideLabel={false}>
        <div className="grouped-inputs">
          <USelect
            className={classNames("file-matcher-select", {
              secretClassName: this.typeInput.metadata.secret,
            })}
            disabled={this.typeInput.metadata.disabled}
            name={this.typeInput.name}
            placeholder={this.typeInput.label}
            onChange={(e) => this._syncTypeInput(e)}
            defaultValue={this.typeInput.$value}
          >
            {this.options}
          </USelect>
          <TextField
            variant="outlined"
            className={this.valueInput.metadata.secret && secretClassName}
            name={this.valueInput.name}
            data-disabled={this.valueInput.metadata.disabled || this._disableInput}
            disabled={this.valueInput.metadata.disabled || this._disableInput}
            onChange={this._syncValueInput}
            defaultValue={this.valueInput.value}
          />
        </div>
      </FormItemDecorator>
    );
  }
}
export const register = (formFieldRegistry: FormFieldRegistry) => {
  formFieldRegistry.register("file-name-matcher", (props) => <FileNameMatcherInputView {...props} />, {
    decorate: false,
  });
};
