import React from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Chip from "@mui/material/Chip";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import Box from "@mui/material/Box";
import { ItemView } from "../../../core/views/SelectItemViews";
import { Item } from "../../../core/views/SuperSelectItem";

export const DefaultItemView = ({ item }: { item: Item }) => {
  if (item.type === "create") {
    return (
      <Box ml={2}>
        <Chip variant="outlined" clickable icon={<AddCircleOutlineIcon />} label={item.title} color="primary" />
      </Box>
    );
  } else if (item.type === "clear") {
    return (
      <Box ml={2}>
        <Chip variant="outlined" clickable icon={<HighlightOffOutlinedIcon />} label={item.title} color="primary" />
      </Box>
    );
  } else if (item.type === "warning") {
    return (
      <span className="action-item warning">
        <strong /> {item.title}
      </span>
    );
  } else {
    return <ItemView item={item} />;
  }
};
