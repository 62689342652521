import { isClassicOrg, Organization } from "./Organization";
import { Role } from "./User";

export interface LoginReply<U = LoginUserInfo> {
  user: U;
  currentOrganization: Organization;
  organizations: Organization[];
}

export interface LoginUserInfo {
  email: string;
  name: string;
  phone?: string;
  company?: string;
  role: Role;
}

export function loginReplyDefaultOrg(loginReply: LoginReply): Organization | null {
  if (loginReply.currentOrganization) {
    return loginReply.currentOrganization;
  } else {
    const classicOrgs = loginReply.organizations.filter(isClassicOrg)
    return classicOrgs.length === 1 ? classicOrgs[0] : null;
  }
}
