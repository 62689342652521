/* eslint-disable */
import React, { useContext } from "react";
import Toolbar from "@mui/material/Toolbar";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import { AppContext } from "./routes/AppContext";
import AppBar from "@mui/material/AppBar";
import { Trial } from "./onboarding/Trial";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { appBarHeight, collapsedSidebarWidth, mainSidebarWidth } from "./styles/sizes";
import LinearProgress from "@mui/material/LinearProgress";
import { grey } from "@mui/material/colors";
import { OutOfDate } from "./OutOfDate";
import { useAuth } from "./GlobalContext";
import { organizationExpired } from "./core/api/contracts/Organization";

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      minHeight: appBarHeight,
      height: appBarHeight,
    },
    appBar: {
      minHeight: appBarHeight,
      width: `calc(100% - ${mainSidebarWidth}px)`,
      boxShadow: "none",
      borderBottom: `1px solid ${grey["300"]}`,
    },
    appBarShift: {
      marginLeft: mainSidebarWidth,
      width: `calc(100% - ${collapsedSidebarWidth}px)`,
    },
  })
);

export const AccountBar: React.FC<any> = observer(({ user, title }) => {
  const { globalUIStore } = useContext(AppContext);
  const auth = useAuth();
  const currentOrganization = auth.currentOrganization;
  const trialOver = organizationExpired(currentOrganization);
  const classes = useStyles();
  return (
    user && (
      <AppBar position="fixed" className={classNames(classes.appBar, globalUIStore.collapsed && classes.appBarShift)}>
        {!trialOver && <Trial auth={auth} />}
        {auth.outOfDate && <OutOfDate />}
        <Toolbar className={classes.toolbar} variant="dense" disableGutters>
          <div className="integration-logo" />
          <Box flexGrow={1}>{title}</Box>
        </Toolbar>
        {globalUIStore.loading && <LinearProgress color="primary" />}
      </AppBar>
    )
  );
});
