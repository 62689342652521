import React, { useContext } from "react";
import { GlobalContext } from "../GlobalContext";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Box from "@mui/material/Box";
import { SelectOrganization } from "../auth/UserOrganizations";
import { SelectWorkspace } from "./SelectWorkspace";

export function SystemPath(props: { children?: React.ReactNode; header?: React.ReactNode; extra?: React.ReactNode }) {
  const { auth } = useContext(GlobalContext);
  if (props.header) {
    return (
      <>
        <Box display="flex" justifyContent="space-between">
          <Box flexGrow={1}>
            <Breadcrumbs>
              {auth.multiOrgs?.length > 0 && <SelectOrganization />}
              <SelectWorkspace />
              <span>{props.header}</span>
            </Breadcrumbs>
          </Box>
          <div>{props.extra!}</div>
        </Box>
        <Box pt={2}>{props.children!}</Box>
      </>
    );
  }
  return (
    <Breadcrumbs>
      {auth.multiOrgs?.length > 0 && <SelectOrganization />}
      <SelectWorkspace />
      {props.children!}
    </Breadcrumbs>
  );
}
