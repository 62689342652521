import React from "react";
import { Button, ButtonProps } from "../../core/views/Button";
import Hidden from "@mui/material/Hidden";
import Box from "@mui/material/Box";
import { EllipsisTooltip } from "../../core/views/EllipsisTooltip";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

export const NotebookPageActionContainer: React.FC<{ noBorder?: boolean; px?: number }> = ({
  noBorder,
  px,
  children,
}) => (
  <Box
    style={{ borderColor: "#d5d5d5" }}
    component="span"
    px={px ?? 1}
    border={1}
    borderTop={0}
    borderLeft={noBorder ? 0 : 1}
    borderBottom={0}
    borderRight={0}
  >
    {children}
  </Box>
);

export const NoteBookPageActionButtonContext = React.createContext<{ disabled: boolean }>({ disabled: false });

export const NotebookPageActionButton: React.FC<
  Partial<ButtonProps> & { noBorder?: boolean; allowOnContextDisabled?: boolean }
> = (props) => (
  <NoteBookPageActionButtonContext.Consumer>
    {(context) => (
      <NotebookPageActionContainer noBorder={props.noBorder}>
        <Button
          {...(props as any)}
          lowercase
          disabled={props.disabled || (context.disabled && !props.allowOnContextDisabled)}
        />
      </NotebookPageActionContainer>
    )}
  </NoteBookPageActionButtonContext.Consumer>
);

NotebookPageActionButton.defaultProps = {
  small: true,
  square: true,
  type: "borderless",
};
export const NotebookPageActionItem: React.FC<
  Partial<ButtonProps> & {
    noBorder?: boolean;
    menuItem?: boolean;
  }
> = (props) => {
  return props.menuItem ? (
    <EllipsisTooltip title={props.tooltip} withEmpty placement="right-end">
      <MenuItem onClick={props.onClick} disabled={props.disabled} data-automation-name={props.automationName}>
        <ListItemIcon>
          <Box color="primary.main">{props.icon}</Box>
        </ListItemIcon>
        <ListItemText>
          <Box color="primary.main">{props.title}</Box>
        </ListItemText>
      </MenuItem>
    </EllipsisTooltip>
  ) : (
    <NotebookPageActionButton {...props}>{props.title}</NotebookPageActionButton>
  );
};

export function CollapsibleContent(props: { primary?: React.ReactNode; secondary?: React.ReactNode }) {
  if (!props.primary && !props.secondary) {
    return null;
  }
  return (
    <>
      <Hidden lgDown>{props.secondary}</Hidden>
      {props.primary}
    </>
  );
}
