import React from "react";
import { observer } from "mobx-react";
import Checkbox from "@mui/material/Checkbox";
import { FormInputProps, getInputId, inputOnChange, secretClassName } from "../FormInputTypes";
import classNames from "classnames";
import { FormFieldRegistry } from "../../FormFieldRegistry";

const FormCheckboxInput: React.FC<FormInputProps> = observer(({ input, onChange }) => {
  const value = input.$value;
  const disabled = input.disabled || input.metadata.disabled || input.metadata.readonly || input.metadata.loading;
  const inputId = getInputId(input);
  return (
    <Checkbox
      data-testid={inputId}
      color="primary"
      id={inputId}
      className={classNames({ [secretClassName]: input.metadata.secret, disabled })}
      readOnly={input.metadata.readonly}
      checked={value}
      onChange={disabled ? () => {} : inputOnChange(input, true)}
      onFocus={input.onFocus}
      onBlur={input.onBlur}
    />
  );
});
export const register = (formFieldRegistry: FormFieldRegistry) =>
  formFieldRegistry.register("checkbox", (props) => <FormCheckboxInput {...props} />);
export default FormCheckboxInput;
