import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { FormInputProps, FromInputMetadata, inputOnChange } from "../FormInputTypes";
import { FormFieldRegistry } from "../../FormFieldRegistry";
import { AppContext } from "../../../routes/AppContext";
import { BasicApi } from "../../api/Api";
import { ValidationException } from "../../BaseForm";
import { useFileUpload } from "../../hooks/useFileUpload";
import CancelIcon from "@mui/icons-material/Cancel";
import { Button } from "../Button";
import { observer } from "mobx-react-lite";
import styles from "../../../styles/FormFileUplaodInput.module.scss";

interface UploadedFile {
  fileName: string;
  content: string;
}
const InlineFileUploadInput = observer((props: FormInputProps) => {
  const [uploadInfo, setUploadInfo] = useState<{
    status: "waiting" | "processing" | "done";
    files: UploadedFile[];
    error?: string;
  }>({
    files: [],
    status: "waiting",
  });
  const { files, error, status } = uploadInfo;
  const statusText = useMemo(() => {
    if (status === "waiting") {
      return null;
    }
    return status === "done" ? files.map((x) => x.fileName).join(", ") : "uploading...";
  }, [status, files]);

  const accept = useMemo(() => (props.input.metadata.options.extensions ?? "").split(","), [
    props.input.metadata.options.extensions,
  ]);

  useEffect(() => {
    if (error) {
      props.input.invalidate(error);
    }
  }, [props.input, error]);

  useEffect(() => {
    if (status === "done") {
      debugger;
      inputOnChange(props.input, false)(files);
    }
  }, [props.input, status, files]);

  const handleFiles = useCallback(
    async (uploadedFiles: File[]) => {
      props.input.clear();
      if (!uploadedFiles.length) {
        return;
      }
      setUploadInfo({ status: "processing", files: [] });
      uploadedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.addEventListener("load", (ev) => {
          const content = (reader.result as string).split("base64,")[1];
          setUploadInfo((x) => {
            const files = [...x.files, { fileName: file.name, content }];
            const status = uploadedFiles.length === files.length ? "done" : x.status;
            return { ...x, files, status };
          });
        });
        reader.addEventListener("error", (ev) => {
          setUploadInfo((x) => ({ ...x, error: `Error processing: ${file.name}` }));
        });
        reader.readAsDataURL(file);
      });
    },
    [props.input]
  );

  const uploader = useFileUpload(handleFiles, true, false, accept);
  return (
    <>
      <input {...uploader.inputProps} className={styles.Input} />
      <div className="description" style={{ maxWidth: 200 }}>
        {statusText}
      </div>
    </>
  );
});

function useBasicApi(): BasicApi {
  return useContext(AppContext).api;
}
const FileUploadInput = observer(
  (props: FormInputProps<any, FromInputMetadata<{ path: string; verifier: string; extensions: string }>>) => {
    const api = useBasicApi();
    const [label, setLabel] = useState<string | null>();
    const accept = useMemo(() => (props.input.metadata.options.extensions ?? "").split(","), [
      props.input.metadata.options.extensions,
    ]);
    const handleFiles = useCallback(
      async (files: File[]) => {
        props.input.clear();
        if (!files.length) {
          return;
        }
        const file = files[0];
        try {
          const result: { path: string; label: string } = await api.upload(
            "upload",
            file,
            props.input.metadata.options
          );
          setLabel(result.label);
          inputOnChange(props.input, false)(result.path);
        } catch (e) {
          const validation = e?.response?.body as ValidationException;
          if (validation?.validationErrors?.length > 0) {
            props.input.invalidate(validation.validationErrors[0].error);
          } else {
            throw e;
          }
        }
      },
      [props.input]
    );

    const uploader = useFileUpload(handleFiles, true, false, accept);

    if (label) {
      return (
        <div>
          <span style={{ userSelect: "all" }}>{uploader.fileNames}</span> <span> was uploaded to: </span>
          <span style={{ userSelect: "all" }}>{label}</span>
          <Button
            type="borderless"
            endIcon={<CancelIcon />}
            small
            onClick={() => {
              setLabel(null);
            }}
          >
            Clear
          </Button>
        </div>
      );
    }

    return <input {...uploader.inputProps} className={styles.Input} />;
  }
);

const FormFileUploadInput = observer(
  (
    props: FormInputProps<
      any,
      FromInputMetadata<{ path: string; verifier: string; extensions: string; inline: string }>
    >
  ) =>
    props.input.metadata.options.inline === "true" ? (
      <InlineFileUploadInput {...props} />
    ) : (
      <FileUploadInput {...props} />
    )
);

export default FormFileUploadInput;

export const registryType = "file-upload";

export const register = (formFieldRegistry: FormFieldRegistry) => {
  formFieldRegistry.register(registryType, (props) => <FormFileUploadInput {...props} />);
};
