import React from "react";
import { action, computed, observable } from "mobx";
import { observer } from "mobx-react";
import zipObject from "lodash/zipObject";
import Papa from "papaparse";
import { ImportFileUpload } from "../FileUpload";
import { capitalizeFirstLetter } from "../../Utils";
import * as FileSaver from "file-saver";
import initial from "lodash/initial";
import Loadable from "react-loadable";
import identity from "lodash/identity";
import { FlatButton } from "../Button";
import "../../../styles/csv-editor.scss";
import { FormSelectInputProps, inputOnChange } from "../FormInputTypes";
import { FormFieldRegistry } from "../../FormFieldRegistry";
import { templateInputsEquals } from "../../../templates/output/stores/templateInputsEquals";

const expression = /csv\|/;

const CSVAsyncLoader = ({ isLoading, error }: { isLoading: boolean; error?: Error }) => {
  if (isLoading) {
    return (
      <div className="page" style={{ height: 215 }}>
        <p style={{ verticalAlign: "middle", textAlign: "center", width: "100%", marginTop: "25%" }}>
          Loading the editor
        </p>
      </div>
    );
  } else if (error) {
    return <div>Sorry, there was a problem loading the editor. {error.toString()}</div>;
  } else {
    return null;
  }
};

const ReactDataGrid = Loadable({
  loader: () => import("react-data-grid" /* webpackChunkName: "react-data-grid" */),
  loading: CSVAsyncLoader,
});

@observer
export class CSVEditor extends React.Component<Partial<FormSelectInputProps>> {
  _reader = new FileReader();
  _element: any;
  @observable _rows: any[] = [];
  @observable _loading: boolean;

  componentDidMount() {
    if (this.props.input.$value) {
      this._loading = true;
      this._setRows(this.props.input.$value);
      this._loading = false;
    } else {
      this._rows.push(this._createEmptyRow());
    }
    window.addEventListener("resize", this._tryResizeGrid);
  }

  componentWillUnmount() {
    this._reader.abort();
    window.removeEventListener("resize", this._tryResizeGrid);
  }

  _tryResizeGrid = () => {
    if (this._element) {
      this._element.forceUpdate();
    }
  };

  _updateRef = (element: any) => (this._element = element);

  _updateInput = () => {
    const { input } = this.props;
    inputOnChange(input)(Papa.unparse(initial(this._rows), { header: false, newline: "\n" }));
    input.validate();
  };

  _createEmptyRow = () =>
    this.options.csvHeaders.reduce((a: Record<string, string>, c) => {
      a[c] = "";
      return a;
    }, {});

  _setRows = action((csvText: any) => {
    const headers = this.options.csvHeaders;
    const data = Papa.parse(csvText, { skipEmptyLines: "greedy" }).data;
    if (data.length && Object.keys(data[0]).length === headers.length) {
      const hasHeader = templateInputsEquals(
        Object.values(data[0]).map((x: any) => x.toLowerCase()),
        headers.map((x) => x.toLowerCase())
      );
      if (hasHeader) {
        data.shift();
      }
      this._rows = data.map((x: any) => zipObject(headers, Object.values(x)));
      this._rows.push(this._createEmptyRow());
      this._updateInput();
    } else {
      this.props.input.invalidate(`data must contain ${headers.length} columns`);
    }
  });

  _onFiles = action((files: Blob[]) => {
    this._loading = true;
    this._reader.addEventListener(
      "loadend",
      action(() => {
        this._setRows(this._reader.result);
        this._loading = false;
      })
    );
    this._reader.readAsText(files[0]);
  });

  _onRowUpdate = action((update: { cellKey: string; fromRow: number; updated: { [key: string]: any } }) => {
    const { fromRow, updated } = update;
    const row = Object.assign({}, this._rows[fromRow], updated);
    if (Object.values(row).some((x: any) => x)) {
      if (fromRow + 1 === this._rows.length) {
        if (this._rows.length === 1) {
          this._rows.unshift(row);
        } else {
          this._rows.splice(fromRow, 0, row);
        }
      } else {
        this._rows[fromRow] = row;
      }
    } else if (fromRow < this._rows.length - 1) {
      this._rows.splice(fromRow, 1);
    }

    this._updateInput();
  });

  _download = () => {
    const blob = new Blob([Papa.unparse(initial(this._rows))], { type: "text/csv" });
    FileSaver.saveAs(blob, `${this.props.input.name}.csv`);
  };

  _delete = action((e: React.KeyboardEvent, args: { rowIdx: number }) => {
    const { rowIdx } = args;
    if (rowIdx > -1 && rowIdx < this._rows.length - 1) {
      this._rows.splice(rowIdx, 1);
      this._updateInput();
    }
  });

  _onKeyDownDelete = action((e: React.KeyboardEvent, args: { rowIdx: number }) => {
    if (e.key === "Enter" || e.key === "Backspace" || e.key === "Delete") {
      this._delete(e, args);
    }
  });

  @computed
  get options(): { csvHeaders: Array<string> } {
    const existing = this.props.options || this.props.input.metadata.options;
    const editor = this.props.type.split("|");
    return editor.length > 1 ? Object.assign({}, existing, { csvHeaders: editor[1].split(",") }) : existing;
  }

  render() {
    const headers: string[] = this.options.csvHeaders;
    return (
      <div
        onKeyPress={(e: React.KeyboardEvent) => {
          if (e.key === "Enter") {
            e.stopPropagation();
            e.preventDefault();
          }
        }}
      >
        <span className="toolbar">
          <ImportFileUpload
            onFiles={this._onFiles}
            multi={false}
            accept={["csv"]}
            fireOnSameFile
            loading={this._loading}
            showFileTypes={true}
          />
          <FlatButton className="export" onClick={this._download}>
            <span className="icon-export" />
            Export
          </FlatButton>
        </span>
        <ReactDataGrid
          ref={this._updateRef}
          enableCellSelect={!this._loading}
          columns={headers
            .map((x) => ({
              key: x,
              name: capitalizeFirstLetter(x),
              editable: true,
              resizeable: true,
            }))
            .concat([
              {
                name: "",
                key: "$delete",
                //@ts-ignore
                getRowMetaData: identity,
                formatter: () => <span className="icon-trash" />,
                width: 50,
                events: {
                  onClick: this._delete,
                  onKeyDown: this._onKeyDownDelete,
                },
              },
            ])}
          rowGetter={(i: number) => this._rows[i]}
          rowsCount={this._rows.length}
          minHeight={200}
          minColumnWidth={100}
          onGridRowsUpdated={this._onRowUpdate}
        />
        <div className="description">Double-click or press Enter on a cell to edit.</div>

        <div className="error-text">{this.props.input.$error}</div>
      </div>
    );
  }
}

export const register = (formFieldRegistry: FormFieldRegistry) => {
  formFieldRegistry.register("csv", (props) => <CSVEditor {...props} />);

  formFieldRegistry.register(expression, (props) => <CSVEditor {...props} />);
};
