import { TimeUnit } from "../TimeUnit";

export class TimeUnits {
  static Values = {
    Day: "Day",
    Hour: "Hour",
    Minute: "Minute",
    Infinite: "Infinite",
    All: ["Minute", "Hour", "Day", "Infinite"],
    NoInfinite: ["Minute", "Hour", "Day"],
  };

  static InfiniteMinutes: number = 14400000;

  static FitToWindow(minutes: number): TimeUnit {
    if (minutes === 0) {
      return { unit: TimeUnits.Values.Minute, amount: 0 };
    }

    if (minutes === Infinity) {
      return { unit: TimeUnits.Values.Infinite, amount: TimeUnits.InfiniteMinutes };
    }

    const toDay = minutes / 1440;
    if (Number.isInteger(toDay)) {
      return { unit: TimeUnits.Values.Day, amount: toDay };
    }

    const toHour = minutes / 60;
    if (Number.isInteger(toHour)) {
      return { unit: TimeUnits.Values.Hour, amount: toHour };
    }

    return { unit: TimeUnits.Values.Minute, amount: minutes };
  }

  static TimeWindowToMinutes(value: any, unit: string): number {
    let expirationWindow;
    switch (unit) {
      case "Minute":
        expirationWindow = value * 1;
        break;
      case "Hour":
        expirationWindow = value * 60;
        break;
      case "Day":
        expirationWindow = value * 60 * 24;
        break;
      case "Infinite":
        expirationWindow = TimeUnits.InfiniteMinutes;
        break;
      default:
        throw new Error("Unknown expiration window:" + value);
    }
    return expirationWindow;
  }

  static TimeWindowToSeconds(value: number, unit: string): number {
    return TimeUnits.TimeWindowToMinutes(value, unit) * 60;
  }

  static pluralizeUnit(timeUnit: TimeUnit) {
    if (timeUnit.amount === 1) {
      return timeUnit;
    } else {
      return { amount: timeUnit.amount, unit: timeUnit.unit + "s" };
    }
  }
}
