import { observer } from "mobx-react";
import React from "react";
import { FormInputProps } from "../FormInputTypes";
import { FormFieldRegistry } from "../../FormFieldRegistry";

@observer
export class PythonEditor extends React.Component<FormInputProps> {
  _handleDrop(e: React.DragEvent) {
    if (e.dataTransfer && e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      const item: DataTransferItem = e.dataTransfer.items[0];
      if (item.kind === "file") {
        e.preventDefault();
        e.stopPropagation();
        if (item.type.startsWith("text/")) {
          const reader = new FileReader();

          reader.onloadend = () => {
            this.props.input.set(reader.result);
          };

          reader.readAsText(e.dataTransfer.files[0]);
        } else {
          window.alert("only text files are supported");
        }
      }
    }
  }

  render() {
    return (
      <div
        onDrop={(e) => {
          this._handleDrop(e);
        }}
        onDragOver={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <p>Python editing currently not supported</p>
      </div>
    );
  }
}
export const register = (formFieldRegistry: FormFieldRegistry) =>
  formFieldRegistry.register("python", (props) => <PythonEditor {...props} />);
