import React, { useCallback, useContext, useEffect, useState } from "react";
import { UField } from "../../inputs/Store";
import { Entry } from "./FieldsTree";
import classNames from "classnames";
import { ComputedFieldGalleryModal } from "../../transform/ComputedFieldGalleryModal";
import { Button } from "./Button";
import { DataSourceWord, FeatureWord } from "../Language";
import { TreeEntry } from "./Tree";
import { useContainerSize } from "../useContainerSize";
import useWindowSize from "../useWindowSize";
import { UFieldsTreeContext } from "../FieldsTreeState";
import { observer } from "mobx-react-lite";
import { Tree3 } from "./Tree2";
import "../../styles/fields-menu.scss";
import { FieldsTreeMultiSelect } from "./FieldsTreeMultiSelect";
import TextField from "@mui/material/TextField";
import Search from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import Box from "@mui/material/Box";
import { GlobalContext } from "../../GlobalContext";
import { FilterIcon } from "./FilterIcon";
import { DisplayData } from "../api/contracts/Core";
import { BoxBottomBorder } from "../BoxBottomBorder";
import { notebookToolbarHeight } from "../../styles/sizes";
import { NotebookPageActionButton } from "../../templates/notebook/NotebookActionButton";
import { FeatureIcon } from "./FeatureIcon";
import { FieldTreeEntry } from "./TreeItem";

export type FieldsMenuProps = {
  fields: UField[];
  quickFilterKeys?: string[];
  onSelect?: (field: UField, entry: TreeEntry) => void;
  onSelectParent?: (parent: string) => void;
  onDoubleSelect?: (item: any) => void;
  selectedItem: string;
  onAddComputedField?: (family: string) => void;
  height?: string;
  selectedQuickFilter?: string | null;
  multi?: boolean;
  multiActions?: React.ReactNode;
  onCheck?: (field: UField, checked?: boolean) => void;
  hideDsl?: boolean;
  noFilters?: boolean;
  fullHeight?: boolean;
  fieldInspectionView?: (field: UField) => React.ReactNode;
  title?: React.ReactNode;
  extraBottomPadding?: number;
  heightOverride?: number;
  noPadding?: boolean;
  narrow?: boolean;
  hoverActions?: (entry: TreeEntry, context: UFieldsTreeContext) => React.ReactNode;
  fieldsTreeContext?: UFieldsTreeContext;
  inputs?: Record<string, DisplayData>;
  showHoverOverride?: boolean;
  blockRecordSelect?: boolean;
  titleFilter?: React.ReactNode;
  titleFooter?: React.ReactNode;
  selectOnCheck?: boolean;
  initialScrollToEntryNumber?: number;
};

const filterKeys = {
  DATA: "data",
  CALCULATED: "calculated",
};

const filterData: Record<string, { key: string; empty: React.ReactNode }> = {
  data: {
    key: filterKeys.DATA,
    empty: <p>{`${DataSourceWord.single()} has no fields`}</p>,
  },
  calculated: {
    key: filterKeys.CALCULATED,
    empty: <p>You have no {FeatureWord.plural()}</p>,
  },
};

export const FieldsMenuV3: React.FC<FieldsMenuProps> = observer(
  ({
    fields,
    onSelect,
    onSelectParent,
    onDoubleSelect,
    selectedItem,
    onAddComputedField,
    height,
    selectedQuickFilter,
    multi,
    multiActions,
    onCheck,
    noFilters,
    fullHeight,
    fieldInspectionView,
    title,
    extraBottomPadding,
    heightOverride,
    noPadding,
    narrow,
    hoverActions,
    fieldsTreeContext,
    inputs,
    showHoverOverride,
    blockRecordSelect,
    titleFilter,
    titleFooter,
    selectOnCheck,
    initialScrollToEntryNumber,
  }) => {
    const [treeRef, setTreeRef] = useState<HTMLDivElement>(null);
    const [treeContext, setTreeContext] = useState<UFieldsTreeContext>(fieldsTreeContext);
    const containerSize = useContainerSize(treeRef);
    const windowSize = useWindowSize();
    const { globalUIStore } = useContext(GlobalContext);

    useEffect(() => {
      if (!fieldsTreeContext) {
        const context = new UFieldsTreeContext(fields || [], onCheck, () => Promise.resolve([]), !!inputs);
        setTreeContext(context);
        return () => context.dispose();
      }
    }, [fields, onCheck, fieldsTreeContext, inputs]);

    useEffect(() => {
      if (fieldsTreeContext) {
        setTreeContext(fieldsTreeContext);
      }
    }, [fieldsTreeContext]);

    const onExpand: (entry: TreeEntry, shouldExpand: boolean) => void = useCallback(
      (entry: TreeEntry, shouldExpand: boolean) => {
        if (!blockRecordSelect && onSelectParent && shouldExpand && entry.children && entry.children.length > 0) {
          requestAnimationFrame(() => {
            onSelectParent(entry.key);
          });
        }
        treeContext.onExpand(entry, shouldExpand);
      },
      [treeContext, onSelectParent]
    );

    const [constantFilterData, setConstantFilterData] = useState<string>();
    const [addComputedField, setAddComputedField] = useState<boolean>(false);

    useEffect(() => {
      if (treeContext && constantFilterData) {
        treeContext.featuresFilter = constantFilterData === filterKeys.CALCULATED;
      }
    }, [constantFilterData, treeContext]);

    useEffect(() => {
      setConstantFilterData(selectedQuickFilter);
    }, [selectedQuickFilter]);

    const toggleAddComputedField = () => {
      setAddComputedField((x) => !x);
    };

    const checkAll = () => {
      if (treeContext) {
        treeContext.onCheckedAll(true);
      }
    };

    const uncheckAll = () => {
      if (treeContext) {
        treeContext.onCheckedAll(false);
      }
    };

    const onSelectItem = useCallback(
      (item: FieldTreeEntry) => {
        const parent = item?.children?.length > 0;
        treeContext.onSelect(item, blockRecordSelect);
        if (onSelectParent && parent) {
          if (!blockRecordSelect) {
            requestAnimationFrame(() => {
              onSelectParent(item.key);
            });
          }
        } else if (onSelect) {
          onSelect(item.field, item);
        }
      },
      [onSelectParent, onSelect, treeContext]
    );

    useEffect(() => {
      if (selectedItem && treeContext && (!treeContext.selected || treeContext.selected.key !== selectedItem)) {
        const selectedEntry = treeContext.findEntry(selectedItem);
        if (selectedEntry) {
          onSelectItem(selectedEntry);
        }
      }
    }, [selectedItem, treeContext, onSelectItem]);

    return (
      <div className={classNames("fields-menu", { noPadding, narrow })}>
        <div className="filter">
          {(title || onAddComputedField) && (
            <BoxBottomBorder display="flex" alignItems="center" height={notebookToolbarHeight}>
              {title}
              {onAddComputedField && (
                <NotebookPageActionButton
                  onClick={toggleAddComputedField}
                  automationName={"add-feature"}
                  noBorder={true}
                  icon={<FeatureIcon />}
                >
                  Add <FeatureWord.component />
                </NotebookPageActionButton>
              )}
            </BoxBottomBorder>
          )}
          <Box display="flex" p={0.25}>
            <Box flexGrow={1} pt={0.5}>
              <TextField
                variant="standard"
                fullWidth
                onChange={(e) => {
                  const value = e.currentTarget.value;
                  treeContext.setFilterText(value);
                }}
                defaultValue={treeContext?.filterText}
                placeholder="Search Fields"
                data-automation-name="search-fields"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search />
                    </InputAdornment>
                  ),
                  startAdornment: (
                    <InputAdornment position={"start"}>
                      {!noFilters && (
                        <div
                          data-automation-name={"quick-filter-calculated"}
                          onClick={() =>
                            setConstantFilterData((x) =>
                              x === filterKeys.CALCULATED ? filterKeys.DATA : filterKeys.CALCULATED
                            )
                          }
                        >
                          <Box
                            display="inline"
                            className={classNames("filter-icon", {
                              active: constantFilterData === filterKeys.CALCULATED,
                            })}
                          >
                            <span className="icon-function" />
                            <FilterIcon fontSize="small" style={{ display: "inline" }} />
                          </Box>
                        </div>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box flexGrow={0}>{titleFilter!}</Box>
          </Box>
        </div>
        {titleFooter!}
        <div ref={(el) => setTreeRef(el)} className="overflow menu">
          {treeContext &&
            (treeContext.filteredOut ? (
              <div className="empty">
                {(treeContext.filterText && `No matches for '${treeContext.filterText}'`) ||
                  (treeContext.featuresFilter
                    ? filterData[filterKeys.CALCULATED].empty
                    : filterData[filterKeys.DATA].empty)}
              </div>
            ) : (
              <React.Fragment>
                {multi && (
                  <FieldsTreeMultiSelect>
                    {multiActions || (
                      <p>
                        <Button type="borderless" small onClick={checkAll}>
                          Select All
                        </Button>
                        |
                        <Button type="borderless" small onClick={uncheckAll}>
                          Deselect All
                        </Button>
                      </p>
                    )}
                  </FieldsTreeMultiSelect>
                )}
                <Tree3<FieldTreeEntry>
                  className={showHoverOverride && "show-hover"}
                  style={
                    heightOverride
                      ? { height: heightOverride }
                      : {
                          height: Math.max(
                            100,
                            height
                              ? windowSize.height - (170 + (extraBottomPadding || 0) + globalUIStore.extraTopBarHeight)
                              : containerSize.height - 50
                          ),
                        }
                  }
                  checkable={multi}
                  treeContext={treeContext}
                  onCheck={(entry, checked) => {
                    treeContext.onChecked(entry, checked);
                    if (selectOnCheck && onSelectItem && checked && !treeContext.expanded.hasOwnProperty(entry.key)) {
                      onSelectItem(entry);
                    }
                  }}
                  onSelect={onSelectItem}
                  onDoubleSelect={onDoubleSelect}
                  selectedKey={selectedItem}
                  onExpand={onExpand}
                  inputs={inputs}
                  renderEntry={(p) => (
                    <Entry
                      {...p}
                      fieldInspectionView={fieldInspectionView}
                      hoverActions={hoverActions}
                      treeContext={treeContext}
                    />
                  )}
                  scrollToItemIndex={initialScrollToEntryNumber}
                />
              </React.Fragment>
            ))}
        </div>
        {addComputedField && (
          <ComputedFieldGalleryModal onClose={toggleAddComputedField} onSelect={onAddComputedField} />
        )}
      </div>
    );
  }
);

FieldsMenuV3.defaultProps = {
  selectOnCheck: true,
};
