/* eslint-disable */
import React from "react";
import { toast, ToastOptions } from "react-toastify";
import { CopyButton } from "./core/views/CopyText";
import classNames from "classnames";

export type SystemNotificationLevel = "info" | "success" | "warning" | "error";

export interface SystemNotification {
  message?: string;
  title?: string;
  id?: string;
  level?: SystemNotificationLevel;
  action?: {
    label: string;
    callback: () => void;
  };
}

const Notification: React.FC<SystemNotification> = ({ title, message, level, id, action }) => (
  <div className={classNames("notification", level)}>
    <h4 className="title">
      <span className={`icon-${level || "info"}`} />
      {title}
      {id && <CopyButton value={id} copyText="Copy Id" />}
    </h4>
    {message && <div className="notification-message">{message}</div>}
    {action && (
      <button className="notification-action-button" onClick={action.callback}>
        {action.label}
      </button>
    )}
  </div>
);

const defaultOptions = { closeButton: <span className="icon-x" />, closeOnClick: true };

let latestToastId: number | string;

export function addNotification(message: SystemNotification, options?: ToastOptions) {
  if (!toast.isActive(latestToastId)) {
    latestToastId = toast(
      <Notification {...message} />,
      Object.assign(
        defaultOptions,
        {
          type: message.level || "info",
          autoClose: message.action ? false : 8000,
        },
        options
      )
    );
  }
}

export class NotificationListener<A> {
  listeners: Array<(listener: A) => void> = [];

  listen(f: (listener: A) => void): () => void {
    this.listeners.push(f);

    return () => {
      if (this.listeners) {
        const index = this.listeners.indexOf(f);
        this.listeners.splice(index, 1);
      }
    };
  }

  publish(item: A): void {
    this.listeners.forEach((f) => f(item));
  }
}

const listener = new NotificationListener<any>();

export const DefaultNotificationListener = () => {
  listener.listen(addNotification);
  return listener;
};
