import { isTimeUnit, TimeUnit } from "../../core/TimeUnit";
import { TimeUnits } from "../../core/views/TimeUnits";

export function windowSizeDisplay(window: string | number | TimeUnit | null): string {
  if (!window) {
    return "N/A";
  }
  const timeUnit: TimeUnit = isTimeUnit(window) && window;
  const minutes =
    (timeUnit && TimeUnits.TimeWindowToMinutes(timeUnit.amount, timeUnit.unit)) ||
    (typeof window === "number" && window);
  if (window === TimeUnits.Values.Infinite || minutes >= TimeUnits.InfiniteMinutes) {
    return TimeUnits.Values.Infinite;
  } else if (timeUnit) {
    const plural = TimeUnits.pluralizeUnit(timeUnit);
    return `${plural.amount} ${plural.unit}`;
  } else {
    return window?.toString() || "N/A";
  }
}
