import React from "react";
import { action, computed, IObservableArray, observable } from "mobx";
import { observer } from "mobx-react";
import { Dropdown } from "./Dropdown";
import { FieldsTree } from "./FieldsTree";
import { TreeEntry } from "./Tree";
import { Tag } from "./General";
import { UFieldView } from "../api/contracts/UFieldComponents";
import { FieldTreeEntry } from "./TreeItem";

export interface FieldSelectionProps {
  items: FieldTreeEntry[];
  onSelect: (item: FieldTreeEntry | FieldTreeEntry[]) => void;
  typeFilterKey: string[];
  initialField: FieldTreeEntry;
  disabled?: boolean;
}

const typeFilter = (types: string[], items: FieldTreeEntry[]) =>
  items.filter((i: FieldTreeEntry) => !i.field || types.includes(i.field.nativeType.name));

@observer
export class FieldSelection extends React.Component<FieldSelectionProps> {
  componentDidMount() {
    this._selectedItem = this.props.initialField;
  }

  componentWillReceiveProps(nextProps: FieldSelectionProps) {
    this._selectedItem = nextProps.initialField;
  }

  @observable.ref _selectedItem: FieldTreeEntry = null;

  @action.bound _onSelect(item: FieldTreeEntry) {
    this._selectedItem = item;
    if (this.props.onSelect) {
      this.props.onSelect(item);
    }
  }

  @action.bound _clear() {
    this._onSelect(null);
  }

  _renderMenu(key: string) {
    return (
      <div className="column-chooser">
        <div className="scroll fields-menu">
          <div className="menu">
            <FieldsTree
              selection="single"
              items={this.props.items as any}
              onSelect={this._onSelect}
              filterPredicate={(value, items) =>
                this.props.typeFilterKey ? typeFilter(this.props.typeFilterKey, items) : (items as any)
              }
              filterKey={key}
              selectedKey={this._selectedItem && this._selectedItem.key}
              checkedKeys={[]}
              onCheck={() => {}}
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Dropdown
        selectedItems={
          this._selectedItem
            ? [
                <Tag key={this._selectedItem.key} onClick={this._clear}>
                  {this._selectedItem.label}
                </Tag>,
              ]
            : []
        }
        menu={(key) => this._renderMenu(key)}
        multi={false}
      />
    );
  }
}

@observer
export class FieldsSelection extends React.Component<FieldSelectionProps> {
  @observable _selectedItem: FieldTreeEntry = null;
  @observable _checkedItems: IObservableArray<FieldTreeEntry> = observable.array([]);

  @action.bound _onSelect(item: FieldTreeEntry) {
    this._selectedItem = item;
  }

  @action.bound _clear() {
    this._onSelect(null);
  }

  @action.bound _remove(index: number) {
    this._checkedItems.splice(index, 1);

    if (this.props.onSelect) {
      this.props.onSelect(this._checkedItems.slice());
    }
  }

  @action.bound _onCheck(entry: TreeEntry, checked: boolean) {
    if (checked) {
      this._checkedItems.push(entry);
    } else {
      this._checkedItems.replace(this._checkedItems.filter((e) => e.key !== entry.key));
    }

    if (this.props.onSelect) {
      this.props.onSelect(this._checkedItems.slice());
    }
  }

  @computed
  get _checkedKeys() {
    return this._checkedItems.map((k) => k.key).slice();
  }

  _renderMenu(key: string) {
    return (
      <div className="column-chooser">
        <div className="scroll fields-menu">
          <div className="menu">
            <FieldsTree
              selection="multi"
              items={this.props.items as any}
              onSelect={this._onSelect}
              filterKey={key}
              selectedKey={null}
              checkedKeys={this._checkedKeys}
              onCheck={this._onCheck}
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Dropdown
        selectedItems={this._checkedItems.map((item, index) => (
          <Tag key={item.key} onClick={() => this._remove(index)}>
            {item.field ? <UFieldView field={item.field} /> : item.label}
          </Tag>
        ))}
        menu={(key) => this._renderMenu(key)}
        multi={true}
      />
    );
  }
}
