function deepClone(obj?: any) {
  if (obj === null || typeof obj !== "object" || "isActiveClone" in obj) return obj;

  let temp = obj instanceof Date ? obj.constructor() : obj.constructor();

  for (let key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      obj["isActiveClone"] = null;
      temp[key] = deepClone(obj[key]);
      delete obj["isActiveClone"];
    }
  }

  return temp;
}

export { deepClone };
