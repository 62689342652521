import { CloudIntegrationStore } from "./cloud-integration-store";
import { ValidationError } from "../core/api/contracts/FeatureDefinition";

export const integrationLauncher = (
  integrations: CloudIntegrationStore,
  payload: any,
  onValidationError?: (error: ValidationError) => void,
  method: string = "basic"
) => {
  const integrationTab = window.open("/redirected", "_blank");
  return integrations.startIntegration(payload, method).then(
    (result) => {
      integrationTab.location.href = result;
    },
    (err: any) => {
      //TODO: ts-convert superagent response type
      integrationTab.close();
      if (err.response && err.response.body && onValidationError) {
        onValidationError(err.response.body);
      } else {
        throw err;
      }
    }
  );
};
