export type Role = "master" | "admin" | "normal" | "sales";

export interface User {
  company?: string;
  email: string;
  name: string;
  phone?: string | null;
  organization: string;
  role: Role;
  impostor?: boolean | null;
  upsolverian?: boolean;
  hasDashboards?: boolean;
}

export type UserDetails = {
  email: string;
  name: string;
  phone?: string;
};
export type UserInfo = {
  organization: string;
  authenticationMethod?: any;
} & UserDetails;

export interface VerificationRequired {
  verificationEmail: string;
}
export function isVerificationRequired(item: any): item is VerificationRequired {
  return item && Reflect.has(item, "verificationEmail");
}

export type NewUserRequest = {
  info: UserDetails;
  password: string;
  permissions?: string;
  authenticationMethod?: any;
};
type UserResponse = {
  info: UserInfo;
  permissions: string;
};
export type ResetUserPasswordRequest = {
  current: string;
  newPassword: string;
};
export type GoogleSignUpHint = {
  userInfo: UserInfo | any;
  authenticationMethod?: any;
};
export const EmptySignUpHint: GoogleSignUpHint = {
  userInfo: {},
  authenticationMethod: undefined,
};
export const EmptyLoginState = {
  hint: EmptySignUpHint,
  original: {},
};
