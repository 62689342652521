import { Field as MField } from "../type-definitions/mobx-react-form";
import { AutoCompleteMetadata, ServerSideValidation, ServerSideValidationStatus } from "../SimpleMetadata";
import { EditorInformation } from "../api/contracts/FormFields";

export type FromInputMetadata<TOptions = any> = Partial<{
  validationResult: ServerSideValidationStatus;
  autocomplete: AutoCompleteMetadata;
  [key: string]: any;
  everHadErrors?: boolean;
  options: TOptions;
  serverSideValidation: ServerSideValidation;
  step: string;
  editorInformation: EditorInformation;
}>;

export type FormInput<TMetadata = FromInputMetadata> = MField & {
  metadata?: TMetadata;
};

export interface AutocompleteInputProps {
  allowCreate?: boolean;
  autocomplete?: boolean;
  createOptions?: any[];
}

export interface ScrollToRefProps {
  scrollRef?: (el: HTMLElement) => void;
}

export interface FormInputProps<TValue = any, TMetadata = FromInputMetadata>
  extends AutocompleteInputProps,
    ScrollToRefProps {
  input: FormInput<TMetadata>;
  style?: any;
  children?: any;
  compact?: boolean;
  hideLabel?: boolean;
  className?: string;
  labelPrefix?: any;
  label?: string;
  disabled?: boolean;
  onChange?: (value: TValue, input?: MField) => void;
  placeholder?: string;
  loading?: boolean;
  readonly?: boolean;
  clearable?: boolean;
  hideDescription?: boolean;
  vertical?: boolean;
  decorate?: boolean;
  required?: boolean;
  hidden?: boolean;
  type?: string;
  step?: string;
}

export interface FormSelectInputProps extends FormInputProps {
  options?: any[];
  showSearch?: boolean;
  multiple?: boolean;
  itemView?: any;
}

export const getInputId = (input: MField) => `input_${input.key}`;

export function inputOnChangeHandler(input: FormInput) {
  return (value: any) => input.metadata.changeHandler(value, input);
}

export function inputOnChange(input: MField, validate: boolean = false) {
  return decorateOnChange(input, input.metadata.changeHandler, validate);
}

export function decorateOnChange(
  input: MField,
  onChange: (e: React.ChangeEvent, input: MField) => void,
  validate: boolean = false
) {
  if (onChange) {
    return (e: any) => {
      input.sync(e);
      onChange(e, input);
      if (validate) {
        input.validate();
      }
    };
  } else {
    return (value: any) => {
      if (
        Array.isArray(value) &&
        value.length > 0 &&
        typeof value[0].slice === "function" &&
        typeof value[0] !== "string"
      ) {
        const ts = value[0].slice().concat(value.slice(1, value.length));
        input.sync(ts);
      } else {
        if (input.value && Array.isArray(input.value) && !Array.isArray(value)) {
          input.$value.replace([value]);
        } else {
          if (value && Array.isArray(value)) {
            input.clear(true);
            input.fields.clear();
            value.forEach((v) => input.add().set(v));
          } else {
            input.sync(value);
          }
        }
      }
      if (validate) {
        input.validate();
      }
    };
  }
}

export const secretClassName = "no-mouseflow";
