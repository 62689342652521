import uniqueId from "lodash/uniqueId";
import Color from "color-js";
import React from "react";
import isNumber from "lodash/isNumber";
import Cookies from "js-cookie";
import { addNotification } from "../AppNotification";
import { TimeUnits } from "./views/TimeUnits";
import { FeatureWord } from "./Language";
import { SimpleMetadata } from "./SimpleMetadata";

export const UUID = (prefix?: string) => uniqueId(prefix);

type StringMap = Record<string, string>;

export type Fields = {
  fields?: string[];
  secret?: string[];
  rules?: StringMap;
  labels?: StringMap;
  types?: StringMap;
  options?: { [key: string]: string[] };
  values?: { [key: string]: string };
};

function debounce(func: Function, context: any, wait: number) {
  let timeout: any;
  return function () {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => func.apply(context, arguments), wait);
  };
}

export function round(value: number, precision: number): number {
  const coefficient = Math.pow(10, precision);
  return Math.round(value * coefficient) / coefficient;
}

export const blendColors = (color1: string, color2: string, percent: number) =>
  Color(color1).blend(Color(color2), percent).toString();

export const baseCold = "#1888b4";
export const baseHot = "#cc3300";

export const blendHotToCold = (percent: number) => blendColors(baseCold, baseHot, Math.min(1, percent));

export { debounce };

const numberOfConsecutiveSlashes = (path: string, from: number): number => {
  let i = from;
  while (i >= 0 && path[i] === "\\") {
    i--;
  }
  return from - i;
};

export const findNextDot = (path: string, from: number = 0): number => {
  let nextIndex = path.indexOf(".", from);
  while (nextIndex !== -1 && numberOfConsecutiveSlashes(path, nextIndex - 1) % 2 === 1) {
    nextIndex = path.indexOf(".", nextIndex + 1);
  }
  return nextIndex;
};

export const splitPath = (path: string): string[] => {
  const result = [];
  let last = 0;
  let current = findNextDot(path, 0);
  while (current !== -1) {
    result.push(path.substring(last, current));
    last = current + 1;
    current = findNextDot(path, last);
  }
  result.push(path.substring(last));
  return result;
};
export const treeFeaturesWord = FeatureWord.plural();
export const fieldsTreeFeaturePrefixes = ["Lookups", treeFeaturesWord];

export const fromLastUnescapedDot = (name: string) => {
  const escapedIndex = name.indexOf("\\.");
  if (escapedIndex > 0 && !name.substr(0, escapedIndex).includes(".")) {
    return name;
  }
  if (escapedIndex === -1) {
    const from = name.lastIndexOf(".");
    const afterDot = from === -1 ? name : name.substr(from + 1);
    return name.substr(0, from - 1).includes(".") ? afterDot : name;
  } else {
    const lastUnescaped = name.substr(0, escapedIndex).lastIndexOf(".");
    return name.substr(lastUnescaped + 1);
  }
};

export const untilLastUnescapedDot = (name: string) => {
  const lastEscaped = name.lastIndexOf("\\.");
  if (lastEscaped === -1) {
    return name.substr(0, name.lastIndexOf("."));
  } else {
    const e = name.substr(0, lastEscaped);
    return e.substr(0, e.lastIndexOf("."));
  }
};

export const lastDot = (name: string) => {
  const lastIndexOfDot = name.lastIndexOf(".");
  return name.substr(lastIndexOfDot + 1);
};

export const removeEmptyStrings = (obj: any): any => {
  const duplicate = Object.assign({}, obj);

  const keys = Object.keys(duplicate);
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    const value = duplicate[key];
    if (value === "" || value === "undeploy") {
      delete duplicate[key];
    }
  }
  return duplicate;
};

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function shallowEquals(a: any, b: any): boolean {
  const aProps = Object.getOwnPropertyNames(a);
  const bProps = Object.getOwnPropertyNames(b);

  if (aProps.length !== bProps.length) {
    return false;
  }

  for (let i = 0; i < aProps.length; i++) {
    const propName = aProps[i];

    if (a[propName] !== b[propName]) {
      return false;
    }
  }

  return true;
}

export function arrayToMap<A>(array: A[], key: (item: A) => string, value: (item: A) => string): StringMap {
  return array.reduce((a: StringMap, c: A) => {
    a[key(c)] = value(c);
    return a;
  }, {});
}

export function scrollToComponent(node: HTMLElement): void {
  function findScrollableParent(node: HTMLElement): HTMLElement | null {
    if (node === null) {
      return null;
    }

    const scrollable = node.scrollHeight > node.clientHeight;
    //TODO: ts-convert parentNode doesn't seem to be an HTMLElement
    return scrollable ? node : findScrollableParent(node.parentNode as HTMLElement);
  }

  const scrollableParent = findScrollableParent(node);
  if (scrollableParent === null) {
    return;
  }

  const elementOffset = node.offsetTop - scrollableParent.offsetTop;
  const elementHeight = node.clientHeight;
  const minimumOffset = scrollableParent.scrollTop;
  const maximumOffset = minimumOffset + scrollableParent.clientHeight;

  if (elementOffset < minimumOffset) {
    scrollableParent.scrollTop = elementOffset;
  } else if (elementOffset + elementHeight > maximumOffset) {
    scrollableParent.scrollTop = elementOffset + node.clientHeight - scrollableParent.clientHeight;
  }
}

export const environmentFieldsNames = ["computeEnvironment", "queryEnvironment"];

export type Edge<T> = {
  item: T;
  incoming: boolean;
  hiddenKeys?: string[];
};

export class ObjectGraph<T> {
  vertices: { [key: string]: T } = {};
  edges: { [key: string]: Array<Edge<T>> } = {};
  vertexEquals: (a: T, b: T) => boolean;
  getKey: (item: T) => string;
  numberOfEdges: number = 0;

  constructor(getKey: (item: T) => string, vertexEquals?: (a: T, b: T) => boolean) {
    this.vertexEquals = vertexEquals || ((a, b) => getKey(a) === getKey(b));
    this.getKey = getKey;
  }

  addVertex(vertex: T, outerKey?: string) {
    const key = outerKey || this.getKey(vertex);
    if (!this.vertices[key]) {
      this.vertices[key] = vertex;
      this.edges[key] = [];
    }
  }

  removeVertex(vertex: T) {
    const key = this.getKey(vertex);
    delete this.vertices[key];
    while (this.edges[key].length) {
      const adjacentVertex = this.edges[key].pop();
      this.removeEdge(adjacentVertex.item, vertex);
    }
  }

  addEdge(vertex1: T, vertex2: T) {
    this.edges[this.getKey(vertex1)].push({ item: vertex2, incoming: false });
    this.edges[this.getKey(vertex2)].push({ item: vertex1, incoming: true });

    this.numberOfEdges++;
  }

  removeEdge(vertex1: T, vertex2: T) {
    const key1 = this.getKey(vertex1);
    const key2 = this.getKey(vertex2);
    if (this.edges[key1]) {
      delete this.edges[key1];
      this.numberOfEdges--;
    }
    if (this.edges[key2]) {
      delete this.edges[key2];
    }
  }

  size() {
    return Object.assign(this.vertices).length;
  }
}

export const getCookie = (cookieName: string): string => Cookies.get(cookieName);

const getOrCreateCookie = (cookieName: string, initial?: any): any => {
  const c = Cookies.getJSON(cookieName);
  if (!c) {
    const value = initial || {};
    Cookies.set(cookieName, value);
    return value;
  } else {
    return c;
  }
};

export type UpsolverBICookie = {
  _biSource: string;
  _biAd: string;
  _biCmp: string;
  _biKw: string;
  _biRef: string;
  _biPlatform: string;
  _sent?: boolean;
};

const DEFAULT_COOKIE = {
  domain: ".upsolver.com",
  path: "/",
  expires: 365,
};
const BI_COOKIE_NAME = "upsolverbi";

export const GetCookies = {
  HubSpot: () => getCookie("hubspotutk"),
  UpsolverBI: () => ({
    get: (): UpsolverBICookie => getOrCreateCookie(BI_COOKIE_NAME, DEFAULT_COOKIE),
    set: (v: any) => Cookies.set(BI_COOKIE_NAME, v),
    clear: () => {
      Cookies.remove(BI_COOKIE_NAME);
      return getOrCreateCookie(BI_COOKIE_NAME, DEFAULT_COOKIE);
    },
  }),
};

export const HUBSPOT_POST_URI =
  "https://forms.hubspot.com/uploads/form/v2/3866910/b1e5ef61-2e4b-4f33-9dd5-085444bb185d";

export const HUBSPOT_POST_SIGNUP_URI =
  "https://forms.hubspot.com/uploads/form/v2/3866910/2e5bc27a-a7ec-4c90-9979-81ddfcc8aa40";

export const labelWithArray = (isArray: boolean, label: string) => label + (isArray ? "[]" : "");

export const numericTypeNames = ["long", "double", "int", "float", "number"];
export const numericTypeNamesCapitalized = ["long", "double", "int", "float", "number"].map((x) =>
  [x[0].toUpperCase(), ...x].join()
);
export const numericTypes = arrayToMap(
  numericTypeNames,
  (i) => i,
  (i) => i
);

export const numericTypeFilterMap = {
  any: (t: string) => true,
  boolean: (t: String) => t === "boolean",
  numeric: (t: string) => numericTypes[t],
  string: (t: string) => t === "string",
};

export const missingItemHandler = (push: any, word: any, redirectTarget: any, id: any) => {
  addNotification({
    title: "Not Found",
    message: `Could not find the ${word.single()} ${id}`,
    level: "warning",
    id: id,
  });
  push(redirectTarget);
};

export const joinPaths = (first: string, second: string): string => {
  const firstFixed = first.endsWith("/") ? first.substr(0, first.length - 1) : first;
  const secondFixed = second.startsWith("/") ? second.substr(1) : second;

  return firstFixed + "/" + secondFixed;
};

const duplicateNameRegex = /^(.*) \((\d+)\)$/;
export const duplicateName = (name: string): string => {
  const match = name.match(duplicateNameRegex);
  if (match !== null) {
    return `${match[1]} (${Number(match[2]) + 1})`;
  }
  return `${name} (1)`;
};

// if number then truncate to 9 digits
export const valueForDisplay = (value: string | number): string =>
  // @ts-ignore trust me it's a number
  (isNumber(value) ? Number(value.toFixed(9)) : value).toString();

// These two functions are paired with the backend implementation in SubscriptionPathPart.scala (escapeFieldName, unescapeFieldName)
export const escapeFieldName = (path: string, escapeArrays: boolean = true): string => {
  const s = path.replace(/\\/, "\\\\").replace(/\./g, "\\.");
  return escapeArrays ? s.replace(/\[/g, "\\[").replace(/\]/g, "\\]") : s;
};

export const unescapeFieldName = (path: string): string => {
  return path.replace(/\\\./g, ".").replace(/\\\[/g, "[").replace(/\\\]/g, "]").replace(/\\\\/g, "\\");
};

export const stripFalse = (...args: Array<false | string>): string[] => {
  return args.filter((f) => f !== false).map((x) => x.toString());
};

export const MathUtils = {
  sigFigs: (n: number, sig: number) => {
    // 0 causes NaN.
    if (n === 0) {
      return n;
    }
    const mult = Math.pow(10, sig - Math.floor(Math.log(n) / Math.LN10) - 1);
    return Math.round(n * mult) / mult;
  },
};

export const TimeWindowToMinutes = (value: number, unit: string) => {
  let expirationWindow;
  switch (unit) {
    case "Minute":
      expirationWindow = value;
      break;
    case "Hour":
      expirationWindow = value * 60;
      break;
    case "Day":
      expirationWindow = value * 60 * 24;
      break;
    case "Infinite":
      expirationWindow = TimeUnits.InfiniteMinutes;
      break;
    default:
      throw new Error("Unknown expiration window:" + value);
  }
  return expirationWindow;
};

export const scrollIntoView = (element: any) => {
  if (element) {
    if (element.scrollIntoViewIfNeeded) {
      // Experimental Feature (Does the same as the else, but for some browser the else version is not supported)
      element.scrollIntoViewIfNeeded(false);
    } else {
      element.scrollIntoView({ block: "nearest" });
    }
  }
};

export const getIsMac = () => navigator.platform.startsWith("Mac");
export const isMac = getIsMac();
export const shiftKey = isMac ? "⇧" : "Shift";
export const ctrlKey = isMac ? "⌃" : "Ctrl";
export const altKey = isMac ? "⌥" : "Alt";
export const modifierKey = isMac ? "⌘" : "Ctrl";

export const modifiedKeyPressed = (e: React.KeyboardEvent | KeyboardEvent) => {
  return getIsMac() ? e.metaKey : e.ctrlKey || e.shiftKey;
};

export const metadataClazzToName = (clazz: string, metadataKey: string) => {
  const metadata = (window.globalStores.metadataStore.Metadata() as any)[metadataKey];
  const item =
    typeof metadata.find === "function"
      ? metadata.find((x: SimpleMetadata) => x.clazz === clazz)
      : metadata[clazz].displayName;
  return item ? item.displayName : null;
};

export const lastLabel = (e: string) => {
  const splitted = splitPath(e);
  return unescapeFieldName(splitted[splitted.length - 1]);
};
