import React, { useContext } from "react";
import { FormInput, FormInputProps } from "./FormInputTypes";
import { GlobalContext } from "../../GlobalContext";
import { DisablerContext } from "./FormInputs/Disabler";
import FormItemDecorator from "./FormInputs/FormItemDecorator";
import FormGroupInput from "./FormInputs/FormGroupInput";
import { observer } from "mobx-react-lite";
import { Field as MField } from "../type-definitions/mobx-react-form";
import ServerSideValidation from "./FormInputs/ServerSideValidation";
import HideOnSingle from "../forms/HideOnSingle";
import HideFormField from "../forms/Hide";

const isFieldsContainer = (input: FormInput) => {
  const lastDot = input.path.lastIndexOf(".");
  const lastDash = input.path.lastIndexOf("-");
  const subFieldContainer = lastDot !== -1 && lastDash !== -1 && lastDash > lastDot;
  return (
    input.metadata && input.incremental && (!input.path.includes(".") || subFieldContainer) && input.fields.size > 0
  );
};

export type UFormFieldProps = Partial<FormInputProps & { props: any; scrollRef: (el: HTMLElement) => void }>;

const __UFormField: React.FC<UFormFieldProps> = observer((props) => {
  const { formStore, formFieldRegistry } = useContext(GlobalContext);
  const disabled = useContext(DisablerContext);
  const input = props.input;
  const metadata = input.metadata;
  const extraProps = props.props || {};

  const invalidErr = (i: MField) => {
    const err = "Got input without type (or invalid type) " + i.path + ": " + metadata.type;
    console.log(err, i);
    throw err;
  };
  if (metadata.customView) {
    const View = metadata.customView;
    return (
      <View ref={props.scrollRef} vertical={props.vertical} input={input} hideLabel={props.hideLabel} {...extraProps} />
    );
  } else {
    const payloadProps = {
      input: input,
      onChange: metadata.changeHandler,
      vertical: props.vertical,
      disabled: disabled,
      ...extraProps,
      hideLabel: props.hideLabel,
      formStore: formStore,
      type: metadata.type,
    };

    let v;
    let decorate = true;
    let compact = props.compact;
    if (metadata.type === undefined) {
      if (isFieldsContainer(input)) {
        v = (
          <FormGroupInput
            {...payloadProps}
            compact={props.compact}
            vertical={props.vertical}
            input={input}
            noCollapse={true}
          />
        );
      } else {
        invalidErr(input);
      }
    } else {
      let formField = formFieldRegistry.tryCreate(metadata.type, payloadProps);
      if (formField?.component) {
        decorate = formField.decorate;
        v = formField.component;
      } else {
        invalidErr(input);
      }
    }

    if (props.decorate === false || !decorate) {
      return v;
    }

    return (
      <FormItemDecorator
        inputRef={props.scrollRef}
        vertical={props.vertical}
        compact={compact}
        hideLabel={props.hideLabel}
        input={input}
      >
        {v}
      </FormItemDecorator>
    );
  }
});

__UFormField.displayName = "UFormField";

export const UFormField: React.FC<UFormFieldProps> = observer((props) => {
  return (
    <HideFormField input={props.input}>
      <HideOnSingle input={props.input}>
        <ServerSideValidation {...props}>
          <__UFormField {...props} />
        </ServerSideValidation>
      </HideOnSingle>
    </HideFormField>
  );
});

UFormField.displayName = "FormFieldWrapper";
