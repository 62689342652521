import { action, IObservableArray, observable } from "mobx";
import { Api } from "../core/api/Api";
import { Fields } from "../core/Utils";

const SelectFields: any = {
  fields: ["General.cloudStorage"],
  rules: {
    "General.cloudStorage": "required",
  },
  labels: {
    "General.cloudStorage": "Storage",
  },
  types: {
    "General.cloudStorage": "select",
  },
  options: {
    "General.cloudStorage": [],
  },

  getFields: (): Fields => ({
    fields: SelectFields.fields,
    rules: SelectFields.rules,
    labels: SelectFields.labels,
    types: SelectFields.types,
    options: SelectFields.options,
  }),

  valuesToDefinition: (cs: any) => cs.General.cloudStorage,
};

const S3ConfigFields = {
  fields: ["S3.bucket", "S3.path", "S3.region", "S3.accessKey", "S3.secretKey"],
  rules: {
    "S3.bucket": "required|string",
    "S3.path": "required|string",
    "S3.region": "required|string",
    "S3.accessKey": "required|string",
    "S3.secretKey": "required|string",
  },
  labels: {
    "S3.bucket": "Bucket",
    "S3.path": "Path",
    "S3.region": "Region",
    "S3.accessKey": "Access Key",
    "S3.secretKey": "Secret Key",
  },
  types: {
    "S3.bucket": "text",
    "S3.path": "text",
    "S3.region": "select",
    "S3.accessKey": "text",
    "S3.secretKey": "text",
  },
  options: {
    "S3.region": ["eu-west-1", "us-east-1"],
  },
  secret: ["S3.accessKey", "S3.secretKey"],

  getFields: (): Fields => ({
    fields: S3ConfigFields.fields,
    rules: S3ConfigFields.rules,
    labels: S3ConfigFields.labels,
    types: S3ConfigFields.types,
    options: S3ConfigFields.options,
    secret: S3ConfigFields.secret,
  }),

  valuesToDefinition: ({ S3 }: any) => ({
    clazz: "S3CloudStorage",
    bucket: S3.bucket,
    path: S3.path,
    region: S3.region,
    accessKey: S3.accessKey,
    secretKey: S3.secretKey,
  }),
};

class CloudStorageStore {
  _api: Api;
  _apiOp: string = "storage/";

  constructor(api: Api) {
    this._api = api;
  }

  @observable list: IObservableArray<any> = observable.array();
  @observable isLoading: boolean = false;

  @action add(item: any) {
    return this._api.post(this._apiOp, item);
  }

  get(id: string): Promise<{ clazz: string; label: string }> {
    return this._api
      .get("connections/id/" + id)
      .then((connection) => CloudStorageStore.mapValue(connection, connection.connection));
  }

  static fullPath(a: string, b: string) {
    return `${a}/${b || ""}`.replace("//", "/");
  }

  static mapValue(value: any, inner: any): { clazz: string; label: string } {
    let label = inner.displayValue;
    // TODO: remove this once backend is updated for all customers
    if (!label) {
      switch (inner.clazz) {
        case "AerospikeConnection":
          label = `aerospike://${inner.hosts}`;
          break;
        case "AzureConnection":
        case "AzureCloudStorage":
          label = `azure://${CloudStorageStore.fullPath(inner.container, inner.path)}`;
          break;
        case "S3Connection":
        case "S3CloudStorage":
        case "S3ConnectionWithSQS":
          label = `s3://${CloudStorageStore.fullPath(inner.bucket, inner.path)}`;
          break;
        case "FileSystemConnection":
        case "FileSystemCloudStorage":
          label = "file://" + inner.path;
          break;
        case "RedshiftConnection":
        case "MySqlConnection":
        case "PosgreSqlConnection":
        case "MicrosoftSqlServerConnection":
        case "SnowflakeConnection":
          label = inner.connectionString;
          break;
        case "ElasticConnection":
          label = inner.connectionString;
          break;
        case "AthenaConnection":
          label = (inner.displayData && inner.displayData.name) || "N/A";
          break;
        case "KinesisConnection":
          label = inner.accessKey || `${inner.displayData.name}/${inner.region}` || "N/A";
          break;
        case "HDFSConnection":
          label = inner.path;
          break;
        case "QuboleConnection":
          label = "Qubole / " + inner.region;
          break;
        case "SpotinstPrivateVPCConnection":
          label = "Spotinst Private VPC";
          break;
        default:
          label = `${inner.clazz}/${inner.path}`;
          break;
      }
    }

    return Object.assign(value, {
      clazz: inner.clazz,
      label: label,
    });
  }

  @action reload() {
    this.isLoading = true;
    return this._api.get(this._apiOp).then(
      (response: any[]) => {
        this.list.replace(response.map((cs) => CloudStorageStore.mapValue(cs, cs.cloudStorage)));
        this.isLoading = false;
        return this.list;
      },
      (err) => {
        console.log("Error loading inputs list: ", err);
        throw err;
      }
    );
  }

  populateDisplayData(target: any[]) {
    this.reload().then(() =>
      this.list.forEach((cs) =>
        target.push({
          clazz: cs.clazz,
          label: cs.bucket + "/" + cs.path,
          id: cs.id,
        })
      )
    );
  }

  @action getSelectFields(): Fields {
    this.reload();
    return Object.assign(SelectFields, { options: { "General.cloudStorage": this.list } }).getFields();
  }
}

export { CloudStorageStore, S3ConfigFields, SelectFields };
