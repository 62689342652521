import { observer } from "mobx-react";
import React, { useEffect, useMemo, useState } from "react";
import { decorateOnChange, FormSelectInputProps, inputOnChange } from "../FormInputTypes";
import { withFormContext, WithFormContextProps } from "../UForm";
import { action, IObservableArray, observable, observe, reaction } from "mobx";
import { debounce } from "../../Utils";
import FormSelectInput from "./FormSelectInput";
import { Spin } from "../Spin";
import { Button } from "../Button";
import Checkbox from "@mui/material/Checkbox";
import { FormFieldRegistry } from "../../FormFieldRegistry";
import { MissingAutoCompleteFieldError } from "../../BaseForm";
import { AutoCompleteMetadata } from "../../SimpleMetadata";
import { AppContext } from "../../../routes/AppContext";
import { useAutocomplete } from "../../hooks/useAutocomplete";

export interface FormAutocompleteInputProps extends FormSelectInputProps, WithFormContextProps {
  autocompleteMetadata?: AutoCompleteMetadata;
}

export const FormAutoCompleteListInput = observer((props: FormAutocompleteInputProps) => {
  const { input, autocompleteMetadata, formContext, onChange } = props;

  const [context, setContext] = useState<any>();
  const { options, loadOptions, error } = useAutocomplete();

  useEffect(() => {
    return reaction(
      () => [Object.entries(input.metadata.context ?? {}), input.metadata.hidden],
      () => {
        try {
          const initialContext =
            input.metadata?.autocomplete?.getContext != null ? input.metadata.autocomplete.getContext() : {};

          setContext(initialContext);
        } catch (e) {
          if (e !== MissingAutoCompleteFieldError) {
            throw e;
          }
        }
      },
      { fireImmediately: true }
    );
  }, [input.metadata]);

  useEffect(() => {
    if (input.metadata.immutable || input.metadata.hidden || context == null) {
      return;
    }

    (async () => {
      input.metadata.loading = true;
      await loadOptions(_autocompleteMetadata, context);
      input.metadata.loading = false;
    })();
  }, [context]);

  useEffect(() => {
    const response = error?.response;
    if (response) {
      const { onValidationError } = formContext;
      if ((!onValidationError || !onValidationError(response.body)) && response.message && response.showInUI) {
        input.invalidate(response.message);
      }
    }
  }, [error]);

  const _autocompleteMetadata = useMemo(() => {
    return autocompleteMetadata || input.metadata.autocomplete;
  }, [autocompleteMetadata, input.metadata]);

  const disabled = useMemo<boolean>(() => {
    return options.length === 0 && _autocompleteMetadata?.required;
  }, [options, _autocompleteMetadata]);

  useEffect(() => {
    if (input.isPristine && options?.length > 0 && !input.$value) {
      const defaultOption = options.find((option: any) => option.default) ?? (options.length === 1 && options[0]);

      if (defaultOption) {
        const value = _autocompleteMetadata?.sequence ? [defaultOption.id] : defaultOption.id;

        decorateOnChange(input, onChange)(value);
      }
    }
  }, [input.isPristine, input.$value, options, _autocompleteMetadata]);

  return (
    <FormSelectInput
      {...props}
      options={options}
      multiple={_autocompleteMetadata?.sequence}
      showSearch
      disabled={disabled}
      allowCreate={_autocompleteMetadata?.required === false}
    />
  );
});

export const FormAutoCompleteListInputWithFormContext = withFormContext(FormAutoCompleteListInput);

export const register = (formFieldRegistry: FormFieldRegistry) =>
  formFieldRegistry.register("autocompletee", (props) => <FormAutoCompleteListInputWithFormContext {...props} />);

@observer
export class FormAutoCompleteMultiSelectInput extends React.Component<
  FormSelectInputProps & {
    autocompleteMetadata?: AutoCompleteMetadata;
    onAdd?: (value: string) => void;
    onRemove?: (value: string) => void;
    checked?: (value: string) => boolean;
  } & WithFormContextProps
> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;
  @observable.ref _options: IObservableArray<any> = observable.array();
  @observable loading: boolean = false;
  _dispose: () => void;
  _numberOfRequests: number = 0;

  componentDidMount() {
    const props = this.props;
    this._loadOptions = debounce(this._loadOptions, this, 300);
    this._dispose = observe(props.input.metadata, (change: any) => {
      if (change.name === "context") {
        this._loadOptions(change.newValue);
      }
    });

    try {
      const initialContext =
        this.props.input.metadata?.autocomplete?.getContext != null
          ? this.props.input.metadata.autocomplete.getContext()
          : {};

      this._loadOptions(initialContext);
    } catch (e) {
      if (e !== MissingAutoCompleteFieldError) {
        throw e;
      }
    }
  }

  _loadOptions(context: any) {
    const {
      input,
      formContext: { onValidationError },
    } = this.props;

    if (input.metadata.immutable || input.metadata.hidden) {
      return;
    }

    const autoCompleteMetadata = this.props.autocompleteMetadata || input.metadata.autocomplete;
    const { clazz, parameters } = autoCompleteMetadata;
    const contextHasItems =
      Object.values(context).findIndex((v) => typeof v !== undefined && v !== null && v !== "") > -1;
    if (autoCompleteMetadata.context.length === 0 || contextHasItems) {
      this.loading = true;
      this._numberOfRequests++;
      input.metadata.loading = true;
      this.context.opsInspection
        .autocompleteItems(Object.assign(context, { clazz, parameters }))
        .catch(
          action((error: any) => {
            //TODO: ts-conver superagent response type
            if (error && error.response) {
              if (!onValidationError || !onValidationError(error.response.body)) {
                input.invalidate(error.response.text);
              }
            }
            return [];
          })
        )
        .then(
          action((l: any[]) => {
            this._options.replace(
              l.map((r) => ({
                id: r.id,
                label: typeof r.displayValue === "function" ? r.displayValue() : r.displayValue,
              }))
            );
            this._numberOfRequests--;
            input.metadata.loading = this._numberOfRequests > 0;
          })
        )
        .finally(action(() => (this.loading = false)));
    }
  }

  componentWillUnmount() {
    if (this._dispose) {
      this._dispose();
    }
  }

  handleCheck = action((id: string, checked: boolean) => {
    const input = this.props.input;
    if (this.props.onAdd) {
      if (checked) {
        this.props.onAdd(id);
      } else {
        this.props.onRemove(id);
      }
    } else {
      const changeHandler = this.props.onChange || inputOnChange(input);
      if (checked && !input.value.includes(id)) {
        input.value.push(id);
        changeHandler(input.value);
      } else {
        changeHandler(input.value.filter((x: any) => x !== id));
      }
    }
  });

  render() {
    const { input } = this.props;
    return (
      <Spin spinning={this.loading}>
        {!this.props.onAdd && (
          <div className="centered-flex-row">
            <Button
              type="borderless"
              onClick={action(() => inputOnChange(this.props.input)(this._options.map((x) => x.id)))}
            >
              Select All
            </Button>
            <Button type="borderless" onClick={action(() => inputOnChange(this.props.input)([]))}>
              Clear Selection
            </Button>
          </div>
        )}
        <ul className="checkbox-input-list">
          {this._options &&
            this._options.map((x: { id: string; label: string }) => (
              <li key={x.id}>
                <Checkbox
                  color="primary"
                  id={x.id}
                  checked={this.props.checked ? this.props.checked(x.id) : input.value.includes(x.id)}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleCheck(x.id, e.currentTarget.checked)}
                />
                <label htmlFor={x.id}>{x.label}</label>
              </li>
            ))}
        </ul>
      </Spin>
    );
  }
}
export const FormAutoCompleteMultiSelectInputWithFormContext = withFormContext(FormAutoCompleteMultiSelectInput);
