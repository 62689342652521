import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { GlobalContext, useAuth } from "../GlobalContext";
import { Spin } from "../core/views/Spin";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import { Button, FlatButton } from "../core/views/Button";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router";
import Menu from "@mui/material/Menu";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import { LimitedText } from "../core/views/LimitedText";
import { Organization } from "../core/api/contracts/Organization";
import { useLoginStateProvider } from "../LoginStateProvider";

const defaultOrgKey = "defaultOrg";

function useRememberDefaultOrg(
  gotoDefault: boolean,
  organizations: Organization[]
): [(org: Organization) => void, (save: boolean) => void, boolean, boolean] {
  const { userPreferences, auth } = useContext(GlobalContext);
  const [redirecting, setRediecting] = useState(true);
  const [saveDefaultOrg, setSaveDefaultOrg] = useState<boolean>(!!userPreferences.get()[defaultOrgKey]);
  const onChangeSaveDefaultOrg = (value: boolean) => {
    if (!value) {
      // userPreferences.removeKey(defaultOrgKey);
    }
    setSaveDefaultOrg(value);
  };
  const trySaveDefaultOrg = (org: Organization) => {
    if (saveDefaultOrg) {
      // userPreferences.upsertKey(defaultOrgKey, org.id);
    }
  };
  useEffect(() => {
    const orgId = false && gotoDefault && userPreferences.get()[defaultOrgKey];
    const org = orgId && organizations.find((x) => x.id === orgId);
    if (org) {
      auth.setLoginOrg(org).finally(() => setRediecting(false));
    } else {
      setRediecting(false);
    }
  }, [userPreferences, gotoDefault]);

  return [trySaveDefaultOrg, onChangeSaveDefaultOrg, saveDefaultOrg, redirecting];
}

export const OrganizationSelectList = observer(
  (props: {
    organizations: Organization[];
    onSelect?: (org: Organization) => void;
    gotoDefault: boolean;
    hasDefault?: boolean;
    fullHeight?: boolean;
  }) => {
    const { onSelect, organizations, gotoDefault } = props;
    const hasDefault = props.hasDefault == false ? false : true;
    const loginState = useLoginStateProvider();
    const [trySaveDefaultOrg, onChangeSaveDefaultOrg, saveDefaultOrg, redirecting] = useRememberDefaultOrg(
      gotoDefault,
      organizations
    );
    const [loading, setLoading] = useState(false);

    const onSelectOrg = (org: Organization) => {
      setLoading(true);
      trySaveDefaultOrg(org);
      if (onSelect) {
        onSelect(org);
      } else {
        loginState.setLoginOrg(org);
      }
    };

    return (
      <Spin spinning={loading || redirecting}>
        <List style={props.fullHeight ? {} : { maxHeight: 300, overflowY: "auto" }}>
          {organizations.map((org, index) => {
            const onClick = () => onSelectOrg(org);
            return (
              <>
                <ListItem className="clickable" onClick={onClick} id="">
                  <ListItemText primary={org.displayData.name} secondary={org.displayData.description} />
                  <ListItemSecondaryAction>
                    <Button type="secondary" onClick={onClick} small>
                      Select
                    </Button>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
              </>
            );
          })}
        </List>
        {hasDefault && (
          <FormControlLabel
            control={
              <Checkbox onChange={(e) => onChangeSaveDefaultOrg(e.currentTarget.checked)} checked={saveDefaultOrg} />
            }
            label={<Typography variant="body2">Use Selection as Default</Typography>}
          />
        )}
      </Spin>
    );
  }
);

export const SelectOrganization: React.FC = observer(() => {
  const [el, setEl] = useState<HTMLButtonElement>();
  const [useAsDefault, setUseAsDefault] = useState(false);
  const loginState = useLoginStateProvider();
  const [trySaveDefaultOrg, onChangeSaveDefaultOrg] = useRememberDefaultOrg(false, loginState.status?.organizations);

  const onSelectOrg = useCallback(
    (org: Organization) => {
      setEl(null);
      if (useAsDefault) {
        trySaveDefaultOrg(org);
        setUseAsDefault(false);
      }
      loginState.setLoginOrg(org, { postUrl: "/" });
    },
    [useAsDefault, loginState.setLoginOrg, trySaveDefaultOrg]
  );

  const selectedOrg = loginState.status?.selectedOrganization;
  if (!selectedOrg) {
    return null;
  }
  return (
    <>
      <FlatButton
        // className={orgStyles.button}
        onClick={(e) => setEl(e.currentTarget)}
        title={selectedOrg.displayData.name}
      >
        <LimitedText limit={150}>{selectedOrg.displayData.name}</LimitedText>
      </FlatButton>
      <Menu
        anchorEl={el}
        open={Boolean(el)}
        onClose={() => {
          setEl(null);
          setUseAsDefault(false);
        }}
      >
        <Box
          fontSize="body2.fontSize"
          fontWeight="bold"
          p={1}
          style={{ textAlign: "center", textTransform: "uppercase" }}
        >
          Select Organization
        </Box>
        <Divider />
        {loginState.status?.organizations?.map((org) => (
          <MenuItem onClick={() => onSelectOrg(org)} key={org.id}>
            <Box p={1} pl={2}>
              {org.displayData.name}
            </Box>
          </MenuItem>
        ))}
        <Divider />
        <MenuItem>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => {
                  const checked = e.currentTarget.checked;
                  if (checked) {
                    onChangeSaveDefaultOrg(checked);
                  }
                  setUseAsDefault(checked);
                }}
                checked={useAsDefault}
              />
            }
            label={<Typography variant="body2">Use As Default</Typography>}
          />
        </MenuItem>
      </Menu>
    </>
  );
});
