import React from "react";
import { FormFieldRegistry } from "../../../FormFieldRegistry";
import { ExplainUpdateOutputIntervalAndWindowSizeOverride } from "./ExplainUpdateOutputIntervalAndWindowSizeOverride";
import { WindowSizeOverrideInput } from "./WindowSizeOverride";

export const register = (formFieldRegistry: FormFieldRegistry) => {
  formFieldRegistry.register("window-size-override", (props) => <WindowSizeOverrideInput {...props} />);
  formFieldRegistry.register(
    "window-size-override-explain",
    (props) => <ExplainUpdateOutputIntervalAndWindowSizeOverride {...props} />,
    {
      decorate: false,
    }
  );
};
