import validatorjs from "validatorjs";
import { Field, Form } from "mobx-react-form";
import { action, computed, entries, extendObservable, IObservableArray, observable, set, values } from "mobx";
import isObjectLike from "lodash/isObjectLike";
import { ValidationResult } from "./api/contracts/ValidationResult";
import { AutoCompleteMetadata, ContextMetadata, ServerSideValidation, StepMetadata } from "./SimpleMetadata";
import { deepClone } from "./deepClone";
import { EditorInformation } from "./api/contracts/FormFields";

export type FormField = {
  name: string;
  label: string;
  rules: string;
};

class UpsolverField extends Field {
  @observable metadata: any = {};
  @observable loading: boolean = false;

  [key: string]: any;

  //@ts-ignore
  constructor(data: any, simple?: boolean) {
    //TODO: something changed in the data we got from mobx-forms when calling add find out if we can avoid this HACK
    if (data.data instanceof Object && !data.data.fields) {
      data.data.fields = deepClone(data.data);
      data.data.simple = simple;
    }
    super(data);
    this.metadata = data.data;
  }

  getForm(): BaseFormWithArrayFields {
    let formOrField: BaseFormWithArrayFields | UpsolverField;
    formOrField = this.container();

    if (formOrField instanceof UpsolverField) {
      return formOrField.getForm();
    }

    return formOrField;
  }

  tryGetField(field: string): UpsolverField | false {
    return tryGetField(field, this);
  }

  /**
   * find field and possibly relate subFieldsSelector fields
   * @param {string} fieldName
   */
  getAllRelevantFields(field: string): UpsolverField[] {
    return getAllRelevantFields(field, this);
  }

  onValidateError(errors?: any, options: OnFormValidateErrorOptions = { override: true }): boolean {
    return onValidateError(errors, this, options);
  }
}

/**
 * Base form class, containes validation plugins.
 */
class BaseForm extends Form {
  // getFields: Function = () => throw new Error("Not Implemented in base");

  static plugins = { dvr: validatorjs };

  constructor(fields: FormField[]) {
    super({ fields, plugins: BaseForm.plugins });
  }

  makeField(data: any) {
    return new UpsolverField(data, true);
  }

  onValidateError: (errors: any | null, options?: any) => boolean;
}

const arrayAccessRegex = new RegExp("^(.*)\\[(\\d*)]$");

/**
 * find field and possibly relate subFieldsSelector fields
 * @param {string} fieldName
 * @param container
 */
function getAllRelevantFields(fieldName: string, container: BaseFormWithArrayFields | UpsolverField): UpsolverField[] {
  return entries(container.fields)
    .filter(([k, v]) => k === fieldName || k.startsWith(`${fieldName}-`))
    .map(([_, v]) => v);
}

function tryGetField(field: string, container: BaseFormWithArrayFields | UpsolverField): UpsolverField | false {
  const splitted = field.split(".");
  let current: any = container;

  for (const x in splitted) {
    const currentFieldName = splitted[x];
    const arrayAccessMatch = arrayAccessRegex.exec(currentFieldName);
    if (arrayAccessMatch) {
      const field = arrayAccessMatch[1];
      const index = arrayAccessMatch[2];
      if (current.has(field)) {
        current = current.$(field);
        current = current.$value[parseInt(index)].form;
      } else {
        return false;
      }
    } else if (current.has(currentFieldName)) {
      current = current.$(currentFieldName);
    } else {
      const wantedPath = current.name + "-" + current.value;
      const parent = current.container();
      if (parent.has(wantedPath) && parent.$(wantedPath).has(currentFieldName)) {
        current = parent.$(wantedPath).$(currentFieldName);
      } else {
        return false;
      }
    }
  }

  return current;
}

type FormContextProvider = (fieldName: string, form: BaseFormWithArrayFields) => any;

export const MissingAutoCompleteFieldError = new Error("Missing autocomplete context field");

/**
 * Attempt to get a value for a fields it's autocomplete/context providers if they exists
 */
class FormFieldValuesProvider {
  constructor(
    private form: BaseFormWithArrayFields,
    private formFields: IObservableArray<UpsolverField>,
    private contextProvider: FormContextProvider
  ) {}

  getValues(fieldNames: string[], parent = this.form, metadata: any, baseFieldName?: string, allowMissing?: boolean) {
    const values: Record<string, Record<string, unknown>> = {};

    const getValueWithContext = (fieldName: string, localValue: any, externalValue: any) => {
      if (Object.keys(metadata.autocomplete?.entityContext ?? {}).length > 0) {
        // Allow using context with entityContext
        return externalValue == null || metadata.autocomplete?.context.includes(fieldName) ? localValue : externalValue;
      } else {
        // When there aren't entityContext prefer external over context
        // Support external which isn't form the entity
        return externalValue == null ? localValue : externalValue;
      }
    };

    fieldNames.forEach((n) => {
      const external = this.contextProvider(n, parent);
      const nameParts = n.split(".");
      const fieldName = nameParts.length > 1 ? nameParts[0] : n;
      const parentField = parent.has(fieldName) && parent.$(fieldName);
      const fieldValue = parentField?.value || (parent.fields.get(n)?.default ??  "");
      const required = parentField?.rules?.includes("required");
      const value = getValueWithContext(n, fieldValue, external);

      if (
        !allowMissing &&
        !value &&
        (!baseFieldName ||
          (parentField?.metadata?.autocomplete && (parentField?.name !== fieldName || !parentField.value)))
      ) {
        throw MissingAutoCompleteFieldError;
      }
      if (required || value !== "") {
        if (!isObjectLike(value) || Object.values(value).some((x: any) => x)) {
          if (n === fieldName) {
            values[fieldName] = value;
          } else {
            values[n] = isObjectLike(value) ? value[nameParts[nameParts.length - 1]] : value;
          }
        }
        const clazz = value?.clazz;
        if (clazz) {
          const wantedPrefix = n + "-" + clazz + ".";
          this.formFields.forEach((field) => {
            const path = field.path;
            const indexOfPrefix = path.indexOf(wantedPrefix);
            if (indexOfPrefix === 0) {
              const subFieldName = path.substr(wantedPrefix.length);
              values[fieldName][subFieldName] = field.value;
            }
          });
        }
      }
    });
    return values;
  }
}

/**
 * Base form class, containes validation plugins.
 */
class BaseFormWithArrayFields extends Form {
  // $: (string) => UField;
  // set: (field: string, value?: any) => void;
  // onSubmit: (e: any, opts: { onSuccess: () => void }) => void;
  // values: () => any;
  validate(options: { showErrors: boolean }): Promise<{ isValid: boolean }> {
    return super.validate(options);
  }

  // each: any;
  // size: number;
  // errors: () => any;
  // has: (string) => boolean;
  // fields: any;
  // isValid: boolean;

  @observable _fields: IObservableArray<UpsolverField> = observable.array();
  _labels: any;
  _types: any;
  _options: any;
  _changeHandlers: any;
  _dependantOptions: any;
  _metadata: any = {};
  _customViews: any;
  _secret: any[] = [];
  _descriptions: Array<string | null> = [];
  _hidden: any;
  _autocomplete: { [key: string]: AutoCompleteMetadata };
  _contextProvider: FormContextProvider;
  _readonly: any;
  _defaultValueProviders: { [key: string]: () => Promise<any> };
  _placeholders: { [key: string]: string };
  _immutables: { [key: string]: boolean };
  public fieldValuesProvider: FormFieldValuesProvider;

  /**
   * Creates an instance of BaseFormWithArrayFields.
   */
  // @ts-ignore
  constructor(
    fields: { [key: string]: any },
    labels?: any,
    rules?: any,
    types?: any,
    options?: any[],
    changeHandlers?: { [key: string]: (value: any) => void },
    dependantOptions?: any[],
    customViews?: any[],
    values?: any[],
    validatorExtender?: (validatorjs: unknown) => void,
    secret: any[] = [],
    descriptions: Array<string | null> = [],
    hidden?: any,
    related?: any[],
    autocomplete?: { [key: string]: AutoCompleteMetadata } | null,
    contextProvider: (c?: string) => any = () => undefined,
    readonly?: any,
    defaultValueProviders: { [key: string]: () => Promise<any> } = {},
    placeholders?: { [key: string]: string },
    immutables: { [key: string]: boolean } = {},
    disabled?: { [key: string]: boolean },
    private serverSideValidation: Record<string, ServerSideValidation> = {},
    private step: Record<string, StepMetadata> = {},
    private editorInformation: Record<string, EditorInformation> = {},
    private dynamicDescriptions?: Record<string, () => Promise<any>>
  ) {
    const plugins = {
      dvr: {
        package: validatorjs,
        extend: ($validator: any) => {
          if (validatorExtender) {
            validatorExtender($validator);
          }
        },
      },
    };

    super(
      {
        fields,
        rules: rules,
        labels: labels,
        initials: values,
        values: values,
        related: related,
        disabled: disabled,
      },
      { plugins: plugins, options: { showErrorsOnUpdate: true, showErrorsOnReset: false } }
    );

    this._disabled = disabled || {};
    this._immutables = immutables || {};
    this._types = types || {};
    this._options = options || {};
    this._labels = labels || {};
    this._changeHandlers = changeHandlers || {};
    this._dependantOptions = dependantOptions || {};
    this._customViews = customViews || {};
    this._secret = secret;
    this._descriptions = descriptions;
    this._hidden = hidden;
    this._autocomplete = autocomplete || {};
    this._contextProvider = contextProvider;
    this._readonly = readonly || {};
    this._defaultValueProviders = defaultValueProviders;
    this._placeholders = placeholders || {};

    this._fields.forEach((f) => {
      const fieldMeta = this._createMetadata(f);
      if (!this._metadata[f.path]) {
        this._metadata[f.path] = fieldMeta;
      } else {
        this._metadata[f.path] = Object.assign(this._metadata[f.path], fieldMeta);
      }
      if (this._defaultValueProviders[f.path] && !values[f.path]) {
        f.loading = true;
        this._defaultValueProviders[f.path]()
          .then((x) => {
            f.sync(x);
            f.loading = false;
          })
          .catch(() => {
            f.loading = false;
          });
      }
    });
    this._fields.forEach((f) => (f.metadata = this._metadata[f.path]));
    this.fieldValuesProvider = new FormFieldValuesProvider(this, this._fields, this._contextProvider);
  }

  _getFieldValues(fieldNames: string[], parent = this, field: UpsolverField): any {
    // allow missing if the autocomplete uses the field itself and it isn't required
    const allowMissing = field?.metadata?.autocomplete?.required === false && fieldNames.includes(field.name);
    return this.fieldValuesProvider.getValues(fieldNames, parent, field.metadata, field.name, allowMissing);
  }

  _arrayPattern = /.+\.\d+\..+/;
  _arrayPostfixPattern = /.*\.[1-9]\d*\..*$/;

  // when the fields is the opening field of an array group field and it isn't the first element in the array then add a remove element method
  _shouldBindRemoveMethod(f: any) {
    return !f.incremental && this._arrayPostfixPattern.exec(f.path);
  }

  _shouldBindAddMethod(f: any) {
    return f.incremental && f.path.endsWith("0");
  }

  buildContextProvider(f: any, fieldContext: any, metadata: ContextMetadata) {
    const call = () => {
      set(
        fieldContext,
        this._getFieldValues([...(metadata.context || []), ...(metadata.entityContext || [])], f.container(), f)
      );
    };

    metadata.context.forEach((n: string) => {
      let observable = f.container();
      observable.observe({
        path: n,
        key: "value",
        call,
      });

      this._fields.forEach((f) => {
        if (f.path.includes(n + "-")) {
          this.observe({
            path: f.path,
            key: "value",
            call,
          });
        }
      });
    });

    metadata.getContext = () => {
      if (metadata) {
        return this._getFieldValues([...(metadata.context || []), ...(metadata.entityContext || [])], f.container(), f);
      } else {
        throw new Error(`Field ${f.path} has no context metadata`);
      }
    };
  }

  _createMetadata(f: any) {
    let metadata: any = {};
    const path = f.path.replace(/\.\d+\./gi, "[].");

    metadata.displayName = this._labels[path] || path;
    metadata.immutable = this._immutables[path] || false;
    metadata.type = this._types[path];

    if (this._dependantOptions[path]) {
      if (this._arrayPattern.exec(f.path) && !this._dependantOptions[f.path]) {
        extendObservable(this._dependantOptions, { [f.path]: [] });
      }
      metadata.options = this._dependantOptions[f.path];
    } else {
      metadata.options = this._options[path] || [];
    }

    metadata.customView = this._customViews[path];
    metadata.description = this._descriptions[path];

    if (this._shouldBindAddMethod(f)) {
      const parentPath = f.path.substr(0, f.path.length - 2);
      if (!this._metadata[parentPath]) {
        this._metadata[parentPath] = {};
      }

      this._metadata[parentPath].add = (e: unknown) => {
        // @ts-ignore
        this.$(parentPath).add();
      };
      this._metadata[parentPath].arrayContainer = true;
    }

    if (this._shouldBindRemoveMethod(f)) {
      const parentPath = f.path.slice(0, f.path.lastIndexOf("."));
      if (!this._metadata[parentPath]) {
        this._metadata[parentPath] = {};
      }

      this._metadata[parentPath].remove = (e: unknown) => {
        // @ts-ignore
        this.$(parentPath).del(f.path);
      };
      this._metadata[parentPath].arrayContainer = true;
    }

    metadata.changeHandler = this._changeHandlers[path];

    if (path.includes("[]")) {
      metadata.arrayElement = true;
    }
    if (this._secret.includes(f.path)) {
      metadata.secret = true;
    }

    metadata.hidden = this._hidden && this._hidden[f.path];
    metadata.autocomplete = this._autocomplete[f.path];
    metadata.readonly = this._readonly && this._readonly[f.path];
    metadata.context = observable.object({});
    metadata.serverSideValidation = this.serverSideValidation[f.path];
    metadata.dynamicDescriptions = this.dynamicDescriptions[f.path];
    metadata.step = this.step[f.path];

    if (metadata.autocomplete) {
      // if all context fields are optional allow sending empty autocomplete
      if(metadata.autocomplete.context != null) {
        const contextFieldsRules = this._fields.filter(x => metadata.autocomplete.context.includes(x.name)).map(x => x.rules)
        if (contextFieldsRules != null && !contextFieldsRules.some(rules => rules?.includes("required"))) {
          metadata.autocomplete.allowMissingContext = true;
        }
      }
      this.buildContextProvider(f, metadata.context, metadata.autocomplete);
    }

    if (metadata.serverSideValidation) {
      this.buildContextProvider(f, metadata.context, metadata.serverSideValidation);
    }

    if (metadata.dynamicDescriptions) {
      this.buildContextProvider(f, metadata.context, metadata.dynamicDescriptions);
    }

    if (this._placeholders.hasOwnProperty(f.path)) {
      metadata.placeholder = this._placeholders[f.path];
    }

    metadata.editorInformation = this.editorInformation[f.path];
    f.originalRules = f.rules;

    f.observe({
      path: "rules",
      call: () => {
        if (f.originalRules !== f.rules && !f.metadata.hidden) {
          f.originalRules = f.rules;
        }
      },
    });

    // // this is to prevent forms getting stuck by validating hidden fields while also allowing to validate them if shown
    // // for example this is used by the subFieldsSelector item fields
    f.observe({
      path: "metadata",
      call: () => {
        if (f.originalRules) {
          f.set("rules", f.metadata.hidden ? "" : f.originalRules);
        }
      },
    });

    return metadata;
  }

  makeField(data: any) {
    this._fields = this._fields || observable.array();
    const f = new UpsolverField(data);
    if (f.metadata && !f.metadata.type && this._types) {
      f.metadata = this._createMetadata(f);
    }
    this._fields.push(f);

    return f as any;
  }

  @action.bound
  onValidationError(errors?: any, options: OnFormValidateErrorOptions = { override: true }): boolean {
    return onValidateError(errors, this, options);
  }

  @action.bound
  onValidateError(errors?: any, options: OnFormValidateErrorOptions = { override: true }): boolean {
    return onValidateError(errors, this, options);
  }

  /**
   * Attempt to get a field by it's key. Supports full paths e.g, "a.b.c" will fetch c nested in b nested in a
   * @param field
   */
  tryGetField(field: string): UpsolverField | false {
    return tryGetField(field, this);
  }

  /**
   * find field and possibly relate subFieldsSelector fields
   * @param {string} fieldName
   */
  getAllRelevantFields(field: string): UpsolverField[] {
    return getAllRelevantFields(field, this);
  }
}

export interface ValidationException {
  validationErrors: ValidationResult[];
}

type OnFormValidateErrorOptions = Partial<{
  override: boolean;
  handlers: { [key: string]: () => void };
  markAsHadErrorFields: string[];
}>;

interface FieldError {
  error: string;
  field?: UpsolverField;
}

interface FormErrors {
  fieldsErrors: {
    [name: string]: FieldError[];
  };
  formErrors: FieldError[];
}

/**
 * Try to display error from an API response in the form if relevant
 * @param errors expected to be ValidationException
 * @param form a mobx-react-forms form instance to show errors on
 * @param options
 */
export const onValidateError = (
  errors: any | null,
  container: any,
  options: OnFormValidateErrorOptions = { override: true, handlers: {}, markAsHadErrorFields: [] }
): boolean => {
  if (errors && typeof errors === "object" && errors.validationErrors instanceof Array) {
    const globalErrors: FormErrors = errors.validationErrors.reduce(
      (globalErrors: FormErrors, currErr: ValidationResult) => {
        let field = container.tryGetField(currErr.property) || container.tryGetField(`Advanced.${currErr.property}`);

        if (field) {
          const fieldErrors = globalErrors.fieldsErrors[field.name] ?? [];

          globalErrors.fieldsErrors[field.name] = fieldErrors.concat({
            error: currErr.error,
            field,
          });
        } else {
          globalErrors.formErrors = globalErrors.formErrors.concat({
            error: currErr.error,
          });
        }

        return globalErrors;
      },
      {
        fieldsErrors: {},
        formErrors: [],
      }
    );

    for (let key in globalErrors.fieldsErrors) {
      const fieldErrors = globalErrors.fieldsErrors[key];

      const { field } = fieldErrors[0];
      const errorMsg = fieldErrors.map(({ error }) => error).join("\r\n\r\n");

      if (options.override || !field.hasError) {
        if (options.markAsHadErrorFields && options.markAsHadErrorFields.includes(field.name)) {
          field.metadata.everHadErrors = true;
        }

        field.invalidate(errorMsg);
      }

      const handler = options && options.handlers && options.handlers[field.path];
      if (handler) {
        handler();
      }

      // There might be a debounced validation from the blur event
      // of the field if the API has responded faster than 250ms
      if (field.debouncedValidation) {
        field.debouncedValidation.cancel();
      }
    }

    let globalErrorMsg = globalErrors.formErrors.map(({ error }) => error).join("\r\n\r\n");
    if (globalErrorMsg) {
      container.invalidate(globalErrorMsg);
    }

    return true;
  }

  return false;
};

export interface FormContainer {
  readonly form: BaseFormWithArrayFields;
  readonly formKey: string;
}

export class FormHolder implements FormContainer {
  @observable.ref innerForm: BaseFormWithArrayFields;

  _formKeyGetter: () => string;

  @computed
  get formKey(): string {
    return this._formKeyGetter();
  }

  constructor(form: BaseFormWithArrayFields, keyProvider: () => string = () => "") {
    this._formKeyGetter = keyProvider;
    this.innerForm = form;
  }

  get form(): BaseFormWithArrayFields {
    return this.innerForm;
  }
}

export function buildContextProvider(form: BaseFormWithArrayFields, fieldContext: any, metadata: ContextMetadata) {
  function _getFieldValues(fieldNames: string[]): any {
    return form.fieldValuesProvider.getValues(fieldNames, form, metadata, null, true);
  }
  const call = () => {
    set(fieldContext, _getFieldValues([...(metadata.context || []), ...(metadata.entityContext || [])]));
  };

  metadata.context.forEach((n: string) => {
    form.observe({
      path: n,
      key: "value",
      call,
    });
  });

  metadata.getContext = () => {
    if (metadata) {
      return _getFieldValues([...(metadata.context || []), ...(metadata.entityContext || [])]);
    } else {
      throw new Error(`Form has no context metadata`);
    }
  };
}

export { BaseFormWithArrayFields, BaseForm };
