import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { MetadataLogo } from "./MetadataLogo";

export const ItemLogo = ({ logo }: { logo: string }) => (
  <span className="logo">{logo && <span className={`logo-${logo}`} />}</span>
);
export const SimpleItemView = ({ item }: any) => <span className="item-simple">{item.title}</span>;
export const ItemView = ({ item }: any) => {
  return (
    <Box display="flex" alignItems="center">
      <Box flexGrow={0} flexShrink={0} flexBasis="auto">
        {item.logoMetadata ? (
          <MetadataLogo metadata={item.logoMetadata} clazz={item.logoMetadata.clazz} />
        ) : (
          <ItemLogo logo={item.logo} />
        )}
      </Box>
      <Box display="flex" flexDirection="column">
        <Typography variant="h6" style={{ fontWeight: "bold" }} noWrap>
          {item.title}
        </Typography>
        <Typography variant="subtitle1" noWrap>
          {item.subtitle && item.subtitle.toString()}
        </Typography>
      </Box>

      {item.validation === "invalid" && <span className="icon-notice" />}
      {item.validation === "loading" && <span className="spinner small" />}
    </Box>
  );
};
