import { UField } from "./api/contracts/UField";
import { fieldsTreeFeaturePrefixes, lastLabel, treeFeaturesWord, untilLastUnescapedDot } from "./Utils";
import { autorun, computed, decorate, observable, reaction } from "mobx";
import { TreeEntry } from "./views/Tree";
import { fuzzyFilter } from "./Fuzzy";
import debounce from "lodash/debounce";
import { BooleanContribRows, LoadingContribRows } from "./views/FieldsTreeContribRow";
import TreeContext, { TreeEntryInfo } from "./TreeContext";
import { DisplayData } from "./api/contracts/Core";
import { FieldTreeEntry } from "./views/TreeItem";
import {
  escapableTraverseItemTree,
  findTreeContextEntry,
  getFieldsTreeFeaturePrefix,
  getUnfilteredFlattenedTreePaths,
  traverseItemTree,
  treeEntryInfo,
} from "./TreeEntityUtils";
import { FastFieldsSplitter } from "./FieldsSplitter";

type Mutable<T> = { -readonly [P in keyof T]: T[P] };

type OnStatusUpdate = (field: UField, status: boolean) => void;

export class TreeEntriesStatus {
  @observable readonly status: Record<string, boolean>;

  constructor(private initialStatus: Record<string, boolean>, private readonly onStatusUpdate?: OnStatusUpdate) {
    this.status = initialStatus;
  }

  private setStatus(i: Partial<FieldTreeEntry>, value: boolean) {
    const hasKey = this.status.hasOwnProperty(i.key);
    if (!hasKey && value) {
      this.status[i.key] = true;
    } else if (hasKey && !value) {
      delete this.status[i.key];
    }

    if (this.onStatusUpdate && i.field && (value || hasKey)) {
      this.onStatusUpdate(i.field, value);
    }
  }

  public onChange(item: FieldTreeEntry, value: boolean, traverse: boolean = true) {
    if (traverse) {
      traverseItemTree([item], (i) => this.setStatus(i, value));
    } else {
      this.setStatus(item, value);
    }
  }

  public clone(onStatusUpdate?: OnStatusUpdate) {
    const clone = new TreeEntriesStatus(
      this.initialStatus,
      onStatusUpdate === undefined ? this.onStatusUpdate : onStatusUpdate
    ) as Mutable<TreeEntriesStatus>;
    clone.status = Object.assign({}, this.status);
    return clone as TreeEntriesStatus;
  }
}

type GetEntryContrib = (item: FieldTreeEntry) => Promise<FieldTreeEntry[]>;

export class UFieldsTreeContext implements TreeContext<FieldTreeEntry> {
  private checkedStatus: TreeEntriesStatus;
  private expandedStatus: TreeEntriesStatus;
  private previousExpanded: TreeEntriesStatus;
  private readonly disposable: Array<() => void> = [];
  private oldSelected: TreeEntry;
  @observable featuresFilter: boolean = false;
  @observable filterText: string = "";
  @observable.ref fields: Record<string, UField> = {};
  @observable contributedNodes: Record<string, FieldTreeEntry[]> = {};

  constructor(
    private readonly treeFields: UField[],
    private readonly onCheck: (field: UField, checked?: boolean) => void,
    private readonly getEntryContrib: GetEntryContrib = () => Promise.resolve([]),
    private readonly splitInputs: boolean = false,
    private readonly inputsContrib: Record<string, FieldTreeEntry[]> = {}
  ) {
    this.checkedStatus = new TreeEntriesStatus({}, onCheck);
    this.expandedStatus = new TreeEntriesStatus({ [treeFeaturesWord]: true });

    this.disposable = [
      reaction(
        () => this.filterText,
        (x) => {
          if (x.length) {
            if (!this.previousExpanded) {
              this.previousExpanded = this.expandedStatus.clone();
            }
            this.tree.forEach((x) => this.expandedStatus.onChange(x, true, true));
          } else if (this.previousExpanded) {
            this.expandedStatus = this.previousExpanded.clone();
            this.previousExpanded = null;
          }
        }
      ),
      autorun(() => {
        const fields: Record<string, UField> = {};
        for (let i = 0; i < this.treeFields.length; i++) {
          const field = this.treeFields[i];
          const key = UField.key(field);
          if (this.splitInputs) {
            const inputs = field.inputs;
            if (inputs && inputs.length > 0) {
              for (let j = 0; j < inputs.length; j++) {
                if (inputs[j].state.clazz !== "NotFound") {
                  fields[`${inputs[j].id}.${key}`] = field;
                }
              }
            } else {
              if (field.feature) {
                fields[`${getFieldsTreeFeaturePrefix(field)}.${key}`] = field;
              }
              if (field.output != null) {
                fields[`Output Fields.${key}`] = field;
              }
              fields[key] = field;
            }
          } else {
            fields[key] = field;
          }
        }
        this.fields = fields;
      }),
    ];
  }

  dispose() {
    this.disposable.forEach((x) => x());
  }

  @computed
  get tree() {
    const textFilteredFields = this.filterText
      ? fuzzyFilter(this.treeFields, ["label"], this.filterText)
      : this.treeFields;
    const filteredFields = this.featuresFilter
      ? textFilteredFields.filter((x) => x.featureField || x.feature)
      : textFilteredFields;
    return FastFieldsSplitter(filteredFields, this.contributedNodes, this.splitInputs, this.inputsContrib);
  }

  @computed
  get flattenedTree() {
    return getUnfilteredFlattenedTreePaths(this.tree, this.expandedStatus.status);
  }

  @computed
  get checked() {
    return this.checkedStatus.status;
  }

  @computed
  get expanded() {
    return this.expandedStatus.status;
  }

  @computed
  get filteredOut() {
    return (this.filterText || this.featuresFilter) && this.flattenedTree.paths.length === 0;
  }

  get selected() {
    return this.oldSelected && Object.assign({}, this.oldSelected);
  }

  get views() {
    return Object.values(this.contributedNodes)
      .concat(Object.values(this.inputsContrib))
      .reduce<Record<string, unknown>>((a, c) => {
        c.forEach((entry) => {
          a[entry.key] = entry.view;
        });
        return a;
      }, {});
  }

  onSelect = async (entry: FieldTreeEntry, blockSelectRecord: boolean = false) => {
    if (!entry) {
      return;
    }
    const currentExpanded = !this.expanded[entry.key];
    if (blockSelectRecord && entry?.children?.length > 0) {
      this.onExpand(entry, currentExpanded);
    } else {
      const previouslySelected = this.oldSelected && entry.key === this.oldSelected.key;
      if (!previouslySelected) {
        this.onExpand(entry, true);
      } else {
        this.onExpand(entry, currentExpanded);
      }
      this.oldSelected = entry;
    }
    if (!entry.children) {
      if (this.contributedNodes.hasOwnProperty(entry.key)) {
        delete this.contributedNodes[entry.key];
      } else {
        this.contributedNodes[entry.key] = entry.key.endsWith("boolean")
          ? observable(BooleanContribRows)
          : observable(LoadingContribRows);
        await this.getEntryContrib(entry).then((entries) => {
          if (entries.length) {
            this.contributedNodes[entry.key] = entries;
          } else {
            delete this.contributedNodes[entry.key];
          }
        });
      }
    }
  };

  onChecked = (entry: FieldTreeEntry, value: boolean) =>
    this.traverseCheckable(entry, (x) => this.checkedStatus.onChange(x, value, false));

  traverseCheckable = (entry: FieldTreeEntry, callback: (entry: FieldTreeEntry) => void) => {
    traverseItemTree([entry], (x) => {
      if (!this.filterText || this.findEntry(x.key) !== null) {
        callback(x);
      }
    });
  };

  onCheckedAll = (value: boolean) => {
    this.tree.forEach((x) => this.checkedStatus.onChange(x, value));
  };

  onExpand = (entry: TreeEntry, value: boolean) => {
    if (value && (!entry.children || !entry.children.length)) {
      const ancestry = untilLastUnescapedDot(entry.key);
      escapableTraverseItemTree(this.tree, (x) => {
        if (ancestry.includes(x.key) || entry.key === x.key) {
          this.expandedStatus.onChange(x, value, false);
        }
        return entry.key !== x.key;
      });
    } else {
      escapableTraverseItemTree([entry], (x) => {
        if (entry.key === x.key) {
          this.expandedStatus.onChange(x, value, false);
          return false;
        } else {
          return true;
        }
      });
    }
  };

  private entryChecked = (entry: FieldTreeEntry) => this.checkedStatus.status[entry.key];
  private isDescendant = (x: FieldTreeEntry) => !x.children && !x.view;
  entryInfo = (entry: FieldTreeEntry): TreeEntryInfo =>
    treeEntryInfo(entry, this.entryChecked, this.isDescendant, !!this.onCheck);

  findEntry = (key: string, onTraversedItem?: (itemWithField: FieldTreeEntry) => void): FieldTreeEntry | null =>
    findTreeContextEntry(this.tree, key, onTraversedItem);

  entryIndexPosition = (key: string): number => {
    let index = 0;
    let found = false;
    for (let i = 0; i < this.flattenedTree.paths.length && !found; i++) {
      if (this.flattenedTree.paths[i][this.flattenedTree.paths[i].length - 1] === key) {
        found = true;
      } else {
        index++;
      }
    }
    return found ? index : -1;
  };

  setFilterText = debounce((value: string) => (this.filterText = value), 150);

  clone = (onCheck?: (field: UField, checked?: boolean) => void, getEntryContrib?: GetEntryContrib) => {
    const treeContextClone = new UFieldsTreeContext(
      this.treeFields,
      onCheck === undefined ? this.onCheck : onCheck,
      getEntryContrib === undefined ? this.getEntryContrib : getEntryContrib,
      this.splitInputs,
      this.inputsContrib
    );
    treeContextClone.cloneCheckedAndExpandedStatus(this, onCheck);
    return treeContextClone;
  };

  private cloneCheckedAndExpandedStatus = (that: UFieldsTreeContext, onStatusUpdate?: OnStatusUpdate) => {
    this.checkedStatus = that.checkedStatus.clone(onStatusUpdate);
    this.expandedStatus = that.expandedStatus.clone();
  };

  getItemByIndex = (index: number, inputs?: Record<string, DisplayData>): FieldTreeEntry => {
    const fieldPaths = this.flattenedTree.paths[index];
    const fieldPath = fieldPaths[fieldPaths.length - 1];
    const field = this.fields[fieldPath];
    const key = fieldPath;
    const children = this.flattenedTree.children[key];
    const input = inputs && inputs[key];
    const view = this.views[key];
    return {
      key,
      label: field ? UField.lastLabel(field) : input ? input.name : lastLabel(fieldPath),
      field,
      children,
      className: (input || fieldsTreeFeaturePrefixes.includes(key)) && "major",
      view,
    };
  };
}

export const itemInputItd = (FieldTreeEntry: FieldTreeEntry) => FieldTreeEntry.key.split(".")[0];
