import { observer } from "mobx-react";
import React, { useContext, useEffect, useState } from "react";
import { decorateOnChange, FormSelectInputProps } from "../FormInputTypes";
import { FormContext } from "../UForm";
import { SuperSelect } from "../SuperSelect";
import { DefaultItemView } from "../../../templates/index/views/DefatultItemView";
import { useHistory } from "react-router";
import { AppContext } from "../../../routes/AppContext";

const SelectOrAddSomething: React.FC<FormSelectInputProps> = observer((props) => {
  const context = useContext(FormContext);
  const appContext = useContext(AppContext);
  const history = useHistory();
  const [createOptions, setCreateOptions] = useState<any[]>([]);
  const [options, setOptions] = useState<any[]>([]);

  const _setCallbackId = (id: any) => {
    context.formState[props.input.path] = id;
  };

  const _innerOnChange = (value: any) => {
    const { input, onChange } = props;

    const createOption = createOptions.find((f) => f.key === value);

    if (createOption) {
      const assign = Object.assign({}, createOption.state, {
        pushback: history.location.pathname,
      });
      appContext.formStore.setSuccessHandler(_setCallbackId);
      history.push(createOption.url, assign);
    } else {
      const option = props.options.find((f) => f.key === value);
      decorateOnChange(input, onChange, true)(value);
      if (option && option.validation === "invalid") {
        input.invalidate(option.validationError || "The selected option is invalid");
      } else if (input.valid) {
        input.validate();
      }
    }
  };

  useEffect(() => {
    const value = props.input.$value;
    if (value) {
      const option = props.options.find((f) => f.key === value);
      if (option && option.validation === "invalid") {
        props.input.invalidate(option.validationError || "The selected option is invalid");
      } else if (props.input.valid) {
        props.input.validate();
      }
    }
  }, [props.options, props.input]);

  useEffect(() => {
    setCreateOptions(
      props.createOptions.map((opt) => {
        const found = opt.clazz
          ? props.options.findIndex((f) => f.clazz === opt.clazz) !== -1
          : props.options.length > 0;
        return {
          key: "create|" + opt.clazz,
          title: "Create " + (found ? "a new " : "your first ") + opt.word,
          type: "create",
          url: opt.url,
          state: opt.state,
          id: "create-" + opt.clazz,
        };
      })
    );
  }, [props.options, props.createOptions]);

  useEffect(() => {
    setOptions(props.options.concat(createOptions));
  }, [props.options, createOptions]);

  const { input, disabled, placeholder, clearable } = props;
  return (
    <SuperSelect
      automationName={`select-${input.name}`}
      options={options}
      onChange={_innerOnChange}
      value={input.$value}
      onFocus={input.onFocus}
      onBlur={input.onBlur}
      disabled={disabled}
      loading={input.metadata.loading}
      placeholder={placeholder}
      renderItem={props.itemView || DefaultItemView}
      // renderValue={this.props.itemView || DefaultItemView}
      clearable={clearable}
    />
  );
});
export default SelectOrAddSomething;
