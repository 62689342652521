import { FormInput } from "../FormInputTypes";
import { useEffect, useState } from "react";
import { observe } from "mobx";

/***
 * Uses the field name in `runOnSuccessfulValidation` from the input metadata and listens to the server side validation
 * status of that field and all related fields (in case it's a subFieldsSelector etc). When the fields become valid it
 * calls onValidated
 * @param {FormInput} input
 * @param {() => void} onValidated calls when the field becomes valid
 * @returns {boolean} waiting for validation
 */
function useRunOnSuccessfulValidation(input: FormInput, onValidated: () => void) {
  const [disabled, setDisabled] = useState(() => input.metadata.editorInformation?.runOnSuccessfulValidation != null);
  useEffect(() => {
    const fieldName = input.metadata.editorInformation?.runOnSuccessfulValidation;
    if (fieldName == null) {
      setDisabled(false);
    }
    const fieldsToListenOn = input.getForm().getAllRelevantFields(fieldName);
    const disposables: (() => void)[] = [];
    if (fieldsToListenOn.length > 0) {
      fieldsToListenOn.forEach((x) =>
        disposables.push(
          observe(x.metadata, () => {
            const valid = x.metadata.validationResult === "VALID";
            if (valid) {
              onValidated();
            }
            setDisabled(!valid);
          })
        )
      );
    }
    return () => disposables.forEach((x) => x());
  }, [input, onValidated]);

  return disabled;
}

export default useRunOnSuccessfulValidation;
