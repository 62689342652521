import React, { useContext, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { createFormFields, deserializeValues } from "../../../../core/views/MetadataForm";
import { FormMetadata, MetadataStore } from "../../../../core/Metadata";
import { BaseFormWithArrayFields } from "../../../../core/BaseForm";
import { FormView } from "../../../../core/views/UForm";
import { Spin } from "../../../../core/views/Spin";
import { action, IObservableArray, observable } from "mobx";
import { RedshiftStore } from "../../../../output/Redshift";
import { Environment, EnvironmentResponse } from "../../../../environments/EnvironmentsStore";
import { AppContext } from "../../../../routes/AppContext";
import { inputOnChange } from "../../../../core/views/FormInputTypes";
import { AggregationsTemplateStore } from "../../../common/TemplatesStore";
import { FormFields } from "../../../../core/api/contracts/FormFields";
import { SimpleMetadata, SimpleMetadataProperty } from "../../../../core/SimpleMetadata";
import { EntityFieldValueProvider, useFormEntityValueProvider } from "../../../../core/forms/FormEntityValueProvider";

type SimpleOutputParametersProps = {
  connectionId: string;
  clazz: string;
  presetValues?: { [key: string]: any };
  outputValues?: { [key: string]: any };
  onSuccess: (values: any) => Promise<unknown>;
  onBack: React.MouseEventHandler;
  mutateValues?: (values: any) => any;
  title: React.ReactNode;
};

export class SubmitParametersState {
  @observable loading: boolean = true;
  @observable saving: boolean = false;
  @observable error: string = "";
  @observable.ref form: BaseFormWithArrayFields;
  itemMetadata: SimpleMetadata;
  metadataStore: MetadataStore;
  presetValues: any;
  previousSchema: string;

  @observable _redshiftSchemaNames: IObservableArray<string> = observable.array();
  @observable _redshiftTableNames: IObservableArray<string> = observable.array();
  _redshiftTables: any[] = [];

  setSchemas = action((schemas: any[]) => {
    this._redshiftSchemaNames.replace(schemas.map((x) => x.name));
  });

  setTables = action((tables: any[]) => {
    this._redshiftTables = tables;
    this._redshiftTableNames.replace(tables.map((x) => x.name));
  });

  constructor(
    metadataStore: MetadataStore,
    clazz: string,
    presetValues: { [key: string]: any },
    redshiftStore?: RedshiftStore,
    metadataOverride?: SimpleMetadata,
    environments?: EnvironmentResponse[],
    fieldValueProvider?: EntityFieldValueProvider,
    container?: SimpleMetadataProperty
  ) {
    const jdbcOutputParametersClazzes = metadataStore.Metadata().jdbcOutputParametersClazzes;
    const jdbcConnectionProperty = metadataStore.Metadata().jdbcConnectionProperty;
    const meta = metadataOverride || metadataStore.Metadata().outputParameters.byClazz[clazz];
    this.metadataStore = metadataStore;
    const shouldFlattenProp = meta.properties.find(
      (x) => x.editorInformation && !!x.editorInformation["shouldFlatten"]
    );
    const mandatoryProp: any =
      metadataOverride ||
      meta.properties.find((p) => p.editorInformation && !!p.editorInformation["metadata-list-name"]);
    const properties =
      shouldFlattenProp && presetValues.hasOwnProperty(shouldFlattenProp.name)
        ? [mandatoryProp, Object.assign({}, shouldFlattenProp, { hidden: true })]
        : [mandatoryProp];

    const extraProp = clazz === "SnowflakeOutputParameters" ? meta.properties.find((x) => x.name === "writeFormat") : undefined;
    if (extraProp != null) {
      properties.push({ ...extraProp, hidden: true });
    }
    this.itemMetadata = { ...(metadataOverride || (mandatoryProp ? { ...meta, properties } : meta)) };
    this.presetValues = presetValues;

    const wrapPromise = <T extends {}>(p: Promise<T>) =>
      p.catch((err) => {
        if (err.response) {
          this.form.onValidateError(err.response.body);
        }
        return p;
      });

    const optionsLists = shouldFlattenProp && {
      contextProvider: (x: string) => {
        const value = fieldValueProvider && fieldValueProvider(x);
        if (value != null) {
          return value;
        }
        if (x === shouldFlattenProp.name) {
          return (
            shouldFlattenProp &&
            presetValues?.hasOwnProperty(shouldFlattenProp.name) &&
            presetValues[shouldFlattenProp.name]
          );
        } else {
          return null;
        }
      },
    };
    const existingValues = (presetValues && mandatoryProp && presetValues[mandatoryProp.name]) || presetValues;
    const mandatory = this.itemMetadata.properties.find((x) => x.name === "mandatory");
    if (mandatory != null) {
      mandatory.mandatory = true;
    }
    const fields: FormFields = createFormFields(this.itemMetadata, {
      formMetadata: metadataStore.getFormMetadata(),
      values: existingValues,
      metadataStore: metadataStore,
      optionLists: Object.assign(
        { environments },
        { tables: this._redshiftTableNames, schemas: this._redshiftSchemaNames },
        optionsLists
      ),
      ignoreImmutable: true,
      contextProvider: fieldValueProvider,
      parentPropertyMetadata: container,
    });

    if (
      clazz !== "ExistingTableOutputParameters" &&
      presetValues &&
      (!mandatoryProp || presetValues.hasOwnProperty(mandatoryProp.name)) &&
      !jdbcOutputParametersClazzes.find((j) => j === clazz)
    ) {
      Object.entries(mandatoryProp ? presetValues[mandatoryProp.name] : presetValues).forEach((kv) => {
        fields.rules[kv[0]] = "";
        fields.hidden[kv[0]] = true;
      });
    }

    if (jdbcOutputParametersClazzes.find((j) => j === clazz)) {
      fields.changeHandlers[jdbcConnectionProperty[clazz]] = (connection) => {
        this.setTables([]);
        this.setSchemas([]);
        const schemaField = this.form.$("schema");
        const tablesField = this.form.$("tableDefinition");
        if (connection) {
          schemaField.metadata.loading = true;
          wrapPromise(redshiftStore.schemas(connection)).then(
            action((schemas: any[]) => {
              this.setSchemas(schemas);
              schemaField.metadata.loading = false;
              schemaField.metadata.disabled = false;
              if (schemaField.$value) {
                inputOnChange(schemaField)(schemaField.$value);
              }
            })
          );
        } else {
          schemaField.metadata.disabled = true;
          tablesField.metadata.disabled = true;
        }
      };
      fields.changeHandlers["schema"] = (schema) => {
        if (schema !== this.previousSchema) {
          this.previousSchema = schema;
          this.setTables([]);
          if (schema) {
            const tablesField = this.form.$("tableDefinition");
            tablesField.metadata.loading = true;
            wrapPromise(redshiftStore.tables(this.form.$(jdbcConnectionProperty[clazz]).$value, schema)).then(
              action((tables: any[]) => {
                this.setTables(tables);
                inputOnChange(tablesField)(presetValues?.tableDefinition?.name ?? "");
                tablesField.metadata.disabled = false;
                tablesField.metadata.loading = false;
              })
            );
          }
        }
      };

      const connection = fields.values[jdbcConnectionProperty[clazz]];
      if (connection) {
        wrapPromise(redshiftStore.schemas(connection)).then(
          action((schemas: any[]) => {
            this.setSchemas(schemas);
            this.setTables([]);
          })
        );
      }
    }

    this.form = fields.createForm();
    // if (jdbcOutputParametersClazzes.find(j => j === clazz)) {
    //   this.form.$("schema").metadata.disabled = true;
    //   this.form.$("tableDefinition").metadata.disabled = true;
    // }

    const schemaField = jdbcOutputParametersClazzes.find((j) => j === clazz) && this.form.tryGetField("schema");
    if (schemaField && schemaField.metadata.changeHandler && schemaField.$value) {
      schemaField.metadata.changeHandler(schemaField.$value);
    }
  }

  trySubmit = (
    values: any,
    templateStore: AggregationsTemplateStore,
    formMetadata: FormMetadata,
    onSuccess: (mandatory?: any) => Promise<unknown>
  ) => {
    const mandatoryValues = deserializeValues(this.itemMetadata, values, {
      formMetadata: formMetadata,
      metadataStore: this.metadataStore,
    });
    if (mandatoryValues.tableDefinition && !mandatoryValues.tableDefinition.name) {
      const tablesInSchema = this._redshiftTables.filter((x) => x.schema === mandatoryValues.tableDefinition.schema);
      if (tablesInSchema.length === 0) {
        this.form
          .$("schema")
          .invalidate("Empty schemas are not currently supported. Please create at least one table in the schema.");
        return;
      }
      const existingTableDefinition = tablesInSchema.find((x) => x.name === mandatoryValues.tableDefinition.table);
      mandatoryValues.tableDefinition = existingTableDefinition || {
        catalog: tablesInSchema[0].catalog,
        name: mandatoryValues.tableDefinition.table,
        schema: mandatoryValues.tableDefinition.schema,
      };
    }
    this.error = "";
    this.saving = true;
    onSuccess(mandatoryValues).catch(
      action((err: any) => {
        //TODO: ts-convert superagnet response type
        this.saving = false;
        this.loading = false;
        this.form.onValidateError(err.response.body);
      })
    );
  };
}

function useCachedComputeEnvironments(): Promise<{ environment: Environment }[]> {
  const { computeEnvironments } = useContext(AppContext);
  return useMemo(
    () =>
      computeEnvironments.cache.length
        ? Promise.resolve(computeEnvironments.cache.map((environment) => ({ environment })))
        : computeEnvironments.list(),
    [computeEnvironments]
  );
}

export const SimpleOutputParameters = observer((props: SimpleOutputParametersProps) => {
  const context = useContext(AppContext);
  const [submitParams, setSubmitParams] = useState<SubmitParametersState | null>();
  const environments = useCachedComputeEnvironments();
  const getEntityFieldValue = useFormEntityValueProvider();

  useEffect(() => {
    environments.then((envs: any[]) => {
      const presetValues = { ...props.presetValues, ...props.outputValues };
      const submitParametersState = new SubmitParametersState(
        context.metadataStore,
        props.clazz,
        presetValues,
        context.redshift,
        null,
        envs,
        getEntityFieldValue
      );
      submitParametersState.loading = false;
      setSubmitParams(submitParametersState);
    });
  }, [environments, props]);

  const _onSuccess = (values: any) => {
    submitParams.trySubmit(values, context.templateStore, context.metadataStore.getFormMetadata(), props.onSuccess);
  };

  const { form = undefined, loading = undefined, saving = undefined, error = undefined } = submitParams || {};
  return (
    <Spin spinning={!form || loading} delay={0}>
      {props.title}
      {form && (
        <FormView
          loading={saving}
          disabled={saving}
          form={form}
          onSuccess={_onSuccess}
          submitText="Next"
          onClose={props.onBack}
          closeText="Back"
        />
      )}
      {error && <div className="description error-text">{error}</div>}
    </Spin>
  );
});
