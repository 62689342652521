import { action, computed, observable } from "mobx";
import { IPreferencesStorage } from "./UserPreferences";
import { Auth } from "../auth/Auth";
import { appBarHeight, collapsedSidebarWidth, mainSidebarWidth } from "../styles/sizes";
import { ConfirmModalProps } from "./hooks/useConfirmModal";

export class GlobalUIStore {
  private userPrefs: IPreferencesStorage;
  @observable hasAccountBar: boolean = true;
  @observable fullSizeContent: boolean = false;
  @observable secondarySidebarTitle: string = "";
  @observable.ref secondarySidebar: React.ReactNode = null;

  @observable private collapsedValue: boolean = false;
  @observable.deep fontSizes: Record<string, number>;
  @observable secondaryCollapsed: boolean = false;
  @observable loading = false;
  @observable confirmModal: ConfirmModalProps = null;

  constructor(private readonly auth: Auth, userPreferences: IPreferencesStorage) {
    this.userPrefs = userPreferences;
    if (auth.currentOrganization.id) {
      userPreferences.load().then((x) => {
        this.collapsedValue = x.collapsedSidebar || false;
      });
    }
    this.fontSizes = userPreferences.get().fontSizes || {};
  }

  @computed
  get collapsed(): boolean {
    // return this.collapsedValue;
    return false;
  }

  set collapsed(value: boolean) {
    this.collapsedValue = value;
    this.userPrefs.upsertKey("collapsedSidebar", value);
  }

  setFontSize = action((key: string, size: number) => {
    this.fontSizes[key] = size;
    if (size > 4) {
      this.userPrefs.upsertKey("fontSizes", this.fontSizes);
    }
  });

  @action.bound
  resetSecondarySidebar() {
    this.secondarySidebar = null;
    this.secondarySidebarTitle = "";
  }

  @observable extraTopBarHeight = 0;

  @computed
  get topBarHeight() {
    return (this.hasAccountBar ? 1 : 0) * (appBarHeight + this.extraTopBarHeight);
  }
  @computed
  get sidebarWidth() {
    return this.collapsed ? collapsedSidebarWidth : mainSidebarWidth;
  }
}
