import React, { useContext } from "react";
import { observer } from "mobx-react";
import { action, computed, get, keys, observable } from "mobx";
import { TypeName, UField } from "../../../inputs/Store";
import isNil from "lodash/isNil";
import classNames from "classnames";
import { SelectFieldModal } from "../SelectFieldModal";
import { ComputedFieldGalleryModal } from "../../../transform/ComputedFieldGalleryModal";
import { Button } from "../Button";
import { SuperSelect } from "../SuperSelect";
import { AppContext } from "../../../routes/AppContext";
import { InputFieldStateIndicator, inputFieldStateToolTip } from "../../api/contracts/UFieldComponents";
import { Field as MField } from "../../type-definitions/mobx-react-form";
import ButtonGroup from "@mui/material/ButtonGroup";
import MuiButton from "@mui/material/Button";
import Add from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import { FunctionAutoCompleteOption } from "./FieldsAutoComplete";
import { FormFieldRegistry } from "../../FormFieldRegistry";
import { FeatureMetadata } from "../../FeatureMetadata";
import { FieldType } from "../FieldType";

export type FunctionAutoCompleteOptions = {
  getItems: (key: string) => any[];
  getOption: (key: string) => any;
  fields: () => UField[];
  fromField?: (field: UField) => any;
};
type FunctionAutoCompleteProps = {
  input: any;
  options: FunctionAutoCompleteOptions;
  onChange?: (option: any) => void;
  disabled?: boolean;
  placeholder?: string;
  dataAutomationName?: string;
};

export const FunctionAutoCompleteOptionView: React.FC<{ option: FunctionAutoCompleteOption }> = ({ option }) => {
  const { inputsStore } = useContext(AppContext);
  const { title, displayValue } = option;
  const stringTitle = isNil(title) ? "null" : title.toString();
  const display = isNil(displayValue) ? stringTitle : displayValue();
  return (
    <React.Fragment>
      <Box
        component="span"
        width="calc(100% - 55px)"
        display="inline-flex"
        height={50}
        lineHeight="50px"
        textOverflow="ellipsis"
        overflow="hidden"
        fontSize="typography.h3"
        title={inputFieldStateToolTip(option.inputs, stringTitle, inputsStore)}
        pl={2}
      >
        {display}
      </Box>
      <span>
        <Box display="inline" width={30} textAlign="center">
          <FieldType typeName={{ name: option.type } as TypeName} />
        </Box>
        {option.icon !== "var" && <Box component="span" px={1} className={classNames(`icon-${option.icon}`)} />}
        {<InputFieldStateIndicator inputs={option.inputs} />}
      </span>
    </React.Fragment>
  );
};

@observer
export class FunctionAutoCompleteInput extends React.Component<FunctionAutoCompleteProps> {
  _lastOptions: any = null;
  @observable _value: any = "";
  @observable _key: number = 0;
  @observable _browse: boolean = false;
  @observable _customFieldMode: boolean;

  _stopNextChange: boolean = false;
  _selectOnBlur: boolean = true;

  componentDidMount(): void {
    this._load(this.props);
  }

  @computed
  get _inputOptions() {
    return Array.isArray(this.props.input.metadata.options) ? this.props.options : this.props.input.metadata.options;
  }

  @computed
  get _options(): any[] {
    return this._inputOptions ? this._inputOptions.getItems("") : [];
  }

  _load(props: FunctionAutoCompleteProps) {
    const { input, options } = props;
    const value = input.$value || input.$default;

    if (value) {
      const option = options.getOption(value);
      if (option) {
        this._handleSelect(option);
      }
    } else {
      this._value = input.$default;
    }
  }

  @action.bound
  _handleBlur(): void {
    if (this._lastOptions) {
      this._lastOptions.options = [];
    }
  }

  @action.bound
  _handleSelect(option: any): void {
    if (option && option.placeholder) {
      this._value = "";
      return;
    }

    const value = option ? option.value : null;

    if (this.props.input.$value !== value) {
      if (value === null) {
        this.props.input.reset();
      } else {
        this.props.input.$value = value;
      }
      this.props.onChange && this.props.onChange(value);
    }

    this._value = option;
    this._stopNextChange = true;
    this._selectOnBlur = false;
  }

  _getFilteredOptions = (input: any) => {
    const extraFilter = this.props.input.metadata.extraFilter;
    const items = this._inputOptions.getItems(input);
    this._lastOptions = extraFilter ? items.filter((o: any) => extraFilter(o.type)) : items;
    return this._lastOptions.slice();
  };

  @action.bound
  _toggleBrowse() {
    this._browse = !this._browse;
  }

  @action.bound
  _selectField(fields: UField[]) {
    if (fields.length > 1) {
      throw new Error("Can only use one field for input");
    }
    const field = fields[0];
    this._browse = false;
    this._handleSelect(this._inputOptions.fromField(field));
  }

  @action.bound
  _showCustomField(e: React.MouseEvent) {
    e.stopPropagation();
    this._customFieldMode = true;
  }

  @action.bound
  _hideCustomField() {
    this._customFieldMode = false;
  }

  @computed
  get _selectedFieldKey(): string | null {
    if (this._value && this._value.value) {
      const obj = JSON.parse(this._value.value);
      if (obj.clazz === "FieldFeatureInput") {
        return UField.key(obj.field);
      }
    }
    return null;
  }

  @computed
  get _modalFields(): any[] {
    const fields = this._inputOptions ? this._inputOptions.fields() : [];
    const extraFilter = this.props.input.metadata.extraFilter;
    return extraFilter ? fields.filter((f: UField) => extraFilter(f.nativeType.name)) : fields;
  }

  render() {
    const props = this.props;
    const onAddComputedField = props.input.metadata.onAddComputedField;
    const readonly = props.input.metadata.readonly;
    const disabled = props.disabled || props.input.metadata.disabled || readonly;
    const allowedTypeNames = (props.input.metadata.allowedTypes || []).map((x: TypeName) => x.name);
    const supportsAny = allowedTypeNames.includes("any");
    return (
      <div
        className={classNames("fields-autocomplete", { secretClassName: props.input.metadata.secret })}
        data-automation-name={props.dataAutomationName || props.input.path}
      >
        <ButtonGroup color="secondary" size="large">
          <SuperSelect
            loading={props.input.metadata.loading}
            disabled={disabled}
            placeholder={props.placeholder ? props.placeholder : props.input.label}
            options={this._options}
            getFilteredOptions={this._getFilteredOptions}
            onChange={this._handleSelect}
            renderValue={({ item }) => <FunctionAutoCompleteOptionView option={item} key={item.title} />}
            onBlur={this._handleBlur}
            value={this._value}
            selectValue
          >
            {(item: FunctionAutoCompleteOption) => <FunctionAutoCompleteOptionView option={item} key={item.title} />}
          </SuperSelect>
          {!readonly && (
            <MuiButton
              color="secondary"
              onClick={this._toggleBrowse}
              data-automation-name={"open-side-fields-menu-" + props.input.path}
            >
              <span className="icon-icon-browse" style={{ fontSize: 20 }} />
            </MuiButton>
          )}
          {onAddComputedField && !readonly && (
            <MuiButton color="secondary" onClick={this._showCustomField}>
              <Add />
            </MuiButton>
          )}
        </ButtonGroup>
        <SelectFieldModal
          isOpen={this._browse}
          onClose={this._toggleBrowse}
          onSelect={this._selectField}
          selectedFieldKey={this._selectedFieldKey}
          fields={this._modalFields}
          onAddComputedField={onAddComputedField}
        />
        {this._customFieldMode && (
          <ComputedFieldGalleryModal
            onClose={this._hideCustomField}
            onSelect={onAddComputedField}
            typesFilter={(f: FeatureMetadata) =>
              !f.determinateOutputType || allowedTypeNames.includes(f.determinateOutputType.name) || supportsAny
            }
            source={props.input.path}
          />
        )}
      </div>
    );
  }
}
@observer
export class FunctionAutoCompleteArray extends React.Component<any> {
  componentDidMount() {
    if (!this.props.input.$changed && this.props.options.initialValue && this.props.options.initialValue.length) {
      this.props.options.initialValue.forEach((value: unknown, index: number) => {
        this.props.input.onAdd(new Event("add"));
        this.props.input.$(index).$default = "{}";
        if (value) {
          this.props.input.$(index).set(value);
        } else {
          this.props.input.$(index).set("{}");
        }
      });
    }
  }

  render() {
    return (
      <div className="fields-autocomplete-array">
        {keys(this.props.input.fields).map((idx) => {
          const input = get(this.props.input.fields, idx) as MField;
          return (
            <div key={idx.toString()} className="flex-two-columns mapped-column">
              <div className="properties">
                <FunctionAutoCompleteInput {...(this.props as any)} input={input} />
              </div>
              <div className="buttons">
                {Number(idx) >= this.props.options.minimumInputs && (
                  <span className="icon-trash clickable" onClick={input.onDel} />
                )}
              </div>
            </div>
          );
        })}
        <Button type="secondary" automationName="nary-add-input" small onClick={this.props.input.onAdd}>
          add input
        </Button>
      </div>
    );
  }
}
export const register = (formFieldRegistry: FormFieldRegistry) => {
  formFieldRegistry.register("autocomplete", (props) => (
    <FunctionAutoCompleteInput {...props} options={props.input.metadata.options} />
  ));
  formFieldRegistry.register("autocomplete-seq", (props) => (
    <FunctionAutoCompleteArray {...props} options={props.input.metadata.options} />
  ));
};
