import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { FormValidators } from "../../FormValidators";
import { USelect } from "../Select";
import { TimeUnits } from "../TimeUnits";
import { DurationEditorProps, DurationEditorState } from "./DurationEditorState";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import { FormFieldRegistry } from "../../FormFieldRegistry";
import { ToggleButtonGroup } from '@mui/material';
import { NotebookToggleButton } from "../../../templates/notebook/NotebookToggleButton";
import { FormItemDecoratorFooter, FormItemDescription } from "../FormInputs/FormItemDecorator";

function InfiniteValue(props: { hasError: boolean }) {
  return (
    <TextField
      error={props.hasError}
      fullWidth
      variant="outlined"
      disabled
      type={"text"}
      data-automation-name="duration-amount"
      value={"Infinite"}
    />
  );
}

export const DurationEditor: React.FC<DurationEditorProps> = observer((props) => {
  const [durationState, setDurationState] = useState<DurationEditorState>();
  useEffect(() => {
    FormValidators.MAX_DAYS.register();
    const editorState = new DurationEditorState(props.input, props.mutateAmount, props.positiveView);
    editorState.init(props);
    setDurationState(editorState);
  }, [props.input, props.disabled, props.mutateAmount, props.positiveView, props.hasInfinite]);

  if (!durationState) {
    return null;
  }

  const input = props.input;
  const disabled = durationState.checked && !props.disabled ? input.$disabled : true;

  return (
    <>
      <Box pt={2.5} pb={1} hidden={props.hideLabel || props.hidden || props.input?.metadata?.hidden}>
        <label>{input.label}</label>
        <FormItemDescription input={input} />
      </Box>

      <div
        ref={props.scrollRef}
        className={classNames("form-input-group retention", {
          hidden: props.hidden || input.metadata.hidden,
        })}
      >
        <Box display="flex" alignItems="center" width="210">
          {props.optional && (
            <Box pr={1}>
              <FormControlLabel
                label="ENABLED"
                data-automation-name={`enable-${props.input.name}`}
                control={
                  <Checkbox
                    onChange={(e) => durationState.changeEnabled(e.currentTarget.checked)}
                    checked={durationState.checked}
                  />
                }
              />
            </Box>
          )}
          <Box width={130}>
            {durationState.infinite ? (
              <InfiniteValue hasError={Boolean(input.error)} />
            ) : (
              <TextField
                error={Boolean(input.error)}
                fullWidth
                variant="outlined"
                disabled={disabled || (durationState.infinite && props.hasInfinite) || Boolean(input.validating)}
                type={"number"}
                data-automation-name="duration-amount"
                value={Number.isInteger(durationState.viewAmount) ? durationState.viewAmount : ""}
                onChange={durationState.changeAmount}
                onBlur={(e) => {
                  input.validate({ showErrors: true }).then(() => input.onBlur(e));
                }}
              />
            )}
          </Box>
          <Box className="select" width={90}>
            <ToggleButtonGroup
              exclusive
              value={durationState.infinite ? TimeUnits.Values.Infinite : durationState.unit}
              onChange={(e, value) => {
                if (value === TimeUnits.Values.Infinite) {
                  durationState.setInfinite();
                } else {
                  durationState.changeUnit(value);
                }
              }}
            >
              {(props.options?.units || (props.hasInfinite ? TimeUnits.Values.All : TimeUnits.Values.NoInfinite)).map(
                (x: string) => (
                  <NotebookToggleButton
                    key={x}
                    value={x}
                    disabled={
                      // when infinite the unit is days
                      disabled || (durationState.infinite ? x === TimeUnits.Values.Infinite : x === durationState.unit)
                    }
                  >
                    {x}
                  </NotebookToggleButton>
                )
              )}
            </ToggleButtonGroup>
          </Box>
        </Box>
      </div>
      <FormItemDecoratorFooter input={input} />
    </>
  );
});

DurationEditor.defaultProps = {
  defaultMinutes: 60,
  hasInfinite: false,
};

// default is 30 days for retention
export const OptionalPositiveDurationEditor = (props: DurationEditorProps) => (
  <DurationEditor
    {...props}
    defaultMinutes={props.options.defaultMinutes || 43200}
    optional={true}
    mutateAmount={(amount: number) => Math.max(amount, 1)}
  />
);

export const register = (formFieldRegistry: FormFieldRegistry) => {
  formFieldRegistry.register(
    "optional-positive-duration",
    (props) => <OptionalPositiveDurationEditor options={props.input.metadata.options} {...props} />,
    { decorate: false }
  );
  formFieldRegistry.register(
    "finite-duration",
    (props) => <DurationEditor options={props.input.metadata.options} {...props} hasInfinite />,
    { decorate: false }
  );
};
