import { observer } from "mobx-react";
import React from "react";
import { AppContext } from "../routes/AppContext";
import { action, computed, observable } from "mobx";
import { Workspace, WorkspaceDashboard, WorkspacesReplyWrapper } from "./WorkspacesStore";
import { BaseFormWithArrayFields } from "./BaseForm";
import { FormFields } from "./api/contracts/FormFields";
import { WorkspaceWord } from "./Language";
import { Button, FlatButton } from "./views/Button";
import classNames from "classnames";
import styles from "../styles/workspaces.module.scss";
import Modal from "./views/Modal";
import { Spin } from "./views/Spin";
import { MultiSelectFilter } from "./views/MultiSelectFilter";
import { ComboBox } from "./views/ComboBox";
import { scrollIntoView } from "./Utils";

import moment from "moment";
import { FormView } from "./views/UForm";
import sortBy from "lodash/sortBy";
import { fuzzyFilter } from "./Fuzzy";

import withStyles from '@mui/styles/withStyles';
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import HomeIcon from "@mui/icons-material/Home";

import "../styles/workspaces.scss";
import { Tooltip } from "@mui/material";
import { EllipsisTooltip } from "./views/EllipsisTooltip";

const DefaultButton = withStyles({
  root: {
    padding: "5px",
    marginLeft: "10px",
  },
})(IconButton);

export const filterByDisplayDataName = (items: Array<any>, filter: string): any[] =>
  sortBy(
    fuzzyFilter(items as any, ["displayData.name"], filter),
    (item: any) => -Date.parse(item.displayData.creationTime)
  );
export const filterByDisplayDataNameDashboard = (items: Array<any>, filter: string): any[] =>
  sortBy(
    fuzzyFilter(items as any, ["workspace.displayData.name"], filter),
    (item: any) => -Date.parse(item.workspace.displayData.creationTime)
  );
export const InlineDelete = ({
  onDelete,
  onCancel,
}: {
  onDelete: React.MouseEventHandler;
  onCancel: React.MouseEventHandler;
}) => (
  <span
    className={styles.delete}
    onClick={(e: React.MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();
    }}
  >
    Are you sure?{" "}
    <Button type="danger" xxsmall onClick={onDelete}>
      Delete
    </Button>
    <Button type="cancel" xxsmall onClick={onCancel}>
      No
    </Button>
  </span>
);

@observer
export class SelectWorkspace extends React.Component {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;
  @observable showModal: boolean = false;
  @observable showCreate: boolean = false;
  @observable deletingId: string;
  @observable _lastSelection: Workspace;
  @observable defaultWorkspace: string;
  createForm: BaseFormWithArrayFields;

  constructor(props: any) {
    super(props);
    this.createForm = new FormFields()
      .addField("name", "required", "Name", "text")
      .addField("description", "", "Description", "text")
      .createForm();
  }

  componentDidMount() {
    this.load();
  }

  @computed
  get workspaces(): WorkspacesReplyWrapper[] {
    return this.context.workspacesStore.items;
  }

  load = (force: boolean = false) => {
    this.defaultWorkspace = this.context.userPreferences.get()?.defaultWorkspace;

    const futureWorkspaces =
      !this.context.workspacesStore.itemsLoaded || force
        ? this.context.workspacesStore.dashboards()
        : Promise.resolve(this.context.workspacesStore.items);

    return futureWorkspaces.then(
      action((x: WorkspaceDashboard[]) => {
        const { api } = this.context;
        const currentWorkspace = api.workspace;
        if (currentWorkspace && x.findIndex((x) => x.workspace.id === currentWorkspace) === -1) {
          api.clearWorkspace();
        }
      })
    );
  };

  open = action(() => {
    this.deletingId = "";
    this.showModal = true;
    this.load(true);
  });

  select = action((workspace: Workspace) => {
    this._lastSelection = workspace;
    if (workspace.id !== this.context.api.workspace) {
      this.context.api.setWorkspace(workspace.id);
      this.refreshAndClose();
    }
  });

  clear = action(() => {
    this._lastSelection = null;
    this.context.api.clearWorkspace();
    this.refreshAndClose();
  });

  create = action(() => {
    //@ts-ignore
    this.createForm.reset();
    this.showCreate = true;
  });

  refreshAndClose = () => {
    window.location.reload();
    this.close();
  };

  close = action((e?: React.MouseEvent) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    this.showModal = false;
    this.showCreate = false;
  });

  askDelete = action((e: React.MouseEvent, id: string) => {
    e.stopPropagation();
    e.preventDefault();
    this.deletingId = id;
  });

  delete = action((e: React.MouseEvent, id: string) => {
    e.stopPropagation();
    e.preventDefault();
    if (this.context.api.workspace === id) {
      this.context.api.clearWorkspace();
    }
    this.context.workspacesStore.remove(id).then(
      action(() => {
        const idx = this.workspaces.findIndex((x) => x.workspace.id === id);
        if (idx > -1) {
          this.workspaces.splice(idx, 1);
        }
      })
    );
  });

  submit = (values: { name: string; description: string }) => {
    this.context.workspacesStore.create({ displayData: { ...values } }).then(
      action((x) => {
        this.workspaces.unshift(new WorkspacesReplyWrapper(x));
        this.showCreate = false;
      })
    );
  };

  setAsDefault = action((e: React.MouseEvent, workspace: Workspace) => {
    e.stopPropagation();
    e.preventDefault();

    const { id } = workspace;

    if (this.defaultWorkspace !== id) {
      this.defaultWorkspace = id;
      this.context.userPreferences.upsertKey("defaultWorkspace", id);
    } else {
      this.defaultWorkspace = null;
      this.context.userPreferences.removeKey("defaultWorkspace");
    }
  });

  @computed
  get currentWorkspace() {
    return (this.workspaces || []).find((x) => x.workspace.id === this.context.api.workspace);
  }

  @computed
  get currentName(): string {
    const all = `All ${WorkspaceWord.plural()}`;
    if (!this.workspaces && !this._lastSelection && this.context.api.workspace) {
      return "Loading...";
    }
    if (!this.workspaces && !this._lastSelection) {
      return all;
    } else if (this._lastSelection) {
      return this._lastSelection.displayData.name;
    } else {
      return this.currentWorkspace ? this.currentWorkspace.workspace.displayData.name : all;
    }
  }

  render() {
    const collapsed = this.context.globalUIStore.collapsed;
    return <>
      <FlatButton
        className={classNames(collapsed && styles.collapsed, this.context.auth.outOfDate && styles.expired)}
        onClick={this.open}
        title={`${WorkspaceWord.single()}: ${this.currentName}`}
      >
        {collapsed && !this.currentWorkspace ? (
          <div className={styles.workspaceIcon}>
            All
            <br />
            Work-
            <br />
            Spaces
          </div>
        ) : (
          `${
            this.currentName.length > (collapsed ? 7 : 14)
              ? this.currentName.substring(0, 10) + "..."
              : this.currentName
          }`
        )}
      </FlatButton>
      <Modal isOpen={this.showModal} onRequestClose={this.close} className="create-feature-form">
        <h2>{this.showCreate ? `Create ${WorkspaceWord.single()}` : <WorkspaceWord.component plural />}</h2>
        <Spin spinning={!this.workspaces}>
          {!this.showCreate && (
            <React.Fragment>
              <MultiSelectFilter className="workspace-selector">
                {this.workspaces && (
                  <ComboBox
                    items={this.workspaces}
                    filterItems={(items, filter) =>
                      ([{ id: "all" }] as any[]).concat(filterByDisplayDataNameDashboard(items, filter))
                    }
                    itemSelected={this.select}
                    placeholder={`Select ${WorkspaceWord.single()}`}
                  >
                    {(dashboard: WorkspaceDashboard, focused: boolean) => {
                      const workspace = new WorkspacesReplyWrapper(dashboard).workspace;
                      const { id } = workspace;

                      if (id === "all") {
                        return (
                          <li
                            key={id}
                            onClick={() => this.select({ id: "ALL" } as any)}
                            className={classNames(
                              "pointer",
                              "flex-row",
                              "all-workspaces",
                              this.context.api.workspace == null ? "active" : ""
                            )}
                          >
                            <span className="flex-expand">
                              <h2 className={classNames("flex-overflow", "workspace-title")}>
                                <span className="icon-copy" />
                                All {WorkspaceWord.plural()}
                              </h2>
                            </span>
                          </li>
                        );
                      } else {
                        const { name = undefined, description = undefined, creationTime = undefined } =
                          workspace.displayData || {};
                        const usageCount = dashboard && workspace.usageCount;
                        const active = this.context.api.workspace === id;

                        return (
                          <li
                            ref={focused && scrollIntoView}
                            key={id}
                            onClick={() => this.select(workspace)}
                            className={classNames(
                              "pointer",
                              "flex-row",
                              { focused },
                              "workspace-item",
                              active ? "active" : ""
                            )}
                            data-automation-name={`select-${name}`}
                          >
                            <span className={classNames("flex-expand", "workspace-content")}>
                              <h2 className={classNames("flex-overflow", "workspace-title")}>
                                {name}
                                <EllipsisTooltip placement="right" title="set as default" enterDelay={0.7}>
                                  <DefaultButton onClick={(e) => this.setAsDefault(e, workspace)}>
                                    <HomeIcon
                                      style={{
                                        color: this.defaultWorkspace === id ? "rgba(28, 173, 228, 1)" : "#c4c4c4",
                                      }}
                                    />
                                  </DefaultButton>
                                </EllipsisTooltip>
                              </h2>

                              <div className="description">
                                Created {moment(creationTime).fromNow()}
                                {description && ` | ${description} `}
                                {usageCount !== -1 &&
                                  (usageCount > 0 ? ` | used by ${usageCount} items` : " | not in use")}
                                <div className="workspace-item-actions">
                                  {this.deletingId === id ? (
                                    <InlineDelete
                                      onDelete={(e: React.MouseEvent) => this.delete(e, id)}
                                      onCancel={action((e: React.MouseEvent) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        this.deletingId = "";
                                      })}
                                    />
                                  ) : (
                                    <IconButton onClick={(e) => this.askDelete(e, id)} size="large">
                                      <DeleteIcon />
                                    </IconButton>
                                  )}
                                </div>
                              </div>
                            </span>
                          </li>
                        );
                      }
                    }}
                  </ComboBox>
                )}
              </MultiSelectFilter>
              <div className="actions">
                <Button type="primary" onClick={this.create}>
                  Create New
                </Button>

                <Button type="secondary" onClick={this.close}>
                  Cancel
                </Button>
              </div>
            </React.Fragment>
          )}
          {this.showCreate && (
            <FormView
              stateless
              form={this.createForm}
              onSuccess={this.submit}
              submitText="Create"
              onClose={action(() => (this.showCreate = false))}
            />
          )}
        </Spin>
      </Modal>
    </>;
  }
}
