import { TypeName } from "./TypeName";
import React from "react";
import { UField } from "./UField";
import { IndexAggregation } from "./PipelineOperation";
import { UFieldView } from "./UFieldComponents";
import { BaseFunctionAutoCompleteOption } from "../../views/FormInputs/FieldsAutoComplete";
import { DisplayFieldName } from "../../views/ReactJoin";
import { LookupFeatureMetadata } from "../../FeatureMetadata";

export interface FeatureInput {
  nativeType: TypeName;
  readonly displayValue: React.ReactNode;
  readonly stringValue: string;
  readonly autocompleteOption: BaseFunctionAutoCompleteOption;
  readonly field?: UField;
  readonly inline?: boolean;
}

export class DslContainer {
  dsl: string;
  get displayValue() {
    return String(this.dsl);
  }

  get stringValue() {
    return this.displayValue;
  }
}

export class InlineAggregatedFeatureInput extends DslContainer implements FeatureInput {
  static clazz: "InlineAggregatedFeatureInput";
  aggregation: IndexAggregation;
  featureInputs: FeatureInput[];
  infix: boolean;
  nativeType: TypeName;

  get autocompleteOption() {
    return { title: this.dsl, type: "Inline", icon: "function" };
  }

  get inline() {
    return true;
  }
}

export abstract class FieldProviderFeatureInput {
  protected fieldForAutocomplete: UField;
  setFieldForAutoComplete(field: UField) {
    this.fieldForAutocomplete = field;
  }
  get autocompleteOption() {
    const f = this.fieldForAutocomplete;
    return {
      key: UField.key(f),
      displayValue: () => (
        <React.Fragment>
          {DisplayFieldName(f.name)}
          {f.nativeType.isArray && "[]"}
        </React.Fragment>
      ),
      title: UField.getFullName(f),
      type: f.nativeType.name,
      icon: f.featureField ? "function" : "var",
      inputs: f.inputs,
    };
  }
  get stringValue() {
    return this.fieldForAutocomplete.name;
  }
}
export class FieldFeatureInput extends FieldProviderFeatureInput implements FeatureInput {
  static clazz: "FieldFeatureInput";
  field: UField;
  isAggregationField: boolean;
  isFromInline: boolean;
  nativeType: TypeName;
  get displayValue() {
    return <UFieldView field={this.field} />;
  }
}

export class PointerFeatureInput extends FieldProviderFeatureInput implements FeatureInput {
  static clazz: "PointerFeatureInput";
  featureId: string;
  subField: string;
  nativeType: TypeName;
  field?: UField;
  isAggregationField: boolean;
  get displayValue() {
    return this.field ? <UFieldView field={this.field} /> : null;
  }
}

export class InlineFeatureInput extends DslContainer implements FeatureInput {
  static clazz: "InlineFeatureInput";
  featureInputs: FeatureInput;
  feature: LookupFeatureMetadata;
  infix: boolean;
  overrideField?: UField;
  nativeType: TypeName;

  get autocompleteOption() {
    return { title: this.displayValue, type: "Inline", icon: "function" };
  }

  get inline() {
    return true;
  }
}

export class LiteralFeatureInput implements FeatureInput {
  static clazz: "LiteralFeatureInput";
  literal: object;
  nativeType: TypeName;

  get displayValue() {
    return String(this.literal);
  }

  get stringValue() {
    return this.displayValue;
  }

  get autocompleteOption() {
    return { title: this.displayValue, type: this.nativeType?.name, icon: "copy" };
  }
}

export class NullFeatureInput implements FeatureInput {
  static clazz: "NullFeatureInput";
  nativeType: TypeName;

  get displayValue() {
    return "NULL";
  }
  get stringValue() {
    return this.displayValue;
  }

  get autocompleteOption() {
    return { title: this.displayValue, type: this.nativeType?.name, icon: "copy" };
  }
}

const allFeatureInputs: Record<string, Function> = {
  InlineAggregatedFeatureInput,
  FieldFeatureInput,
  PointerFeatureInput,
  InlineFeatureInput,
  LiteralFeatureInput,
  NullFeatureInput,
};

export function createFeatureInput(clazz: string, content: object = {}): FeatureInput {
  const instance = Reflect.construct(allFeatureInputs[clazz], []);
  return Object.assign(instance, content);
}

export function parseFeatureInput(obj: { clazz: string }) {
  const instant = createFeatureInput(obj.clazz, obj);
  return instant as FeatureInput;
}
