import React, { HTMLProps } from "react";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useTheme } from "@mui/material/styles";
import Input from "@mui/material/Input";
import { roundedInputStyle } from "../../styles/useSharedStyles";

type ExtendedUSelectProps = USelectProps & { input: any; props: any };
const Normal = ({ props, flat, input, fullWidth }: ExtendedUSelectProps) => {
  return (
    <Select
      native
      data-testid="mui-select"
      variant={flat ? "standard" : "outlined"}
      {...props}
      input={
        input || (flat ? <Input fullWidth={fullWidth} disableUnderline /> : <OutlinedInput fullWidth={fullWidth} />)
      }
    />
  );
};

const Rounded = ({ input, flat, fullWidth, ...rest }: ExtendedUSelectProps) => {
  const theme = useTheme();
  return (
    <Normal
      {...(rest as any)}
      flat={flat}
      input={input || <Input fullWidth={fullWidth} disableUnderline style={roundedInputStyle(theme)} />}
    />
  );
};

type USelectProps = HTMLProps<HTMLSelectElement> & {
  Input?: React.ReactNode;
  rounded?: boolean;
  fullWidth?: boolean;
  flat?: boolean;
};
export const USelect: React.FC<USelectProps> = ({ Input, rounded, flat, fullWidth, ...rest }) => {
  return rounded ? (
    <Rounded props={rest} flat={flat} input={Input} fullWidth={fullWidth} />
  ) : (
    <Normal props={rest} flat={flat} input={Input} fullWidth={fullWidth} />
  );
};
