import { Api } from "../core/api/Api";
import { Auth } from "./Auth";
import globalApiLocation from "../core/api/globalApiLocation";

export class UsersStore {
  api: Api;
  resetServletName: string;
  auth: Auth;

  constructor(api: Api, auth: Auth) {
    this.api = api;
    this.auth = auth;
  }

  getServletName = () => {
    if (!this.resetServletName) {
      this.resetServletName = this.auth.user ? "users" : "signup";
    }
    return this.resetServletName;
  };

  resetPasswordDetails(id: string, onError: () => void) {
    return this.api._requestByLocationObj({
      location: globalApiLocation(),
      method: "GET",
      op: `${this.getServletName()}/reset-password-details/${id}`,
      errorHandlers: { 404: onError },
    });
  }

  resetPassword(email: string, id: string, password: string) {
    return this.api._requestByLocationObj({
      location: globalApiLocation(),
      method: "POST",
      op: `signup/reset-password/${id}/${email}`,
      body: { password },
    });
  }
}
