export interface FileMatcherType {
  id: string;
  label: string;
  noValue?: boolean;
}

export const FileMatcherTypes: FileMatcherType[] = [
  { id: "StartsWithMatcher", label: "Starts With" },
  { id: "EndsWithMatcher", label: "Ends With" },
  { id: "IncludesMatcher", label: "Includes" },
  { id: "AllMatcher", label: "All", noValue: true },
  { id: "RegexMatcher", label: "Regular Expression" },
  { id: "GlobFileMatcher", label: "Glob Expression" },
];
