import React, { Component } from "react";
import { action, get, keys } from "mobx";
import { observer } from "mobx-react";
import { Button } from "../Button";
import { FormSelectInputProps } from "../FormInputTypes";
import { FormNumberInput } from "./FormNumberInput";
import { FormFieldRegistry } from "../../FormFieldRegistry";

@observer
export class IntervalsInput extends Component<FormSelectInputProps> {
  componentDidMount() {
    if (this.props.input.$value) {
      this.props.input.$value.forEach((x: unknown, i: number) => {
        this.props.input.add();
        this.props.input.$(i).set(x);
      });
    }
  }

  @action.bound
  _onAdd(e: React.MouseEvent) {
    e.stopPropagation();
    this.props.input.add();
  }

  @action.bound
  _onDel(id: string, idx: number) {
    this.props.input.del(idx);
    const currentIndex = this.props.input.$value.findIndex((x: unknown) => x === id);
    this.props.input.$value[currentIndex] = null;
  }

  render() {
    return (
      <div className="fields-autocomplete-array">
        {keys<string>(this.props.input.fields).map((idx) => {
          const input = get(this.props.input.fields, idx);
          return (
            <div key={idx} className="flex-two-columns mapped-column">
              <div className="properties">
                <FormNumberInput {...this.props} input={input} />
              </div>
              <div className="buttons">
                {Number(idx) > 0 && (
                  <span
                    className="icon-trash clickable"
                    onClick={action(() => this._onDel(input.$value, Number(idx)))}
                  />
                )}
              </div>
            </div>
          );
        })}
        <Button type="secondary" automationName="nary-add-input" small onClick={this._onAdd}>
          Add Interval
        </Button>
      </div>
    );
  }
}

export const register = (formFieldRegistry: FormFieldRegistry) =>
  formFieldRegistry.register("intervals", (props) => (
    <IntervalsInput {...props} options={props.input.metadata.options} />
  ));
