import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { GlobalContext } from "../../GlobalContext";
import { SupportedFeatures } from "../api/contracts/SupportedFeatures";
import { useBasicApi, useFullApi } from "../api/Api";
import { Spin } from "./Spin";

export function useSupportsFeature(feature: SupportedFeatures) {
  const { metadataStore } = useContext(GlobalContext);
  return useMemo(() => {
    return metadataStore.Metadata().supports(feature);
  }, [feature, metadataStore]);
}

interface SupportedFeatureSwitchProps {
  feature: SupportedFeatures;
  children?: React.ReactNode;
  fallback?: React.ReactNode;
}

/**
 * If feature is supported return children otherwise return fallback
 * @param {{feature: SupportedFeatures, children?: React.ReactNode, fallback?: React.ReactNode}} props
 * @returns {any}
 * @constructor
 */
export function SupportedFeatureSwitch(props: SupportedFeatureSwitchProps): any {
  const supported = useSupportsFeature(props.feature);
  if (supported === null) {
    return null;
  }

  return (supported ? props.children : props.fallback) || null;
}

export function useGlobalSupportedFeatureFeature(feature: SupportedFeatures) {
  const api = useFullApi();
  const [supporetedStatus, setSupportedStatus] = useState<"loading" | boolean>("loading");
  const timer = useRef<NodeJS.Timeout>();

  useEffect(() => {
    const getGlobalSupportedFeatures = async () => {
      try {
        const features = await api.shortGetGlobal<SupportedFeatures[]>("metadata/supported-features");
        setSupportedStatus(features.includes(feature));
      } catch {
        timer.current = setTimeout(getGlobalSupportedFeatures, 300);
      }
    };
    getGlobalSupportedFeatures();
    return () => clearTimeout(timer.current);
  }, [api, feature]);

  return supporetedStatus;
}

/**
 * If feature is supported by global api return children otherwise return fallback.
 * Global supported features are fetched on load instead of using MetadataStore.
 * @param {SupportedFeatureSwitchProps} props
 * @returns {any}
 * @constructor
 */
export function GlobalSupportedFeaturesSwitch(props: SupportedFeatureSwitchProps): any {
  const supported = useGlobalSupportedFeatureFeature(props.feature);
  if (supported === "loading") {
    return <Spin />;
  }

  return (supported ? props.children : props.fallback) || null;
}
