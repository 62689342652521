import React from "react";
import { observer } from "mobx-react";

export const DisablerContext = React.createContext(false);

@observer
export class Disabler extends React.Component<{ disabled?: boolean; className?: string }> {
  _node: any;

  updateDisabled(div: any) {
    const node = this._node || div;
    this._node = node;
    const disabled = this.props.disabled;

    node.querySelectorAll("input, select, button, textarea").forEach((element: HTMLInputElement) => {
      const originalDisabled = element.dataset.disabled === "true";
      element.disabled = disabled || originalDisabled;
    });
  }

  render() {
    return (
      <DisablerContext.Provider value={this.props.disabled}>
        <div ref={(div) => this.updateDisabled(div)} className={this.props.className}>
          {this.props.children}
        </div>
      </DisablerContext.Provider>
    );
  }
}
