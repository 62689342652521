import { observer } from "mobx-react";
import React, { Component } from "react";
import { ComboBox } from "../ComboBox";
import { scrollIntoView } from "../../Utils";
import classNames from "classnames";
import Checkbox from "@mui/material/Checkbox";
import { FormChoicesInputProps } from "./FormChoicesInput";
import { FormInput, inputOnChange } from "../FormInputTypes";
import FormItemDecorator from "./FormItemDecorator";
import { FormFieldRegistry } from "../../FormFieldRegistry";

@observer
export class MultiChoiceSelectInput extends Component<FormChoicesInputProps & { input: FormInput }> {
  componentDidMount() {
    const input = this.props.input;
    if (!(input.$value && input.$value.length)) {
      input.$value = [];
    }

    if (input.$value && input.$value.length && input.fields.size === 0) {
      input.$value.forEach((x: unknown) => {
        input.add();
        input.$(input.fields.size - 1).set(x);
      });
    }
  }

  select = (value: string) => {
    const input = this.props.input;
    const index = input.$value.indexOf(value);
    const clone = input.$value.slice();
    if (index > -1) {
      inputOnChange(input)(clone.splice(index, 1));
    } else {
      clone.push(value);
      inputOnChange(input)(clone);
    }
  };

  toggleAll = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const { input, options } = this.props;
    if (e.currentTarget.checked) {
      inputOnChange(input)(options.map((x) => x.value));
    } else {
      inputOnChange(input)([]);
    }
  };

  render() {
    const { input, options } = this.props;
    return (
      <FormItemDecorator
        hideLabel={!input.$label}
        input={input}
        labelPrefix={
          <Checkbox
            color="primary"
            indeterminate={input.$value && input.$value.length > 0 && input.$value.length !== options.length}
            onChange={this.toggleAll}
            checked={input.$value && input.$value.length === options.length}
          />
        }
      >
        <ComboBox
          showInput={false}
          items={options}
          filterItems={() => options} //v => v ? fuzzyFilter(options, ["title", "description"], v) : options}
          itemSelected={console.log}
          placeholder={input.placeholder}
        >
          {(option: any, focused) => {
            return (
              <li
                key={option.value}
                className={classNames("pointer", "flex-row", {
                  focused,
                })}
                ref={focused && scrollIntoView}
                onClick={() => this.select(option.value)}
                data-automation-name={`select-${option.value}`}
              >
                <Checkbox
                  onChange={() => this.select(option.value)}
                  color="primary"
                  checked={input.value && input.value.includes(option.value)}
                />
                <span className="flex-expand">
                  <h3 className="flex-overflow">{option.title}</h3>
                  <span className="description">{option.description}</span>
                </span>
              </li>
            );
          }}
        </ComboBox>
      </FormItemDecorator>
    );
  }
}
export const register = (formFieldRegistry: FormFieldRegistry) =>
  formFieldRegistry.register(
    "choice-combo",
    (props) => <MultiChoiceSelectInput {...props} options={props.input.metadata.options} />,
    { decorate: false }
  );
