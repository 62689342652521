import { action, computed, observable } from "mobx";
import { ButtonType } from "../../core/views/Button";
import { NotificationRelatedObject } from "../../notifications/NotificationsStore";

type ForceInformation = {
  title: string;
  ok: string;
  buttonType?: ButtonType;
  relatedItem?: NotificationRelatedObject;
  noCancel?: boolean;
};

type Throwable = {
  clazz: string;
  detailMessage: string;
  forceInformation?: ForceInformation;
  okDisplayName?: string;
};

export default class ErrorStore {
  _promise: Promise<any> = Promise.resolve();
  @observable _error: Throwable | null = null;

  _onOk?: (retry: Boolean) => void = null;
  _onCancel?: (goBack: Boolean) => void = null;

  push(error: Throwable): Promise<{ okay: { shouldRetry: Boolean }; cancel: { goBack: Boolean } }> {
    this._promise = this._promise.then(() => {
      return new Promise(
        action((resolve: (payload: any) => void) => {
          this._error = error;
          this._onOk = (shouldRetry) => {
            this._error = null;
            resolve({ okay: { shouldRetry } });
          };

          this._onCancel = (goBack) => {
            this._error = null;
            resolve({ cancel: { goBack } });
          };
        })
      );
    });

    return this._promise;
  }

  @computed get error(): Throwable | null {
    return this._error;
  }

  ok(options: { shouldRetry: Boolean }) {
    if (this._onOk) {
      this._onOk(options.shouldRetry);
    }
  }

  cancel(options: { goBack: Boolean }) {
    if (this._onCancel) {
      this._onCancel(options.goBack);
    }
  }
}
