import React, { Component } from "react";
import { action, computed, IObservableArray, observable } from "mobx";
import { observer } from "mobx-react";

import { FieldsMenuV3 } from "./FieldsMenuV3";
import { FieldViewContext, UFieldViewStore } from "../api/contracts/UFieldComponents";
import { Button } from "./Button";
import classNames from "classnames";
import { UField } from "../api/contracts/UField";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";

type SelectFieldModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSelect: (fields: UField[]) => void;
  selectedFieldKey?: string;
  fields: UField[];
  onAddComputedField?: (family: string) => void;
  selectedQuickFilter?: string;
  multi?: boolean;
  loading?: boolean;
  error?: string;
  noFilters?: boolean;
};

export const FieldInspectionContext = React.createContext<{ fieldInspection: (field: UField) => React.ReactNode }>(
  null
);

@observer
export class SelectFieldModal extends Component<SelectFieldModalProps> {
  static contextType = FieldInspectionContext;
  context!: React.ContextType<typeof FieldInspectionContext>;
  @observable.shallow _selectedMultiFields: IObservableArray<UField> = observable.array();
  @observable.ref _selectedField: UField;
  _fieldsStore = new UFieldViewStore();

  constructor(props: SelectFieldModalProps) {
    super(props);
    this._select = this._select.bind(this);
    this._trySelect(props.selectedFieldKey);
  }

  componentWillReceiveProps(nextProps: SelectFieldModalProps) {
    this._selectedMultiFields.clear();
    this._selectedField = null;
    this._trySelect(nextProps.selectedFieldKey);
  }

  _trySelect(key?: string) {
    if (key && key !== this._selectedItemKey) {
      const newField = this.props.fields.find((f) => UField.key(f) === key);
      if (newField) {
        this._selectedMultiFields.push(newField);
        this._selectedField = newField;
      }
    }
  }

  @action.bound
  _selectField(field: UField) {
    this._selectedField = field;
    this._fieldsStore.select(field);
  }

  @action.bound
  _checkField(field: UField, value: boolean) {
    if (this.props.multi) {
      if (!value) {
        this._selectedMultiFields.remove(field);
      } else if (value && !this._selectedMultiFields.includes(field)) {
        this._selectedMultiFields.push(field);
      }
    }
  }

  @computed get _selectedItemKey(): string | null {
    return this._selectedField && UField.key(this._selectedField);
  }

  _select(sendAll: boolean = true) {
    if (sendAll && this.props.multi && this._selectedMultiFields.length) {
      this.props.onSelect(this._selectedMultiFields);
    } else if (this._selectedItemKey) {
      this.props.onSelect([this._selectedField]);
    }
  }

  @computed
  get _title(): string {
    const length = this._selectedMultiFields.length;
    let title = "Select";
    if (this.props.multi && length) {
      title += ` ${length} fields`;
    }
    return title;
  }

  render() {
    const inspection = this._selectedField && this.context.fieldInspection(this._selectedField);
    const noSelection = !this._selectedField;
    return (
      <FieldViewContext.Provider value={this._fieldsStore}>
        <Drawer
          anchor="right"
          open={this.props.isOpen}
          onClose={this.props.onClose}
          style={{ zIndex: 1601 }}
          PaperProps={{ style: { maxHeight: "none", height: "100vh" } }}
        >
          <Box className="select-field" style={{ height: "calc(100% - 80px)" }}>
            <div className={classNames("top", { "no-selection": noSelection })}>
              <FieldsMenuV3
                height="100%"
                extraBottomPadding={35}
                noFilters={this.props.noFilters}
                fields={this.props.fields}
                onSelect={this._selectField}
                onCheck={this._checkField}
                onDoubleSelect={() => this._select(false)}
                selectedItem={this._selectedItemKey}
                onAddComputedField={this.props.onAddComputedField}
                selectedQuickFilter={this.props.selectedQuickFilter}
                multi={this.props.multi}
              />
              {this._selectedField && <div className="inspections">{inspection}</div>}
            </div>
            <div className="actions">
              {this.props.error && <div className="error-text description">{this.props.error}</div>}
              <Button
                type="primary"
                automationName="select-field"
                onClick={() => this._select()}
                loading={this.props.loading}
                disabled={!this._selectedField && !this._selectedMultiFields?.length}
              >
                {this._title}
              </Button>
              <Button type="cancel" onClick={this.props.onClose}>
                Cancel
              </Button>
            </div>
          </Box>
        </Drawer>
      </FieldViewContext.Provider>
    );
  }
}
