import validatorjs from "validatorjs";
import trimEnd from "lodash/trimEnd";
import { untilLastUnescapedDot } from "./Utils";
import classNames from "classnames";
import moment from "moment";
import { DataSourceWord } from "./Language";
import { ZonedDateTime } from "./MomentProvider";
import { TypeName } from "./api/contracts/TypeName";
import { UField } from "./api/contracts/UField";
import { TimeUnits } from "./views/TimeUnits";
import { BaseFormWithArrayFields } from "./BaseForm";

validatorjs.register(
  "alpha_undercore",
  function (value: string, requirement: string, attribute: string) {
    // requirement parameter defaults to null
    return /^[a-zA-Z0-9_\-]+$/.test(value);
  },
  "The :attribute field may only contain alpha-outputType characters, as well as underscores."
);
export const registerValidator = (name: string, message: string, validator: Function, partial?: any) =>
  validatorjs.register(name, partial ? (v: unknown) => validator(partial, v) : validator, message);

export const registerAsyncValidator = (name: string, validator: Function, partial?: any) =>
  validatorjs.registerAsyncRule(name, partial ? (v: unknown) => validator(partial, v) : validator);

export const validPathValidator = (value: { name: string }): boolean =>
  value && value.name
    ? !(
        (value.name.endsWith(".") && !value.name.endsWith("\\.")) ||
        value.name.endsWith(".[]") ||
        value.name.length === 0
      )
    : false;

export type DuplicateFieldValidatorState = { inputFields: Array<UField>; returnType: () => TypeName };

const dupFieldValidator = (
  state: DuplicateFieldValidatorState,
  payload: string | { name?: string; currentName?: string }
) => {
  const value = typeof payload === "string" ? payload : payload ? payload.name || payload.currentName : "";
  const nameAvailable = (name: string, dropLast: boolean = false) => {
    const nameWithoutArraySuffix = trimEnd(name, "[]");
    const desiredNames = { [name]: true, [nameWithoutArraySuffix]: true };
    if (dropLast) {
      return state.inputFields.findIndex((f) => desiredNames.hasOwnProperty(untilLastUnescapedDot(f.name))) === -1;
    } else {
      return state.inputFields.findIndex((f) => desiredNames.hasOwnProperty(f.name)) === -1;
    }
  };

  const returnType = state.returnType();
  if (!returnType) {
    return true;
  }
  if (returnType.subFields.length) {
    return nameAvailable(value) && returnType.subFields.findIndex((x) => nameAvailable(`${value}.${x.name}`)) > -1;
  } else {
    return nameAvailable(value) && nameAvailable(value, true);
  }
};

const dupAggNameValidator = (state: Array<string>, value: string) => {
  return state.findIndex((x) => x === value) === -1;
};

const validateMinLength = (value: Record<number, string>, requirement: unknown) => {
  for (let i = 0; i < Number(requirement); i++) {
    if (value[i] === "{}" || !value[i]) {
      return false;
    }
  }

  return true;
};

const validateContainFields = (value: Array<string>) => {
  return (
    value.findIndex((v) => {
      if (v && v !== "{}") {
        return typeof JSON.parse(v).field !== "undefined";
      }

      return false;
    }) !== -1
  );
};

export const EMPTY_NAME_ERROR = "must be set to a valid path.";

export const FormValidators = {
  VALID_PATH: {
    name: "validPath",
    register: () => registerValidator("validPath", `:attribute ${EMPTY_NAME_ERROR}`, validPathValidator),
  },
  DUPLICATE_FIELD_NAME: {
    name: "duplicateFieldName",
    register: (state: DuplicateFieldValidatorState) =>
      registerValidator("duplicateFieldName", "A field with same name already exists.", dupFieldValidator, state),
  },
  REQUIRED_WITH_WHITESPACE: {
    name: "allowWhiteSpace",
    register: () =>
      registerValidator(
        "allowWhiteSpace",
        "The :attribute field is required.",
        (value: unknown) => typeof value === "string" && value.length > 0
      ),
  },
  DUPLICATE_AGGREGATION_NAME: {
    name: "duplicateAggregationName",
    register: (state: Array<string>) =>
      registerValidator(
        "duplicateAggregationName",
        "Aggregation with same name already exists",
        dupAggNameValidator,
        state
      ),
  },
  FEATURE_PROPERTY: {
    name: "feature_property",
    validator: (update: () => Promise<any>, errorsHolder: any) => (validator: any) =>
      validator.registerAsyncRule(
        "feature_property",
        (value: any, req: any, key: any, passes: any) => {
          update().then(
            () => {
              const error = errorsHolder[key];
              if (error) {
                passes(false, error);
              } else {
                passes();
              }
            },
            () => {
              // Promise not resolved on loadItem of form.
              passes();
            }
          );
        },
        ":attribute is not valid."
      ),
  },
  MIN_NARY_INPUTS: {
    name: (min: string | number) => `minimumNaryInputs:${min}`,
    register: (min: number) =>
      registerValidator(
        `minimumNaryInputs`,
        min === 1 ? "Please fill the first input" : "Please fill the first :minimumNaryInputs inputs",
        validateMinLength
      ),
  },
  NARY_CONTAIN_FIELDS: {
    name: "naryContainFields",
    register: () =>
      registerValidator("naryContainFields", "Inputs must contain at least one field", validateContainFields),
  },
  MAX_DAYS: {
    name: "maxDays",
    register: () => registerValidator("maxDays", "Limited to 10,000 Days", (value: unknown) => value <= 14401440), // 10,000 days
  },
  MAX_EXPIRATION_WINDOW: {
    name: "maxExpirationWindow",
    register: () =>
      registerValidator(
        "maxExpirationWindow",
        "Limited to 99,999 Days",
        (value: unknown) => !value || value <= 143998560
      ),
  },
  AFTER_INPUT_TIME: {
    name: "afterInputStartTime",
    register: (inputStartTime: unknown) =>
      registerValidator("afterInputStartTime", `Should be after ${DataSourceWord.single()} Beginning`, (value: any) => {
        const time = value && moment(value);
        return time && time.isSameOrAfter(inputStartTime);
      }),
  },
  MAX_MINUTES: {
    name: (max: string | number) => `maxMinutes:${max}`,
    register: (max: number) =>
      registerValidator(
        "maxMinutes",
        `Cannot be more than ${TimeUnits.FitToWindow(max).amount} ${TimeUnits.FitToWindow(max).unit}`,
        (value: unknown) => value <= max
      ),
  },
  VALID_MOMENT: {
    name: "validMoment",
    register: () =>
      registerValidator(
        "validMoment",
        "Invalid date",
        (m: any) => !m || (typeof m === "string" ? moment(m).isValid() : !m.isValid || m.isValid())
      ),
  },
  AFTER_ZONED_TIME: {
    name: (name: string) => `afterZonedTime:${name}`,
    register: (getStartValue: () => string, getEndValue: () => string, name: string) =>
      registerValidator(`afterZonedTime`, `Must be after ${name}`, () => {
        const end = getEndValue();
        const start = getStartValue();
        return !end || moment(end).isAfter(start);
      }),
  },
  REQUIRED_ENABLED_OPTIONAL: {
    name: "requiredEnabledOptional",
    register: () =>
      registerValidator("requiredEnabledOptional", ":attribute must have a value when enabled", (value: unknown) => {
        console.log(value);
        return value != null;
      }),
  },
};

export function registerAllStatelessValidators() {
  FormValidators.VALID_MOMENT.register();
  FormValidators.MAX_DAYS.register();
  FormValidators.NARY_CONTAIN_FIELDS.register();
  FormValidators.VALID_PATH.register();
  FormValidators.REQUIRED_WITH_WHITESPACE.register();
  FormValidators.VALID_MOMENT.register();
  FormValidators.MAX_EXPIRATION_WINDOW.register();
  FormValidators.REQUIRED_ENABLED_OPTIONAL.register();
}

export const validatorNames = (a: any, b?: any) => classNames.apply(validatorNames, [a, b]).split(" ").join("|");
export const schemeTypeToValidatorType = (schemaType: string): string => {
  let t;
  switch (schemaType) {
    case "String":
      t = "string";
      break;
    case "Char":
      t = "string|max:1|min:1";
      break;
    case "Boolean":
      t = "boolean";
      break;
    case "OperationReference":
      t = "";
      break;
    case "FiniteDuration":
      t = FormValidators.MAX_DAYS.name;
      break;
    case "Double":
    case "Long":
    case "Integer":
    case "Int":
    case "Float":
      t = "numeric";
      break;
    case "Instant":
      t = "date";
      break;
    case "IndexAggregation":
    case "SchemaFile":
    case "PolicyStatement":
      t = "";
      break;
    default:
      t = "string";
      break;
  }

  return t;
};
