import { action, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import classNames from "classnames";
import moment from "moment";
import Modal from "./Modal";
import { Button } from "./Button";
import { EllipsisTooltip } from "./EllipsisTooltip";
import { shortDateTimeFormat, shortDateTimeZoneFormat } from "../dateFormats";
import { RequestError } from "../api/RequestError";

//TODO: make this into the warning box thing
export const Alert: React.FC<{ message: string; type: string; showIcon?: boolean }> = ({ message, type, showIcon }) => (
  <div>
    {type}:&nbsp;{message}
  </div>
);

export type TitleWithCompanionProps = {
  title: any;
  companion: any;
  onClick: React.MouseEventHandler;
  disabled?: boolean;
};

export const TitleWithCompanion = observer(({ title, companion, onClick, disabled }: TitleWithCompanionProps) => (
  <Button type="primary" onClick={onClick} disabled={disabled}>
    {title}
  </Button>
));

type ErrorPlaceholder = {
  msg: string;
  err?: RequestError;
};
export const ErrorPlaceholder = ({ msg, err }: ErrorPlaceholder) => (
  <p>
    Error {msg}: {err && err.response && err.response.body ? err.response.body : err && err.toString()}
  </p>
);

export function DateFromNow(props: { date: string | number; short?: boolean }) {
  const value = props.date;
  const timeValue = moment(value);
  const formattedTime = timeValue.format(shortDateTimeFormat);
  const fromNow = moment(value).fromNow();
  return (
    <EllipsisTooltip title={timeValue.format(shortDateTimeZoneFormat)}>
      <span>{props.short ? fromNow : `${formattedTime} (${fromNow})`}</span>
    </EllipsisTooltip>
  );
}

@observer
export class PopconfirmButton extends React.Component<{
  disabled?: boolean;
  use?: boolean;
  action: (payload?: any) => void;
  title?: string;
  buttonProps?: any;
  input?: boolean;
  isSpan?: boolean;
  password?: boolean;
  borderless?: boolean;
}> {
  @observable _open: boolean = false;

  @observable _value: string;

  @action
  _handleDisabledChange() {
    if (this.props.disabled) {
      this._open = false;
    }
  }

  @action
  _handleVisibleChange(visible: boolean) {
    if (this.props.use && !this.props.disabled) {
      this._open = visible;
    } else {
      this._open = false;
    }
  }

  @action
  _toggleOpen() {
    if (this.props.disabled) {
      return;
    }

    if (this.props.use) {
      this._open = !this._open;
    } else {
      this._open = false;
      this.props.action();
    }
  }

  @action
  _cancel() {
    this._open = false;
  }

  @action
  _confirm(v?: any) {
    this._open = false;
    this.props.action(v);
  }

  render() {
    const title = this.props.title;
    const children = this.props.children;
    const buttonProps = this.props.buttonProps || {};
    buttonProps.type = buttonProps.type || "ghost";
    const disabled = this.props.disabled || false;

    const input = this._open && this.props.input && (
      <input
        className="popconfirm-input"
        type={this.props.password ? "password" : "text"}
        onChange={(e) => (this._value = e.target.value)}
      />
    );
    const button = this.props.isSpan ? (
      <span
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
          this._toggleOpen();
        }}
        {...buttonProps}
      >
        {children}
      </span>
    ) : (
      <Button type="primary" onClick={() => this._toggleOpen()} {...buttonProps} disabled={disabled}>
        {children}
      </Button>
    );

    const body = input ? (
      <div className="popconfirm-children">
        {button}
        {input}
      </div>
    ) : (
      button
    );

    if (disabled) {
      return body;
    }

    return (
      <span>
        {body}
        <Modal isOpen={this._open} onRequestClose={() => this._handleVisibleChange(false)}>
          {title}
          <Button type="primary" onClick={() => this._confirm(this._value)} {...buttonProps} disabled={disabled}>
            {this.props.input ? "Ok" : "Yes"}
          </Button>
          <Button
            type="secondary"
            onClick={() => this._handleVisibleChange(false)}
            {...buttonProps}
            disabled={disabled}
          >
            {this.props.input ? "Cancel" : "No"}
          </Button>
        </Modal>
      </span>
    );
  }
}

export class Tag extends React.Component<{ closable?: boolean; onClick?: React.MouseEventHandler }> {
  render() {
    return (
      <span className={classNames("tag", { closable: this.props.closable })} {...this.props}>
        {this.props.children}
      </span>
    );
  }
}

type IconProps = {
  type: string;
};

export class Icon extends React.Component {
  props: IconProps;

  render() {
    return false;
  }
}

export const ErrorCounts: React.FC<{
  tooltipPostfixText?: string;
  count?: number;
  threshold?: number;
  icon?: string;
}> = ({ tooltipPostfixText, count, threshold, icon }) =>
  count <= threshold ? null : (
    <span className="error-counts" title={`${count} ${tooltipPostfixText}`}>
      {icon ? (
        <span className={`${icon} error`} />
      ) : (
        <React.Fragment>
          {" "}
          (<span className="error">{count}</span>)
        </React.Fragment>
      )}
    </span>
  );
export const addWordBreakMarkers = (text: string) => {
  if (!text || text.length < 3) {
    return text;
  }
  return (
    <span>
      {text
        .split(/([\.\-\_\]])/)
        .reduce((a, c, i) => (i === 0 ? [c] : a.concat(c.length > 1 ? [<wbr key={i} />, c] : [c])), [])}
    </span>
  );
};
export const GraphTime = ({ points }: { points: any[] }) => {
  const first = points[0];
  const last = points[points.length - 1];

  const diff = Math.abs(Date.parse(last.time) - Date.parse(first.time)) / 1000 / 60 + 1;
  const hoursDiff = Math.floor(diff / 60);
  const minutesDiff = diff - hoursDiff * 60;

  if (hoursDiff > 0 && minutesDiff > 0) {
    return (
      <span>
        {hoursDiff} hours and {minutesDiff} minutes.
      </span>
    );
  } else if (hoursDiff > 0) {
    return <span>{hoursDiff} hours.</span>;
  } else {
    return <span>{minutesDiff} minutes.</span>;
  }
};
