import React from "react";
import { MetadataStore } from "../core/Metadata";
import { InputStore } from "../inputs/Store";
import { GlobalUIStore } from "../core/GlobalUIStore";
import { UserDefinedFunctionsStore } from "../udf/Store";
import { AggregationsTemplateStore } from "../templates/common/TemplatesStore";
import { EnvironmentsStore } from "../environments/EnvironmentsStore";
import { IndexAliasStore } from "../templates/index/stores/IndexAliasStore";
import { OutputConnectionStore } from "../templates/output/stores/OutputConnectionsStore";
import { DslStore } from "../pipelines/Store";
import { ApiTokensStore } from "../core/api/ApiTokensStore";
import { RedshiftStore } from "../output/Redshift";
import { PaymentStore } from "../auth/PaymentServlet";
import { GitIntegrationStore } from "../auth/git/GitIntegrationStore";
import { IndexInspectionStore } from "../index/InspectionStore";
import { MonitoringStore } from "../monitoring/model";
import { ManageCreditsApi } from "../billing/credits/api/credits";
import { CloudStorageStore } from "../cloud-storage/Store";
import { MomentProvider } from "../core/MomentProvider";
import { FormStore } from "../core/views/FormStore";
import { NotificationsStore } from "../notifications/NotificationsStore";
import { Analytics } from "../analytics/analytics";
import { Auth } from "../auth/Auth";
import { Api } from "../core/api/Api";
import { IPreferencesStorage } from "../core/UserPreferences";
import { ChartStore } from "../core/ChartStore";
import { FormFieldRegistry } from "../core/FormFieldRegistry";
import { WorkspacesStore } from "../core/WorkspacesStore";
import { CustomDataSourceStore } from "../inputs/CustomInputsStore";
import { ReferenceDataStore } from "../core/ReferenceDataStore";
import { OperationInspection } from "../operation-inspection/OperationInspection";
import { TableSampleStore } from "../operation-inspection/TableSampleStore";
import { EnvironmentMonitoringStore } from "../monitoring/EnvironmentMonitoringStore";
import { CloudIntegrationStore } from "../cloud-integrations/cloud-integration-store";

export interface AppStores {
  metadataStore: MetadataStore;
  tableSamples: TableSampleStore;
  globalUIStore: GlobalUIStore;
  udfStore: UserDefinedFunctionsStore;
  auth: Auth;
  indexTemplateStore: AggregationsTemplateStore;
  computeEnvironments: EnvironmentsStore;
  indexAliasStore: IndexAliasStore;
  opsInspection: OperationInspection;
  referenceDataStore: ReferenceDataStore;
  customDataSources: CustomDataSourceStore;
  outputConnections: OutputConnectionStore;
  dslStore: DslStore;
  apiTokensStore: ApiTokensStore;
  redshift: RedshiftStore;
  userPreferences: IPreferencesStorage;
  payment: PaymentStore;
  api: Api;
  templateStore: AggregationsTemplateStore;
  gitIntegrationStore: GitIntegrationStore;
  indexInspection: IndexInspectionStore;
  monitoring: MonitoringStore;
  manageCredits: ManageCreditsApi;
  cloudStorage: CloudStorageStore;
  envMonitoringStore: EnvironmentMonitoringStore;
  inputsStore: InputStore;
  momentProvider: MomentProvider;
  integrations: CloudIntegrationStore;
  workspacesStore: WorkspacesStore;
  formStore: FormStore;
  notificationsStore: NotificationsStore;
  analytics: Analytics;
  chartStore: ChartStore;
  formFieldRegistry: FormFieldRegistry;
}

export const AppContext = React.createContext<AppStores>(null);
export const AppContextProvider = AppContext.Provider;
export const AppContextConsumer = AppContext.Consumer;
