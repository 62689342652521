import React, { PropsWithChildren } from "react";
import { TopBar } from "../../TopBar";

export type GlobalTitleProps = PropsWithChildren<{ title: React.ReactNode; hide?: boolean }>;

/**
 * Show a title in the top bar. You can conditionally hide it using the hide prop.
 */
export function GlobalTitle(props: GlobalTitleProps) {
  return (
    <>
      {!props.hide && <TopBar key="top-bar">{props.title}</TopBar>}
      {props.children}
    </>
  );
}
