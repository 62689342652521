const knownNoLocalApiReasons = ["created-local-api", "deployed-local-api", "ui-initialize-request"] as const;
type ReasonKeys = typeof knownNoLocalApiReasons[number];

export type KnownNoLocalApiReasons = ReasonKeys | string;
export const noLocalApiPathBase = "/no-local-api";
export type NoLocalApiPath = `/no-local-api/${KnownNoLocalApiReasons}`;

export function isKnownNoLocalApiReason(reason: string): reason is ReasonKeys {
  return knownNoLocalApiReasons.includes(reason as any);
}
