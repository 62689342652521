import { MainLoadingPlaceholder } from "./MainLoadingPlaceholder";
import React from "react";

type MainAsyncPlaceholderProps = {
  isLoading: boolean;
  error?: Error;
};
export const MainAsyncPlaceHolder = ({ isLoading, error }: MainAsyncPlaceholderProps) => {
  if (isLoading) {
    return <MainLoadingPlaceholder />;
  } else if (error) {
    console.log("Error loading app", error);
    return <div>Sorry, there was a problem loading the page. {error.toString()}</div>;
  } else {
    return null;
  }
};
