/* eslint-disable */
import React, { Component } from "react";
import { UpsolverLocalStorage } from "./core/UpsolverLocalStorage";

export class UserPic extends Component<{ userName: string; title?: string; showPic?: boolean }> {
  static defaultProps = {
    showPic: true,
  };

  picUrl = this.props.showPic && UpsolverLocalStorage.get().profilePicUrl;
  initials = (this.props.userName ?? "")
    .split(" ")
    .map((f) => f[0])
    .join("");
  alt = this.props.title || this.props.userName;

  render() {
    return (
      <React.Fragment>
        {this.picUrl ? (
          <img className="initials" src={this.picUrl} alt={this.props.userName} title={this.alt} />
        ) : (
          <span className="initials" title={this.alt}>
            {this.initials}
          </span>
        )}
      </React.Fragment>
    );
  }
}
