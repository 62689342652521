import React, { Component } from "react";
import { Bytes, Scalar } from "../Units";
import classNames from "classnames";
import { Button, FlatLinkButton } from "./Button";
import { useFileUpload } from "../hooks/useFileUpload";

export class UploadFile {
  file: File;
  error: string | null;
  sizeError: boolean;

  constructor(file: File, error: string | null = null, sizeError: boolean = false) {
    this.file = file;
    this.error = error;
    this.sizeError = sizeError;
  }
}

export type UploadFileListProps = {
  files: UploadFile[];
};

export class UploadFileList extends Component<UploadFileListProps> {
  props: UploadFileListProps;

  render() {
    return (
      <div className="upload-file-list">
        <table>
          <tbody>
            {this.props.files.map((f, i) => (
              <tr key={i}>
                <td className={classNames({ error: f.error && !f.sizeError })}>{f.file.name}</td>
                <td className={classNames({ error: f.sizeError })}>
                  <Scalar {...Bytes.measure(f.file.size)} />
                </td>
                <td className="error">
                  {f.error && <span className="icon-error" />} {f.error}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export type FileUploadProps = {
  onFiles: (files: File[]) => void;
  accept?: string[];
  fireOnSameFile: boolean;
  multi?: boolean;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
  title?: React.ReactNode;
  showFileTypes?: boolean;
};

export class ButtonFileUpload extends Component<FileUploadProps> {
  render() {
    return (
      <GenericFileUpload {...this.props} className="single-file-upload">
        {(args: ImportFileUploadChildrenCallback) => (
          <React.Fragment>
            <p>
              {args.files.length ? (
                args.files.join(",")
              ) : (
                <React.Fragment>
                  <span className="up-arrow" />
                  {`${this.props.multi ? "Select files" : "Select a file"} to import from your computer`}
                </React.Fragment>
              )}
            </p>
            <Button type="primary" small onClick={args.browse} disabled={args.disabled} loading={args.loading}>
              Select
            </Button>
          </React.Fragment>
        )}
      </GenericFileUpload>
    );
  }
}

export class ImportFileUpload extends Component<FileUploadProps> {
  static defaultProps = { title: "Import", showFileTypes: false };

  getFileTypesStr = () => {
    const fileTypesArr = this.props.accept;
    return ` (${fileTypesArr.join(", ")})`;
  };

  render() {
    const { className, ...props } = this.props;

    return (
      <GenericFileUpload {...props} className={classNames("inline-file-upload", "import", className)}>
        {(args) => (
          <FlatLinkButton onClick={args.browse} className="export">
            <span className="icon-export rotated-180" />
            {`${props.title}${props.showFileTypes ? this.getFileTypesStr() : ""}`}
          </FlatLinkButton>
        )}
      </GenericFileUpload>
    );
  }
}

export type ImportFileUploadChildrenCallback = {
  browse: React.MouseEventHandler;
  disabled: boolean;
  loading: boolean;
  files: string[];
  className?: string;
  children?: any;
};

function GenericFileUpload(props: FileUploadProps & { children: (e: ImportFileUploadChildrenCallback) => any }) {
  const uploader = useFileUpload(props.onFiles, props.fireOnSameFile, props.multi, props.accept);
  const { disabled, loading, children, className } = props;

  return (
    <div className={className}>
      <input {...uploader.inputProps} />
      {children({
        browse: () => uploader.inputProps.ref.current.click(),
        disabled: disabled,
        loading: uploader.loading || loading,
        files: uploader.fileNames,
      })}
    </div>
  );
}
