import React, { useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import { TreeEntry, TreeProps } from "./Tree";
import { FixedSizeList } from "react-window";
import { observer } from "mobx-react-lite";
import { lastLabel } from "../Utils";
import { UField } from "../api/contracts/UField";
import Checkbox from "@mui/material/Checkbox";
import { FieldTreeEntry } from "./TreeItem";
import TreeContext from "../TreeContext";
import { DisplayData } from "../api/contracts/Core";
import { getFlattenedTreePaths } from "../TreeEntityUtils";

function arrayToMap<T>(keys: string[], defaultValue: T): Record<string, T> {
  return keys.reduce((a, key) => Object.assign(a, { [key]: defaultValue }), {});
}

const Tree2: React.FC<TreeProps<any> & { fieldsMap: { [key: string]: UField } }> = observer(
  ({
    onExpand,
    onSelect,
    onDoubleSelect,
    onCheck,
    selectedKey,
    checkable,
    style,
    renderEntry,
    entries,
    expandedKeys,
    filteredEntries,
    checkedKeys,
    fieldsMap,
  }) => {
    const listRef = useRef<FixedSizeList>(null);
    const checkedKeysMap = useMemo(() => arrayToMap<boolean>(checkedKeys || [], true), [checkedKeys.length]);
    const expandedKeysMap = useMemo(() => arrayToMap<boolean>(expandedKeys || [], true), [expandedKeys.length]);
    const [flatTree, setFlatTree] = useState(getFlattenedTreePaths(entries, expandedKeysMap, filteredEntries));
    const [scrolledToKey, setScrolledToKey] = useState("");
    const [userClicked, setUserClicked] = useState(false);

    useEffect(() => {
      if (
        !userClicked &&
        selectedKey &&
        listRef.current &&
        flatTree.paths.length > 0 &&
        selectedKey !== scrolledToKey
      ) {
        const selectIndex = flatTree.paths.findIndex((x) => x[x.length - 1].startsWith(selectedKey));
        if (selectIndex > -1) {
          listRef.current.scrollToItem(selectIndex, "center");
          setScrolledToKey(selectedKey);
        }
      }
    }, [selectedKey, flatTree, scrolledToKey, userClicked]);

    useEffect(() => {
      const flattenedTreePaths = getFlattenedTreePaths(entries, expandedKeysMap, filteredEntries);
      setFlatTree(flattenedTreePaths);
    }, [expandedKeysMap, filteredEntries, entries]);

    const clickEntry = (entry: FieldTreeEntry, e: React.MouseEvent<HTMLInputElement>) => {
      setUserClicked(true);
      const clickedOnCheckbox = (e.target as HTMLInputElement).type === "checkbox";
      e.stopPropagation();
      if (!clickedOnCheckbox) {
        if (entry.children && entry.children.length) {
          onExpand(entry, !expandedKeysMap.hasOwnProperty(entry.key));
        } else {
          onSelect(entry);
        }
      }
    };

    const toggleExpand = (entry: TreeEntry, e: React.MouseEvent<HTMLSpanElement>) => {
      e.stopPropagation();
      onExpand(entry, !expandedKeysMap.hasOwnProperty(entry.key));
    };

    const toggleCheck = (entry: TreeEntry, e: React.ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation();
      if (onCheck && checkedKeysMap) {
        onCheck(entry, !checkedKeysMap.hasOwnProperty(entry.key));
      }
    };

    const descedantsChecked = (entry: TreeEntry, checkedKeysMap: any, func: Function): boolean => {
      if (entry.children) {
        return func.call(entry.children, (e: TreeEntry) => descedantsChecked(e, checkedKeysMap, func));
      } else {
        return checkedKeysMap[entry.key] === true;
      }
    };

    const doubleClickProps = onDoubleSelect ? { onDoubleClick: onDoubleSelect } : {};
    const renderNode = (entry: TreeEntry<any>, style: Partial<React.CSSProperties>, depth: number) => {
      const hasChildren = entry.children ? entry.children.length > 0 : false;
      const key = entry.key;
      const selected = key === selectedKey;
      const allDescendantsChecked = descedantsChecked(entry, checkedKeysMap, Array.prototype.every);
      const anyDescendantsChecked = descedantsChecked(entry, checkedKeysMap, Array.prototype.some);
      const markedAsChecked = checkedKeysMap[key] === true;
      return (
        <span
          style={style}
          className={classNames("tree-item", {
            submenu: hasChildren,
            close: !(expandedKeysMap[key] === true),
            selected: !hasChildren && selected,
            child: depth > 1,
            "selected-parent": hasChildren && selected,
          })}
        >
          {depth > 1 && [...new Array(depth - 1)].map((x, idx) => <span key={idx} className="line" />)}
          {renderEntry({
            item: entry,
            onClick: (e: React.MouseEvent<HTMLInputElement>) => clickEntry(entry, e),
            doubleClickProps,
            checkbox: checkable && (
              <Checkbox
                color="primary"
                indeterminate={(markedAsChecked || anyDescendantsChecked) && !allDescendantsChecked}
                checked={markedAsChecked || allDescendantsChecked}
                onChange={(e) => toggleCheck(entry, e)}
              />
            ),
            after: hasChildren && <span className="icon-arrow-down" onClick={(e) => toggleExpand(entry, e)} />,
          })}
        </span>
      );
    };

    return (
      <FixedSizeList
        ref={listRef}
        className="tree usable-nice-scroll"
        itemSize={38}
        height={style && !Number.isNaN(style.height) ? style.height : flatTree.paths.length * 38}
        overscanCount={10}
        itemCount={flatTree.paths.length}
        width={"100%"}
      >
        {({ index, style }) => {
          const fieldPaths = flatTree.paths[index];
          const fieldPath = fieldPaths[fieldPaths.length - 1];
          const entry = fieldsMap[fieldPath];
          const key = entry ? UField.key(entry) : fieldPath;
          const children = flatTree.children[key];
          return renderNode(
            {
              key,
              label: entry ? UField.lastLabel(entry) : lastLabel(fieldPath),
              field: entry,
              children,
            },
            {
              ...(style as any),
            },
            fieldPaths.length
          );
        }}
      </FixedSizeList>
    );
  }
);
export default Tree2;
const TreeCheckbox = observer(
  <T extends TreeEntry>(props: {
    treeContext: TreeContext<T>;
    entry: T;
    hasChildren: boolean;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
  }) => {
    const info = props.treeContext.entryInfo(props.entry);
    const allDescendantsChecked = info.checked === "checked";
    const anyDescendantsChecked = info.checked === "partial";
    const markedAsChecked = props.treeContext.checked[props.entry.key] === true;
    return (
      <Checkbox
        color="primary"
        indeterminate={props.hasChildren && (markedAsChecked || anyDescendantsChecked) && !allDescendantsChecked}
        checked={markedAsChecked || (props.hasChildren && allDescendantsChecked)}
        onChange={props.onChange}
      />
    );
  }
);

export type EntryData<T extends TreeEntry> = {
  item: T;
  onClick: (e: React.MouseEvent<HTMLInputElement>) => void;
  doubleClickProps: any;
  checkbox: React.ReactElement;
  after: React.ReactElement;
  descendantsCount: number;
};

interface Tree3Props<T extends TreeEntry> {
  onExpand: (entry: T, expanded: boolean) => void;
  onSelect: (entry: T) => void;
  onDoubleSelect: (entry: T) => void;
  onCheck: (entry: T, checked?: boolean) => void;
  selectedKey?: string;
  renderEntry: (entryInfo: EntryData<T>) => void;
  checkable: boolean;
  style?: any;
  className?: string;
  treeContext: TreeContext<T>;
  inputs?: Record<string, DisplayData>;
  scrollToItemIndex?: number;
}

export const Tree3 = observer(<T extends TreeEntry>(props: Tree3Props<T>) => {
  const {
    onExpand,
    onSelect,
    onDoubleSelect,
    onCheck,
    selectedKey,
    checkable,
    style,
    renderEntry,
    treeContext,
    inputs,
    className,
    scrollToItemIndex,
  } = props;
  const listRef = useRef<FixedSizeList>(null);
  const [userClicked, setUserClicked] = useState(false);
  const [previousScrollKey, setPreviewScrollKey] = useState<string>();
  const [handledUserClick, setHandledUserClick] = useState(false);

  useEffect(() => {
    if (scrollToItemIndex && listRef.current) {
      listRef.current.scrollToItem(scrollToItemIndex, "start");
    }
  }, [scrollToItemIndex]);

  useEffect(() => {
    if (treeContext && selectedKey && selectedKey !== previousScrollKey) {
      if (userClicked) {
        setHandledUserClick(true);
        setUserClicked(false);
      } else {
        const selectedIndex = treeContext.flattenedTree.paths.findIndex((x) => x[x.length - 1].startsWith(selectedKey));
        if (selectedIndex > -1) {
          listRef.current.scrollToItem(selectedIndex, "auto");
          setPreviewScrollKey(selectedKey);
        }
      }
    }
  }, [selectedKey, userClicked, handledUserClick, treeContext, treeContext.flattenedTree, previousScrollKey]);

  const clickEntry = (entry: TreeEntry<any>, e: React.MouseEvent<HTMLInputElement>) => {
    setUserClicked(true);
    setHandledUserClick(false);
    const clickedOnCheckbox = (e.target as HTMLInputElement).type === "checkbox";
    e.stopPropagation();
    if (!clickedOnCheckbox) {
      onSelect(entry);
    }
  };

  const toggleExpand = (entry: TreeEntry<any>, e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    onExpand(entry, !treeContext.expanded[entry.key]);
  };

  const toggleCheck = (entry: T, e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    if (onCheck) {
      onCheck(entry, !treeContext.checked[entry.key]);
    }
  };

  const doubleClickProps = onDoubleSelect ? { onDoubleClick: onDoubleSelect } : {};
  const renderNode = (entry: T, style: React.CSSProperties, depth: number) => {
    const hasChildren = entry.children ? entry.children.length > 0 : false;
    const key = entry.key;
    const selected = key === selectedKey;
    const info = treeContext.entryInfo(entry);
    return (
      <span
        style={style}
        className={classNames("tree-item", {
          submenu: hasChildren,
          close: !(treeContext.expanded[key] === true),
          selected: !hasChildren && (selected || treeContext.contributedNodes.hasOwnProperty(key)),
          child: depth > 1,
          "selected-parent": hasChildren && selected,
        })}
      >
        {depth > 1 && [...new Array(depth - 1)].map((x, idx) => <span key={idx} className="line" />)}
        {renderEntry({
          item: entry,
          onClick: (e) => clickEntry(entry, e),
          doubleClickProps,
          checkbox: checkable && (
            <TreeCheckbox
              entry={entry}
              treeContext={treeContext}
              hasChildren={hasChildren}
              onChange={(e) => toggleCheck(entry, e)}
            />
          ),
          after: hasChildren && <span className="icon-arrow-down" onClick={(e) => toggleExpand(entry, e)} />,
          descendantsCount: info.descendantsCount,
        })}
      </span>
    );
  };

  return (
    <FixedSizeList
      ref={listRef}
      className={classNames("tree nice-scroll", className, { inputs })}
      itemSize={28}
      height={style && !Number.isNaN(style.height) ? style.height : treeContext.flattenedTree.paths.length * 38}
      overscanCount={10}
      itemCount={treeContext.flattenedTree.paths.length}
      width={"100%"}
    >
      {({ index, style }) => {
        const fieldPaths = treeContext.flattenedTree.paths[index];
        const item = treeContext.getItemByIndex(index, inputs);
        return renderNode(
          item,
          {
            ...(style as any),
          },
          fieldPaths.length
        );
      }}
    </FixedSizeList>
  );
});
