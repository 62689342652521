import React, { Component } from "react";
import classNames from "classnames";
import Checkbox from "@mui/material/Checkbox";

type ToggleProps = {
  options: string[];
  onChange: (value: string) => void;
  value: string;
  medium?: boolean;
  disabled?: boolean;
  views?: { [key: string]: React.ReactNode };
  className?: string;
  style?: React.CSSProperties;
};

export class Toggle extends Component<ToggleProps> {
  props: ToggleProps;

  static defaultProps = {
    views: {},
  };

  constructor(props: ToggleProps) {
    super(props);
    this._onChange = this._onChange.bind(this);
  }

  _onChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (!this.props.disabled) {
      const newValue = e.currentTarget.value;
      const { onChange } = this.props;

      if (onChange) {
        onChange(newValue === this.props.value ? this.props.options.find((x) => x !== newValue) || newValue : newValue);
      }
    }
  }

  render() {
    const { options, value, medium, views, className } = this.props;

    return (
      <div
        role="radiogroup"
        className={classNames("toggle", className, { medium, disabled: this.props.disabled })}
        style={this.props.style}
      >
        {options.map((opt) => {
          const checked = value === opt;
          return (
            <label
              role="radio"
              aria-checked={checked}
              title={opt}
              key={opt}
              className={classNames({ checked })}
              data-automation-name={`toggle-to-${opt}`}
            >
              <Checkbox style={{ display: "none" }} value={opt} checked={checked} onChange={this._onChange} />
              {views.hasOwnProperty(opt) ? views[opt] : <span>{opt}</span>}
            </label>
          );
        })}
      </div>
    );
  }
}
