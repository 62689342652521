import { BaseFormWithArrayFields } from "../../BaseForm";
import {
  AutoCompleteMetadata,
  DynamicDescriptionMetadata,
  ServerSideValidation,
  StepMetadata,
} from "../../SimpleMetadata";

export type EditorInformation = Partial<{
  hideOnSingle: boolean;
  runOnSuccessfulValidation: string;
}>;

/**
 * Properties of a form field
 */
export interface FieldProperties<Value = any> {
  /**
   * The key of the field.
   */
  name: string;
  /**
   * Validation rules to apply on the fields, validation configurations separated by |.
   * Examples: required|outputType , between:2,12, required|email.
   * @see {@link https://github.com/skaterdav85/validatorjs} for complete list of validators.
   */
  rule: string;
  /**
   * Form field label (displayed name)
   */
  label: string;
  /**
   * UFormField type
   * @see UFormField in UFormField.tsx
   */
  type?: string;
  /**
   * Options to pass to the field's metadata. e.g, array of options for a select.
   */
  options?: any;
  initialValue?: Value;
  /**
   * Will be called when the value of the form field changes with the new value and the instance of the backing mobx-react-forms field
   */
  changeHandler?: (value: any, input: any) => void;
  /**
   * Content will be shown using asterisks and will not be sent to external tracking systems
   */
  secret?: boolean;
  /**
   * Form field description to display
   */
  description?: string;
  /**
   * Should be create as part of the form but not displayed to user
   */
  hidden?: boolean;
  /**
   * Form field name to place this field after
   */
  after?: string;
  /**
   * Add Form field before existing fields
   */
  insert?: boolean;
  /**
   * Metadata for providing values for the fields using the auto complete API
   */
  autocomplete?: AutoCompleteMetadata;
  readonly?: boolean;
  /**
   * Place holder content to display when the fields is pristine
   */
  placeholder?: string;

  serverSideValidation?: ServerSideValidation;

  step?: StepMetadata;

  editorInformation?: EditorInformation;
}

type PathToProvider = Record<string, () => Promise<any>>;

/**
 * Create an object representing a the fields of a form.
 * @see {FieldProperties} for description of field configuration properties.
 */
export class FormFields {
  [key: string]: any;

  /**
   * List of fields names (internal keys used in following maps not display labels)
   */
  fields: string[] = [];
  rules: any = {};
  labels: any = {};
  descriptions: any = {};
  types: any = {};
  options: any = {};
  related: any = {};
  values: any = {};
  changeHandlers: { [key: string]: (...args: any[]) => void } = {};
  dependantOptions: any;
  secret: string[] = [];
  validatorExtenders: Array<(validator: any) => void> = [];
  hidden: any = {};
  disabled: { [key: string]: boolean } = {};
  immutables: { [key: string]: boolean } = {};
  readonly: any = {};
  customViews: { [key: string]: any } = {};
  autocomplete: { [key: string]: AutoCompleteMetadata } = {};
  placeholders: { [key: string]: string | null } = {};
  serverSideValidation: { [key: string]: ServerSideValidation } = {};
  step: Record<string, StepMetadata> = {};
  editorInformation: Record<string, EditorInformation> = {};
  _contextProvider?: (value: string) => any;
  _defaultValueProviders?: { [key: string]: () => Promise<any> };
  dynamicDescriptions?: PathToProvider;

  /**
   * @constructor
   * @param contextProvider a function used to dynamically retrieve a value for the fields
   * @param defaultValueProviders a function used to get the default value of the field from the API.(used when default value is dependant on current organization etc)
   */
  constructor(
    contextProvider?: (value: string) => any,
    defaultValueProviders?: PathToProvider,
    dynamicDescriptions?: PathToProvider
  ) {
    this._contextProvider = contextProvider;
    this._defaultValueProviders = defaultValueProviders;
    this.dynamicDescriptions = dynamicDescriptions ?? {};
  }

  getInnerFieldName = (name: string) => name.split("[]")[0];

  addFieldProperties(properties: FieldProperties): FormFields {
    const {
      name,
      rule,
      label,
      type,
      options,
      initialValue,
      changeHandler,
      secret,
      description,
      hidden,
      after,
      insert,
      autocomplete,
      readonly,
      placeholder,
      serverSideValidation,
      step,
      editorInformation,
    } = properties;
    return this.addField(
      name,
      rule,
      label,
      type,
      options,
      initialValue,
      changeHandler,
      secret,
      description,
      hidden,
      after,
      insert,
      autocomplete,
      readonly,
      placeholder,
      serverSideValidation,
      step,
      editorInformation
    );
  }

  addMultipleFieldProperties(properties: FieldProperties[]) {
    properties.forEach((x) => this.addFieldProperties(x));
    return this;
  }

  addField(
    name: string,
    rule: string,
    label: string,
    type?: string,
    options?: any,
    initialValue?: any,
    changeHandler?: any,
    secret?: boolean,
    description?: string,
    hidden: boolean = false,
    after?: string,
    insert: boolean = false,
    autocomplete?: AutoCompleteMetadata,
    readonly?: boolean,
    placeholder?: string,
    serverSideValidation?: ServerSideValidation,
    step?: StepMetadata,
    editorInformation?: EditorInformation
  ): FormFields {
    const innerName = this.getInnerFieldName(name);
    if (after) {
      this.fields.splice(this.fields.indexOf(after) + 1, 0, name);
    } else {
      if (insert) {
        this.fields.unshift(name);
      } else {
        this.fields.push(name);
      }
    }
    this.rules[innerName] = rule;
    this.labels[innerName] = label;
    this.types[innerName] = type;
    this.descriptions[innerName] = description;
    this.hidden[innerName] = hidden;
    this.readonly[innerName] = readonly;
    this.placeholders[innerName] = placeholder;

    if (autocomplete) {
      this.autocomplete[innerName] = autocomplete;
    }

    if (changeHandler) {
      this.changeHandlers[innerName] = changeHandler;
    }

    this.setFieldValue(innerName, initialValue);

    if (type === "checkbox") {
      this.setFieldValue(innerName, Boolean(initialValue));
    }

    if (options) {
      this.options[innerName] = options;
    }

    if (secret) {
      this.secret.push(innerName);
    }

    if (serverSideValidation) {
      this.serverSideValidation[innerName] = serverSideValidation;
    }

    if (step) {
      this.step[innerName] = step;
    }

    this.editorInformation[innerName] = editorInformation ?? {};

    return this;
  }

  setFieldValue(name: string, initialValue?: any) {
    if (typeof initialValue !== "undefined") {
      if (name.includes("[]")) {
        this.values[name.substr(0, name.indexOf("["))] = initialValue;
      } else {
        this.values[name] = initialValue;
      }
    }
    return this;
  }

  setArrayRecordInitialValues(name: string, values: any): FormFields {
    this.values[name.includes("[]") ? name.substr(0, name.indexOf("[")) : name] = values;
    return this;
  }

  /**
   * Merge with existing fields configuration
   * @param fields
   */
  addFields(fields: Partial<FormFields>): FormFields {
    fields.fields.forEach((name) => {
      this.addField(
        name,
        fields.rules[name],
        fields.labels[name],
        fields.types[name],
        fields.options[name] ? fields.options[name] : null,
        fields.values ? fields.values[name] : null,
        fields.changeHandlers ? fields.changeHandlers[name] : null,
        fields.secret ? fields.secret.includes(name) : null,
        fields.descriptions ? fields.descriptions[name] : null,
        fields.hidden ? fields.hidden[name] : null
      );
    }, this);
    return this;
  }

  /**
   * Register custom async validators
   * @param method callback with validator instance
   */
  extendValidator(method: (validator: any) => void): FormFields {
    this.validatorExtenders.push(method);
    return this;
  }

  /**
   * Custom view to use for a field instead of the default view from the type property
   * @param name field name
   * @param view custom view type
   */
  registerCutomView(name: string, view: any): FormFields {
    this.customViews[this.getInnerFieldName(name)] = view;
    return this;
  }

  /**
   * Create an instance of the mobx-form-fields form modal using this form fields configuration
   * @param customViews map of fields name to custom view as in registerCutomView
   */
  createForm(customViews?: any): BaseFormWithArrayFields {
    return new BaseFormWithArrayFields(
      this.fields,
      this.labels,
      this.rules,
      this.types,
      this.options,
      this.changeHandlers,
      this.dependantOptions,
      Object.assign({}, this.customViews, customViews),
      this.values,
      (validator) => {
        this.validatorExtenders.forEach((method) => method(validator));
      },
      this.secret,
      this.descriptions,
      this.hidden,
      this.related,
      this.autocomplete,
      this._contextProvider,
      this.readonly,
      this._defaultValueProviders,
      this.placeholders,
      this.immutables,
      this.disabled,
      this.serverSideValidation,
      this.step,
      this.editorInformation,
      this.dynamicDescriptions
    );
  }
}
