import React, { Component } from "react";
import { ResponsiveWidthContainer } from "./ResponsiveWidthContainer";
import { uriTransformer } from "react-markdown";

type ExternalLinkProps = {
  children?: React.ReactNode;
  href: string;
};

class ExternalLink extends Component<ExternalLinkProps> {
  render() {
    const { href, children } = this.props;
    return (
      <a href={href} target="_blank" rel="noopener">
        {children}
      </a>
    );
  }
}

const LimitedWidthTable: React.FC = ({ children }) => (
  <ResponsiveWidthContainer>
    <table>{children}</table>
  </ResponsiveWidthContainer>
);

const renderers = {
  link: ExternalLink,
  table: LimitedWidthTable,
};

const linkPrefixes: Record<string, (id: string) => string> = {
  "output://": (id: string) => "/outputs/" + id,
};

export const markdownProps = {
  renderers,
  transformLinkUri: (uri?: string): string => {
    const prefix = uri ? Object.keys(linkPrefixes).find((prefix) => uri.startsWith(prefix)) : false;

    if (prefix) {
      const transformer = linkPrefixes[prefix];
      return transformer(uri.substring(prefix.length));
    } else {
      return uriTransformer(uri);
    }
  },
};
