import React, { PropsWithChildren, useContext } from "react";
import useLoginState, { LoginState } from "./core/api/useLoginState";
import { AuthenticationApi } from "./core/api/AuthenticationApi";
import { useHistory } from "react-router";
import { noLocalApiPathBase } from "./core/api/noLocalApi";

export const LoginStateContext = React.createContext<LoginState>(null);

export function useLoginStateProvider() {
  return useContext(LoginStateContext);
}

function LoginStateProvider({
  children,
  loader,
  loginAuth,
}: PropsWithChildren<{ loader: React.ComponentType; loginAuth: AuthenticationApi }>) {
  const loginState = useLoginState(loginAuth);
  const history = useHistory();
  const noLocalApi = history.location.pathname.startsWith(noLocalApiPathBase);

  if (!noLocalApi && loginState.status.loading) {
    return React.createElement(loader);
  }
  return <LoginStateContext.Provider value={loginState}>{children}</LoginStateContext.Provider>;
}
export default LoginStateProvider;
