import React from "react";
import { FormMetadata, MetadataFormOptionLists, MetadataStore } from "../Metadata";
import { Icon } from "./General";
import { ExternalLink } from "./ExternalLink";
import getPath from "lodash/get";
import setPath from "lodash/set";
import { FormView } from "./UForm";
import isNil from "lodash/isNil";
import flatMap from "lodash/flatMap";
import { FormValidators, schemeTypeToValidatorType, validatorNames } from "../FormValidators";
import moment from "moment";
import isArrayLike from "lodash/isArrayLike";
import { UField } from "../api/contracts/UField";
import { TimeUnits } from "./TimeUnits";
import { action } from "mobx";
import { Field as MField } from "../type-definitions/mobx-react-form";
import { BaseFormWithArrayFields } from "../BaseForm";
import { EditorInformation, FieldProperties, FormFields } from "../api/contracts/FormFields";
import { DisplayIf, Documentation, SimpleMetadata, SimpleMetadataProperty, StepMetadata } from "../SimpleMetadata";
import { defaultClazz as defaultClusterDeploymentMethod } from "./FormInputs/defaultClusterDeploymentMethod";
import { deserializeHiveMetastoresValue, hiveMetastoresValidator } from "./FormInputs/HiveMetastoreSerializer";
import { FeatureProperty } from "../FeatureMetadata";
import { schemaTypeToInputType } from "../SchemaType";
import { Item } from "./SuperSelectItem";
import { mergeRules } from "./mergeRules";
import { CreateFormFieldOptions, FormFieldsValueGetter } from "./CreateFormFieldOptions";

export const getRule = (property: FeatureProperty) => {
  const validators = {
    ...property.validators
  };

  if (property.serverSideValidation) {
    validators["serverSide"] = null;
  }

  const additional = property.validators && buildValidatorsString(validators);

  return mergeRules(validatorNames(property.mandatory ? "required" : "", !property.autoComplete && schemeTypeToValidatorType(property.schemaType)), additional);
};

const isTextualAutocompleteValue = (p: FeatureProperty, value?: any) => p.autoComplete && (p.schemaType === "String" || (value && value.userCreated));

export const deserializePossibleAutocompleteValues = (p: FeatureProperty, value: any) => {
  if (value !== null && isTextualAutocompleteValue(p, value)) {
    const isArray = isArrayLike(value);
    const head = (isArray && value.length > 0 && value[0]) || value;
    const isKeyed = head.hasOwnProperty("key");
    if (isArray) {
      return isKeyed ? value.map((x: any)  => x.key) : value;
    } else {
      return isKeyed ? head.key : head;
    }
  } else {
    return value;
  }
};

interface DeserializeValuesOptions { prefix?: string, metadataStore?: MetadataStore, formMetadata: FormMetadata };
/**
 * Get values to send back to API from a from that was generated form metadata. a wrapper around deserializeFormValues.
 * @param itemMetadata
 * @param values
 * @param {DeserializeValuesOptions} options
 */
export const deserializeValues = (itemMetadata: SimpleMetadata, values: any, options: DeserializeValuesOptions) => {
  return deserializeFormValues(itemMetadata, options.formMetadata, values, options.prefix, options.metadataStore);
};

/**
 * Get values to send back to API from a from that was generated form metadata
 * @param itemMetadata
 * @param formMetadata
 * @param values
 * @param prefix
 * @param metadataStore
 * @param current
 */
export const deserializeFormValues = (itemMetadata: SimpleMetadata, formMetadata: FormMetadata, values: any, prefix?: string, metadataStore?: MetadataStore, current?: any) => {
  const result: any = current || { clazz: itemMetadata.clazz };
  itemMetadata.properties.filter(p => !p.generated || p.defaultValue && !(p.editorInformation && p.editorInformation["metadata-list-name"])).forEach((p: FeatureProperty) => {
    const propName = prefix ? `${prefix}.${p.name}` : p.name;
    const propNamePrefix = propName.split(".")[0];
    const formValue = (p.group ? values[p.group][p.name] : getPath(values, propName));
    const value: Item | any = typeof formValue === "undefined" ? p.defaultValue : formValue;
    const fields = (formMetadata.lists && formMetadata.lists.fields) || [];
    if (p.editor === "subFields" && (values[p.name] || (prefix && values[prefix]))) {
      deserializeFormValues(getSubFieldsMetadata(p, metadataStore), formMetadata, values, p.name, metadataStore, result);
      setPath(result, p.name, Object.assign(result[p.name], { clazz: p.schemaType }));
    } else if (p.editor === "subFieldsSelector") {
      const wantedClazz = getPath(values, propName);
      const subFieldsMetadata = getsubFieldsSelectorMetadata(p, metadataStore);
      const subFieldItemMetadata = subFieldsMetadata.find(f => f.clazz === wantedClazz);
      if (subFieldItemMetadata) {
        setPath(result, propName, deserializeFormValues(subFieldItemMetadata, formMetadata, getPath(values, `${propName}-${wantedClazz}`),
            null, metadataStore, null));
      }
    } else {
      switch (p.schemaType) {
        case "Credentials":
          Reflect.set(itemMetadata, propNamePrefix, values[propNamePrefix]);
          break;
        case "FiniteDuration":
          if (!value || (value === 0 && !p.mandatory)) {

          } else if (typeof value === "number") {
            setPath(result, propName, value);
          } else if (value.amount !== "") {
            setPath(result, propName, TimeUnits.TimeWindowToMinutes(value.amount, value.unit));
          }
          break;
        case "ContentType":
          const avroSchemaRegistry = values.AvroSchemaRegistryContentType;
          setPath(result, propName, {
            clazz: value,
            inferTypes: values.inferTypes,
            header: values.header,
            delimiter: values.delimiter,
            nullValue: values.nullValue,
            mainFile: values.protobuf && values.protobuf.mainFile,
            schemaFiles: values.protobuf && values.protobuf.schemaFiles && values.protobuf.schemaFiles.slice(),
            messageType: values.protobuf && values.protobuf.messageType,
            schema: values.schema && values.schema,
            schemaRegistryUrl: avroSchemaRegistry && avroSchemaRegistry.schemaRegistryUrl,
            schemaRegistryFormat: avroSchemaRegistry && avroSchemaRegistry.schemaRegistryFormat && { clazz: avroSchemaRegistry.schemaRegistryFormat }
          });
          break;
        case "Instant":
          if (p.mandatory || value) {
            const momentValue = moment(value);
            if (momentValue.isValid()) {
              setPath(result, propName, momentValue.toISOString());
            } else if (value !== "") {
              setPath(result, propName, value);
            }
          }
          break;
        case "UField":
          if (value.field) {
            setPath(result, propName, value.field);
          } else if (value.key) {
            setPath(result, propName, fields.find(f => UField.key(f) === value.key));
          } else {
            setPath(result, propName, fields.find(f => f.label === value));
          }

          break;
        case "DateExtractor":
          // This assumes that the value is from an autocomplete field and can be created.
          // When it is a newly created pattern we send the date pattern (value.key)
          // and the API has a deserializer for it, otherwise we chose an existing object clazz,
          // we can send the clazz.
          setPath(result, propName, value.userCreated ? value.key : { clazz: value.key });
          break;
        case "Compression":
          setPath(result, propName, value && (typeof value === "string" ? { clazz: value } : value));
          break;
        case "OutputFormat":
          setPath(result, propName, { clazz: value });
          break;
        case "ConnectionPointer":
          if (value || p.mandatory) {
            setPath(result, propName, (value !== null ? value.id : null) || value);
          }
          break;
        case "OutputTemplateField":
          setPath(result, propName, value || []);
          break;
        case "RequiredCredentials":
          setPath(result, propName, values["Additional Integrations"] || values[propName]);
          break;
        case "HiveMetastoreArgs":
          setPath(result, propName, deserializeHiveMetastoresValue(value));
          break;
        case "MetastoreArgs":
          setPath(result, propName, deserializeHiveMetastoresValue(value));
          break;
        case "TableDefinition":
          setPath(result, propName, {});
          setPath(result, `${propName}.schema`,  values.schema);
          setPath(result, `${propName}.table`,  value.key ?? value);
          break;
        default:
          const metadataListName = p.editorInformation["metadata-list-name"];
          if (metadataListName) {
            setPath(result, propName, { clazz: value });
          } else if (p.mandatory || value) {
            setPath(result, propName, deserializePossibleAutocompleteValues(p, value));
          }
          // else if (p.mandatory === false && numericTypeNames.includes(p.schemaType.toLowerCase())) { // basically handles ""/null as number, this is because outputType inputs actually use strings as payloads
          //     const number = Number(value) || 0;
          //     setPath(result, propName, number);
          // }
      }
    }
  });
  return result;
};

export const propertyFormFieldName = (p: FeatureProperty, prefix?: string) => {
  const name = p.group ? p.group + "." + p.name : p.name;
  return prefix ? `${prefix}.${name}` : name;
};

const getDisplayDataMetadata = (step?: StepMetadata) => {
  const { displayData } = window.globalStores.metadataStore.Metadata();

  return {
    ...displayData,
    step,
    properties: displayData.properties.map(x => ({...x, step}))
  };
};

const hiveStoreMutator = (formBuilder: FormFields) => {
  formBuilder.extendValidator(validator => {
    validator.register("athenaValidName", (v: string) => /^[\w]*$/.test(v),
      ":attribute should contain only letters, numbers and underscores.");
  });
  formBuilder.rules["table.databaseName"] = (formBuilder.rules["table.databaseName"] || "required") + "|athenaValidName";
  formBuilder.rules["table.tableName"] = (formBuilder.rules["table.databaseName"] || "required") + "|athenaValidName";

  formBuilder.descriptions["table.tableName"] = "Choose a name for a new Athena table";
};

export const addFiniteDurationInputs = (formBuilder: FormFields, name: string, defaultRule: string, p: Partial<FeatureProperty>, value: any, max?: any) => {
  if (max) {
    FormValidators.MAX_MINUTES.register(Number(max));
  }
  const initialMinuteValue = Number(isNil(value) ? p.defaultValue : value);
  formBuilder.addField(name, defaultRule, p.displayName, "finite-duration", null, initialMinuteValue);
};

const buildValidatorsString = (validators: { [key: string]: any }) => {
  return Object.entries(validators).reduce((acc: string, c: [string, any]) => {
    return (acc ? acc + "|" : acc) + c[0] + (c[1] ? ":" + c[1] : "");
  }, "");
};

type GetDefaultValue = (payload: any) => Promise<any>;

export const createFormFields = (itemMetadata: SimpleMetadata, options: CreateFormFieldOptions): FormFields => {
  return formFieldsFromMetadata(itemMetadata, options.formMetadata, options.optionLists, options.defaultStorageId, options.values, options.hiddenFields,
    options.getDefaultValue, options.metadataStore, options.formBuilder, options.fieldsPrefix, options.readOnlyForm, options.ignoreImmutable, false,
    null, options.valueGetter, options.parentMetadata, options.parentPropertyMetadata);
};

export const getSubFieldsMetadata = (p: FeatureProperty, metadataStore: MetadataStore): SimpleMetadata => {
  return getPath(metadataStore.Metadata(), p.editorInformation["metadata-list-name"] || "general").find((x: SimpleMetadata) => x.clazz === p.schemaType);
};

export const getsubFieldsSelectorMetadata = (p: FeatureProperty, metadataStore: MetadataStore): SimpleMetadata[] => {
  return getPath(metadataStore.Metadata(), p.editorInformation["metadata-list-name"] || "general").filter((x: SimpleMetadata) => x.traits.includes(p.schemaType));
};

function getPropertyValue(property: SimpleMetadataProperty, fieldsPrefix?: string, valuesPrefix?: string, valueGetter?: FormFieldsValueGetter, values?: object) {
  if(valueGetter && values) {
    return valueGetter(property, values);
  }
  const baseName = property.valueField || property.name;
  return  getPath(values, baseName)
    || getPath(values, propertyFormFieldName(property, fieldsPrefix))
    || getPath(values, propertyFormFieldName(property, valuesPrefix))
}

function checkDisplayIf(displayIf?: DisplayIf, values?: any): boolean {
 return displayIf && values && getPath(values, displayIf.path) !== displayIf.value;
}

function getEditorInformation(p: SimpleMetadataProperty): EditorInformation {
  return {
    hideOnSingle: p.editorInformation.hideOnSingle === "true",
    runOnSuccessfulValidation: p.editorInformation.runOnSuccessfulValidation
  }
}

/**
 * Create a FormFields instance from metadata
 * @param {SimpleMetadata} itemMetadata  - metadata describing the form
 * @param {FormMetadata} formMetadata - additional utility metadata used, can be obtained via metadataStore.getFormMetadata()
 * @param {MetadataFormOptionLists} optionLists - lists of system entities used as value lists for various form fields
 * @param {string=} defaultStorageId  - default organization storage connection id
 * @param {any} [values={}] - pre-existing form field values (usually used when creating an "edit" form)
 * @param {Record<string, boolean>} hiddenFields - fields to hide,
 * @param {GetDefaultValue} getDefaultValue - default value providers for fields, usually used when the default value is contextual (dependant on current organization etc). See DefaultValueProvider in the API.
 * @param {MetadataStore} metadataStore - metadata store instance
 * @param {FormFields=} externalFormBuilder - pass existing form configuration to mutate instead of creating a new one
 * @param {string=} fieldsPrefix - prefix to add to all fields names
 * @param {boolean} [readOnlyForm=false] - readonly form
 * @param {boolean} [ignoreImmutable=false] - ignore immutable settings from metadata
 * @param {boolean} [allHidden=false] - make all fields hidden
 * @param {string} [valuesPrefix] - prefix of fields inside supplied values
 * @param {FormFieldsValueGetter} [valueGetter] - function for getting values of fields instead of from values, used in properties pages where the value sometimes comes from a different object
 */
export const formFieldsFromMetadata = (itemMetadata: SimpleMetadata,
                                       formMetadata: FormMetadata,
                                       optionLists: MetadataFormOptionLists = {},
                                       defaultStorageId?: string | null,
                                       values: object = {},
                                       hiddenFields: Record<string, boolean> = {},
                                       getDefaultValue?: GetDefaultValue,
                                       metadataStore?: MetadataStore,
                                       externalFormBuilder?: FormFields,
                                       fieldsPrefix?: string,
                                       readOnlyForm?: boolean,
                                       ignoreImmutable: boolean = false,
                                       allHidden: boolean = false,
                                       valuesPrefix?: string,
                                       valueGetter?: FormFieldsValueGetter,
                                       parentMetadata?: SimpleMetadata,
                                       parentPropertyMetadata?: SimpleMetadataProperty): FormFields => {
  const defaultValueProviders: Record<string, () => Promise<any>> = {};
  const dynamicDescriptions: Record<string, () => Promise<any>> = {};
  const optionsContextProvider = optionLists && optionLists.contextProvider;
  const nonGeneratedProperties = itemMetadata.properties.filter(p => !p.generated);
  const subFieldSelectors = nonGeneratedProperties.filter(p => p.editor === "subFieldsSelector");
  const subFieldSelectorContextProvider: any = subFieldSelectors.length > 0 && ((fieldName: string, form: BaseFormWithArrayFields) => {
    const selectorProp = subFieldSelectors.find(p => propertyFormFieldName(p, fieldsPrefix) === fieldName);
    if(!selectorProp) {
      return null;
    }
    const clazzField = form.tryGetField(fieldName);
      return clazzField ? ({clazz: clazzField.value}) : null;
  });
  const formBuilder = externalFormBuilder || new FormFields(optionsContextProvider || subFieldSelectorContextProvider || undefined, defaultValueProviders, dynamicDescriptions);
  const edit = (name: string) => values.hasOwnProperty(name);
  const hasCurrentValues = Object.keys(values).length > 0;
  const defaultValueApi = getDefaultValue || (metadataStore && metadataStore.defaultValue);


  function addSubFields(p: SimpleMetadataProperty, step?: StepMetadata) {
    formBuilder.addFieldProperties({
      name: p.name,
      rule: "",
      label: "",
      serverSideValidation: p.serverSideValidation &&  {
        ...p.serverSideValidation,
        subFields: true,
        validationCallToAction: p.editorInformation.validationCallToAction
      },
      step
    });

    //TODO: need to move display data metadata into the general metadata list in the API so we don't need this
    const innerItemMetadata = p.schemaType === "DisplayData" || p.schemaType === "DisplayDataRequest" ?  getDisplayDataMetadata(step) : getSubFieldsMetadata(p, metadataStore);

    formFieldsFromMetadata(innerItemMetadata, formMetadata, optionLists, defaultStorageId, values, hiddenFields,
      defaultValueApi, metadataStore, formBuilder, p.name, readOnlyForm, ignoreImmutable, false, null, null, itemMetadata, p);
  }

  nonGeneratedProperties.forEach((p: SimpleMetadataProperty) => {
      const name = propertyFormFieldName(p, fieldsPrefix);
      const value = getPropertyValue(p, fieldsPrefix, valuesPrefix, valueGetter, values)
      const valueOrDefault = value === undefined ? p.defaultValue : value;
      const validators = p.validators && buildValidatorsString(p.validators);
      const defaultRule = mergeRules(p.mandatory ? "required" : "", validators);
      const valueClazz = valueOrDefault && valueOrDefault.clazz;
      const hasValue = value || edit(name);
      const immutable = !ignoreImmutable && Boolean(p.immutable || (p.editorInformation && p.editorInformation.immutable));
      const changesetRequest = p.traits?.includes("ChangesetRequest") || parentMetadata?.traits?.includes("ChangesetRequest")
      const hidden = hiddenFields[name] && (!readOnlyForm && immutable && (p.mandatory ? hasValue : hasCurrentValues)) ||
      (p.hidden && !readOnlyForm && !changesetRequest) ||  (!isNil(p.showInProperties) && !p.showInProperties && readOnlyForm) || checkDisplayIf(p.displayIf, values) || hiddenFields[name] || allHidden;
      const editorType = p.editorInformation && p.editorInformation["type"];
      const credentials = editorType && formMetadata.credentials[editorType] && formMetadata.credentials[editorType].map(x => ({
        label: x.displayName,
        id: x.clazz
      }));

      const step = p.step || (itemMetadata as any)?.step;

      if (defaultValueApi && p.defaultValueProvider) {
        defaultValueProviders[name] = () => defaultValueApi(p.defaultValueProvider);
      }
      if (defaultValueApi && p.dynamicDescription) {
        dynamicDescriptions[name] = () => defaultValueApi(p.dynamicDescription);
      }

    const propToInputType = (prop: SimpleMetadataProperty) => prop.editor || (prop.autoComplete && "autocompletee") || schemaTypeToInputType(prop.schemaType, prop.sequence);

      const addInputBySchema = (prop: SimpleMetadataProperty, formPropsName: string, options?: { defaultValue?: any }) => {
        const { defaultValue = undefined } = options || {};
        const inputType = propToInputType(prop);

        if (defaultValueApi && prop.defaultValueProvider) {
          defaultValueProviders[formPropsName] = () => {
            let context = {};
            if(prop.defaultValueProvider?.entityContextFieldNames?.length > 0) {
              if (optionsContextProvider) {
                context = Object.fromEntries(prop.defaultValueProvider.entityContextFieldNames.map(fieldName => [fieldName, optionsContextProvider(fieldName)]));
              } else {
                console.error("Got Default Value Provider w/ EntityContextFieldNames but no context provider provided.", p);
              }
            }
            return defaultValueApi({ ...prop.defaultValueProvider, ...context } );
          };
        }

        if (defaultValueApi && prop.dynamicDescription) {
          dynamicDescriptions[formPropsName] = () => defaultValueApi(prop.dynamicDescription );
        }
        const autocomplete = prop.autoComplete;
        if (autocomplete) {
          autocomplete.sequence = p.sequence;
        }

        const initialValue = !isNil(defaultValue) ? defaultValue : (valueOrDefault && prop.schemaType === "ContentType" ? valueOrDefault.clazz : valueOrDefault);

        const label = (parentPropertyMetadata?.displayNameOverride && parentPropertyMetadata.displayNameOverride[prop.name]) ?? prop.displayName;

        formBuilder.addFieldProperties({
          name: formPropsName,
          rule: getRule({...prop, mandatory: parentPropertyMetadata?.editor === "subFields" && parentPropertyMetadata?.mandatory === false ? false : prop.mandatory}),
          label,
          type: inputType,
          options: inputType.includes("select") && (formMetadata.enums[prop.schemaType] || optionLists[prop.schemaType]),
          // EncryptedString is for backward compatibility (changed to RemotableSecret)
          secret: prop.secret || ["EncryptedString", "RemotableSecret", "InlineSecret"].includes(prop.schemaType),
          description: prop.description,
          serverSideValidation: prop.serverSideValidation && {...prop.serverSideValidation, validationCallToAction: prop.editorInformation.validationCallToAction},
          insert: false,
          initialValue,
          hidden,
          autocomplete,
          step: prop.step,
          editorInformation: getEditorInformation(p)
        })

        return formBuilder;
      };

      if (p.editor) {
        if (p.editor === "subFields") {
          addSubFields(p, step);
        } else if (p.editor === "subFieldsSelector") {
          if(p.group) {
            console.error("subFieldsSelector fields cannot be inside a group ")
          }
          const innerItemMetadata = getsubFieldsSelectorMetadata(p, metadataStore);
          const allMembers = innerItemMetadata.map(i => `${name}-${i.clazz}`);
          const type = p.editorInformation.selectorType ??  (p.autoComplete ?"autocompletee" : "searchable-select");
          const options = innerItemMetadata.map(metadata => ({id: metadata.clazz, label: metadata.displayName}));
          const defaultOptionClazz = p.defaultSubFieldsSelectorOption && options.find(x => x.id === p.defaultSubFieldsSelectorOption || x.id.endsWith(`${p.defaultSubFieldsSelectorOption}`))?.id

          const selectorFieldProps: FieldProperties = {
            name: name,
            rule: defaultRule,
            label: p.displayName,
            options: !p.autoComplete && options,
            type,
            autocomplete: p.autoComplete,
            initialValue: valueClazz || defaultOptionClazz,
            hidden,
            step,
            description: p.description,
            readonly: readOnlyForm,
            changeHandler: action((value, input) => {
              const newValueMetadata = innerItemMetadata.find(x => x.clazz === value);
              let root = input;
              while (!(root instanceof BaseFormWithArrayFields)) {
                root = root.container();
              }

              const fieldSetHidden = (field: MField, hidden: boolean) => {
                const property = newValueMetadata?.properties?.find(x => x.name ===  field.key);
                if(!property || !property.hidden) {
                  field.metadata.hidden = hidden;
                  field.set('disabled', hidden);
                  field.resetValidation();
                }
              };

              const recursiveSetHidden = (field: MField, hidden: boolean) => {
                fieldSetHidden(field, hidden);
                field.each((inner: MField) => {
                  if (inner.path.indexOf('-', field.path.length) === -1) {
                    fieldSetHidden(inner, hidden);
                  }
                });
              };

              const setHidden = (m: string, hidden: boolean) => {
                const splitted = m.split('.');
                let input = root;
                for (const i in splitted) {
                  const part = splitted[i];
                  if (!input.has(part)) {
                    return;
                  }
                  input = input.$(part);
                }

                recursiveSetHidden(input, hidden);
              };

              allMembers.forEach(m => setHidden(m, true));
              setHidden(`${name}-${value}`, false);
            })
          };
          const readOnlyDisplayValue = p.displayValueSubFieldPath && readOnlyForm;
          if(readOnlyDisplayValue) {
            selectorFieldProps.type = "text";
            delete selectorFieldProps.options;
            delete selectorFieldProps.changeHandler;
            selectorFieldProps.initialValue = getPath(value, p.displayValueSubFieldPath);
          }
          formBuilder.addFieldProperties(selectorFieldProps);

          innerItemMetadata
            .map(item => ({ ...item, step, }))
            .forEach(innerItemMetadata => {
              const subName = `${name}-${innerItemMetadata.clazz}`;
              const hidden = readOnlyDisplayValue || valueClazz !== innerItemMetadata.clazz;

            //   if (innerItemMetadata.clazz === "ProtobufContentType") {
            //     formBuilder.addFieldProperties({
            //       name: subName,
            //       step,
            //       rule: null,
            //       label: innerItemMetadata.displayName,
            //       type: "protobuf",
            //       initialValue: {
            //         schemaFiles: null,
            //         mainFile: null,
            //         messageType: ""
            //       },
            //     hidden,
            //   });
            // } else
              if (p.serverSideValidation) {
              formBuilder.addFieldProperties({
                name: subName,
                rule: "",
                label: "",
                serverSideValidation: {
                  ...p.serverSideValidation,
                  subFields: true,
                  validationCallToAction: p.editorInformation.validationCallToAction
                },
                step,
                hidden
                });
              } else if (innerItemMetadata.properties?.length > 0) {
                formBuilder.addFieldProperties({
                  name: subName,
                  rule: "",
                  label: "",
                  step,
                  hidden
                });
              }

              formFieldsFromMetadata(innerItemMetadata, formMetadata, optionLists, defaultStorageId, values, hiddenFields,
                  defaultValueApi, metadataStore, formBuilder, subName, readOnlyForm, ignoreImmutable, hidden, name, null, itemMetadata, p);
            });
        } else {
          if (p.editor === "HiveMetastoreArgs") {
            formBuilder.extendValidator((validator: any) => validator.registerAsyncRule("meta-stores", (value: any, req: any, key: any, passes: any) => {
              hiveMetastoresValidator(passes, value);
            }));
            formBuilder.options[name] = { ...p.editorInformation, containerMetadata: p};
          }
          addInputBySchema(p, name);
          if (p.editor === "file-upload") {
            formBuilder.options[name] = p.editorInformation
          }
        }
      } else {
        const requiredRule = p.mandatory && "required";
        switch (p.schemaType) {
          case "ClusterDeploymentMethod":
            formBuilder.addField(p.name, "", "");
            formBuilder.addField(p.name + ".clazz", "required", p.displayName, "cluster-deployment-method", formMetadata.clusterDeploymentMethods, defaultClusterDeploymentMethod, null, null, p.description);
            flatMap(formMetadata.clusterDeploymentMethods, x => x.properties)
              .forEach(x => addInputBySchema(x, `${p.name}.${x.name}`, { defaultValue: x.defaultValue }));
            break;
          case "RequiredCredentials":
            formBuilder.addField(name, "required", "Permissions", "required-credentials", formMetadata.general["RequiredCredentials"].properties);
            formMetadata.general["RequiredCredentials"].properties.forEach(x => {
              x.displayName = x.description;
              addInputBySchema(x, name + "." + x.name, { defaultValue: x.defaultValue });
            });
            formBuilder.addField(name + ".user-fake", "required", "Create a dedicated Upsolver user", "checkbox", null, true, null, null, null, null, null, null, null, true);
            formBuilder.addField(name + ".bucket-fake", "required", "Create a dedicated Upsolver S3 bucket", "checkbox", null, true, null, null, null, null, null, null, null, true);
            break;
          case "AwsCredentials":
            formBuilder.addField(p.name, "required", "Credentials Type", "searchable-select", credentials, value || valueClazz || (credentials && credentials.length && credentials[0].id));
            break;
          case "DisplayData":
          case "DisplayDataRequest":
            // formFieldsFromMetadata(getDisplayDataMetadata(step), formMetadata, optionLists, defaultStorageId, values, hiddenFields, defaultValueApi, metadataStore, formBuilder, p.name);
            addSubFields(p, step)

            break;
          case "CloudStoragePointer":
            formBuilder.addField(name, defaultRule, p.displayName, "searchable-select", optionLists.storage, valueOrDefault || defaultStorageId, null, p.secret, p.description);
            break;
          case "EnvironmentPointer":
            formBuilder.addFieldProperties({
              name: name,
              rule: defaultRule,
              label: p.displayName,
              type: `${p.sequence ? "multi-" : ""}${p.editorInformation.replay === "true" ? "replay-" : ""}${p.editorInformation.type}-environment-select`,
              options: (optionLists.environments || []).filter(e => e.environmentType === editorType || e.environment && (e.environment.environmentType === editorType)).map(o => ({
                ...o,
                key: o.environment.id
              })),
              initialValue: valueOrDefault,
              secret: p.secret,
              description: p.description,
              step: step,
              editorInformation: getEditorInformation(p)
            });

            break;
          case "EnvironmentPointerSequence":
            formBuilder.addField(name, defaultRule, p.displayName,
              `multi-${p.editorInformation.replay === "true" ? "replay-" : ""}${p.editorInformation.type}-environment-select`,
              (optionLists.environments || []).filter(e => e.environmentType === editorType || e.environment && (e.environment.environmentType === editorType)).map(o => ({
                ...o,
                key: o.environment.id
              })),
              valueOrDefault, null, p.secret, p.description);
            break;
          case "ConnectionPointer":
            formBuilder.addFieldProperties({
              name,
              rule: defaultRule,
              label: p.displayName,
              type: `select-connection-${p.editorInformation.type}`,
              options: {
                useDefault: p.editorInformation.default === "true",
                readOnly: p.editorInformation.readOnly,
                connections: (optionLists.connections || []).filter(c => {
                  if (p.editorInformation.type) {
                    return c.clazz === p.editorInformation.type || c.traits.includes(p.editorInformation.trait);
                  }
                  return true;
                }),
                hasCreateOptions: p.editorInformation.hasCreateOptions !== "false",
                mandatory: p.mandatory,
                clazz: p.editorInformation.type,
              },
              initialValue: valueOrDefault || (p.editorInformation.type === "CloudStorageConnection" ? defaultStorageId : null),
              secret: p.secret,
              description: p.description,
              step,
              editorInformation: getEditorInformation(p)
            });

            break;
          case "Compression":
            formBuilder.addField(name, defaultRule, p.displayName, "searchable-select",
              (p.editorInformation && p.editorInformation.type === "output") ? formMetadata.outputCompressions : formMetadata.compressions,
              valueClazz, null, p.secret, p.description);
            break;
          case "FiniteDuration":
            addFiniteDurationInputs(formBuilder, name, mergeRules(defaultRule, p.mandatory ?  "min:1" : ""), p, value, p.validators && p.validators["maxMinutes"]);
            break;
          case "FileNameMatcher":
            if(p.editor !== "subFields") {
              formBuilder.addField(name, defaultRule, p.displayName, "file-name-matcher", null, value || {
                clazz: "AllMatcher",
                value: (valueOrDefault && valueOrDefault.value) || (p.defaultValue && p.defaultValue.value)
              });
            }
            break;
          case "OutputTemplateField":
            break;
          case "OperationReference":
            const inputTypes = formMetadata.operationReferenceTypes[p.editorInformation["type"]];
            const operations = optionLists.operations;
            formBuilder.addField(name, defaultRule, p.displayName, "searchable-select", operations[0].clazz ? operations.filter(op => inputTypes.includes(op.clazz)) : operations, valueOrDefault, null, p.secret, p.description);
            break;
          case "Field":
            if (p.sequence) {
              formBuilder.addField(name, requiredRule, p.displayName, "field-multi-select", optionLists.fields, valueOrDefault && valueOrDefault, null, p.secret, p.description);
            } else {
              formBuilder.addField(name, requiredRule, p.displayName, "field-select", optionLists.fields, valueOrDefault && valueOrDefault, null, p.secret, p.description);
            }
            break;
          case "DateExtractor":
            // The purpose of this case is to take the actual pattern of the DateExtractor object as the value of this field.
            formBuilder.addField(name, defaultRule, p.displayName, propToInputType(p), null, valueOrDefault?.pattern, null, p.secret, p.description, null, null, null, p.autoComplete);
            break;
          case "OutputFormat":
            formBuilder.addField(name, getRule(p), p.displayName, p.autoComplete ? "autocompletee" : "searchable-select", formMetadata.outputFormats, valueClazz, null, p.secret, p.description, null, null, null, p.autoComplete);
            break;
          case "AthenaTable":
            formBuilder.addField(name, null, "Table Properties");
            formBuilder.addField(name + ".databaseName", p.mandatory && "required|string", "Database", "text", null, valueOrDefault && valueOrDefault.databaseName);
            formBuilder.addField(name + ".tableName", p.mandatory && "required|string", "Table", "text", null, valueOrDefault && valueOrDefault.tableName);
            break;
          case "ContentType":
            formBuilder.addField(name
              , p.mandatory ? "required" : ""
              , p.displayName
              , "searchable-select"
              , formMetadata.contentTypes
              , valueOrDefault && valueOrDefault.clazz
              , null
              , p.secret
              , p.description);
            break;
          case "TableDefinition":
            formBuilder.addField("schema", requiredRule, "Schema", "searchable-select", optionLists.schemas, valueOrDefault?.schema, null, p.secret, p.description);
            formBuilder.addField(name, requiredRule, "Table", itemMetadata.editorInformation["createTable"] ? "searchable-select-create" : "searchable-select", optionLists.tables, valueOrDefault?.name, null, p.secret, p.description);
            break;
          case "AutoScaleSize":
            formBuilder.addField(name, requiredRule, p.displayName, "size", null, valueOrDefault, null, p.secret, p.description);
            break;
          case "InitialLoadConfiguration":
            if(p.editor !== "subFields") {
              formBuilder.addField(name, defaultRule, p.displayName);
              formBuilder.addField(name + ".prefix", defaultRule, "Prefix", "text", null, valueOrDefault && valueOrDefault.prefix);
              formBuilder.addField(name + ".pattern", defaultRule, "Pattern", "text", null, valueOrDefault && valueOrDefault.pattern);
            }
            break;
          default:
            const metadataListName = p.editorInformation["metadata-list-name"];
            if (metadataListName && metadataStore) {
              formBuilder.addField(p.name, requiredRule, p.displayName, "searchable-select",
                Reflect.get(metadataStore.Metadata(), metadataListName).map((x: SimpleMetadata) => ({
                  label: x.displayName || x.clazz,
                  id: x.clazz
                })),
                valueOrDefault && valueOrDefault.clazz, null, p.secret, p.description, hidden);
            } else {
              addInputBySchema(p, name);
            }
            break;
        }
      }
      // Use immutable if there are existing values, this is use for properties pages
      formBuilder.immutables[name] = Boolean(immutable && hasValue);
      formBuilder.hidden[name] = hidden;
      formBuilder.disabled[name] = allHidden;
      if(readOnlyForm) {
        formBuilder.readonly[name] = Boolean(immutable && hasValue);
      }
    }
  );

  if (itemMetadata?.traits?.includes("BaseHiveMetastoreOutputParameters")) {
    hiveStoreMutator(formBuilder)
  }


  return formBuilder;
};

export type MetadataFormProps = {
  metadata: SimpleMetadata;
  formMetadata: FormMetadata;
  optionsLists?: MetadataFormOptionLists;
  onSubmit: (values: any) => void;
}


export type DocumentationViewProps = {
  docs: Documentation
}
export const DocumentationView = ({ docs }: DocumentationViewProps) => <span>{docs.blurb} <ExternalLink
  to={docs.url}><Icon
  type="question-circle"/></ExternalLink></span>;

export const MetadataForm = ({ metadata, formMetadata, optionsLists, onSubmit }: MetadataFormProps) =>
  <div>
    <h3>Create {metadata.clazz} </h3>
    {metadata.documentation && <DocumentationView docs={metadata.documentation}/>}
    <FormView form={formFieldsFromMetadata(metadata, formMetadata, optionsLists).createForm()}
              onSuccess={onSubmit}
              submitText="Create"/>
  </div>;
