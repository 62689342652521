import { observer } from "mobx-react";
import React from "react";
import { action, computed, extendObservable, observable, reaction } from "mobx";
import { FormInputProps, inputOnChange } from "../FormInputTypes";
import { CloudStorageStore } from "../../../cloud-storage/Store";
import SelectOrAddSomething from "./SelectOrAdd";
import { FormFieldRegistry } from "../../FormFieldRegistry";
import { ValidationResult } from "../../api/contracts/ValidationResult";
import { AppContext } from "../../../routes/AppContext";
import { ServerSideValidationStatus, SimpleMetadata } from "../../SimpleMetadata";
import { HideFormFieldConsumer } from "../../forms/Hide";
import { useServerSideValidationProvider } from "./ServerSideValidation";

type ConnectionSelectProps = FormInputProps & {
  clazz?: string;
  readOnly?: boolean;
  onHide: (name: string, value: boolean) => void;
  onServerValidate?: (valid?: ServerSideValidationStatus) => void;
};
const isInlineCreatableConnection = (x: SimpleMetadata) =>
  !x.editorInformation || !x.editorInformation["no-inline-create"];

const expression = /select-connection-(.*)/;

@observer
export class ConnectionsSelect extends React.Component<ConnectionSelectProps> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;
  @observable.ref _connectionsList: any[] = [];
  @observable.ref _connectionValidations: { [key: string]: ValidationResult[] } = null;
  @observable.ref _selectedOutputClazz: string;
  @observable.ref _classesMetadata: SimpleMetadata[];

  dispose: any;

  @observable loading = true;

  componentDidMount() {
    const props = this.props;
    const input = props.input;
    input.metadata.loading = true;

    if (!input.metadata.clazz) {
      const regExpExecArray = this.props.type.match(expression);
      const clazz = this.props.clazz ?? regExpExecArray[1];
      extendObservable(input.metadata, { clazz });
    }

    this.context.metadataStore.load().then((metadata) => {
      this._classesMetadata = metadata.connections;
    });

    this.context.outputConnections
      .list()
      .then(
        action((l: any[]) => {
          this._connectionsList = l;

          // Sometimes input set to undefined id, in that case, nullify.
          if (!l.map((c) => c.id).includes(input.$value)) {
            inputOnChange(input)(null);
          }

          const defaultConnectionId = this.context.auth.currentOrganization.defaultConnection;
          if (!input.$value && input.metadata.options.useDefault && defaultConnectionId) {
            input.$value = defaultConnectionId;
          }

          this.validateSelection();
        })
      )
      .finally(() => {
        this.loading = false;
      })
      .finally(() => (input.metadata.loading = false));
    // start as hidden and show control after loading connections if necessary. Seems better to me then showing it and then having it disappear.
    // in the future we probably want to delay loading the form somehow or show some loading state instead of the connections input
    if (this.props.input.metadata.editorInformation?.hideOnSingle) {
      this.props.input.metadata.hidden = true;
    }
    this.dispose = reaction(
      () => this.hideSelf,
      (change) => {
        if (this.props.input.metadata.hidden !== change) {
          this.props.input.metadata.hidden = change;
        }
      }
    );
  }

  componentWillUnmount() {
    if (this.dispose) {
      this.dispose();
    }
  }

  _isInlineCreatable = (x: SimpleMetadata) => {
    return (
      x.clazz === this.props.clazz ||
      x.clazz === this.props.input.metadata?.options?.clazz ||
      isInlineCreatableConnection(x)
    );
  };

  @computed get _connections() {
    const currentValue = this.props.input.$value;
    return this._connectionsList.map((c) => ({
      key: c.id,
      logo: c.connection.clazz,
      title: c.connection.displayData.name,
      clazz: c.connection.clazz,
      subtitle: CloudStorageStore.mapValue(c, c.connection).label,
      readOnly: c.connection.readOnly,
      validation:
        c.id === currentValue
          ? this._connectionValidations
            ? (this._connectionValidations[c.id] || []).length > 0
              ? "invalid"
              : "valid"
            : "loading"
          : "",
      validationError: "The selected connection is in an invalid state, please contact us for further information.",
    }));
  }

  componentDidUpdate() {
    const input = this.props.input;
    if (this._filteredConnections.length === 1) {
      inputOnChange(input)(this._filteredConnections[0].key);
      this.validateSelection();
    } else if (
      this._filteredConnections.length &&
      input.$value &&
      !this._filteredConnections.find((x) => x.key === input.$value)
    ) {
      inputOnChange(input)(null);
    }
  }

  @computed
  get classes() {
    const clazz = this.props.input.metadata.clazz;
    return (this._classesMetadata || []).filter((f) => f.clazz === clazz || f.traits.includes(clazz));
  }

  @computed
  get creatableClasses() {
    if (this.props.input.metadata.loading || this.props.input.metadata.options.hasCreateOptions === false) {
      return [];
    }
    return this.classes.filter(this._isInlineCreatable);
  }

  @computed
  get _filteredConnections() {
    const classes = this.classes.map((c) => c.clazz);
    const readOnly = this.props.readOnly || this.props.input.metadata.options.readOnly;
    const needsWritePermission = readOnly === false || readOnly === "false";
    return this._connections.filter((c) => classes.includes(c.clazz) && (needsWritePermission ? !c.readOnly : true));
  }

  private innerOnChange = action((...args: any) => {
    if (this.props.onChange) {
      this.props.onChange.apply(null, args as any);
    }

    this.validateSelection();
  });

  @computed
  get hideSelf() {
    return (
      this.props.input.metadata?.editorInformation?.hideOnSingle &&
      (this.loading || this._filteredConnections.length === 1)
    );
  }

  @action
  private validateSelection() {
    const input = this.props.input;
    const currentValue = input.$value;
    if (currentValue && (!this._connectionValidations || !this._connectionValidations[currentValue])) {
      this._connectionValidations = null;
      this.context.outputConnections.validationForIds([currentValue]).then(
        action((l: any) => {
          if (currentValue === input.$value) {
            this._connectionValidations = l;
            const validationSucceeded = Object.values<any[]>(l).every((x) => x.length === 0);
            this.props.onServerValidate(validationSucceeded ? "VALID" : "INVALID");
          }
        })
      );
    }
  }

  render() {
    const classes = this.classes;
    return (
      <SelectOrAddSomething
        loading={this.props.input.metadata.loading}
        disabled={!classes || this.props.input.metadata.loading}
        {...this.props}
        options={this._filteredConnections}
        onChange={this.innerOnChange}
        createOptions={(this.creatableClasses || []).map((c) => ({
          url: "/connections/create/" + c.clazz,
          word: c.displayName + " Connection",
          clazz: c.clazz,
          id: "create-" + c.clazz,
          state: { readOnly: this.props.readOnly || this.props.input.metadata.readOnly },
        }))}
      />
    );
  }
}
export const register = (formFieldRegistry: FormFieldRegistry) => {
  formFieldRegistry.register("select-connection", (props) => {
    const { requestValidation } = useServerSideValidationProvider();
    return (
      <HideFormFieldConsumer>
        {({ setHiddenPaths }) => (
          <ConnectionsSelect {...props} onHide={setHiddenPaths} onServerValidate={requestValidation} />
        )}
      </HideFormFieldConsumer>
    );
  });

  formFieldRegistry.register(expression, (props) => {
    const { requestValidation } = useServerSideValidationProvider();
    return (
      <HideFormFieldConsumer>
        {({ setHiddenPaths }) => (
          <ConnectionsSelect {...props} onHide={setHiddenPaths} onServerValidate={requestValidation} />
        )}
      </HideFormFieldConsumer>
    );
  });
};
