import React from "react";
import { EllipsisTooltip } from "../EllipsisTooltip";
import classNames from "classnames";
import "../../../styles/definition-list.scss";
import { DefinitionHeader } from "./DefinitionHeader";

type DefinitionProps = {
  limitSize?: boolean;
  title?: any;
  value?: any;
  children?: any;
  toolTipText?: string;
  className?: string;
  containerStyle?: React.CSSProperties;
};

export class Definition extends React.Component<DefinitionProps> {
  render() {
    const { limitSize, title, value, children, containerStyle } = this.props;
    const showTitleAndContent = title || value;
    return (
      <li title={this.props.toolTipText}>
        {showTitleAndContent && <DefinitionHeader text={title} />}
        {showTitleAndContent &&
          (limitSize ? (
            <EllipsisTooltip block={false} className="limited">
              {value}
            </EllipsisTooltip>
          ) : (
            <span style={containerStyle}>{value}</span>
          ))}
        {children && children}
      </li>
    );
  }
}

export class DefinitionList extends React.Component<{
  children?: any;
  short?: boolean;
  className?: string | any;
  justifyContent?: string;
}> {
  static defaultProps = {
    justifyContent: "normal",
  };
  render() {
    const { children, short, className, justifyContent } = this.props;
    return (
      <ul className={classNames(className, "definition-list", { short })} style={{ justifyContent }}>
        {children}
      </ul>
    );
  }
}
