import React from "react";
import { action, get, keys, observable } from "mobx";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { MetadataStore } from "../../Metadata";
import { observer } from "mobx-react";
import { SubmitParametersState } from "../../../templates/output/views/OutputParameters/Simple";
import Popup from "reactjs-popup";
import { GlobalContext } from "../../../GlobalContext";
import { FormInputProps } from "../FormInputTypes";
import { UFormField } from "../UFormField";
import { FormFieldRegistry } from "../../FormFieldRegistry";
import { AppContext } from "../../../routes/AppContext";
import { SimpleMetadataProperty } from "../../SimpleMetadata";
import { EllipsisTooltip } from "../EllipsisTooltip";

function createFormState(
  metadata: MetadataStore,
  clazz: string,
  container: SimpleMetadataProperty | null,
  values: any = {}
) {
  return new SubmitParametersState(
    metadata,
    "",
    values,
    null,
    metadata.Metadata().hiveMetastores.byClazz[clazz],
    null,
    null,
    container
  );
}

const AddHiveStore = ({ onAdd }: { onAdd: (clazz: string) => void }) => (
  <GlobalContext.Consumer>
    {({ metadataStore }) => (
      <Popup
        trigger={
          <span className="action-item clickable">
            <span className="icon-plus" />
            ADD
          </span>
        }
        on="click"
        position="bottom left"
      >
        {(close) => (
          <div>
            {metadataStore.Metadata().hiveMetastores.map((x) => (
              <span
                key={x.clazz}
                className="clickable logo"
                onClick={() => {
                  onAdd(x.clazz);
                  close();
                }}
              >
                <span className={`small logo-${x.clazz}`} />
                {x.displayName || x.clazz}
              </span>
            ))}
          </div>
        )}
      </Popup>
    )}
  </GlobalContext.Consumer>
);

const FormError = observer(({ form }: any) => {
  if (!form || form.isValid) {
    return null;
  }
  return (
    <EllipsisTooltip
      title={
        (form.isEmpty && "Please fill all in all metastore details or remove it before continuing") ||
        form.error ||
        "Check form for errors"
      }
    >
      <span className="error-text icon-notice" />
    </EllipsisTooltip>
  );
});

@observer
export class HiveMetastoreArgsFormInput extends React.Component<FormInputProps> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;
  @observable _forms: SubmitParametersState[] = [];
  @observable _selectedTabIndex: number = 0;

  componentDidMount() {
    const props = this.props;
    if (!props.input.$value && !props.input.$value.length) {
      props.input.$value = [];
      const selectedOutputType = this.context.templateStore.current?.outputParameters?.selectedOutputType;
      if (selectedOutputType) {
        this._add(selectedOutputType);
      } else if (props.input.metadata.options?.defaultMetastoreClazz != null) {
        this._add(props.input.metadata.options.defaultMetastoreClazz);
      }
    } else {
      props.input.$value.forEach((x: { clazz: string }) => {
        if (x.clazz) {
          this._add(x.clazz, x);
        }
      });
    }

    props.input.set("rules", props.input.rules.includes("required") ? "required|meta-stores" : "meta-stores");
  }

  _add = action((clazz: string, values?: any) => {
    const state = createFormState(
      this.context.metadataStore,
      clazz,
      this.props.input.metadata.options?.containerMetadata,
      values
    );
    state.form["clazz"] = clazz;
    this._forms.push(state);
    if (this.props.input.$value.hasOwnProperty(this._forms.length - 1)) {
      this.props.input.$value[this._forms.length - 1] = Object.assign(values || {}, { form: state.form });
    } else {
      this.props.input.$value[this._forms.length - 1] = { form: state.form };
    }
    this._selectLast();
  });

  _remove = action((e: React.MouseEvent<HTMLElement>, index: number) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.input.$value.splice(index, 1);
    this._selectTab(index - 1);
    this._forms.splice(index, 1);
  });

  _selectLast = action(() => this._selectTab(this._forms.length - 1));
  _selectTab = action((index: number, last?: number, event?: Event): void => {
    this._selectedTabIndex = index > -1 ? index : 0;
  });

  render() {
    return (
      <Tabs className="big-tabs" onSelect={this._selectTab} selectedIndex={this._selectedTabIndex}>
        <TabList>
          {this._forms.map((x, idx) => (
            <Tab key={idx}>
              {idx !== this._selectedTabIndex && <FormError form={x.form} />}
              {x.itemMetadata.displayName || x.itemMetadata.clazz}
              <span className="remove" title="remove" onClick={(e) => this._remove(e, idx)}>
                <span className="icon-x" />
              </span>
            </Tab>
          ))}
          <AddHiveStore onAdd={this._add} />
        </TabList>
        {this._forms.map((x, idx) => (
          <TabPanel key={idx}>
            {keys(x.form.fields).map((key) => (
              <UFormField key={key.toString()} input={get(x.form.fields, key)} />
            ))}
          </TabPanel>
        ))}
      </Tabs>
    );
  }
}
export const register = (formFieldRegistry: FormFieldRegistry) =>
  formFieldRegistry.register("HiveMetastoreArgs", (props) => <HiveMetastoreArgsFormInput {...props} />, {
    decorate: true,
  });
