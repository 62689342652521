import React from "react";
import { action, computed, IObservableArray, observable } from "mobx";
import { Observer } from "mobx-react";
import { splitPath } from "../Utils";
import { TreeEntry } from "./Tree";
import { Button } from "./Button";
import { fuzzyFilter } from "../Fuzzy";
import classNames from "classnames";
import { AppContext } from "../../routes/AppContext";
import Tree2, { EntryData } from "./Tree2";
import { UField } from "../api/contracts/UField";
import {
  InputFieldStateIndicator,
  inputFieldStateToolTip,
  OutputFieldStateIndicator,
} from "../api/contracts/UFieldComponents";
import { FieldsTreeMultiSelect } from "./FieldsTreeMultiSelect";
import { DisplayData } from "../api/contracts/Core";
import { FieldTreeEntry } from "./TreeItem";
import { UFieldsTreeContext } from "../FieldsTreeState";
import { iterateAllItemsWithParents, traverseItemTree } from "../TreeEntityUtils";
import { FieldType } from "./FieldType";
import { FieldZoomPopup } from "../api/contracts/FieldZoomPopup";

const searchFilter = (value: string, items: FieldTreeEntry[]): FieldTreeEntry[] => fuzzyFilter(items, ["key"], value);

const itemsByKey = (items: FieldTreeEntry[]): { [key: string]: FieldTreeEntry } => {
  const map: Record<string, FieldTreeEntry> = {};
  traverseItemTree(items, (item) => {
    map[item.key] = item;
  });
  return map;
};

const fixFilter = (
  underlying: Array<(value: string, items: TreeEntry[]) => TreeEntry[]>
): ((value: string, items: TreeEntry[]) => Set<string>) => {
  return function (value: string, items: TreeEntry[]): Set<string> {
    const itemsByKeys = itemsByKey(items);
    let currentItems = items;
    let result = new Set<string>();

    underlying.forEach((uf) => {
      const allItems: TreeEntry[] = [];
      traverseItemTree(currentItems, (i) => allItems.push(i));
      const filtered = uf(value, allItems);
      const fixedItems = new Set<string>();

      filtered.forEach((item) => {
        let key = item.key;
        fixedItems.add(key);
        while (key !== null) {
          const splittedPath = splitPath(key);
          if (splittedPath.length <= 1) {
            key = null;
          } else {
            splittedPath.pop();
            key = splittedPath.join(".");
            const parent = itemsByKeys[key];
            if (fixedItems.has(parent.key)) {
              break;
            }
            fixedItems.add(parent.key);
          }
        }
      });
      currentItems = filtered;
      result = fixedItems;
    });
    return result;
  };
};

export type FieldsTreeProps = {
  selection: "single" | "multi";
  items: FieldTreeEntry[];
  fields?: UField[];
  onCheck: (item: FieldTreeEntry, value: boolean, traverse?: boolean) => void;
  onSelect: (entry: FieldTreeEntry) => void;
  onDoubleSelect?: () => void;
  filterPredicate?: (value: string, items: TreeEntry[]) => TreeEntry[];
  filterKey?: string;
  selectedKey?: string;
  checkedKeys?: string[];
  showLine?: boolean;
  overrideView?: any;
  onSelectParent?: (entry: FieldTreeEntry) => void;
  style?: React.CSSProperties;
  inputs?: Record<string, DisplayData>;
};

export const Entry = React.memo<
  EntryData<FieldTreeEntry> & {
    fieldInspectionView?: Function;
    hoverActions?: Function;
    treeContext: UFieldsTreeContext;
  }
>(
  ({
    item,
    onClick,
    doubleClickProps,
    checkbox,
    after,
    descendantsCount,
    fieldInspectionView,
    hoverActions,
    treeContext,
  }) => {
    const automationName = `field-${item.label}`;
    if (item.view) {
      return <span className="fields-container">{item.view}</span>;
    }
    if (item.children) {
      return (
        <span
          className={classNames("fields-container parent", item.className)}
          onClick={onClick}
          data-automation-name={automationName}
        >
          <span className="field">
            {after}
            {checkbox}
            <span className={classNames("label")}>
              {item.label} ({descendantsCount || item.children.length})
            </span>
            {hoverActions && <span className="hover">{hoverActions(item, treeContext)}</span>}
          </span>
        </span>
      );
    } else {
      if (!item.field) {
        return <span className="fields-container" />;
      }
      const more = item.field.featureField && <span className="icon-function" />;
      return (
        <AppContext.Consumer>
          {({ inputsStore }) => (
            <span
              className="fields-container"
              title={inputFieldStateToolTip(item.field.inputs, item.label, inputsStore)}
            >
              <span
                className="field"
                onClick={onClick}
                {...(doubleClickProps || {})}
                data-automation-name={automationName}
              >
                {checkbox}
                {fieldInspectionView && item.field && (
                  <FieldZoomPopup popupViewProvider={() => fieldInspectionView(item.field)} />
                )}
                <FieldType typeName={item.field.nativeType} />
                <span className="label">{item.label}</span>
                {hoverActions && <span className="hover">{hoverActions(item, treeContext)}</span>}
                {more && <span className="more">{more}</span>}
                {item.field.inputs && <InputFieldStateIndicator inputs={item.field.inputs} />}
                <OutputFieldStateIndicator field={item.field} />
              </span>
            </span>
          )}
        </AppContext.Consumer>
      );
    }
  }
);

export class FieldsTree extends React.Component<FieldsTreeProps> {
  @observable.shallow _expandedKeys: IObservableArray<FieldTreeEntry> = observable.array();

  constructor(props: FieldsTreeProps) {
    super(props);
    this._initialize(props);
  }

  componentWillReceiveProps(nextProps: FieldsTreeProps) {
    if (this.props.selectedKey !== nextProps.selectedKey || this.props.items !== nextProps.items) {
      this._initialize(nextProps);
    }

    if (this.props.filterKey !== nextProps.filterKey || this.props.filterPredicate !== nextProps.filterPredicate) {
      this._expandedKeys.replace(this._computeExpandedKeys(nextProps));
    }
  }

  _initialize(props: FieldsTreeProps) {
    if (props.items && props.items.length) {
      iterateAllItemsWithParents(props.items, (parents: FieldTreeEntry[], item: FieldTreeEntry) => {
        if (item.key === props.selectedKey) {
          parents.forEach((p) => this._addExpandedKey(p.key));
          this._addExpandedKey(item.key);
        }
      });
    }
  }

  _addExpandedKey(key: any): void {
    const expandedKeys = this._expandedKeys;
    if (expandedKeys.indexOf(key) === -1) {
      expandedKeys.push(key);
    }
  }

  @action.bound
  _onCheck(entry: any, value: boolean) {
    if (this.props.onCheck) {
      const keys = this._filteredItems; // Array.from(this._filteredItems).map(x => x.key);
      traverseItemTree([entry], (x) => {
        if (keys.has(x.key)) {
          this.props.onCheck(x, value, false);
        }
      });
    }
  }

  @action.bound
  _onSelect(entry: TreeEntry) {
    if (this.props.onSelect) {
      this.props.onSelect(entry);
    }
  }

  @action.bound
  _onExpand(entry: any, shouldExpand: boolean) {
    if (this.props.onSelectParent && shouldExpand) {
      this.props.onSelectParent(entry);
    }
    this._expandedKeys.remove(entry.key);
    if (shouldExpand) {
      this._addExpandedKey(entry.key);
    }
  }

  @computed
  get _filteredItems() {
    const filters = [];

    if (this.props.filterPredicate) {
      filters.push(this.props.filterPredicate);
    }

    if (this.props.filterKey) {
      filters.push(searchFilter);
    }

    if (filters.length > 0) {
      return fixFilter(filters)(this.props.filterKey, this.props.items);
    } else {
      const allSet = new Set();
      traverseItemTree(this.props.items, (item) => allSet.add(item.key));
      return allSet;
    }
  }

  _computeExpandedKeys(props: FieldsTreeProps): any[] {
    let retVal: any[];
    if (props.filterKey) {
      // return Array.from(this._filteredItems).map(i => i.key);
      return Array.from(this._filteredItems.keys());
    } else {
      retVal = this._expandedKeys.slice();
    }

    if (props.selectedKey && retVal.indexOf(props.selectedKey) === -1) {
      retVal.push(props.selectedKey);
    }

    return retVal;
  }

  @action.bound
  _selectAll() {
    this._filteredItems.forEach((x) => this._onCheck(x, true));
  }

  @action.bound
  _deselectAll() {
    this._filteredItems.forEach((x) => this._onCheck(x, false));
  }

  render() {
    const checkable = this.props.selection === "multi";
    const filteredItems: Set<any> = this._filteredItems;
    return filteredItems && filteredItems.size < 1 && this.props.overrideView ? (
      this.props.overrideView
    ) : (
      <Observer>
        {() => (
          <div>
            {checkable && (
              <FieldsTreeMultiSelect>
                <p>
                  <Button type="borderless" small onClick={this._selectAll}>
                    Select All
                  </Button>
                  |
                  <Button type="borderless" small onClick={this._deselectAll}>
                    Deselect All
                  </Button>
                </p>
              </FieldsTreeMultiSelect>
            )}
            <Tree2
              style={this.props.style}
              className="fields-tree"
              loadData={this.props.items && this.props.items.length > 0}
              showLine={this.props.showLine}
              checkable={checkable}
              onCheck={this._onCheck}
              onSelect={this._onSelect}
              onDoubleSelect={this.props.onDoubleSelect}
              selectedKey={this.props.selectedKey}
              checkedKeys={this.props.checkedKeys}
              expandedKeys={this._expandedKeys as any}
              onExpand={this._onExpand}
              entries={this.props.items}
              filteredEntries={filteredItems}
              renderEntry={(p: any) => <Entry {...(p as any)} />}
              fieldsMap={(this.props.fields || []).reduce((a: Record<string, UField>, c) => {
                a[UField.key(c)] = c;
                return a;
              }, {})}
            />
          </div>
        )}
      </Observer>
    );
  }
}
