/* eslint-disable */
import React, { useContext } from "react";
import { IPreferencesStorage } from "./core/UserPreferences";
import { Auth } from "./auth/Auth";
import { Api } from "./core/api/Api";
import { SimpleFormStore } from "./core/views/FormStore";
import { UsersStore } from "./auth/UsersStore";
import { NotificationsStore } from "./notifications/NotificationsStore";
import { MetadataStore } from "./core/Metadata";
import { Analytics } from "./analytics/analytics";
import { GlobalUIStore } from "./core/GlobalUIStore";
import { FormFieldRegistry } from "./core/FormFieldRegistry";
import { AuthenticationApi } from "./core/api/AuthenticationApi";

export class BlockNavigationHandlers {
  private readonly handlers: Record<string, any> = {};
  register = (key: string, handler: any) => {
    this.handlers[key] = handler;
  };
  get = (key: string) => this.handlers[key];
  release = (key: string) => delete this.handlers[key];
}

export interface GlobalStores {
  api: Api;
  metadataStore?: MetadataStore;
  auth: Auth;
  userPreferences: IPreferencesStorage;
  formStore: SimpleFormStore;
  usersStore: UsersStore;
  notificationsStore: NotificationsStore;
  analytics: Analytics;
  blockNavigations: BlockNavigationHandlers;
  globalUIStore: GlobalUIStore;
  formFieldRegistry: FormFieldRegistry;
  authApi: AuthenticationApi;
}

export const GlobalContext = React.createContext<GlobalStores>(null);

export function useAuth() {
  return useContext(GlobalContext).auth;
}
