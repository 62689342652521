import { useCallback, useEffect, useRef, useState } from "react";
import { LoginReply, loginReplyDefaultOrg } from "./contracts/login";
import { User } from "./contracts/User";
import { isClassicOrg, Organization } from "./contracts/Organization";
import { UpsolverLocalStorage } from "../UpsolverLocalStorage";
import { SupportedFeatures } from "./contracts/SupportedFeatures";
import { AllocatedTests } from "./AbTests/model";
import { useHistory } from "react-router";
import { AuthenticationApi } from "./AuthenticationApi";
import { UnsupportedOperationError } from "./UnsupportedOperationError";
import { setUserForCapture, clearUserForCapture } from "../../event-reporting";

export interface Status {
  loading: boolean;
  loginReply?: LoginReply<User>;
  noOrg?: boolean;
  organizations?: Organization[];
  selectedOrganization?: Organization;
}
type LoadingBoolean = "loading" | boolean;
interface ReloadUserOptions {
  mainLoader: boolean;
}

export interface LoginState {
  logout: () => void;
  handleLoginReply: (reply: LoginReply, options?: Partial<HandleLoginReplyOptions>) => Promise<void>;
  setLoginOrg: (org: Organization, options?: Partial<SetLoginOrgOptions>) => Promise<void>;
  status: Status;
  impersonate: (orgId: string) => Promise<boolean>;
  clearImpersonate: () => void;
  reloadUser: (options?: ReloadUserOptions) => Promise<void>;
  analyticsStatus: LoadingBoolean;
  userAbTests: AllocatedTests;
}

interface HandleLoginReplyOptions {
  loading: boolean;
}
interface SetLoginOrgOptions {
  postUrl: string;
  loading: boolean;
}

const allowedDashboardOrgs = ["6c0a6c7d-d1df-4f68-a57b-35a1a84e18bd", "9181cf36-c281-4540-9c2f-5f62f20658fe"];
const disallowedDashboardOrgs = ["1b834115-5cd1-4939-897e-eda130dafdf8"];
function createUserFromLogin(reply: LoginReply, impostor: boolean = false): User {
  const upsolverian = reply.user.email.toLowerCase().endsWith("@upsolver.com");
  return {
    ...reply.user,
    impostor,
    upsolverian,
    hasDashboards:
      reply.currentOrganization?.id &&
      !disallowedDashboardOrgs.includes(reply.currentOrganization.id) &&
      (upsolverian || allowedDashboardOrgs.includes(reply.currentOrganization.id)),
    organization: reply.currentOrganization?.id,
  };
}

function useLoginState(auth: AuthenticationApi): LoginState {
  const history = useHistory();
  const initialized = useRef(false);
  const impersonateOrganizationId = useRef<string>(UpsolverLocalStorage.get()["impersonate"]);

  const [status, setStatus] = useState<Status>({
    loading: true,
  });

  const [analyticsStatus, setAnalyticsStatus] = useState<LoadingBoolean>("loading");
  const abtestStatus = useRef<LoadingBoolean>("loading");
  const [abTests, setAbTests] = useState<AllocatedTests>();
  const {
    logout: logoutApi,
    handleBadLogin,
    onAuthenticationError,
    setLoginOrg: setLoginOrgApi,
    onUserOrg,
    getAbTests: getAbTestsApi,
    getSupportedFeatures,
    setMultiOrgs,
    setUser,
    getLoginUserInfo,
    invalidate,
    setAuthentication,
    setLocation,
    setUserData,
    clearImpersonate: clearImpersonateApi,
    impersonate: impersonateApi,
  } = auth;

  const logout = useCallback(() => {
    logoutApi().then(() => {
      initialized.current = false;
      setStatus({ loading: false });
      clearUserForCapture();
      history.replace("/login");
    });
  }, [logoutApi, history]);

  useEffect(
    () =>
      onAuthenticationError(() => {
        if (initialized.current) {
          handleBadLogin();
          initialized.current = false;
          setStatus({ loading: false });
          history.replace("/login");
        }
      }),
    [onAuthenticationError, handleBadLogin, history]
  );

  const setLoginOrg = useCallback(
    (org: Organization, options?: Partial<SetLoginOrgOptions>): Promise<void> =>
      setLoginOrgApi(org)
        .then(() => {
          setStatus((x) => ({
            loginReply: {
              ...x.loginReply,
              currentOrganization: org,
              user: createUserFromLogin(x.loginReply, !!impersonateOrganizationId.current),
            },
            selectedOrganization: org,
            organizations: x.loginReply.organizations,
            loading: options?.loading ?? false,
            noOrg: false,
          }));
          onUserOrg(org, options?.postUrl);
        })
        .catch((err) => {
          if (!err || !(err instanceof UnsupportedOperationError)) {
            throw err;
          }
        }),
    [setLoginOrgApi, onUserOrg]
  );

  const getAbTests = useCallback(async () => {
    try {
      if (abtestStatus.current === "loading") {
        const features: SupportedFeatures[] = await getSupportedFeatures();
        abtestStatus.current = features.includes("ab-testing");
        setAnalyticsStatus(features.includes("user-tracking-proxy"));
      }
      if (abtestStatus.current) {
        setAbTests(await getAbTestsApi());
      }
    } catch (e) {}
  }, [getAbTestsApi, getSupportedFeatures]);

  const handleLoginReply = useCallback(
    async (reply: LoginReply, options?: Partial<HandleLoginReplyOptions>) => {
      const orgs = reply.organizations.filter(isClassicOrg);
      setMultiOrgs(orgs);
      const currentOrganization = loginReplyDefaultOrg(reply);
      const loginReply: LoginReply<User> = {
        ...reply,
        currentOrganization,
        user: createUserFromLogin(reply, !!impersonateOrganizationId.current),
      };
      await getAbTests();
      setUser(loginReply.user);

      const noOrg = orgs.length === 0;
      const loading = options?.loading ?? (!noOrg && initialized.current === false && currentOrganization != null);
      setStatus((x) => {
        const status: Status = {
          loginReply,
          loading,
          noOrg,
          organizations: orgs,
        };
        if (x?.selectedOrganization != null && x.selectedOrganization.id === currentOrganization?.id) {
          status.selectedOrganization = currentOrganization;
        }
        return status;
      });
    },
    [getAbTests, setUser, setMultiOrgs]
  );

  const loadUser = useCallback(
    async (showLoading: boolean = true) => {
      // initialize user ab tests in user session
      await getAbTests();
      try {
        const loginReply = await getLoginUserInfo();
        await handleLoginReply(loginReply, { loading: showLoading });
      } catch (err) {
        if (err?.status === 401 || err?.response?.status === 401) {
          setStatus({ loading: false });
          invalidate(err);
        } else {
          throw err;
        }
      }
    },
    [handleLoginReply, getAbTests]
  );

  const reloadUser = useCallback(
    async (options: ReloadUserOptions = { mainLoader: true }) => {
      initialized.current = false;
      await loadUser(options.mainLoader);
    },
    [loadUser]
  );

  /**
   * After initial user load set the login org if the user has one.
   * This means the user was already logged in to some org (had a valid session) when they opened the app.
   */
  useEffect(() => {
    const loginReply = status.loginReply;
    if (!loginReply) {
      return;
    }
    if (!initialized.current) {
      initialized.current = true;
      const currentOrganization = loginReplyDefaultOrg(loginReply);
      if (currentOrganization) {
        void setLoginOrg(currentOrganization);
      } else {
        setStatus({ ...status, loading: false });
        if (status.noOrg) {
          setLocation().then(() => setAuthentication(true));
        } else {
          history.replace("/login");
        }
      }
    }
  }, [status, history, setLocation, setAuthentication]);

  useEffect(() => {
    loadUser();
  }, [loadUser]);

  const impersonate = useCallback(
    async (organizationId: string) => {
      initialized.current = false;
      impersonateOrganizationId.current = organizationId;
      await impersonateApi(organizationId);
      await loadUser();
      return true;
    },
    [loadUser, impersonateApi]
  );

  useEffect(() => {
    const reply = status?.loginReply;
    if (reply?.user != null) {
      setUserForCapture({ id: reply.user.email, currentOrganizationId: status.selectedOrganization?.id });
      setUserData({ userId: status.loginReply.user.email, organizationId: status.selectedOrganization?.id });
    }
  }, [status, setUserData]);

  return {
    status,
    setLoginOrg,
    handleLoginReply,
    logout,
    impersonate,
    clearImpersonate: clearImpersonateApi,
    reloadUser,
    analyticsStatus,
    userAbTests: abTests,
  };
}

export default useLoginState;
