import React from "react";
import Loadable from "react-loadable";
import { FormFieldRegistry } from "../../FormFieldRegistry";

type UDSLAsyncPlaceholderProps = {
  isLoading: boolean;
  error?: Error;
};
const UDSLAsyncLoader = ({ isLoading, error }: UDSLAsyncPlaceholderProps) => {
  if (isLoading) {
    return (
      <div className="page" style={{ height: 215 }}>
        <p style={{ verticalAlign: "middle", textAlign: "center", width: "100%", marginTop: "25%" }}>
          Loading the DSL Editor
        </p>
      </div>
    );
  } else if (error) {
    return <div>Sorry, there was a problem loading the editor. {error.toString()}</div>;
  } else {
    return null;
  }
};
export const AsyncFeatureDslEditor = Loadable({
  loader: () => import("../../../transform/UDSL" /* webpackChunkName: "dsl" */),
  loading: UDSLAsyncLoader,
});

export const register = (formFieldRegistry: FormFieldRegistry) =>
  formFieldRegistry.register("dsl-editor", (props) => <AsyncFeatureDslEditor {...props} />, { decorate: false });
