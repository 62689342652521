import React, { Component } from "react";
import { MathUtils } from "./Utils";

export interface Measure {
  unit: string;
  amount: string | number;
  factor?: string;
}

export interface Unit {
  measure: (amount: number) => Measure;
  format: (amount: number, options?: Partial<{ pluralize: boolean; hideZero: boolean }>) => string;
  empty: Measure;
}

class SimpleUnit implements Unit {
  _units: Array<string>;
  _factor: number;
  _empty: Measure;

  constructor(units: Array<string>, factor: number) {
    this._units = units;
    this._factor = factor;
    this._empty = { unit: this._units[0], amount: "#" };
  }

  measure(amount: number): Measure {
    let unit = 0;
    const sign = Math.sign(amount);
    amount *= sign;
    amount = MathUtils.sigFigs(amount, 3);

    while (amount > this._factor && unit < this._units.length - 1) {
      amount = MathUtils.sigFigs(amount / this._factor, 3);
      unit += 1;
    }

    amount *= sign;
    return { amount, unit: this._units[unit] };
  }

  format = (amount: number, options?: Partial<{ pluralize: boolean; hideZero: boolean }>): string => {
    if (options?.hideZero && !amount) {
      return "";
    }
    const measure = this.measure(amount);
    return measure.amount + " " + measure.unit;
  };

  get empty(): Measure {
    return this._empty;
  }

  per(value: string): SimpleUnit {
    return new SimpleUnit(
      this._units.map((v) => v + value),
      this._factor
    );
  }
}

class StructuredUnit implements Unit {
  _units: Array<{ key: string; factor: number }>;
  _factors: Array<number>;

  constructor(units: Array<{ key: string; factor: number }>) {
    this._units = units;
    this._factors = units.map((x) => x.factor);
  }

  measure(amount: number): Measure {
    let fixedAmount = amount;
    if (this._factors.length && fixedAmount > 0) {
      let scaleFactor = 0;
      while (fixedAmount < 1 && scaleFactor < this._factors.length) {
        fixedAmount = amount * this._factors[scaleFactor];
        scaleFactor++;
      }
      const underlying = Count.measure(fixedAmount);
      return {
        amount: underlying.amount,
        factor: underlying.unit,
        unit: this._units[scaleFactor ? scaleFactor - 1 : 0].key,
      };
    }

    return { amount, unit: this._units[0].key };
  }

  get empty(): Measure {
    return { unit: this._units[0].key, amount: "#" };
  }

  format(amount: number, options: Partial<{ pluralize: boolean; hideZero: boolean }>): string {
    const measure = this.measure(amount);
    return `${measure.amount} ${measure.unit}${options?.pluralize ? pluralize(measure.amount.toString()) : ""}`;
  }
}

export class ScalingUnit implements Unit {
  private readonly factors: Array<number>;

  constructor(private units: Array<{ key: string; factor: number }>, private pluralizeDefault?: boolean) {
    this.units = units;
    this.factors = units.map((x) => x.factor);
  }

  measure = (amount: number): Measure => {
    let fixedAmount = amount;
    if (this.factors.length && fixedAmount > 0) {
      let scaleFactor = 0;
      while (fixedAmount >= this.factors[scaleFactor] && scaleFactor < this.factors.length) {
        scaleFactor++;
      }
      const unit = this.units[scaleFactor ? scaleFactor - 1 : 0];
      return {
        amount: Math.round(amount / unit.factor),
        unit: unit.key,
      };
    }

    return { amount, unit: this.units[0].key };
  };

  get empty(): Measure {
    return { unit: this.units[0].key, amount: "#" };
  }

  format = (amount: number, options: Partial<{ pluralize: boolean; hideZero: boolean }>): string => {
    if (options?.hideZero && !amount) {
      return "";
    }
    const measure = this.measure(amount);
    return `${measure.amount} ${measure.unit}${
      (options?.pluralize || this.pluralizeDefault) && measure.amount ? pluralize(measure.amount.toString()) : ""
    }`;
  };
}
export function pluralize(amount: string): string {
  const parsed = parseFloat(amount);

  if (isNaN(parsed) || parsed === 1) {
    return "";
  } else {
    return "s";
  }
}

class StaticUnit implements Unit {
  constructor(private unit: string, private decimals?: number) {
    this.unit = unit;
  }

  measure(amount: number | string) {
    return { unit: this.unit, amount };
  }

  format = (amount: number) => {
    if (typeof amount === "number" && Number.isInteger(this.decimals) && !Number.isInteger(amount)) {
      return `${amount.toFixed(this.decimals)}${this.unit}`;
    } else {
      return `${amount}${this.unit}`;
    }
  };

  get empty() {
    return this.measure("#");
  }
}

export const Count: Unit = new SimpleUnit(["", "K", "M", "B", "T", "Q"], 1000);
export const Bytes: Unit = new SimpleUnit(["B", "KB", "MB", "GB", "TB", "PB", "EB"], 1024);
export const TimeMillis: Unit = new SimpleUnit(["ms", "s"], 1000);
export const TimeMillisToWeek: Unit = new ScalingUnit(
  [
    { key: "milli sec", factor: 1 },
    { key: "second", factor: 1000 },
    { key: "minute", factor: 60000 },
    { key: "hour", factor: 3600000 },
    { key: "day", factor: 86400000 },
    { key: "week", factor: 604800000 },
  ],
  true
);

export const TimeMillisToWeekShortHand: Unit = new ScalingUnit(
  [
    { key: "ms", factor: 1 },
    { key: "s", factor: 1000 },
    { key: "min", factor: 60000 },
    { key: "hr", factor: 3600000 },
    { key: "d", factor: 86400000 },
    { key: "wk", factor: 604800000 },
  ],
  true
);

export const TimeSecondsToWeek: Unit = new ScalingUnit(
  [
    { key: "second", factor: 1 },
    { key: "minute", factor: 60 },
    { key: "hour", factor: 3600 },
    { key: "day", factor: 86400 },
    { key: "week", factor: 604800 },
  ],
  true
);

export const TimeSecondsToWeekShortHand: Unit = new ScalingUnit(
  [
    { key: "sec", factor: 1 },
    { key: "min", factor: 60 },
    { key: "hr", factor: 3600 },
    { key: "d", factor: 86400 },
    { key: "wk", factor: 604800 },
  ],
  true
);

export const TimeSeconds: Unit = new StructuredUnit([
  { key: "sec", factor: 1 },
  { key: "min", factor: 60 },
  { key: "hour", factor: 3600 },
  { key: "day", factor: 86400 },
  { key: "lifetime", factor: 86400 },
]);
export const PercentDecimal = (decimals: number) => new StaticUnit("%", decimals);
export const Percent: Unit = PercentDecimal(2);

type LastValueProps = {
  value: number;
  unit: Unit;
};

export class Scalar extends Component {
  props: { unit: string; amount: string | number; showSpace?: boolean; unitSeparator?: string; factor?: any };

  render() {
    const { unit, amount, showSpace, unitSeparator, factor } = this.props;
    const separator = unitSeparator || (showSpace && " ");
    return (
      <span>
        {amount}
        <span className="unit">
          {factor && factor}
          {separator && separator}
          {unit}
        </span>
      </span>
    );
  }
}

export class LastValue extends Component<LastValueProps> {
  render() {
    const props = this.props;
    const measure = props.unit.measure(props.value);
    return (
      <span className="last-value">
        <Scalar {...measure} />
      </span>
    );
  }
}
