/* eslint-disable */
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "mobx-react-lite/batchingForReactDom";
import { createBrowserHistory } from "history";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import ErrorStore from "./core/api/ErrorStore";
import { Auth } from "./auth/Auth";
import { Api } from "./core/api/Api";
import { SimpleFormStore } from "./core/views/FormStore";
import { IPreferencesStorage, UserPreferences, UserPreferencesStorage } from "./core/UserPreferences";
import "react-toastify/dist/ReactToastify.css";
import { DefaultNotificationListener } from "./AppNotification";
import { UsersStore } from "./auth/UsersStore";
import { NotificationsStore } from "./notifications/NotificationsStore";
import { BlockNavigationHandlers, GlobalStores } from "./GlobalContext";
import { GlobalUIStore } from "./core/GlobalUIStore";
import formFieldRegistry from "./core/FormFieldRegistry";
import registerAll from "./core/views/FormInputs/registerAll";
import { ZendeskSettings, ZendeskWidget } from "./core/zendesk/zendesk";
import { UpsolverRemoteStorage } from "./core/UpsolverRemoteStorage";
import { QueryClient } from "react-query";
import { createAnalytics } from "./core/createAnalytics";
import { SupportChat } from "./SupportChat";
import { MainAsyncPlaceHolder } from "./MainAsyncPlaceHolder";
import { CloudIntegrationStore } from "./cloud-integrations/cloud-integration-store";
import { AuthenticatedUserData, AuthenticationApi, UserAuthenticationStatus } from "./core/api/AuthenticationApi";
import { computed } from "mobx";
import { User } from "./core/api/contracts/User";
import { Organization } from "./core/api/contracts/Organization";

const blockNavigations = new BlockNavigationHandlers();
const errorStore = new ErrorStore();
export const browserHistory = createBrowserHistory({
  getUserConfirmation: (message, callback) => {
    const handler = blockNavigations.get(message);
    if (handler) {
      return handler(callback);
    }
    // don't block if no handler
    callback(true);
  },
});
let cloudIntegStore: CloudIntegrationStore = null;
const getCloundIntegration: (api: Api) => CloudIntegrationStore = (api) =>
  cloudIntegStore || new CloudIntegrationStore(api);
const api = new Api(DefaultNotificationListener(), getCloundIntegration, browserHistory, errorStore);
cloudIntegStore = new CloudIntegrationStore(api);
const analytics = createAnalytics(api);
const auth = new Auth(api, browserHistory, analytics);
const usersStore = new UsersStore(api, auth);
const userPreferences: IPreferencesStorage = new UserPreferencesStorage(
  new UpsolverRemoteStorage<UserPreferences>(api, "user")
);

const notificationsStore = new NotificationsStore(api, auth);
const formStore = new SimpleFormStore();
const globalUIStore = new GlobalUIStore(auth, userPreferences);

class AuthApi implements AuthenticationApi {
  constructor(private readonly api: Api, private readonly auth: Auth) {}

  handleBadLogin = () => {
    this.auth.handleBadLogin();
  };

  invalidate = (error: unknown) => {
    return this.auth.invalidate(error);
  };

  logout = () => {
    return this.auth.logout();
  };

  onUserOrg = (org: Organization | undefined, postUrl: string | undefined) => {
    this.auth.onUserOrg(org, postUrl);
  };

  setAuthentication = (value: boolean) => {
    this.auth.setAuthentication(value);
  };

  setUser = (user: User) => {
    this.auth.setUser(user);
  };

  @computed
  get user() {
    return this.auth.user;
  }

  clearImpersonate = () => {
    this.api.clearImpersonate();
  };

  impersonate = (organizationId: string) => {
    this.api.impersonate(organizationId);
  };

  getAbTests = () => {
    return this.api.getGlobal("userabtest/");
  };

  getSupportedFeatures = () => {
    return this.api.getGlobal("metadata/supported-features");
  };

  onAuthenticationError = (handler: () => void) => {
    this.api.onAuthenticationError(handler);
  };

  setLocation = async () => {
    await this.api.setLocation();
  };

  setLoginOrg = this.api.setLoginOrg;

  setMultiOrgs = (organizations: Organization[]) => {
    this.api.setMultiOrgs(organizations);
  };

  setUserData = (userData: AuthenticatedUserData) => {
    return this.api.setUserData(userData);
  };

  getLoginUserInfo = () => {
    return this.api.getGlobal("users/");
  };

  @computed
  get userStatus(): UserAuthenticationStatus {
    return {
      authenticated: this.auth.authentication?.authenticated,
      userData: this.api.userData,
      currentOrganization: this.auth.currentOrganization,
      multiOrgs: this.auth.multiOrgs,
    };
  }
}

const globalStores: GlobalStores = {
  api,
  auth,
  userPreferences,
  formStore,
  usersStore,
  notificationsStore,
  analytics,
  blockNavigations,
  globalUIStore,
  formFieldRegistry,
  authApi: new AuthApi(api, auth),
};

registerAll(formFieldRegistry);

declare global {
  interface Window {
    globalStores?: GlobalStores;
    gtag: any;
    element: any;
    analytics: SegmentAnalytics.AnalyticsJS;
    monacoEnvironment: { getWorkerUrl: () => string };
    zESettings?: ZendeskSettings;
    zE?: ZendeskWidget;
    supportChat: SupportChat;
    jQuery: any;
    integStore: CloudIntegrationStore;
    userGuiding: any;
    hj: (...args: unknown[]) => void;
  }
}

window.supportChat = new SupportChat(api);
window.globalStores = globalStores;
window.integStore = cloudIntegStore;


window.supportChat.hide();

const doNotRetryStatus = [400, 401, 403, 404, 500, 501];

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: (failureCount, error: any) => {
        return !(failureCount > 2 || doNotRetryStatus.includes(error?.status));
      },
    },
  },
});

const OldAppRoot = React.lazy(() => import("./OldAppRoot" /* webpackChunkName: "old-app-root" */));

Sentry.init({
  dsn: "https://4db8a0f4ab054c3db4e9b584cf22e400@relay.upsolver.com/6654661",
  environment: process.env.REACT_APP_ENV_TYPE,
  release: process.env.SENTRY_RELEASE,
  integrations: [
    new BrowserTracing({
      tracingOrigins: [/localhost:3\d+/, /^\//],
    }),
  ],
  tracesSampleRate: 1.0,
  ignoreErrors: ["ResizeObserver loop limit exceeded"],
  enabled: process.env.REACT_APP_ENV_TYPE !== "local"
});

ReactDOM.render(
  <Suspense fallback={<MainAsyncPlaceHolder isLoading={true} />}>
    <OldAppRoot
      errorStore={errorStore}
      globalStores={globalStores}
      queryClient={queryClient}
      browserHistory={browserHistory}
    />
  </Suspense>,
  document.getElementById("root")
);
