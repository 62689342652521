import { observer } from "mobx-react";
import { FieldSelection, FieldsSelection } from "../FieldSelectionControls";
import { decorateOnChange, FormSelectInputProps } from "../FormInputTypes";
import React from "react";
import { FormFieldRegistry } from "../../FormFieldRegistry";

export const FromFieldSelectionInput: React.FC<FormSelectInputProps> = observer(
  ({ input, onChange, options, disabled }) => {
    return (
      <FieldSelection
        disabled={disabled}
        initialField={input.initial}
        items={options}
        typeFilterKey={input.metadata.allowedTypes}
        onSelect={decorateOnChange(input, onChange)}
      />
    );
  }
);

export const FromFieldsSelectionInput: React.FC<FormSelectInputProps> = observer(
  ({ input, onChange, options, disabled }) => {
    return (
      <FieldsSelection
        disabled={disabled}
        initialField={input.initial}
        items={options}
        typeFilterKey={input.metadata.allowedTypes}
        onSelect={decorateOnChange(input, onChange)}
      />
    );
  }
);

export const register = (formFieldRegistry: FormFieldRegistry) => {
  formFieldRegistry.register("field-select", (props) => (
    <FromFieldSelectionInput {...props} options={props.input.metadata.options} />
  ));
  formFieldRegistry.register("field-multi-select", (props) => (
    <FromFieldsSelectionInput {...props} options={props.input.metadata.options} />
  ));
};
