export const SCHEMA_INT_MAX_VALUE = 2 ** 31 - 1;
export const SCHEMA_INT_MIN_VALUE = -(2 ** 31);
export const primitiveSchemaTypes = ["Char", "String", "Boolean", "Long", "Number", "Int", "Float", "Double"];
export const convertSchemeTypeBooleans: any = {
  True: true,
  true: true,
  T: true,
  t: true,
  False: false,
  false: false,
  F: false,
  f: false,
};
export const schemaTypeToDefaultValue = (schemaType: string, sequence: boolean, required: boolean): any => {
  if (sequence) {
    return [];
  }
  switch (schemaType) {
    case "String":
    case "Char":
      return "";
    case "Boolean":
      return false;
    case "OperationReference":
      return "";
    default:
      return required ? 0 : null;
  }
};
export const schemaTypeToInputType = (schemaType: string, sequence: boolean = false): string => {
  if (schemaType === "PolicyStatement") {
    return "policy-editor";
  }
  if (sequence) {
    return "multi-select";
  }
  let t;
  switch (schemaType) {
    case "String":
    case "Char":
      t = "text";
      break;
    // EncryptedString is for backward compatibility (changed to RemotableSecret)
    case "EncryptedString":
    case "RemotableSecret":
    case "InlineSecret":
      t = "password-no-autocomplete";
      break;
    case "Boolean":
      t = "checkbox";
      break;
    case "OperationReference":
      t = "searchable-select";
      break;
    case "Instant":
      t = "datetime";
      break;
    case "Double":
    case "Long":
    case "Integer":
    case "Int":
    case "Float":
      t = "number";
      break;
    default:
      t = "searchable-select";
      break;
  }
  return t;
};
