import { observer } from "mobx-react";
import { SuperSelect } from "../SuperSelect";
import { FormSelectInputProps, inputOnChange } from "../FormInputTypes";
import React from "react";
import { FormFieldRegistry } from "../../FormFieldRegistry";
import { ItemView } from "../SelectItemViews";
import FormItemDecorator from "./FormItemDecorator";

export const SelectOutputType: React.FC<FormSelectInputProps> = observer(
  ({ input, options, disabled, placeholder }) => (
    <SuperSelect
      automationName={`select-${input.name}`}
      options={options}
      onFocus={input.onFocus}
      onBlur={input.onBlur}
      onChange={inputOnChange(input, true)}
      value={input.$value}
      disabled={disabled}
      placeholder={placeholder}
      renderItem={ItemView}
    />
  )
);
export const register = (formFieldRegistry: FormFieldRegistry) =>
  formFieldRegistry.register("select-output-type", (props) => (
    <SelectOutputType options={props.input.metadata.options} {...props} />
  ));
