import React, { useEffect, useState } from "react";
import { CopyButton } from "./CopyText";
import "../../styles/Tooltip.scss";
import Tooltip from "@mui/material/Tooltip";
import classNames from "classnames";

type EllipsisTooltipProps = {
  block?: boolean;
  className?: string;
  children: any;
  copyButton?: boolean;
  placement?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
  title?: React.ReactNode;
  enterDelay?: number;
  withEmpty?: boolean;
  button?: boolean;
};

// export const WhiteTooltip = withStyles(theme =>
//   createStyles({
//     tooltip: {
//       background: theme.palette.background.paper,
//       color: theme.palette.text.primary,
//       fontSize: theme.typography.body1.fontSize,
//       maxWidth: 480,
//       margin: 0,
//       padding: 0
//     }
//   })
// )(Tooltip);

export const EllipsisTooltip: React.FC<EllipsisTooltipProps> = ({
  block,
  className,
  children,
  copyButton,
  placement,
  title,
  enterDelay,
  withEmpty,
  button,
}) => {
  const [contentClassNames, setContentClassNames] = useState<string>();
  useEffect(() => {
    setContentClassNames(classNames(className, { TooltipContent: true, inline: !block, Button: button }));
  }, [block, className]);
  if (withEmpty && !title) {
    return children;
  }
  return (
    <Tooltip
      placement={placement}
      enterDelay={enterDelay ? enterDelay * 1000 : 300}
      title={
        <div className="TooltipTrigger">
          {title || <span>{children}</span>} {copyButton && <CopyButton value={title || children} />}
        </div>
      }
    >
      <div className={contentClassNames}>{children}</div>
    </Tooltip>
  );
};
