import React from "react";
import { action, observable } from "mobx";
import { observer } from "mobx-react";

export type ResponsiveWidthContainerProps = {
  children: any;
};

@observer
export class ResponsiveWidthContainer extends React.Component<ResponsiveWidthContainerProps> {
  @observable _draw: boolean = false;
  @observable _width: number;

  @action.bound _updateRef(element: HTMLDivElement) {
    this._draw = element !== null;
    if (this._draw) {
      this._width = element.offsetWidth;
    }
  }

  render() {
    const style = this._draw ? { width: this._width } : {};

    return (
      <div ref={this._updateRef} style={style}>
        {this._draw && this.props.children}
      </div>
    );
  }
}
