import React from "react";
import { decorateOnChange, FormInputProps, secretClassName } from "../FormInputTypes";
import { observer } from "mobx-react";
import { Switch } from "../Switch";
import classNames from "classnames";
import { FormFieldRegistry } from "../../FormFieldRegistry";

const FormSwitchInput: React.FC<FormInputProps> = observer(({ input, onChange }) => {
  if (typeof input.$value !== "boolean") {
    setTimeout(() => (input.$value = input.$initial), 0);
  }
  return (
    <Switch
      checked={input.$value}
      className={classNames(input.metadata.secret && secretClassName, "hidden")}
      disabled={input.metadata.disabled}
      onChange={decorateOnChange(input, onChange, true)}
    />
  );
});
export const register = (formFieldRegistry: FormFieldRegistry) =>
  formFieldRegistry.register("switch", (props) => <FormSwitchInput {...props} />);
export default FormSwitchInput;
