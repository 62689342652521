import React from "react";
import { splitPath } from "../Utils";
import Box from "@mui/material/Box";

export const ReactJoin = <A extends {}>(arr: A[], joinNode: React.ReactNode): React.ReactNode => {
  if (arr.length === 0) {
    return <React.Fragment />;
  }

  const returnArray = [];

  for (let i = 0; i < arr.length; i++) {
    returnArray.push(arr[i]);
    if (i !== arr.length - 1) {
      returnArray.push(joinNode);
    }
  }

  return (
    <React.Fragment key={"react-join-key"}>
      {returnArray.map((item, key) => (
        <React.Fragment key={key}>{item}</React.Fragment>
      ))}
    </React.Fragment>
  );
};
export const DisplayFieldName = (name: string): React.ReactNode => {
  return ReactJoin(
    splitPath(name).map((x) => x.replace(/\\\./g, ".").replace(/\\\[\\\]/g, "[]")),
    <Box component="strong" style={{ fontWeight: 900, fontSize: "1.5rem", color: "#9f9f9f" }} display="contents">
      .
    </Box>
  );
};
