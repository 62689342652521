export interface FormStore {
  get(id: string): any;

  getNew(): { id: string; state: any };

  remove(id: string): void;

  onSuccess(payload: any): boolean;

  setSuccessHandler(fn?: (result: any) => void): void;
}

export class SimpleFormStore implements FormStore {
  _index: number = 0;
  _data: { [key: string]: any } = {};
  _successHandler: (result: any) => void;

  get(id: string): any {
    // Might be null since the user refreshed the page,
    // currently we're not saving the form data in the user local storage.
    const state = this._data[id] || {};
    this._data[id] = state;
    return state;
  }

  getNew(): { id: string; state: any } {
    const id = (++this._index).toString();
    const state = {};
    this._data[id] = state;

    return { id, state };
  }

  remove(id: string): void {
    delete this._data[id];
  }

  setSuccessHandler(fn?: (result: any) => void) {
    this._successHandler = fn;
  }

  onSuccess(payload: any) {
    if (this._successHandler) {
      this._successHandler(payload);
      this._successHandler = null;
      return true;
    } else {
      return false;
    }
  }
}
