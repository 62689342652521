import { IObservableArray, observable } from "mobx";
import { NewUserRequest, User } from "./User";
import { DisplayData } from "./Core";
import { SamlConfiguration } from "./Saml";
import { PlanMetadata } from "../../PlanMetadata";
import moment from "moment";

export type OnboardingStatus = {
  name: string;
  step: number;
};
export interface Organization {
  users: IObservableArray<User>;
  displayData?: DisplayData;
  id: string;
  defaultCloudStorage: string; // for file upload only
  defaultConnection?: string;
  enabled?: boolean;
  plan: PlanMetadata;
  cloudIntegrations?: any[];
  name?: string;
  unsupportedClazzes: string[];
  flags?: OrganizationFlags;
  saml?: SamlConfiguration;
  defaultHiveMetastoreConnection?: string;
}
export const defaultOrganization: any = {
  users: observable.array(),
  id: "",
  defaultCloudStorage: "",
  plan: { clazz: "FreePlan" },
  saml: undefined,
};

type DbxSupportState = { exposeToUsers?: boolean; exposeUpsolverClassic?: boolean };

export interface OrganizationFlags {
  allowCustomDataSources: boolean;
  metadataFlags: string[];
  allowPrestoClusters: boolean;
  hiveMetastoreHierarchicalOutputs: boolean;
  dbxSupportState?: DbxSupportState;
}

export interface UserSignupRequest {
  admin: NewUserRequest;
  captcha?: string;
}

export interface NewOrganizationRequest {
  name: string;
  admin: NewUserRequest;
  captcha?: string;
  plan?: { clazz: PlanTypeClazz };
}

export type PlanTypeClazz = "TrialPlanRequest" | "FreePlanRequest";

export const organizationExpired = ({ plan }: Organization) => {
  if (plan.clazz !== "TrialPlan") {
    return false;
  }
  const { shouldDisableOrganizationWhenExpired, startTime, duration } = plan;
  if (shouldDisableOrganizationWhenExpired === false) {
    return false;
  }

  const total = duration;
  const current = moment.duration(moment().diff(moment(startTime))).asMinutes();
  return total - current < 1;
};

export function isClassicOrg(org: Pick<Organization, "flags">) {
  const dbxSupportState = org.flags?.dbxSupportState;
  return dbxSupportState == null || dbxSupportState.exposeUpsolverClassic === true || (!dbxSupportState.exposeUpsolverClassic && !dbxSupportState.exposeToUsers);
}
