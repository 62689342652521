import React from "react";

export type ExternalLinkProps = Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, "target" | "href" | "rel"> & {
  to: string;
};

export class ExternalLink extends React.Component<ExternalLinkProps> {
  render() {
    const { to, children, ...props } = this.props;

    return (
      <a href={to} target="_blank" rel="noopener noreferrer" {...props}>
        {children}
      </a>
    );
  }
}
