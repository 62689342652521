import React, { useContext, useState } from "react";
import { AppContext } from "../../routes/AppContext";
import { AutocompleteValue } from "../api/contracts/AutoComplete";

function defaultSerializer(item: any) {
  return {
    id: item.id,
    label: typeof item.displayValue === "function" ? item.displayValue() : item.displayValue,
    default: item.default,
  };
}

export function useAutocomplete<OptionType = AutocompleteValue>() {
  const { opsInspection } = useContext(AppContext);
  const [error, setError] = useState<any>();
  const [options, setOptions] = useState<OptionType[]>([]);

  const loadOptions = (autoCompleteMetadata: any, context: any, serializer: (item: any) => any = defaultSerializer, useGlobalApi: boolean = false) => {
    const { clazz, parameters } = autoCompleteMetadata;

    const contextHasItems =
      autoCompleteMetadata.allowMissingContext ||
      Object.values(context).findIndex((val: any) => val != null && val?.length != 0) > -1;

    if (autoCompleteMetadata.context.length === 0 || contextHasItems) {
      const req = { ...context, ...{ clazz, parameters } };

      return opsInspection
        .autocompleteItems(req, useGlobalApi)
        .catch((error: any) => {
          setError(error);
          setOptions([]);

          return [];
        })
        .then((l: any[]) => {
          const _options: any[] = l.map(serializer);

          setOptions(_options);
        });
    }
  };

  return { options, loadOptions, error };
}
