import React, { PropsWithChildren, useCallback, useContext, useEffect, useState } from "react";
import { observer } from "mobx-react";

import { useBasicApi } from "../../api/Api";
import { FormInputProps } from "../FormInputTypes";
import { Button } from "../Button";
import { registerAsyncValidator } from "../../FormValidators";
import { buildMetadataContextRequest, ServerSideValidationStatus } from "../../SimpleMetadata";
import styles from "../../../styles/server-side-validation.module.scss";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import { extendObservable } from "mobx";
import { red, green } from "@mui/material/colors";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

interface ServerSideValidationContext {
  requestValidation: () => void;
}

const Context = React.createContext<ServerSideValidationContext>({
  requestValidation: (validationStatus?: ServerSideValidationStatus) => {},
});

export function useServerSideValidationProvider() {
  return useContext(Context);
}

export function ServerSideValidationProvider({
  children,
}: {
  children: (context: ServerSideValidationContext) => JSX.Element;
}) {
  const context = useServerSideValidationProvider();
  return children(context);
}

type ServerSideValidatorProps = PropsWithChildren<Partial<FormInputProps>>;

function useServerSideValidation<T>() {
  const api = useBasicApi();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const validate = (body: T) => {
    setIsLoading(true);
    return api.post("validation/validate", body).finally(() => {
      setIsLoading(false);
    });
  };

  return { validate, isLoading };
}

const ServerSideValidationWrapper: React.FC = observer((props: ServerSideValidatorProps) => {
  if (props.input.metadata?.serverSideValidation == null) {
    return props.children;
  }

  return <ServerSideValidation {...props} />;
});

ServerSideValidationWrapper.displayName = "ServerSideValidationWrapper";

const StatusIcon = React.memo(
  ({ status }: { status: ServerSideValidationStatus }) =>
    (status === "VALID" && (
      <CheckCircleOutline fontSize="large" style={{ color: green[300], verticalAlign: "middle" }} />
    )) ||
    (status === "INVALID" && <ErrorOutline fontSize="large" style={{ color: red[300], verticalAlign: "middle" }} />) ||
    null
);

const ServerSideValidation = observer((props: ServerSideValidatorProps) => {
  const { validate, isLoading } = useServerSideValidation();

  const { input } = props;
  const { serverSideValidation } = input.metadata;
  const { subFields } = input.metadata.serverSideValidation;
  const [status, setStatus] = useState<ServerSideValidationStatus>(null);

  useEffect(() => {
    if (!subFields) {
      registerAsyncValidator("serverSide", (value: any, req: any, key: any, passes: any) => {
        const form = input.getForm();
        const reqBody = buildMetadataContextRequest(form.$(key)?.metadata.serverSideValidation);

        validate(reqBody).then((data) => {
          if (data.length > 0) {
            passes(false, "Error Msgg");
            setStatus("INVALID");
          } else {
            passes();
            setStatus("VALID");
          }
        });
      });
    }
  }, [subFields]);

  const requestValidation = useCallback(
    (validationStatus?: ServerSideValidationStatus) => {
      if (validationStatus != null) {
        setStatus(validationStatus);
      } else {
        setStatus(null);
        const reqBody = buildMetadataContextRequest(serverSideValidation);
        validate(reqBody).then((data) => {
          if (data?.length === 0) {
            // Reset all subFields
            input.validate();
            setStatus("VALID");
          } else {
            setStatus("INVALID");
            input.onValidateError({ validationErrors: data });
          }
        });
      }
    },
    [input, validate]
  );

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    requestValidation();
  };

  // reset validation button status indicator when form is touched
  useEffect(() => {
    if (input.focused) {
      setStatus(null);
    }
  }, [input.focused]);

  useEffect(() => {
    extendObservable(input.metadata, { validationResult: status });
  }, [status, input]);

  return (
    <Context.Provider value={{ requestValidation }}>
      <div className={styles.ServerSideValidation}>
        {props.children}
        {subFields && !input.metadata.hidden && (
          <span className={styles.ValidateButton}>
            <Button
              automationName={`validate-${input.name}`}
              type="primary"
              icon={<PlayArrowIcon />}
              medium
              loading={isLoading}
              onClick={handleClick}
            >
              {input.metadata.serverSideValidation.validationCallToAction ?? "Validate"}
            </Button>
            <StatusIcon status={status} />
          </span>
        )}
      </div>
    </Context.Provider>
  );
});

export default ServerSideValidationWrapper;
