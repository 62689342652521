import React from "react";
import { action, computed, observable, reaction } from "mobx";
import { observer } from "mobx-react";
import classNames from "classnames";
import "../../styles/popover.scss";
import TextField from "@mui/material/TextField";

export type DropdownProps = {
  selectedItems: any[];
  menu: (text: string) => React.ReactNode;
  multi?: Boolean;
};

export function elementOffsetTop(el: any) {
  let top = Math.abs(el.offsetTop);

  while (el.offsetParent) {
    el = el.offsetParent;
    top += el.offsetTop;
  }

  return top;
}

export function elementInViewport(el: HTMLElement) {
  const top = elementOffsetTop(el);
  const height = el.offsetHeight;

  return top + height < window.pageYOffset + window.innerHeight && top + height > window.pageYOffset;
}

@observer
export class Dropdown extends React.Component {
  props: DropdownProps;
  _element: HTMLInputElement | null;
  _popupElement: HTMLElement;
  _reaction: () => void;

  @observable _showMenu: boolean;
  @observable _filterText: string;
  @observable _popupToTop: boolean = false;

  constructor(props: DropdownProps) {
    super(props);
    this.registerToSelectedItemsChanges(props);
  }

  componentWillReceiveProps(nextProps: DropdownProps) {
    this.registerToSelectedItemsChanges(nextProps);
  }

  registerToSelectedItemsChanges(props: DropdownProps) {
    this.deregisterFromSelectedItemsChanges();

    if (!props.multi) {
      this._reaction = reaction(
        () => props.selectedItems.length,
        action(() => (this._showMenu = false))
      );
    }
  }

  deregisterFromSelectedItemsChanges() {
    if (this._reaction) {
      this._reaction();
    }
  }

  componentWillUnmount() {
    this.deregisterFromSelectedItemsChanges();
  }

  @action.bound _renderMenu() {
    this._filterText = this._element.value;
  }

  @action.bound _onBlur(e: React.FocusEvent) {
    const currentTarget = e.currentTarget;
    setTimeout(
      action(() => {
        if (!currentTarget.contains(document.activeElement)) {
          this._showMenu = false;
          this._element.value = "";
          this._filterText = "";
        }
      }),
      0
    );
  }

  @action.bound _keyDown(e: React.KeyboardEvent) {
    this._showMenu = e.which !== 27;

    if (e.which === 13) {
      const filterText = this._filterText;
      this._filterText = "";
      setTimeout(
        action(() => {
          this._filterText = filterText;
        }),
        0
      );
    }
  }

  @computed get control() {
    return this.props.menu(this._filterText);
  }

  @action.bound _onShowMenu() {
    this._showMenu = true;

    if (this._popupElement) {
      // See if popup element fits in screen (?)
      this._popupToTop = !elementInViewport(this._popupElement.childNodes.item(0) as HTMLElement);
    }
  }

  @action.bound _onPopupContainer(element: HTMLElement) {
    this._popupElement = element;
  }

  render() {
    return (
      <div className="dropdown" onBlur={this._onBlur} tabIndex={0}>
        <div
          className="dropdown-tags"
          role="combobox"
          onClick={() => this._element.focus()}
          aria-expanded={this._showMenu}
        >
          {this.props.selectedItems}
          <TextField
            className="no-style"
            inputRef={(e) => (this._element = e)}
            onFocus={this._onShowMenu}
            onChange={this._renderMenu}
            onKeyDown={this._keyDown}
          />
        </div>

        <div
          className={classNames("popup-container", { "popup-container-hidden": !this._showMenu })}
          ref={this._onPopupContainer}
          hidden={!this._showMenu}
        >
          {this.control}
        </div>
      </div>
    );
  }
}

type PopoverProps = {
  isOpen: boolean;
  overlay: any;
  children: any;
  onBlur: () => void;
  closeOnClick?: boolean;
  className?: string;
};

export const PopoverTip = () => <div className="tip" />;

export class Popover extends React.Component {
  props: PopoverProps;

  static defaultProps = {
    closeOnClick: false,
  };

  constructor(props: PopoverProps) {
    super(props);
    this._onBlur = this._onBlur.bind(this);
    this._close = this._close.bind(this);
  }

  _onBlur(e: React.FocusEvent<HTMLDivElement>) {
    const currentTarget = e.currentTarget;
    setTimeout(() => {
      if (!currentTarget.contains(document.activeElement)) {
        this._close();
      }
    }, 0);
  }

  _close() {
    const onBlur = this.props.onBlur;
    if (onBlur) {
      onBlur();
    }
  }

  render() {
    const clickProps = this.props.closeOnClick ? { onClick: this._close } : {};
    return (
      <div className={classNames("popover-container", this.props.className)} onBlur={this._onBlur} tabIndex={0}>
        {this.props.children}

        <div className={classNames("popover", { hidden: !this.props.isOpen })} {...clickProps}>
          <PopoverTip />
          <div className="popover-content">{this.props.overlay}</div>
        </div>
      </div>
    );
  }
}

type ButtonPopoverProps = {
  children: any;
  overlay: any;
  className?: string;
  closeOnClick?: boolean;
};

@observer
export class ButtonPopover extends React.Component {
  props: ButtonPopoverProps;

  @observable _isOpen: boolean = false;

  @action.bound _toggleMenu(e: React.MouseEvent<HTMLSpanElement>) {
    e.preventDefault();
    e.stopPropagation();

    this._isOpen = !this._isOpen;
  }

  @action.bound _closeMenu() {
    this._isOpen = false;
  }

  render() {
    return (
      <Popover
        overlay={this.props.overlay}
        isOpen={this._isOpen}
        onBlur={this._closeMenu}
        className={this.props.className}
        closeOnClick={this.props.closeOnClick}
      >
        <span onClick={this._toggleMenu}>{this.props.children}</span>
      </Popover>
    );
  }
}
