import { decorateOnChange, FormSelectInputProps } from "../FormInputTypes";
import React, { PropsWithChildren } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { Observer, observer } from "mobx-react";
import { FormContext, WithFormContextProps, FormContextValue, withFormContext } from "../UForm";
import { computed } from "mobx";
import { Environment, EnvironmentResponse } from "../../../environments/EnvironmentsStore";
import { capitalizeFirstLetter } from "../../Utils";
import { EnvironmentWord } from "../../Language";
import classNames from "classnames";
import { SuperSelect } from "../SuperSelect";
import { MultiSelectComponent } from "./MultiSelectComponent";
import { FormFieldRegistry } from "../../FormFieldRegistry";
import { EnvironmentView } from "./EnvironmentItemView";
import { AppContext } from "../../../routes/AppContext";

export interface EnvSelectInputProps extends FormSelectInputProps {
  type?: string;
  replay?: boolean;
}

@(withRouter as any)
@(withFormContext as any)
@observer
export class EnvironmentSelectInput extends React.Component<
  EnvSelectInputProps & RouteComponentProps & WithFormContextProps
> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  componentWillMount() {
    const { input, options } = this.props;
    const validOptions = options.filter((f) => f.environment.environmentType === this.props.type);
    if (validOptions.length === 1) {
      this._innerOnChange(this.props.formContext, validOptions[0].environment.id);
    } else if (input.$value === "") {
      input.sync(null);
    }
  }

  _setCallbackId = (context: FormContextValue, id: any) => {
    if (context) {
      context.formState[this.props.input.path] = id;
    }
  };

  _innerOnChange = (context: FormContextValue, value: any) => {
    const { input, onChange, type } = this.props;

    if (value === "create") {
      this.context.formStore.setSuccessHandler((id) => this._setCallbackId(context, id));

      const reqType = `${capitalizeFirstLetter(type)}EnvironmentRequest`;
      this.props.history.push(`/environments/create/${reqType}`, { pushback: "back" });
    } else {
      decorateOnChange(input, onChange, true)(value);
      input.validate();
    }
  };

  @computed
  get currentEnv(): Environment | null {
    const envRespone =
      this.props.input.$value && this.props.options.find((x) => x.environment.id === this.props.input.$value);
    return envRespone && envRespone.environment;
  }

  render() {
    const { input, options, disabled, placeholder, type, replay } = this.props;

    const environmentOptions: { subtitle?: any; title: string; key: string; type?: string }[] = options
      .filter((f) => f.environment.environmentType === type)
      .map((c: EnvironmentResponse) => ({
        key: c.environment.id,
        title: c.environment.displayData.name,
        subtitle: c.environment,
      }));

    const createTitleNewVerb = environmentOptions.length === 0 ? "Your First" : "A new";

    environmentOptions.push({
      key: "create",
      title: "Create " + createTitleNewVerb + " " + capitalizeFirstLetter(type) + " " + EnvironmentWord.single(),
      type: "create",
    });

    const currentEnv = options.find((f) => f.environment.id === input.$value);
    const showInfo =
      this.context.auth.currentOrganization?.plan?.clazz !== "TrialPlan" &&
      currentEnv &&
      type === "compute" &&
      replay === true;
    const infoClassName = showInfo
      ? "super-select-" + (currentEnv.environment.maxReplayClusterSize ? "info" : "warning")
      : "";
    // const readMore = <a href="http://docs.upsolver.com/Clusters/compute.html">Read more</a>;
    const EditCluster =
      currentEnv &&
      (({ children }: { children: React.ReactChild }) => (
        <Link
          to={{
            pathname: `/environments/view/${currentEnv.environment.id}/edit`,
            state: { pushback: "back" },
          }}
        >
          {children}
        </Link>
      ));

    return (
      <FormContext.Consumer>
        {(context) => {
          return (
            <Observer>
              {() => (
                <div>
                  <div className={classNames(infoClassName, "env-select")}>
                    <SuperSelect
                      automationName={`select-${input.name}`}
                      options={environmentOptions}
                      onChange={(value) => this._innerOnChange(context, value)}
                      value={input.$value}
                      disabled={disabled}
                      placeholder={placeholder}
                      onFocus={input.onFocus}
                      onBlur={input.onBlur}
                      renderItem={EnvironmentView}
                      renderValue={EnvironmentView}
                    />
                    {showInfo &&
                      (currentEnv.environment.maxReplayClusterSize ? (
                        <span
                          className="super-select-info"
                          title={
                            'If you run your task retroactively ("Starting from" option below), your Compute Cluster will ' +
                            "handle the burst of additional tasks with dedicated resources. This feature assures you won't " +
                            "experience delay in other Outputs and Lookup Tables running on this Cluster. " +
                            "To disable this feature and avoid extra charges, Click here to edit your Cluster and set Additional Processing Units for Replay to 0."
                          }
                        >
                          <EditCluster>
                            <span className="icon-info" />
                          </EditCluster>
                        </span>
                      ) : (
                        <span
                          className="super-select-info"
                          title={
                            'If you run your task retroactively ("Starting from" option below), your Compute Cluster will ' +
                            "process a burst of additional tasks, causing possible delays in Outputs and Materialized " +
                            "Views running on this Cluster. To prevent this click here to edit your Cluster and set Additional Processing Units for Replay option to a " +
                            "number of servers greater than 0."
                          }
                        >
                          <EditCluster>
                            <span className="icon-notice" />
                          </EditCluster>
                        </span>
                      ))}
                  </div>
                  {this.currentEnv && !this.currentEnv.running && (
                    <div className="description warning-color">
                      The selected <EnvironmentWord.component lowercase /> is currently stopped. Items deployed using it
                      will only start running when it is started.
                    </div>
                  )}
                </div>
              )}
            </Observer>
          );
        }}
      </FormContext.Consumer>
    );
  }
}

export class MultiEnvironmentSelectInput extends React.Component<FormSelectInputProps> {
  render() {
    return (
      <MultiSelectComponent {...this.props}>
        {(props: any) => {
          const { childInput, setValue, filteredOptions } = props;
          return (
            <EnvironmentSelectInput
              {...props}
              onChange={setValue}
              input={childInput}
              options={filteredOptions}
              type="query"
            />
          );
        }}
      </MultiSelectComponent>
    );
  }
}
export const register = (formFieldRegistry: FormFieldRegistry) => {
  // "ComputeEnvironmentRequest"

  formFieldRegistry.register("replay-compute-environment-select", (props) => (
    <EnvironmentSelectInput {...(props as any)} options={props.input.metadata.options} type="compute" replay />
  ));
  formFieldRegistry.register("compute-environment-select", (props) => (
    <EnvironmentSelectInput {...(props as any)} options={props.input.metadata.options} type="compute" replay={false} />
  ));
  formFieldRegistry.register("query-environment-select", (props) => (
    <EnvironmentSelectInput {...(props as any)} options={props.input.metadata.options} type="query" />
  ));
  formFieldRegistry.register("ingestion-environment-select", (props) => (
    <EnvironmentSelectInput {...(props as any)} options={props.input.metadata.options} type="ingestion" />
  ));
  formFieldRegistry.register("multi-query-environment-select", (props) => (
    <MultiEnvironmentSelectInput {...props} options={props.input.metadata.options} />
  ));
};
