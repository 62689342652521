import { Api } from "./api/Api";
const errHadnlers = { ignoreNoLocalApi: true };
export class UpsolverRemoteStorage<T = Record<string, any>> {
  private readonly op: string;

  constructor(private api: Api, private kind: "user" | "organization") {
    this.op = `preferences/${kind}`;
  }

  get = (): Promise<T> => this.api.get(this.op, null, null, null, errHadnlers);
  upsertKey = (key: keyof T | string, value: any): Promise<T> =>
    this.api.post(`${this.op}/${encodeURIComponent(key.toString())}`, JSON.stringify(value), null, null, errHadnlers);
  removeKey = (key: keyof T | string): Promise<T> =>
    this.api.post(`${this.op}/${encodeURIComponent(key.toString())}`, null, null, null, errHadnlers);
  set = (preferences: T): Promise<T> => this.api.post(this.op, { preferences }, null, null, errHadnlers);
}
