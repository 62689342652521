import React from "react";

export const LimitedText: React.FC<React.HTMLProps<HTMLDivElement> & { limit?: number }> = ({ limit, ...rest }) => (
  <div
    {...rest}
    style={Object.assign(
      { overflowX: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth: limit || 100 },
      rest.style
    )}
  />
);
