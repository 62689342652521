import { computed, observable } from "mobx";
import { FieldMetadata, FieldStates, UField } from "./UField";
import { observer, useObserver } from "mobx-react";
import React, { PureComponent, useContext, useEffect, useMemo } from "react";
import classNames from "classnames";
import isNil from "lodash/isNil";
import { CopyButton } from "../../views/CopyText";
import { fromLastUnescapedDot, splitPath } from "../../Utils";
import keyBy from "lodash/keyBy";
import { FeatureDefinition } from "./FeatureDefinition";
import { InputStore } from "../../../inputs/Store";
import groupBy from "lodash/groupBy";
import { PercentIndicator } from "../../Indicators";
import { addWordBreakMarkers } from "../../views/General";
import { TimeUnits } from "../../views/TimeUnits";
import "../../../styles/field.scss";
import { EllipsisTooltip } from "../../views/EllipsisTooltip";
import { ReactJoin } from "../../views/ReactJoin";
import { createFeatureInput } from "./FeatureInput";
import { GlobalContext } from "../../../GlobalContext";
import { isLookupFeature } from "./isLookupFeature";
import { FeatureMetadata, FeatureProperty } from "../../FeatureMetadata";
import useToggle from "../../hooks/useToggle";
import { useSupportsFeature } from "../../views/SupportedFeatureSwitch";

export const FieldViewContext = React.createContext<UFieldViewStore>(null);

export class UFieldViewStore {
  @observable.ref _selectedField: UField = null;

  @computed
  get selectedFieldKey(): string | null {
    return this._selectedField && UField.key(this._selectedField);
  }

  select(field: UField = null) {
    this._selectedField = field;
  }
}

export function MoreInfoDots(props: { selected?: boolean }) {
  return useObserver(() => <span className={classNames("more-info", { selected: props.selected })} />);
}

export function MoreInfoRangeDots(props: { info: string[]; delay?: number; open?: boolean; selected?: boolean }) {
  const [internalOpen, toggle] = useToggle(false);
  const isOpen = useMemo(() => (isNil(props.open) ? internalOpen : props.open), [internalOpen, props.open]);

  const full = props.info.join(", ");
  return isOpen ? (
    <>
      <span className={classNames("more-info", { selected: props.selected })} onClick={() => toggle()} />
      {addWordBreakMarkers(full)}
    </>
  ) : (
    <EllipsisTooltip
      enterDelay={props.delay || 1}
      placement="top"
      title={
        <>
          {full}
          <CopyButton value={full} />
        </>
      }
    >
      <span>
        {props.info[0]}
        <span className={classNames("more-info", { selected: props.selected })} onClick={() => toggle()} />
        {props.info[props.info.length - 1]}
      </span>
    </EllipsisTooltip>
  );
}

export const PathView = (props: { name: string }): any => props.name.replace(/\\\./g, ".").replace(/\\\[\\\]/g, "[]");
export const FullPathView = (props: { name: string }): any => {
  if (props.name) {
    return ReactJoin(
      splitPath(props.name).map((x) => <PathView name={x} />),
      <>
        <wbr />
        <strong>.</strong>
      </>
    );
  } else {
    return null;
  }
};

type UFieldViewProps = { field?: UField; title?: boolean; full?: boolean; expanded?: boolean; fullName?: string };

/**
 * Default display for a UField instance
 * title - used as a title, doesn't respect selected in FieldViewContext
 * full - render as [[FullPathView]]
 * expanded - use full name or just suffix (data.a.b or b)
 * fullName - use this as full field name instead of UField.getFullName
 * @type {(props: UFieldViewProps) => JSX.Element}
 */
export const UFieldView = observer((props: UFieldViewProps) => {
  const [expanded, toggleExpanded] = useToggle(!!props.expanded);
  useEffect(() => {
    toggleExpanded(props.expanded);
  }, [props.expanded]);

  const toggleExpand = (e: React.MouseEvent<HTMLSpanElement>) => {
    if (e) {
      e.stopPropagation();
    }
    toggleExpanded();
  };

  const fullName = props.fullName || (props.field && UField.getFullName(props.field)) || "";
  let canExpand = false;
  let children: any;
  if (props.full) {
    children = <FullPathView name={fullName} />;
  } else {
    const name = expanded ? fullName : fromLastUnescapedDot(fullName);
    const showMoreInfo = fullName.includes(".") && (expanded || name.length < fullName.length);
    canExpand = showMoreInfo;
    children = (
      <EllipsisTooltip enterDelay={0.5} placement="top" title={fullName}>
        {showMoreInfo && <MoreInfoDots key="info" selected={expanded} />}
        <FullPathView name={name} />
      </EllipsisTooltip>
    );
  }

  return (
    <FieldViewContext.Consumer>
      {(fieldsView) => {
        const cn = classNames("field-view", {
          "selected-field": !fullName && !props.title && fieldsView?.selectedFieldKey === UField.key(props.field),
          expanded: expanded,
          expandable: canExpand,
        });

        return (
          <span key="container" role="button" className={cn} onClick={canExpand ? toggleExpand : undefined}>
            {children}
          </span>
        );
      }}
    </FieldViewContext.Consumer>
  );
});

export class UFieldMultiView extends PureComponent<{ fields: UField[]; separator?: string }> {
  render() {
    return (
      <span className="multi-field-view">
        {this.props.fields
          .map((f, i) => <UFieldView key={`${i}-field`} field={f} />)
          // @ts-ignore
          .reduce((prev, current, index) => [
            prev,
            <span key={index}>{this.props.separator || ","}&nbsp;</span>,
            current,
          ])}
      </span>
    );
  }
}

export const FeatureDisplayNamePart = ({ name }: { name: any }) => <span className="dsl-name">{name}</span>;
export const FeatureDisplayInputPart = ({ inputs, propsView }: { inputs: any[]; propsView?: any }) =>
  inputs.length || propsView ? (
    <React.Fragment>
      ({inputs.length > 0 && <FeatureInputMultiView inputs={inputs} />}
      {propsView && propsView})
    </React.Fragment>
  ) : null;
export const TimeWindowValueView = ({ minutes }: { minutes: number }) => {
  const timeWindow = TimeUnits.FitToWindow(minutes);
  return (
    <span>
      {timeWindow.amount} {timeWindow.unit}
      {timeWindow.amount > 1 && "s"}
    </span>
  );
};
export const TextAreaPropView = ({ value }: { value: string }) => {
  const shortenText = (length: number) => `'${value.length > length ? value.substring(0, length) + "..." : value}'`;
  const textValue = shortenText(50);
  const overlayValue = shortenText(150);
  return (
    <EllipsisTooltip
      placement={"top"}
      title={
        <span>
          {overlayValue} <CopyButton value={value} />
        </span>
      }
    >
      <span>{textValue}</span>
    </EllipsisTooltip>
  );
};
export const typedPropertyValue = (
  name: string,
  value: any,
  schemaType: string,
  editor?: string,
  sequence?: boolean
): any => {
  if (!value) {
    return "";
  }
  if (sequence) {
    const arr = value.slice().map((x: any) => typedPropertyValue(name, x, schemaType, editor, false));
    return (
      <React.Fragment>
        {arr.length > 3 ? <MoreInfoRangeDots info={arr} open={false} /> : <span>{arr.join(", ")}</span>}
      </React.Fragment>
    );
  }
  switch (schemaType) {
    case "Boolean":
      return Boolean(value).toString();
    case "String":
    case "Char":
    // EncryptedString is for backward compatibility (changed to RemotableSecret)
    case "EncryptedString":
    case "RemotableSecret":
    case "InlineSecret":
    case "OperationReference":
      return editor === "textarea" || value.length > 50 ? <TextAreaPropView value={value} /> : `'${value}'`;
    case "FiniteDuration":
      return <TimeWindowValueView minutes={value} />;

    case "TimeFrame":
      return `From: ${value.from} To: ${value.to}`;
    case "Field":
      return UField.getFullName(value);
    case "IndexAggregation":
      return value.name;
    case "FeatureInput":
      return UField.getFullName(value.field);
    case "Double":
    case "Long":
    case "Integer":
    case "Int":
    case "Float":
    default:
      return value.toString();
  }
};
export const getPropertiesPart = (metadata: FeatureMetadata, featureDef: FeatureDefinition): any[] | null => {
  if (isLookupFeature(featureDef.feature)) {
    return null;
  }
  const propertiesValues = getFeaturePropertiesForDisplay(featureDef.feature, metadata);
  const propKeys = Object.keys(propertiesValues);
  const propMetadataMap = keyBy(metadata.properties, "displayName");
  return propKeys.length
    ? propKeys.map((k, idx) => {
        const propMeta = propMetadataMap[k];
        return (
          <span key={idx} className="dsl-args">
            {", "}
            {typedPropertyValue(k, propertiesValues[k], propMeta.schemaType, propMeta.editor, propMeta.sequence)}
          </span>
        );
      })
    : null;
};
export const getFeatureDisplayName = (metadata: FeatureMetadata, dslName: string, featureDef: FeatureDefinition) => (
  <span>
    <FeatureDisplayNamePart name={dslName} />
    <FeatureDisplayInputPart inputs={featureDef.featureInputs} propsView={getPropertiesPart(metadata, featureDef)} />
  </span>
);
export const getFeaturePropertiesForDisplay = (feature: any, metadata: FeatureMetadata): { [key: string]: any } =>
  metadata.properties
    .filter((p) => !p.generated)
    .reduce((a: any, c: FeatureProperty) => {
      const value = feature[c.name];
      if (value) {
        a[c.displayName] = value;
      }
      return a;
    }, {});

export function ExplainFeature(props: { featureDef: FeatureDefinition }) {
  const { metadataStore } = useContext(GlobalContext);
  const dslName = metadataStore.getDslName(props.featureDef);
  const clazz = props.featureDef.feature.clazz;
  if (isLookupFeature(clazz)) {
    return (
      <span>
        <FeatureDisplayNamePart name={dslName} />
        <FeatureDisplayInputPart inputs={props.featureDef.inputs || props.featureDef.featureInputs} />
      </span>
    );
  } else {
    return getFeatureDisplayName(metadataStore.Metadata().featuresMap[clazz], dslName, props.featureDef);
  }
}

export class FeatureInputView extends PureComponent<{ clazz: string; field: UField; literal: any; dsl?: string }> {
  render() {
    if (!this.props.clazz) {
      return <UFieldView field={this.props.field} />;
    }
    const instant = createFeatureInput(this.props.clazz, this.props);
    return instant.displayValue;
  }
}

export class FeatureInputMultiView extends PureComponent<{
  inputs: Array<{ clazz: string; field: UField; literal: any }>;
  separator?: string;
}> {
  render() {
    const inputs = this.props.inputs;
    return (
      <span className="dsl-args">
        {inputs.length &&
          inputs
            .map((input, i) => <FeatureInputView key={"inner-" + i} {...input} />)
            // @ts-ignore
            .reduce((prev, current, index) => [
              prev,
              <span key={index}>{this.props.separator || ","}&nbsp;</span>,
              current,
            ])}
      </span>
    );
  }
}

export const InputFieldStateIndicator: React.FC<{ inputs: FieldMetadata[] }> = ({ inputs }) => {
  let percent = 1;
  let className = "field-state-indicator";
  if (!inputs || !inputs.length || inputs.every((x: FieldMetadata) => x.state.clazz === "FoundInData")) {
    return null;
  } else if (inputs.some((x: FieldMetadata) => x.state.clazz === "FoundInData")) {
    percent = 0.3;
  } else {
    percent = 0;
  }
  return <PercentIndicator className={className} percent={percent} />;
};

// this takes the entire field so it can observe changes in output without making the container observable as well
export const OutputFieldStateIndicator: React.FC<{ field: UField }> = observer(({ field }) => {
  const newVersion = useSupportsFeature("running-output-fields");
  if (newVersion) {
    const output = field?.output;
    if (output != null && output.state.clazz !== "FoundInData") {
      return (
        <EllipsisTooltip
          placement="right-end"
          title="Field statistics not yet available; if you've just deployed the Output, it might take a few minutes to collect the relevant data."
        >
          <PercentIndicator className={"field-state-indicator"} percent={0} />
        </EllipsisTooltip>
      );
    } else {
      return null;
    }
  } else {
    const output = field?.output;
    if (!output || output.state.clazz !== "NotFound") {
      return null;
    } else {
      return (
        <EllipsisTooltip
          placement="right-end"
          title="Field statistics not yet available; if you've just deployed the Output, it might take a few minutes to collect the relevant data."
        >
          <PercentIndicator className={"field-state-indicator"} percent={0} />
        </EllipsisTooltip>
      );
    }
  }
});
const stateNames: { [key: string]: string } = {
  FoundInData: "Found in",
  FoundInSchema: "Only in schema",
  NotFound: "Missing from",
};
const getInputNames = (type: FieldStates, groups: { [key: string]: FieldMetadata[] }, store: InputStore) => {
  const list = groups[type] || [];
  const filteredList = list
    .map((x) => {
      const name = store.names[x.id];
      return name && (name.length > 30 ? name.substr(0, 30) + "..." : name);
    })
    .filter((x) => x);

  return filteredList.length > 0 && stateNames[type] + ":\n\t" + filteredList.join("\n\t");
};
export const inputFieldStateToolTip = (
  inputs: FieldMetadata[] | null,
  defaultValue: string,
  inputsStore: InputStore
) => {
  if (!inputs || !inputs.length || inputs.every((x: FieldMetadata) => x.state.clazz === "FoundInData")) {
    return defaultValue;
  } else {
    const groups: { [key: string]: FieldMetadata[] } = groupBy(inputs, (x: FieldMetadata) => x.state.clazz);
    const description = Object.keys(stateNames)
      .map((key) => getInputNames(key as FieldStates, groups, inputsStore))
      .filter((f) => f)
      .join("\n\n");
    return `${defaultValue}\n\n${description}`;
  }
};
