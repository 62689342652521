import React from "react";
import classNames from "classnames";
// generate colors
// let red = 208; // red start D9
// let green = 0;
// let stepSize = 20 //how many colors
//
// function intToHex(i) {
//     var hex = parseInt(i).toString(16);
//     return (hex.length < 2) ? "0" + hex : hex;
// }
//
//
// while (green < 255) {
//     green += stepSize;
//     if (green > 255) {
//         green = 255;
//     }
//     console.log(`'#${intToHex(red)}${intToHex(green)}${intToHex(0)}',`)
// }
// while (red > 0) {
//     red -= stepSize;
//     if (red < 0) {
//         red = 0;
//     }
//     console.log(`'#${intToHex(red)}${intToHex(green)}${intToHex(0)}',`);
// }

const percentColors = [
  "#ff1400",
  "#ff2800",
  "#ff3c00",
  "#ff5000",
  "#ff6400",
  "#ff7800",
  "#ff8c00",
  "#ffa000",
  "#ffb400",
  "#ffc800",
  "#ffdc00",
  "#fff000",
  "#ffff00",
  "#ebff00",
  "#d7ff00",
  "#c3ff00",
  "#afff00",
  "#9bff00",
  "#87ff00",
  "#73ff00",
  "#5fff00",
  "#4bff00",
  "#37ff00",
  "#23ff00",
  "#0fff00",
  "#00ff00",
];

export const PercentIndicator = ({
  percent,
  iconName,
  className,
}: {
  percent: number;
  iconName?: string;
  className?: string;
}) => {
  const color = percentColors[Math.ceil(percent * (100 % percentColors.length))];
  return <span className={classNames(`icon-${iconName ? iconName : "uniE928"}`, className)} style={{ color }} />;
};
