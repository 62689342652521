import { observer } from "mobx-react";
import React from "react";
import { decorateOnChange, FormSelectInputProps } from "../FormInputTypes";
import { computed, set } from "mobx";
import { MultiSuperSelect, Select } from "../SuperSelect";
import { FormFieldRegistry } from "../../FormFieldRegistry";

@observer
class FormSelectInput extends React.Component<FormSelectInputProps> {
  constructor(props: FormSelectInputProps) {
    super(props);
    if (!props.input.metadata) {
      props.input.metadata = {};
    }
    set(props.input.metadata, {
      loading: props.input.metadata.loading || false,
      disabled: props.input.metadata.disabled || false,
    });
  }

  _getValue() {
    const getInitialValue = (value: any) =>
      !value || value === "" || typeof value.slice === "undefined" ? value : value.slice();
    const { input, options } = this.props;
    if (input.initial && (input.initial === input.$value || !input.$value) && options && options.length > 0) {
      if (input.touched && input.$value === null) {
        return null;
      }
      if (options[0].id) {
        const valueFromOptions = options.find((o) => o.id === input.initial);
        return valueFromOptions ? valueFromOptions.id : this.props.allowCreate ? input.initial : null;
      } else {
        return getInitialValue(input.$value);
      }
    } else {
      return getInitialValue(input.$value);
    }
  }

  @computed
  get value() {
    return this._getValue();
  }

  @computed
  get options(): any[] {
    return this.props.options.map((opt) =>
      typeof opt.label === "undefined" ? { key: opt, title: opt } : { key: opt.id, title: opt.label }
    );
  }

  changeHandler = (payload: any) => {
    const { multiple, onChange, input } = this.props;
    let value = payload;
    if (multiple && payload && payload.length && payload[0].value) {
      value = payload.map((x: any) => x.value);
    } else if (payload && payload.value) {
      value = payload.value;
    }
    decorateOnChange(input, onChange, true)(value);
    input.validate();
  };

  render() {
    const { input, disabled, placeholder, multiple, allowCreate } = this.props;

    const finalOptions = {
      disabled: input.metadata.disabled || disabled || input.metadata.loading,
      loading: input.metadata.loading,
      name: input.name,
      placeholder: placeholder ? placeholder : input.label,
      onChange: this.changeHandler,
      value: multiple ? input.values() : this.value,
      multi: multiple,
      menuBuffer: 50,
      onFocus: input.onFocus,
      onBlur: input.onBlur,
      options: this.options,
      allowCreate,
      selectValue: allowCreate || multiple,
    };

    const SelectView: any = multiple ? MultiSuperSelect : Select;
    return <SelectView {...finalOptions} automationName={this.props.input.path} />;
  }
}

const componentFactory = (props: FormSelectInputProps) => (
  <FormSelectInput {...props} options={props.input.metadata.options} />
);
export const register = (formFieldRegistry: FormFieldRegistry) => {
  formFieldRegistry.register("select", componentFactory);
  formFieldRegistry.register("searchable-select", componentFactory);
  formFieldRegistry.register("searchable-select-create", (props) =>
    componentFactory(Object.assign(props, { allowCreate: true }))
  );
};

export default FormSelectInput;
