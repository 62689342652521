import { action, computed, IObservableArray, observable } from "mobx";
import { fromPromise, IPromiseBasedObservable } from "mobx-utils";
import { Api } from "../core/api/Api";
import { CustomField, FeatureDefinition } from "../core/api/contracts/FeatureDefinition";
import { PipelineOperation } from "../core/api/contracts/PipelineOperation";
import { InputPreviewResponse, InputStatistics } from "../ops-dashboard/Store";
import { PipelineVersion } from "../core/api/contracts/Pipeline";
import { InputChangeset } from "./InputChangesets";
import { IndexTemplate } from "../core/api/contracts/IndexTemplates";
import { Auth } from "../auth/Auth";
import { TypeName } from "../core/api/contracts/TypeName";
import { UField } from "../core/api/contracts/UField";
import { MetadataStore } from "../core/Metadata";
import orderBy from "lodash/orderBy";
import { Range } from "../core/views/time-range/TimeRangeModel";
import { Column, GridData } from "../core/api/contracts/GridData";
import { FieldPresentation } from "../core/api/contracts/FieldPresentation";
import { AllFields, OutputLink } from "../core/api/contracts/templates";
import { deserializeAllFieldsPart } from "../templates/common/deserializeAllFieldsPart";
import { enrichSamplesGrid, TableSampleResponse } from "../core/api/contracts/TableSampleResponse";
import {
  InputDataPoint,
  InputFieldInspection,
  InputInspection,
  RecordInspectionResult,
} from "../core/api/contracts/InputInspection";
import { UpsolverLocalStorage } from "../core/UpsolverLocalStorage";

const e = encodeURIComponent;

export const ColumnTypes = {
  FieldColumnType: "FieldColumnType",
  RelativeTimeColumnType: "RelativeTimeColumnType",
  BooleanColumnType: "BooleanColumnType",
  LimitedNumberColumnType: "LimitedNumberColumnType",
  StringArrayColumnType: "StringArrayColumnType",
  TypeNameColumnType: "TypeNameColumnType",
  SparsityColumnType: "SparsityColumnType",
  NumberColumnType: "NumberColumnType",
  TimeColumnType: "TimeColumnType",
  DateColumnType: "DateColumnType",
  StringColumnType: "StringColumnType",
  SelectColumnType: "SelectColumnType",
};

export type CloudStorageInputFile = {
  name: string;
  included: boolean;
  modifiedDate: string;
  patternDate?: string;
  usedDate?: string;
};

type CustomFieldTableSample = {
  inputId: string;
  customFields: FeatureDefinition[];
  fields: UField[];
};

export type References = {
  pipelines: PipelineVersion[];
  templates: IndexTemplate[];
};

export function inputHasShowSparseEnabled(inputId: string) {
  return (
    Object.entries(UpsolverLocalStorage.get().showSparseFields || {}).findIndex(([k, v]: any) => k === inputId && v) >
    -1
  );
}

export const showSparseFieldsQueryValues = { minFieldPercent: 0, minFieldCount: 0 };

/**
 * Adds show sparse fields query params to query if it's defined in the user preferences;
 * @param {string} id
 * @param query
 * @returns {any}
 */
export function withSparse(id: string, query: any = {}) {
  return inputHasShowSparseEnabled(id) ? Object.assign({}, query, showSparseFieldsQueryValues) : query;
}

export function getInputMetadataClazz(input: any) {
  return input?.classDefinition?.className || input.requestType || input.clazz + "Request";
}

export type ReloadInputsListOptions = { allWorkspaces?: boolean };

function fillEmptyInspectionPointMutate(points: InputDataPoint[]) {
  points.forEach((point) => {
    point.events = point.events ?? 0;
    point.failures = point.failures ?? 0;
    if (typeof point.time == "number") {
      point.time = (point.time * 1000) as never;
    }
  });
}

export class InputStore {
  apiOp: string = "inputs/";
  @observable public initialized = false;

  @observable listFilter: string = "";
  @observable listTypeFilter: string = "";
  @observable eventTypeValue: string = "";

  constructor(private readonly api: Api, private readonly auth: Auth, private metadataStore: MetadataStore) {}

  withEventTypeFilter = (query: any = {}) => {
    return this.eventTypeValue === "" || !this.eventTypeValue
      ? query
      : Object.assign({}, query, { eventType: this.eventTypeValue });
  };
  @observable list: IObservableArray<PipelineOperation & any> = observable.array();

  @computed
  get names(): { [key: string]: string } {
    return this.list.reduce((a, c) => {
      a[c.id] = c.displayData.name;
      return a;
    }, {});
  }

  @action
  create(values: any) {
    if ((!values.workspaces || values.workspaces.length === 0) && this.api.workspace) {
      values.workspaces = [this.api.workspace];
    }
    return this.api.post(this.apiOp, values);
  }

  @computed
  get options(): any[] {
    const metadata = this.metadataStore.Metadata().inputs;
    const activeInputs = this.list.filter((x) => x.status.clazz !== "Deleting");
    if (activeInputs.length === 0) {
      return [];
    }
    return orderBy(activeInputs, (x) => x.displayData.name.toLowerCase(), "asc").map((i: PipelineOperation) => {
      const logoClazz = getInputMetadataClazz(i);
      const byClazz = metadata.byClazz[logoClazz];
      const iconOverride = byClazz?.iconOverride;
      const option: any = {
        key: i.id,
        title: i.displayData.name,
        subtitle: i.displayData.creationTime,
      };
      if (iconOverride) {
        option.logoMetadata = byClazz;
      } else {
        option.logo = logoClazz;
      }
      return option;
    });
  }

  @action.bound
  reloadApi(options: ReloadInputsListOptions = {}) {
    const listUri = this.metadataStore.Metadata().supports("lean-inputs-list") ? this.apiOp + "ui/list" : this.apiOp;
    return this.api.get(listUri, null, null, null, null, options.allWorkspaces).then(
      (response) => {
        this.initialized = true;
        this.list.replace(response);
        return this.list;
      },
      (err) => {
        this.list.replace([]);
        throw err;
      }
    );
  }

  @action.bound
  getMany(ids: string[], options: ReloadInputsListOptions = {}) {
    return this.api
      .post(`${this.apiOp}get`, ids, null, null, null, null, options.allWorkspaces)
      .then((inputs: PipelineOperation[]) => {
        inputs.forEach((input) => {
          const index = this.list.findIndex((x) => x.id === input.id);
          if (index > -1) {
            this.list[index] = input;
          } else {
            this.list.push(input);
          }
        });
        return inputs;
      });
  }

  @action
  reload(): IPromiseBasedObservable<any[]> {
    return fromPromise(this.reloadApi());
  }

  @action
  getAll() {
    return this.reloadApi();
  }

  listArchived = () => {
    return this.api.get(this.apiOp + "?archived=true");
  };

  remove(id: string, force: boolean = false, payload: any) {
    return this.api.patch(this.apiOp + "delete/" + e(id), payload, { force });
  }

  archive(id: string, force: boolean = false) {
    return this.api.post(this.apiOp + "archive/" + e(id), null, { force });
  }

  abortDeleting(id: string) {
    return this.api.post(`${this.apiOp}stop-deleting/${e(id)}`);
  }

  restore(id: string) {
    return this.api.post(this.apiOp + "restore/" + e(id));
  }

  getName(id: any): Promise<string> {
    return this.api.get(this.apiOp + e(id)).then((input) => input.displayData.name);
  }

  inputInspection(
    id: string,
    options: Partial<{ width: string; errHandlers: any; timeRangeProps: any; query: object }> = {}
  ): Promise<InputInspection> {
    const { timeRangeProps, width, errHandlers, query } = options;
    const q = {
      ...withSparse(
        id,
        this.withEventTypeFilter((timeRangeProps && timeRangeProps.start && timeRangeProps.end && timeRangeProps) || {})
      ),
      ...query,
    };
    return this.api.get(`inspection/input/inspect/${e(id)}/${e(width)}`, null, q, null, errHandlers);
  }

  inputRecordInspection(id: string, recordKey: string, timeRangeProps: any): Promise<RecordInspectionResult> {
    const query = (timeRangeProps && timeRangeProps.start && timeRangeProps.end && timeRangeProps) || {};
    return this.api
      .get(
        `inspection/input/record/${id}/${e(recordKey)}/`,
        null,
        withSparse(id, this.withEventTypeFilter(query)),
        null
      )
      .then((x) => {
        x.grid.columns.forEach((x: Column, i: number) => (x.index = i));
        return x;
      });
  }

  inputRecordInspectionExportUrl(id: string, recordKey: string): string {
    return `inspection/input/record/export/${id}/${e(recordKey)}/`;
  }

  buildOperationFieldInspectionUrl = (
    operation: "input" | "template",
    id: string,
    fieldName: string,
    fieldType: string
  ) => `inspection/${operation}/field/${e(id)}/${e(fieldName)}/${e(fieldType)}`;

  buildFieldInspectionUrl = (id: string, fieldName: string, fieldType: string) =>
    this.buildOperationFieldInspectionUrl("input", id, fieldName, fieldType);

  fieldInspection(
    id: string,
    fieldName: string,
    fieldType: string,
    timeRangeParams: any = {},
    take?: number,
    skip?: number,
    onlyDistributions: boolean = true
  ): Promise<InputFieldInspection> {
    const query = { onlyDistributions, ...((timeRangeParams.start && timeRangeParams.end && timeRangeParams) || {}) };

    if (take) {
      query["distribution.take"] = take;
    }

    if (query && Number.isInteger(skip)) {
      query["distribution.skip"] = skip;
    }

    return this.api.get(this.buildFieldInspectionUrl(id, fieldName, fieldType), null, this.withEventTypeFilter(query));
  }

  templateFieldInspection(
    id: string,
    fieldName: string,
    fieldType: string,
    timeRangeParams: any = {},
    take?: number,
    skip?: number,
    onlyDistributions: boolean = true
  ): Promise<InputFieldInspection> {
    const query = { onlyDistributions, ...((timeRangeParams.start && timeRangeParams.end && timeRangeParams) || {}) };

    if (take) {
      query["distribution.take"] = take;
    }

    if (query && Number.isInteger(skip)) {
      query["distribution.skip"] = skip;
    }

    return this.api.get(
      this.buildOperationFieldInspectionUrl("template", id, fieldName, fieldType),
      null,
      this.withEventTypeFilter(query)
    );
  }

  fieldLifeTimeInspection(inputId: string, field: UField): Promise<InputFieldInspection> {
    return this.api.get(this.buildFieldInspectionUrl(inputId, field.name, field.nativeType.name), null, {
      "distribution.take": 1000,
    });
  }

  downloadFieldInspection(inputId: string, field: UField) {
    return (
      "inspection/input/field/" +
      e(inputId) +
      "/" +
      e(field.name) +
      "/" +
      e(field.nativeType.name) +
      "/distribution.csv"
    );
  }

  getCreateTableStatement(pipelineId: string, version: string, operationId: string): Promise<ApiResult> {
    return this.api.get(
      "inspection/operation/createAthenaTable/" + e(pipelineId) + "/" + e(version) + "/" + e(operationId)
    );
  }

  samplesInspection(id: string, range: any, items?: number): Promise<any[]> {
    return this.api.get(
      "inspection/input/rawsample/" + e(id),
      null,
      Object.assign({}, range.start && range.end && range, items && { items })
    );
  }

  parseErrorsInspection(id: string, range: any) {
    return this.api.get("inspection/input/errorsample/" + e(id), null, range.start && range.end && range);
  }

  contentTypePreview(contentType: any) {
    return this.api.post(this.apiOp + "content-type-preview", contentType);
  }

  uploadFile(token: string, file: File): Promise<any> {
    const uriToken = token ? "?token=" + e(token) : "";
    return this.api.upload(this.apiOp + "upload/" + uriToken, file);
  }

  cloudStoragePreview(formValues: any): Promise<{ datePattern?: string; files: CloudStorageInputFile[] }> {
    return (
      this.api
        .post(this.apiOp + "list", formValues, null, null, {
          500: () => {},
        })
        // backwards compatability
        .then((response) => (Array.isArray(response) ? { files: response } : response))
    );
  }

  count(archived: boolean = false): Promise<number> {
    return this.api.get(this.apiOp, null, { archived }).then((values: any[]) => values?.length ?? 0);
  }

  listWithDashboard(archived: boolean = false, timeRange: any, abort: AbortSignal): Promise<InputStatistics[]> {
    return this.api.get("dashboard/inputs", null, Object.assign({ archived }, timeRange), null, null, false, false, abort).then((items) => {
      if (this.metadataStore.Metadata().supports("lean-inputs-list")) {
        if(!items) {
          return [];
        }
        items.forEach((item) => {
          fillEmptyInspectionPointMutate(item.operation.points);
        });
        return items;
      } else {
        return items;
      }
    });
  }

  getMultiWithDashbord(ids: string[], timeRange?: any): Promise<InputStatistics[]> {
    return this.api.post("dashboard/inputs/ids", ids, timeRange).then((items) => {
      if(!items) {
        return [];
      }
      if (this.metadataStore.Metadata().supports("lean-inputs-list")) {
        items.forEach((item) => {
          fillEmptyInspectionPointMutate(item.operation.points);
        });
        return items;
      } else {
        return items;
      }
    });
  }

  getMultiWithDashbord2(
    ids: string[],
    timeRangeProps?: any,
    unsupportedHandler?: () => void
  ): Promise<InputStatistics[]> {
    const query = (timeRangeProps.start && timeRangeProps.end && timeRangeProps) || null;
    return this.api
      .post("dashboard/inputs/ids2", ids, query, null, unsupportedHandler && { 405: unsupportedHandler })
      .then((items) => {
        if(!items) {
          return [];
        }
        if (this.metadataStore.Metadata().supports("lean-inputs-list")) {
          items.forEach((item) => {
            fillEmptyInspectionPointMutate(item.operation.points);
          });
          return items;
        } else {
          return items;
        }
      });
  }
  getWithDashboard(id: string, errHandler: any): Promise<InputStatistics> {
    return Promise.all([
      this.api.get(`dashboard/inputs/${e(id)}`, null, null, null, errHandler),
      this.api.get(`${this.apiOp}${e(id)}`, null, null, null, errHandler),
    ]).then(([item, input]: [InputStatistics, InputStatistics["operation"]["operation"]]) => {
      if (this.metadataStore.Metadata().supports("lean-inputs-list")) {
        item.operation.operation = { ...item.operation.operation, ...input };
        fillEmptyInspectionPointMutate(item.operation.points);
        return item;
      } else {
        return item;
      }
    });
  }

  tableSample(
    inputId: string,
    fields: FieldPresentation[],
    additionalCustomFields: FeatureDefinition[],
    timeRangeProps?: Range,
    long: boolean = true
  ): Promise<GridData> {
    if ((!fields || !fields.length) && (!additionalCustomFields || !additionalCustomFields.length)) {
      return Promise.resolve({ columns: [], data: [] });
    }
    const query = (timeRangeProps && timeRangeProps.start && timeRangeProps.end && timeRangeProps) || null;
    return this.api
      .post(
        "inspection/input/tablesample/grid",
        { inputId, fields, additionalCustomFields },
        Object.assign({ items: long ? 1000 : 10 }, query)
      )
      .then((x: TableSampleResponse) =>
        enrichSamplesGrid(
          x,
          fields.concat(additionalCustomFields.map((x: any) => ({ field: x.field.field || x.field })))
        )
      );
  }

  createCustomField = (inputId: string, featureDefinition: FeatureDefinition): Promise<CustomField> => {
    return this.api.post("customfields/" + e(inputId), featureDefinition);
  };

  updateCustomField = (inputId: string, featureDefinition: FeatureDefinition): Promise<CustomField> => {
    return this.api.put("customfields/" + e(inputId), featureDefinition);
  };

  deleteCustomField(inputId: string, featureId: string): Promise<void> {
    return this.api.remove(`customfields/${e(inputId)}/${e(featureId)}`);
  }

  getCustomFields(inputId: string): Promise<CustomField[]> {
    return this.api.get("customfields/" + e(inputId));
  }

  references(inputId: string, includeCompleted: boolean): Promise<References> {
    return this.api.get(this.apiOp + "running/" + e(inputId), null, { includeCompleted });
  }

  start(inputId: string): Promise<any> {
    return this.api.post(this.apiOp + "start?input=" + inputId);
  }

  stop(inputId: string): Promise<any> {
    return this.api.post(this.apiOp + "stop?input=" + inputId);
  }

  preview(input: any): Promise<InputPreviewResponse> {
    return this.api.post(this.apiOp + "preview/", input);
  }

  rename(id: string, name: string): Promise<PipelineOperation> {
    return this.api.patch(`${this.apiOp}rename/${id}`, null, { name });
  }

  setRetention(id: string, retention?: number): Promise<PipelineOperation> {
    return this.api.patch(`${this.apiOp}update-retention/${id}`, { retention: retention });
  }

  patch(inputId: string, changeset: InputChangeset): Promise<PipelineOperation> {
    return this.api.patch(`${this.apiOp}${inputId}`, changeset);
  }

  links(id: string): Promise<OutputLink[]> {
    return this.api.get(`${this.apiOp}links/${id}`);
  }

  fields(inputs: string[]): Promise<AllFields> {
    const op = `${this.apiOp}fields/`;
    return this.api.post(op, { inputs }, this.withEventTypeFilter()).then((x) => deserializeAllFieldsPart("inputs", x));
  }

  setInputEventType(id: string, eventTypeField: UField) {
    return this.patch(id, { clazz: "SetEventType", eventTypeField });
  }

  removeInputEventType(id: string) {
    return this.patch(id, { clazz: "RemoveEventType" }).then(() => {
      this.eventTypeValue = "";
    });
  }

  getMulti(ids: string[]): Promise<PipelineOperation[]> {
    return this.api.post(this.apiOp + "get", ids);
  }
}

type ApiResult = {
  value: string;
};

export { UField, TypeName };
