import React from "react";
import { values } from "mobx";
import classNames from "classnames";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioButton from "@mui/material/Radio";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";
import { decorateOnChange, FormSelectInputProps } from "../FormInputTypes";
import FormItemDecorator from "./FormItemDecorator";
import { UFormField } from "../UFormField";
import { FormFieldRegistry } from "../../FormFieldRegistry";
import { Field as MField } from "../../type-definitions/mobx-react-form";

export type FormChoicesInputProps = {
  options: Array<{ value: any; title: string; description: string }>;
};

export function FormInputChoice(props: {
  checked: boolean;
  onClick: () => void;
  option: { title: string; description?: string };
  extraField?: React.ReactNode;
}) {
  return (
    <FormControlLabel
      className={classNames("clickable Choice", { checked: props.checked })}
      onClick={props.onClick}
      control={
        <RadioButton
          data-automation-name={`${props.option.title}-choice-radio`}
          color="primary"
          checked={props.checked}
          onChange={props.onClick}
        />
      }
      label={
        <Box display="flex">
          <Box display="inline" className={classNames("choices-logo", props.option.title)} />
          <Box display="inline" style={{ textTransform: "none" }}>
            <Typography variant="h6">{props.option.title}</Typography>
            <Typography variant="body1">{props.option.description}</Typography>
            {props.extraField!}
          </Box>
        </Box>
      }
      labelPlacement="end"
    />
  );
}

export const FormChoicesInput: React.FC<FormSelectInputProps & FormChoicesInputProps> = observer((props) => {
  const input = props.input;
  const extraFieldsByChoice = values(input.fields)
    .map((x) => ({ k: x.name, v: x }))
    .reduce((a: Record<string, MField>, c) => {
      a[c.k] = c.v;
      return a;
    }, {});
  return (
    <FormItemDecorator input={input} hideLabel={props.hideLabel ?? true}>
      <div className={classNames("form-input-group FormChoices", input.name)}>
        {props.options.map((o, i) => {
          const choose = () => decorateOnChange(input, props.onChange, true)(o.value);
          const extraField = extraFieldsByChoice[o.value];
          const checked = input.$value === o.value;
          return (
            <FormInputChoice
              checked={checked}
              onClick={choose}
              option={o}
              extraField={extraField && <UFormField hideLabel input={extraField} />}
            />
          );
        })}
      </div>
    </FormItemDecorator>
  );
});
export const register = (formFieldRegistry: FormFieldRegistry) =>
  formFieldRegistry.register(
    "choices",
    (props) => <FormChoicesInput {...props} options={props.input.metadata.options} />,
    {
      decorate: false,
    }
  );
