import React, { PropsWithChildren } from "react";
import { observer } from "mobx-react-lite";
import { UFormFieldProps } from "../views/UFormField";

function hasSingleOption(options: any) {
  if (!options) {
    return false;
  }
  return Array.isArray(options) && options.length === 1;
}

/**
 * Hides a form field if there is hideOnSingle metadata and there is only 1 item in the options arrray
 */
const HideOnSingle: React.FC<PropsWithChildren<UFormFieldProps>> = observer(({ input, children }) => {
  const hidden = input.metadata?.editorInformation?.hideOnSingle && hasSingleOption(input.metadata?.options);
  if (hidden) {
    return <span hidden={hidden}>{children}</span>;
  } else {
    return children;
  }
});

export default HideOnSingle;
