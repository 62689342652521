import React from "react";
import { AwsIntegrationLaunch } from "./aws-views";
import { MissingIntegrationException } from "./MissingIntegrationException";
import { MissingIntegrationModal } from "./MissingIntegrationModal";

export interface AnyExternalIntegration {
  clazz: string;
}
export interface ExternalIntegrationState {
  externalIntegration: AnyExternalIntegration;
  url?: string;
  payload?: object;
}

export const integrationStates: { [key: string]: IntegrationState } = {
  STARTED: "STARTED",
  COMPLETED: "COMPLETED",
  REQUIRED: "REQUIRED",
  INITIAL: "INITIAL",
};

export const methodsOrDefault = (methods: string): string[] => (methods ? methods.split("|") : ["basic"]);

export type IntegrationState = "STARTED" | "COMPLETED" | "REQUIRED" | "INITIAL";

type Mode = "modal" | "page";
export const integrationViews = (exception: MissingIntegrationException): React.ReactNode => {
  if (exception.payload.clazz === "AwsIntegrationRequest") {
    return (options: { mode: Mode }) => {
      return options?.mode === "modal" ? null : <AwsIntegrationLaunch />;
    };
  } else {
    return () => <MissingIntegrationModal {...exception} />;
  }
};
