import { UField } from "./api/contracts/UField";
import { FieldTreeEntry } from "./views/TreeItem";
import { findNextDot } from "./Utils";
import { getFieldsTreeFeaturePrefix } from "./TreeEntityUtils";

export const FastFieldsSplitter = (
  fields: UField[],
  contributedNodes: Record<string, FieldTreeEntry[]> = {},
  splitInputs: boolean = false,
  inputsContrib: Record<string, FieldTreeEntry[]>
): FieldTreeEntry[] => {
  const retVal: FieldTreeEntry[] = [];
  const itemByKey: { [key: string]: FieldTreeEntry } = {};

  const getKey = (item: UField, prefix?: string): [string, string] => {
    const fieldKey = UField.key(item);
    const key = prefix ? prefix + "." + fieldKey : fieldKey;
    return [key, fieldKey];
  };

  const handleItem = (item: UField, getKey: (field: UField) => [string, string], input?: string) => {
    let [key, fieldKey] = getKey(item);
    let parentKey = null;
    let parentItem: FieldTreeEntry = null;
    let pushed = false;

    let indexOfDot = findNextDot(key, 0);
    while (indexOfDot !== -1) {
      parentKey = key.substr(0, indexOfDot);
      indexOfDot = findNextDot(key, parentKey ? parentKey.length + 1 : 0);

      let existingItem: FieldTreeEntry = itemByKey[parentKey];
      if (!existingItem) {
        const inputParent = parentKey === input && parentKey;
        existingItem = {
          label: parentKey,
          key: parentKey,
          children: [],
          input: inputParent,
        };

        if (inputParent) {
          const contrib = inputsContrib[inputParent];
          if (contrib) {
            existingItem.children.push(...contrib);
          }
        }

        if (!pushed) {
          retVal.push(existingItem);
        }
        itemByKey[parentKey] = existingItem;

        if (parentItem !== null) {
          parentItem.children.push(existingItem);
          parentItem.label = parentItem.key;
        }
      }

      parentItem = existingItem;
      pushed = true;
    }

    const fieldItem = {
      label: getKey(item)[1],
      key: key,
      field: item,
    };

    if (pushed) {
      parentItem.children.push(fieldItem);
      const contrib = contributedNodes[key];
      if (contrib) {
        parentItem.children.push(...contrib);
      }
    } else {
      retVal.push(fieldItem);
    }
  };
  fields.forEach((item) => {
    if (splitInputs) {
      if (item.inputs) {
        item.inputs
          .filter((input) => input.state.clazz !== "NotFound")
          .forEach((input) => {
            handleItem(item, (x) => getKey(x, input.id), input.id);
          });
      } else if (item.feature) {
        handleItem(item, (x) => getKey(x, getFieldsTreeFeaturePrefix(item)));
      } else if (item.output) {
        handleItem(item, (x) => getKey(x, "Output Fields"), "Output Fields");
      } else {
        handleItem(item, getKey);
      }
    } else {
      handleItem(item, getKey);
    }
  });

  return retVal;
};
export const FieldsSplitter = (
  fields: UField[],
  labelFn: (field: UField) => any,
  parentLabelFn: (key: string, items?: FieldTreeEntry[]) => any
): FieldTreeEntry[] => {
  const retVal: FieldTreeEntry[] = [];
  const itemByKey: { [key: string]: FieldTreeEntry } = {};

  fields.forEach((item) => {
    const key = item.key || UField.key(item);
    let parentKey = null;
    let parentItem: FieldTreeEntry = null;
    let pushed = false;

    let indexOfDot = findNextDot(key, 0);
    while (indexOfDot !== -1) {
      parentKey = key.substr(0, indexOfDot);
      indexOfDot = findNextDot(key, parentKey ? parentKey.length + 1 : 0);

      let newParentItem: any = itemByKey[parentKey];
      if (!newParentItem) {
        newParentItem = {
          label: parentLabelFn(parentKey),
          key: parentKey,
          children: [],
        };

        if (!pushed) {
          retVal.push(newParentItem);
        }
        itemByKey[parentKey] = newParentItem;

        if (parentItem !== null) {
          parentItem.children.push(newParentItem);
          parentItem.label = parentLabelFn(parentItem.key, parentItem.children);
        }
      }

      parentItem = newParentItem;
      pushed = true;
    }

    const fieldItem = {
      label: UField.key(item), //labelFn(item),
      key: key,
      field: item,
    };

    if (pushed) {
      parentItem.children.push(fieldItem);
    } else {
      retVal.push(fieldItem);
    }
  });

  return retVal;
};
