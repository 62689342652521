import { FormInputProps } from "../FormInputTypes";
import { observer } from "mobx-react";
import React from "react";
import { action, autorun, computed, observable, runInAction, values } from "mobx";
import { AdvancedCollapse } from "../AdvacnedViews";
import { capitalizeFirstLetter } from "../../Utils";
import { Spin } from "../Spin";
import { Button } from "../Button";
import { UFormField } from "../UFormField";

interface FormGroupInputProps extends FormInputProps {
  vertical?: boolean;
  noCollapse?: boolean;
  compact?: boolean;
}

const advanced = "Advanced";

@observer
class FormGroupInput extends React.Component<FormGroupInputProps> {
  _subscription: () => void;

  @observable _localShowContent: boolean = false;
  @observable _isLoading: boolean;

  @computed
  get _isAdvancedView() {
    const { input } = this.props;
    return input.key === advanced || input.label === advanced;
  }

  @computed
  get _showContent(): boolean {
    return (
      (this.props.noCollapse && !this._isAdvancedView) ||
      this._localShowContent ||
      this.props.input.metadata.openContainer
    );
  }

  @computed
  get _hasVisibleChildren(): boolean {
    return values(this.props.input.fields).findIndex((f) => f.metadata && !f.metadata.hidden) > -1;
  }

  set _showContent(value: boolean) {
    runInAction("setLocalShowContent", () => {
      this._localShowContent = value;
    });
  }

  @action.bound
  _toggleShowContent() {
    this._localShowContent = !this._localShowContent;
    this.props.input.metadata.openContainer = this._localShowContent;
  }

  constructor(props: FormGroupInputProps) {
    super(props);
    this._isLoading = props.input.loading === true;
    this._subscription = autorun(() => {
      const hasError = values(props.input.fields).findIndex((f) => f.hasError) !== -1;
      if (hasError) {
        this._showContent = true;
      }
    });
  }

  componentWillUnmount() {
    this._subscription();
  }

  render() {
    const { input, vertical } = this.props;

    if (this._isAdvancedView) {
      if (!this._hasVisibleChildren) {
        return false;
      }

      return (
        <AdvancedCollapse toggle={this._toggleShowContent} open={this._showContent}>
          {values(input.fields).map(
            (f) =>
              !f.metadata.hidden && (
                <UFormField vertical={vertical} key={f.key} input={f} compact={this.props.compact} />
              )
          )}
        </AdvancedCollapse>
      );
    }

    return (
      <div>
        {!this.props.noCollapse ||
          (this._hasVisibleChildren && input.label.length > 1 && (
            <h2>
              {!this.props.noCollapse && (
                <div className="caret" onClick={action(() => (this._showContent = !this._showContent))} />
              )}
              {this._hasVisibleChildren && input.label.length > 1 && capitalizeFirstLetter(input.label)}
            </h2>
          ))}
        {input.error && (
          <span data-automation-name={`error-text-${input.name}`} className="error-text">
            {input.error}
          </span>
        )}
        {this._showContent && (
          <Spin spinning={this._isLoading} delay={100}>
            {input.metadata.remove && <Button type="primary" small onClick={(e) => input.metadata.remove(e)} />}

            {values(input.fields).map((f, i) => (
              <UFormField vertical={vertical} key={i} input={f} compact={this.props.compact} />
            ))}

            {input.metadata.add && (
              <Button type="primary" onClick={(e) => input.metadata.add(e)}>
                Add
              </Button>
            )}
          </Spin>
        )}
      </div>
    );
  }
}

export default FormGroupInput;
