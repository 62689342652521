import React, { ChangeEvent, KeyboardEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import TextField from "@mui/material/TextField";
import ArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import CheckIcon from "@mui/icons-material/CheckCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import { FormAutocompleteInputProps } from "./Autocomplete";
import { FormFieldRegistry } from "../../FormFieldRegistry";
import { EllipsisTooltip } from "../EllipsisTooltip";

import "../../../styles/inputs/preview-list.scss";
import { withFormContext } from "../UForm";
import { useAutocomplete } from "../../hooks/useAutocomplete";
import { decorateOnChange } from "../FormInputTypes";
import { MissingAutoCompleteFieldError } from "../../BaseForm";
import { Button } from "../Button";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import useRunOnSuccessfulValidation from "./useRunOnSuccessfulValidation";

interface PreviewResponse {
  id: string;
  displayValue: string;
  isIncluded: boolean;
}

const PreviewItem = React.forwardRef(
  ({ id, displayValue, isIncluded }: PreviewResponse, ref: React.LegacyRef<HTMLSpanElement>) => {
    const className = isIncluded ? "item include" : "item";

    return (
      <div className={className} key={id}>
        {isIncluded ? <CheckIcon /> : <HighlightOffIcon />}
        <span ref={ref}>{displayValue}</span>
      </div>
    );
  }
);

function PreviewItemWrapper({ id, displayValue, isIncluded }: PreviewResponse) {
  const [hasOverflow, setHasOverflow] = useState<boolean>();

  const spanRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (spanRef.current) {
      const { offsetWidth, scrollWidth } = spanRef.current;

      if (offsetWidth < scrollWidth) {
        setHasOverflow(true);
      }
    }
  }, [spanRef]);

  return (
    <>
      {hasOverflow ? (
        <EllipsisTooltip placement="top" title={displayValue} enterDelay={1}>
          <PreviewItem id={id} displayValue={displayValue} isIncluded={isIncluded} ref={spanRef} />
        </EllipsisTooltip>
      ) : (
        <PreviewItem id={id} displayValue={displayValue} isIncluded={isIncluded} ref={spanRef} />
      )}
    </>
  );
}

const PreviewList = observer((props: FormAutocompleteInputProps) => {
  const { input, autocompleteMetadata, onChange } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [showList, setShowList] = useState<boolean>(true);

  const { options, loadOptions } = useAutocomplete<PreviewResponse>();

  const _autocompleteMetadata = useMemo(() => {
    return autocompleteMetadata || input.metadata.autocomplete;
  }, [autocompleteMetadata, input.metadata]);

  const toggleShow = () => {
    setShowList(!showList);
  };

  const reload = useCallback(async () => {
    try {
      const context = input.metadata?.autocomplete?.getContext() ?? {};

      setLoading(true);
      await loadOptions(_autocompleteMetadata, context, (item) => item);
      setLoading(false);
    } catch (e) {
      if (e !== MissingAutoCompleteFieldError) {
        throw e;
      }
    }
  }, [loadOptions, input, _autocompleteMetadata]);

  const waitingForValidation = useRunOnSuccessfulValidation(props.input, reload);

  const disabled = input.disabled || waitingForValidation;
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!disabled) {
      decorateOnChange(input, onChange)(e.currentTarget.value);
    }
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();

      reload();
    }
  };

  return (
    <div className="preview-list">
      <div className="preview-list-line">
        <TextField
          disabled={disabled}
          fullWidth
          variant="outlined"
          className={classNames("preview-list-input", { "has-value": !!input.$value })}
          value={input.$value}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
        />
        <div className="preview-list-gap" />
        <Button
          disabled={disabled}
          className="preview-list-execute"
          automationName="request-preview"
          icon={<PlayArrowIcon />}
          type="primary"
          medium
          loading={loading}
          onClick={reload}
        >
          Preview
        </Button>
      </div>
      <div className="preview-list-toggle" onClick={toggleShow}>
        {showList ? <ArrowDownIcon /> : <ArrowRightIcon />}
        Tables included
      </div>
      <div className="preview-list-items">
        {showList && options.length > 0 && options.map((option: PreviewResponse) => <PreviewItemWrapper {...option} />)}
        {showList && options.length === 0 && (
          <div className="item">
            <span>No Data</span>
          </div>
        )}
      </div>
    </div>
  );
});

export const PreviewListWithFormContext = withFormContext(PreviewList);

export const register = (formFieldRegistry: FormFieldRegistry) =>
  formFieldRegistry.register("preview-list", (props) => <PreviewListWithFormContext {...props} />);
