import React, { useContext } from "react";
import { action } from "mobx";
import Slider from "rc-slider";
import { observer } from "mobx-react";
import { Definition, DefinitionList } from "../definition-list/DefinitionList";
import uniq from "lodash/uniq";
import { GlobalContext } from "../../../GlobalContext";
import { FormInputProps, inputOnChange } from "../FormInputTypes";
import { FormFieldRegistry } from "../../FormFieldRegistry";

type SizeInputProps = FormInputProps;

export const sizes = ["XS", "S", "M", "L", "XL", "2XL", "3XL"];

const defaultMinValue = "S";
const defaultMaxValue = "M";

type ProcessingUnitsProps = {
  min: string;
  max?: string;
};

export function ReplaySize(props: { value: string }) {
  const { metadataStore } = useContext(GlobalContext);
  return <>{(props.value && metadataStore.Metadata().environmentSizes[props.value]?.processingUnit) || 0}</>;
}

export class ProcessingUnits extends React.Component<ProcessingUnitsProps> {
  static contextType = GlobalContext;
  context!: React.ContextType<typeof GlobalContext>;
  _descriptions: any;

  componentWillMount() {
    this._descriptions = this.context.metadataStore.Metadata().environmentSizes;
  }

  render() {
    if (!this.props.max && this.props.min) {
      return <span>{this._descriptions[this.props.min]?.processingUnit}</span>;
    }
    const sizes = uniq([this.props.min, this.props.max]);
    const descriptions = sizes.map((f) => this._descriptions[f]);

    return <span>{descriptions.map((x) => (x ? x.processingUnit : 0)).join("-")}</span>;
  }
}

@observer
export class FormSizeInput extends React.Component<SizeInputProps> {
  componentWillMount() {
    const { input } = this.props;

    if (!input.$value) {
      input.set("value", { min: defaultMinValue, max: defaultMaxValue });
    }
  }

  @action.bound _sync(value: any) {
    this.props.input.set("value", { min: sizes[value[0]], max: sizes[value[1]] });
  }

  render() {
    const input = this.props.input;
    const value = input.hasNestedFields ? { min: input.$("min").$value, max: input.$("max").$value } : input.$value;
    return (
      <div ref={this.props.scrollRef}>
        <Slider.Range
          min={0}
          max={6}
          step={1}
          defaultValue={[sizes.indexOf(value.min), sizes.indexOf(value.max)]}
          onChange={this._sync}
          marks={{ 1: "", 2: "", 3: "", 4: "", 5: "" }}
        />
        <div className="form-input-group">
          <DefinitionList>
            <Definition title="">
              <ProcessingUnits {...value} />
            </Definition>
          </DefinitionList>
        </div>
      </div>
    );
  }
}
@observer
export class FormReplaySizeInput extends React.Component<SizeInputProps> {
  componentWillMount() {
    const { input } = this.props;
    if (!input.$value) {
      inputOnChange(this.props.input)(null);
    }
  }

  @action.bound _sync(value: any) {
    inputOnChange(this.props.input)(value >= 0 ? sizes[value] : null);
  }

  render() {
    const input = this.props.input;
    const value = input.$value;
    const indexOfSize = sizes.indexOf(value);
    return (
      <div>
        <Slider
          min={-1}
          max={6}
          step={1}
          value={indexOfSize !== -1 ? indexOfSize : -1}
          onChange={this._sync}
          marks={{ 0: "", 1: "", 2: "", 3: "", 4: "", 5: "" }}
        />
        <div className="form-input-group">
          <DefinitionList>
            <Definition title="">
              <ProcessingUnits min={null} max={value} />
            </Definition>
          </DefinitionList>
        </div>
      </div>
    );
  }
}
export const register = (formFieldRegistry: FormFieldRegistry) => {
  formFieldRegistry.register("size", (props) => {
    props.input.metadata.simple = true;
    return <FormSizeInput {...props} />;
  });
  formFieldRegistry.register("replaySize", (props) => <FormReplaySizeInput {...props} />);
};
