import * as Sentry from "@sentry/browser";

function setUserForCapture({ id, currentOrganizationId }: { id: string; currentOrganizationId?: string }) {
  Sentry.setUser({ id, segment: currentOrganizationId });
}

function clearUserForCapture() {
  Sentry.setUser(null);
}

export { setUserForCapture, clearUserForCapture };
