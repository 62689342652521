import { DefaultTimeZone } from "./UserPreferences";
import moment, { Moment } from "moment";
import { computed, observable, reaction } from "mobx";
import { add, sub } from "date-fns";

type DateTypes = moment.MomentInput;

export class MomentProvider {
  @observable _defaultTimeZone: DefaultTimeZone | null =
    window.globalStores.userPreferences.get().defaultTimeZone || null;

  moment = (): ((value: DateTypes, format?: string) => Moment) =>
    this._defaultTimeZone === "LOCAL"
      ? (v: DateTypes, format?: string) => moment(v, format).local()
      : (v: DateTypes, format?: string) => moment.utc(v, format);

  toTimeZoneMoment(value: DateTypes, format?: string): Moment {
    return this.moment()(value, format);
  }

  set defaultTimeZone(value: DefaultTimeZone) {
    window.globalStores.userPreferences.upsertKey("defaultTimeZone", value);
    this._defaultTimeZone = value;
  }

  @computed
  get defaultTimeZone(): DefaultTimeZone {
    return this._defaultTimeZone || window.globalStores.userPreferences.get().defaultTimeZone || "UTC";
  }

  toggle = (e?: Event) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    this.defaultTimeZone = this._defaultTimeZone === "LOCAL" ? "UTC" : "LOCAL";
    return this.moment();
  };

  /**
   * Manipulate date object to present the time according to the chosen time zone
   * @param momentDate
   */
  convertMomentToDateWithoutTimeZone = (momentDate: Moment) => {
    return new Date(
      momentDate.year(),
      momentDate.month(),
      momentDate.date(),
      momentDate.hour(),
      momentDate.minute(),
      momentDate.second()
    );
  };

  /**
   * Convert reverse manipulation to turn date object back to moment according to time zone
   * @param date
   */
  convertDateWithoutTimeZoneToMoment = (date: Date) => {
    const offsetMinutes = date.getTimezoneOffset();
    return this.moment()(
      this.defaultTimeZone === "LOCAL" ? moment(date) : moment(date).subtract(offsetMinutes, "minute")
    );
  };
}

const DefaultMomentProvider = new MomentProvider();

export class ZonedDateTime {
  _dispose: Function;
  @observable rawISOTime: string;
  @observable rawDateTime: string;

  constructor(initial?: string) {
    this._set(initial);
    this._dispose = reaction(
      () => DefaultMomentProvider.defaultTimeZone,
      () => {
        if (this.rawISOTime) {
          this.rawDateTime = DefaultMomentProvider.moment()(this.rawISOTime).toISOString(true);
        }
      }
    );
  }

  _set(value?: string) {
    if (value) {
      const momentValue = DefaultMomentProvider.moment()(value);
      this.rawISOTime = momentValue.toISOString();
      this.rawDateTime = momentValue.toISOString(true);
    } else {
      this.rawISOTime = undefined;
      this.rawDateTime = undefined;
    }
  }

  set current(value: string) {
    this._set(value);
  }

  @computed
  get current(): string | null {
    return this.rawDateTime;
  }

  /**
   * Manipulate raw date (UTC - ISO): Current browser doesn't support displaying UTC only Local
   * Add timezone offset let us display the UTC date (For display purpose only).
   *
   * e.g. - "2021-07-11T14:08:46.579Z" w/ GMT+3 --> "2021-07-11T17:08:46.579Z"
   */
  @computed
  get fakeFromRaw(): Date | null {
    if (!this.rawISOTime) return null;

    const date = new Date(this.rawISOTime);

    const offset: number = date.getTimezoneOffset();
    const fakeDate: Date = DefaultMomentProvider.defaultTimeZone === "UTC" ? add(date, { minutes: offset }) : date;

    return fakeDate;
  }

  /**
   * After using 'fakeFromRaw()' we need to convert it back to it's raw form
   * @param date - manipulated date
   *
   * e.g. - "2021-07-11T17:08:46.579Z" w/ GMT+3 --> "2021-07-11T14:08:46.579Z"
   */
  static fakeToRaw(date: Date | null) {
    if (!date) return null;

    const offset: number = date.getTimezoneOffset();
    const fakeDate: Date = DefaultMomentProvider.defaultTimeZone === "UTC" ? sub(date, { minutes: offset }) : date;

    return fakeDate;
  }

  dispose() {
    if (this._dispose) {
      this._dispose();
    }
  }
}

export default DefaultMomentProvider;
