import { action, comparer, computed, IObservableArray, observable } from "mobx";
import { IPromiseBasedObservable } from "mobx-utils";
import { Api, apiImpersonateOrganizationHeader, logMiddleware, useFullApi } from "../core/api/Api";
import request from "superagent";
// import { BillingInformation, PaymentInformation } from "./PaymentServlet";
import { GetCookies, HUBSPOT_POST_SIGNUP_URI, HUBSPOT_POST_URI } from "../core/Utils";
import { googleAuthenticationPayload } from "./SocialLogin";
import { Analytics } from "../analytics/analytics";
import {
  EmptyLoginState,
  GoogleSignUpHint,
  isVerificationRequired,
  NewUserRequest,
  ResetUserPasswordRequest,
  User,
} from "../core/api/contracts/User";
import { LocationState } from "history";
import { RequestError } from "../core/api/RequestError";
import { AuthenticationMethod } from "../core/api/contracts/AuthenticationMethod";
import {
  defaultOrganization,
  NewOrganizationRequest,
  Organization,
  organizationExpired,
  UserSignupRequest,
} from "../core/api/contracts/Organization";
import { SamlConfiguration } from "../core/api/contracts/Saml";
import { UpsolverLocalStorage } from "../core/UpsolverLocalStorage";
import { authenticationResultToObject, AzureAuthenticationObject } from "./login/azure/AzureAuthenticationObject";
import { LoginReply } from "../core/api/contracts/login";
import { TrialPlan } from "../core/PlanMetadata";
import { PermissibleUser } from "../core/api/contracts/PermissibleUser";
import { UnsupportedOperationError } from "../core/api/UnsupportedOperationError";

export const impersonateOrganizationKey = "impersonate-organization";

const pathsWithoutVerification = [
  "/login",
  "/verify",
  "/signup",
  "/accept-invite",
  "/forgot-password",
  "/reset-password",
  "/azure-marketplace",
  "/marketplace",
  "/no-local-api",
  "/verification-required",
];

export const populateBICookie = () => {
  const marketingParams = new URL(document.location.href).searchParams;
  const referrer = document.referrer;
  const bi = GetCookies.UpsolverBI();
  const cookie: any = bi.get();
  try {
    JSON.parse(cookie);
    const utmSource = marketingParams.get("utm_source");
    if (utmSource || referrer || !cookie._biSource) {
      if (referrer) {
        cookie._biRef = referrer;
      }
      if (utmSource) {
        cookie._biAd = marketingParams.get("utm_content");
        cookie._biCmp = marketingParams.get("utm_campaign");
        cookie._biKw = marketingParams.get("utm_term");
        cookie._biPlatform = marketingParams.get("utm_source");
        cookie._biSource = marketingParams.get("utm_medium");
      } else if (!referrer) {
        cookie._biSource = "Direct";
      } else {
        const refUrl = new URL(referrer);
        cookie._biPlatform = `${refUrl.protocol}//${refUrl.host}`;
        cookie._biSource =
          referrer.toLowerCase().indexOf("google") !== -1 || referrer.toLowerCase().indexOf("bing") !== -1
            ? (cookie._biSource = "Organic Search")
            : (cookie._biSource = "Referral");
      }
      bi.set(cookie);
    }
  } catch (err) {
    if (err instanceof SyntaxError) {
      bi.clear();
    }
  }
};

/**
 * Seed object for HubSpot form payloads
 * @returns {{hs_context: string}}
 * @constructor
 */
const hubSpotFormSeed = (): Readonly<any> => {
  const hbCookie = GetCookies.HubSpot();
  return Object.freeze({
    hs_context: JSON.stringify({ hutk: hbCookie }),
  });
};

const hubSpotPost = (logger?: any) => (payload: any, uri: string): Promise<unknown> => {
  let superAgentRequest = request.post(uri).type("form");
  if (logger) {
    superAgentRequest = superAgentRequest.use(logger);
  }
  return superAgentRequest
    .send(payload)
    .then((result) => result.body || result.text)
    .catch(() => {});
};

export type HubspotSignupForm = {
  firstname: string;
  lastname: string;
  company: string;
  email: string;
  phone: string;
};
export function hubSpotSignup(formValues: HubspotSignupForm, logger: any): Promise<unknown> {
  return hubSpotPost(logger)(Object.assign({}, formValues, hubSpotFormSeed()), HUBSPOT_POST_SIGNUP_URI);
}

export function useHubSpotInformer() {
  const { _envType } = useFullApi();
  return _envType === "prod"
    ? (user: User, orgName: string) => HubSpotInformer(user, orgName, logMiddleware)
    : () => Promise.resolve();
}

export const HubSpotInformer = (user: User, orgName: string, logger: any) => {
  const formSeed = hubSpotFormSeed();
  const bi = GetCookies.UpsolverBI();
  let biCookie = bi.get();
  if (!biCookie._biSource) {
    populateBICookie();
    biCookie = GetCookies.UpsolverBI().get();
  }
  const names = user.name.split(" ");
  const payload: any = {
    ...formSeed,
    email: user.email,
    phone: user.phone,
    firstname: names[0],
    lastname: names.length > 1 ? names[names.length - 1] : names[0],
    company: orgName,
  };
  if (!biCookie._sent) {
    Object.assign(payload, {
      referral: biCookie._biRef,
      source: biCookie._biSource,
      platform: biCookie._biPlatform,
      adcampaign: biCookie._biCmp,
      ad: biCookie._biAd,
      keyword: biCookie._biKw,
      form: "Trial Form",
    });

    biCookie._sent = true;
    bi.set(biCookie);
  }

  return hubSpotPost(logger)(payload, HUBSPOT_POST_URI);
};

class ManageAuthenticationMethods {
  private auth: Auth;

  constructor(auth: Auth) {
    this.auth = auth;
  }

  googleAttach = (obj: any): Promise<User> => this.auth.attachGoogleAuth(googleAuthenticationPayload(obj));
  azureAttach = (result: any): Promise<User> => this.auth.attachAzureAuth(authenticationResultToObject(result));
  detach = (method: AuthenticationMethod): Promise<User> => this.auth.detachAuthMethod(method);
}

const server_error_no_redirect_paths = ["/login", "/server-error"];

// 6c0a6c7d-d1df-4f68-a57b-35a1a84e18bd - Hive Media
// 9181cf36-c281-4540-9c2f-5f62f20658fe - Limeroad
// 1b834115-5cd1-4939-897e-eda130dafdf8 - Upsolver Demo
const allowedDashboardOrgs = ["6c0a6c7d-d1df-4f68-a57b-35a1a84e18bd", "9181cf36-c281-4540-9c2f-5f62f20658fe"];
const disallowedDashboardOrgs = ["1b834115-5cd1-4939-897e-eda130dafdf8"];

export class Auth {
  _api: Api;
  _browserHistory: any;
  lastState: LocationState;
  private readonly analytics: Analytics;

  @observable currentOrganization: Organization = defaultOrganization;
  @observable dismissedTrial = false;
  reportedStatistics: boolean = false;

  get multiOrgs() {
    return this._api.multiOrgs;
  }

  @computed
  get unsupportedClazzes() {
    return (this.currentOrganization && this.currentOrganization.unsupportedClazzes) || [];
  }

  @observable user: User | null = null;
  @observable loadingUser = true;
  @observable authentication = { loading: false, authenticated: false };

  @computed
  get outOfDate(): boolean {
    return this._api.outOfDate;
  }

  @computed
  get impersonationText(): string | null {
    return this.user?.impostor ? " @ " + this.currentOrganization?.displayData?.name?.substr(0, 6) : "";
  }

  @observable.ref loginState: { original: any; hint: GoogleSignUpHint };

  authenticationMethodManager: ManageAuthenticationMethods;

  constructor(api: Api, browserHistory: any, analytics: Analytics) {
    this._api = api;
    this.analytics = analytics;
    this._browserHistory = browserHistory;
    this.lastState = browserHistory.location;
    this._browserHistory.listen((state: LocationState) => {
      if (state.pathname !== "/login") {
        this.lastState = state;
      }
    });

    this.authenticationMethodManager = new ManageAuthenticationMethods(this);

    this.resetLoginState();
  }

  refreshTrial = () => {
    if (this.currentOrganization?.plan?.clazz === "TrialPlan") {
      if (this.user) {
        this._api.shortGet("organizations").then(
          action((org: Organization) => {
            if (org.plan.clazz === "TrialPlan") {
              if (organizationExpired(this.currentOrganization) && !organizationExpired(org)) {
                window.location.reload();
              }
              (this.currentOrganization.plan as TrialPlan).duration = org.plan.duration;
            }
          })
        );
      }
    }
  };

  resetLoginState = () => {
    this.loginState = EmptyLoginState;
  };

  @action
  async authMethodLogin(
    payload: any,
    kind: string,
    notFound?: (error?: RequestError) => void,
    unAuthorized?: (error?: RequestError) => void
  ): Promise<LoginReply> {
    const reply = await this._api.authenticate(kind, payload, { 404: notFound, 401: unAuthorized });
    const picUrl = payload && payload.profileObj && payload.profileObj.profilePicURL;
    if (picUrl) {
      UpsolverLocalStorage.upsertKey("profilePicUrl", picUrl);
    } else {
      UpsolverLocalStorage.removeKey("profilePicUrl");
    }
    return reply;
  }

  @action.bound
  invalidate(err: any) {
    this._api.clearImpersonate();
    if (isVerificationRequired(err)) {
      this._browserHistory.push(`/verification-required/${encodeURIComponent(err.verificationEmail)}`);
    } else {
      const currentState = this._browserHistory.location;
      if (pathsWithoutVerification.findIndex((path) => currentState.pathname.startsWith(path)) === -1) {
        if (!currentState || !server_error_no_redirect_paths.includes(currentState.pathname)) {
          this._browserHistory.push("/login");
        }
      }
    }
  }

  @action.bound
  setUser(user?: User) {
    this.loadingUser = false;
    if (!comparer.structural(user, this.user)) {
      this.user = user;
    }
  }

  remove(email: string): Promise<User> {
    return this._api.remove("users/" + encodeURI(email));
  }

  removeUsers(userIds: string[]): any {
    return this._api.remove("users/", userIds);
  }

  logout(): Promise<void> {
    if (this.user == null) {
      return;
    }
    return this._api.logout().finally(() => {
      this.lastState = null;
      this.handleBadLogin();
    });
  }

  @action.bound
  handleBadLogin() {
    this.currentOrganization = defaultOrganization;
    this.authentication.authenticated = false;
    this.authentication.loading = false;
    window.supportChat.logout();
    this.setUser();
    this._api.clearImpersonate();
  }

  @action.bound
  changeOrganization(organization: Partial<Organization>): IPromiseBasedObservable<PermissibleUser[]> {
    // @ts-ignore
    return this.listUsers(organization.id).then((users: IObservableArray<User>) => {
      this.currentOrganization = Object.assign(this.currentOrganization, organization);
      this.currentOrganization.users = users;
      return this.currentOrganization.users;
    });
  }

  @action.bound
  listUsers(organization?: string): Promise<PermissibleUser[]> {
    return this._api
      .get("users/list/" + (organization || this.currentOrganization.id))
      .then((userResponses: PermissibleUser[]) => {
        this.currentOrganization.users.replace(
          userResponses.map((ur: any) => ({
            email: ur.info.email,
            name: ur.info.name,
            organization: ur.info.organization,
            role: ur.permissions,
          }))
        );
        return userResponses;
      });
  }

  @action.bound
  updateDefaultStorage(id: string): Promise<string> {
    return this._api
      .post("organizations/defaultstorage", { value: id })
      .then(() => (this.currentOrganization.defaultCloudStorage = id));
  }

  @action.bound refreshOrganization(): Promise<Organization> {
    return this._api.shortGet("organizations").then((org) => {
      this.currentOrganization = org;
      return org;
    });
  }

  @action
  listOrganizations(): Promise<Organization[]> {
    return this._api.get("organizations/list");
  }

  @action.bound
  disableOrganization(id: string): Promise<Organization> {
    return this._api.post("organizations/disable/" + id);
  }

  @action.bound
  enableOrganization(id: string): Promise<Organization> {
    return this._api.post("organizations/enable/" + id);
  }

  @action.bound
  extendTrial(id: string, additionalDays: number): Promise<Organization> {
    return this._api
      .post(`organizations/extend-trial/${id}`, { additionalDays })
      .then((o) => this._api.get("store/cache/clear/organization").then(() => o));
  }

  clearCache = (cacheName: string): Promise<string> => this._api.get(`store/cache/clear/${cacheName}`);

  @action
  createOrganization(req: NewOrganizationRequest): Promise<Organization> {
    return this._api.post("signup/", req);
  }

  @action
  signUp(req: NewOrganizationRequest): Promise<Organization> {
    return this._api.signup(req).then((x) => {
      if (window.gtag) {
        window.gtag("event", "Submit", {
          event_category: "Trial Form",
          event_label: "Success",
        });
      }
      return x;
    });
  }

  @action
  signUpUser(req: UserSignupRequest): Promise<any> {
    return this._api.signupUser(req);
  }

  signUpUserV3(req: UserSignupRequest): Promise<LoginReply> {
    return this._api.signupUserV3(req);
  }

  changePassword(current: string, newPassword: string): Promise<void> {
    const req: ResetUserPasswordRequest = { current: current, newPassword: newPassword };
    return this._api.post("users/reset-password", req);
  }

  inviteUser(req: NewUserRequest) {
    const orgId = this.user && this.user.role === "master" ? this.currentOrganization.id : "";
    return this._api.inviteUser(Object.assign(req, { organizationId: orgId })).then(() => this.listUsers());
  }

  forgotPassword(email: string) {
    return this._api.forgotPassword(email);
  }

  getInvitationDetails(guid: string, onError: () => void) {
    return this._api.getGlobal("signup/invitation-details/" + guid, { 404: onError, 400: onError });
  }

  acceptInvitation(guid: string, password: string) {
    return this._api
      .postGlobal<User>("signup/accept-invitation/" + guid, { password })
      .then((user) => this.authMethodLogin({ email: user.email, password }, "upsolver"));
  }

  acceptInvitationGoogle(guid: string, payload: any) {
    return this._api
      .postGlobal("signup/accept-invitation/google/" + guid, payload)
      .then(() => this.authMethodLogin(payload, "google"));
  }

  acceptInvitationAzure(guid: string, payload: AzureAuthenticationObject) {
    return this._api
      .postGlobal("signup/accept-invitation/azure/" + guid, payload)
      .then(() => this.authMethodLogin(payload, "azure"));
  }

  resendInvitation(id: string) {
    return this._api.post("users/resend-invitation/" + id);
  }

  resendVerification(id: string) {
    return this._api.post("users/resend-verification/" + id);
  }

  onUserOrg = action((org?: Organization, postUrl?: string) => {
    this.currentOrganization = Object.assign(this.currentOrganization, org);
    this.setAuthentication(true);

    if (postUrl) {
      this._browserHistory.push(postUrl);
    } else {
      const lastStatePath = this.lastState?.pathname?.startsWith("/login") ? "/" : this.lastState?.pathname;
      const pathname =
        org.plan.clazz === "FreePlan"
          ? lastStatePath ?? "/home"
          : org.plan.clazz === "EnterprisePlan" || org.cloudIntegrations.length > 0
          ? lastStatePath ?? "/"
          : "/integrations/welcome";
      this._browserHistory.push({ pathname, search: this._browserHistory.location?.search });
    }
  });

  setLoginOrg = (org: Organization, postUrl?: string): Promise<any> => {
    return this._api
      .setLoginOrg(org)
      .catch((err: unknown) => {
        if ((err as RequestError)?.status === 403) {
          return this.logout();
        }
        if (!err || !(err instanceof UnsupportedOperationError)) {
          throw err;
        }
      })
      .then(() => this.onUserOrg(org, postUrl));
  };

  // getBillingInformation(onNotFound: () => void | null): Promise<BillingInformation> {
  getBillingInformation(onNotFound: () => void | null): Promise<any> {
    return this._api.get("organizations/billingInformation", null, null, null, onNotFound ? { 404: onNotFound } : null);
  }

  // getPaymentInformation(onNotFound: () => void | null): Promise<PaymentInformation> {
  getPaymentInformation(onNotFound: () => void | null): Promise<any> {
    return this._api.get("organizations/paymentInformation", null, null, null, onNotFound ? { 404: onNotFound } : null);
  }

  // updateBillingInformation(info: BillingInformation): Promise<BillingInformation> {
  updateBillingInformation(info: any): Promise<any> {
    return this._api.post("organizations/billingInformation", info);
  }

  getCreaditsReqeust(uri: string, organizationId: string = null) {
    return this._api.getGlobal(
      uri,
      null,
      organizationId && { organizationId },
      organizationId && { [apiImpersonateOrganizationHeader]: organizationId }
    );
  }

  creditsOverview(timeRange: string = "lastmonth", organizationId: string): Promise<any> {
    return this.getCreaditsReqeust(`organizations/credits/${timeRange}`, organizationId);
  }

  creditsByEnv(timeRange: string = "lastmonth", organizationId: string): Promise<any> {
    return this.getCreaditsReqeust(`organizations/credits/by-environments/${timeRange}`, organizationId);
  }

  creditsOrders(organizationId: string): Promise<any> {
    return this.getCreaditsReqeust("organizations/orders", organizationId);
  }

  creditsReport(month: string, year: string, organizationId: string): Promise<any> {
    return this._api.download(
      `organizations/credits/report/${year}/${month}`,
      "text/csv",
      null,
      organizationId && { [apiImpersonateOrganizationHeader]: organizationId }
    );
  }

  @action.bound
  downgradeToFree(): Promise<Organization> {
    return this._api
      .post("organizations/plan", { clazz: "FreePlan" })
      .then(action((o: Organization) => (this.currentOrganization = o)));
  }

  getUserAuthenticationMethods(userId: string): Promise<AuthenticationMethod[]> {
    return this._api.get(`authenticate/methods/${userId}`);
  }

  attachGoogleAuth(googleObj: any): Promise<User> {
    return this._api.post("users/attach/google", googleObj);
  }

  attachAzureAuth(payload: any): Promise<User> {
    return this._api.post("users/attach/azure", payload);
  }

  detachAuthMethod(method: AuthenticationMethod): Promise<User> {
    return this._api.post(`authenticate/detach/${method.clazz}`, method);
  }

  addSaml(configuration: SamlConfiguration): Promise<Organization> {
    return this._api
      .post("organizations/saml", configuration)
      .then(action((saml) => Object.assign(this.currentOrganization, { saml })));
  }

  removeSaml(): Promise<Organization> {
    return this._api
      .remove("organizations/saml")
      .then(action(() => Object.assign(this.currentOrganization, { saml: null })));
  }

  getSamlOptions(): Promise<any> {
    return this._api.get("organizations/saml/options");
  }

  zendeskToken(): Promise<string> {
    return this._api.zendeskToken();
  }

  attachAzureSaas(token: string): Promise<string> {
    return this._api.post("organizations/attach-azure-saas", { token });
  }

  @action.bound
  setAuthentication(authenticated: boolean) {
    this.authentication = {
      loading: false,
      authenticated,
    };
  }
}
