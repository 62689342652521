import React, { PropsWithChildren, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState } from "react";
import classNames from "classnames";
import styles from "../../styles/Spin.module.scss";
import { useContainerSize } from "../useContainerSize";

export function useDelayedRender(delay: number, spinning: boolean) {
  const [delayed, setDelayed] = useState(delay > 0);
  useEffect(() => {
    if (delay > 0 && spinning) {
      const timeout = setTimeout(() => setDelayed(false), delay);
      return () => clearTimeout(timeout);
    } else {
      setDelayed(false);
    }
  }, [delay, spinning]);
  return delayed;
}

type SpinProps = PropsWithChildren<{
  spinning?: boolean;
  delay?: number;
  className?: string;
  main?: boolean;
}>;

export const SpinLoader = (props: SpinProps & { loading: boolean }) => <Spin {...props} spinning={props.loading} />;

export function Spin(props: SpinProps) {
  const delayed = useDelayedRender(props.delay, props.spinning);
  const spin = Boolean(props.spinning && !delayed);
  const [el, setEl] = useState<HTMLDivElement>();
  const [paddingTop, setPaddingTop] = useState(0);
  const size = useContainerSize(el);

  useLayoutEffect(() => {
    if (size?.height) {
      setPaddingTop(Math.max(size.height * 0.25, 0));
    }
  }, [size]);

  return (
    <>
      {spin ? (
        <div
          title="Loading..."
          ref={setEl}
          style={{ paddingTop }}
          className={classNames({ [styles.Spin]: true, [styles.Main]: props.main }, props.className)}
        >
          <div className={styles.Spinner} />
        </div>
      ) : (
        props.children
      )}
    </>
  );
}

(Spin as React.FC).defaultProps = {
  delay: 300,
  main: true,
  spinning: true,
};
