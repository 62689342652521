import React from "react";
import { Button } from "../core/views/Button";
import classNames from "classnames";
import { action, observable } from "mobx";
import { observer } from "mobx-react";
import { Auth } from "../auth/Auth";
import { SiteWideNotification } from "../SiteWideNotification";
import Typography from "@mui/material/Typography";
import { organizationExpired } from "../core/api/contracts/Organization";

const dayEpoch = 1000 * 60 * 60 * 24;

export const upgradeViaChat = () => {
  window.supportChat.sendMessage("Hi, I would like to upgrade my Upsolver account.");
};

@observer
export class Trial extends React.Component<{ auth?: Auth }> {
  _interval: any;

  @observable now: Date = new Date();

  componentDidMount() {
    this._interval = setInterval(
      action(() => {
        this.now = new Date();
      }),
      10000
    );
  }

  clear = () => {
    if (this._interval) {
      clearInterval(this._interval);
    }
  };

  dismiss = () => {
    this.props.auth.dismissedTrial = true;
    this.clear();
  };

  componentWillUnmount() {
    this.clear();
  }

  render() {
    const currentOrganization = this.props.auth.currentOrganization;

    if (currentOrganization?.plan?.clazz !== "TrialPlan" || !organizationExpired(currentOrganization)) {
      return false;
    }

    const { plan } = currentOrganization;

    const startTime = plan.startTime;
    if (!startTime) {
      return false;
    }

    const startTimeEpoch = Date.parse(startTime);
    const days = plan.duration / 1440;
    const now = this.now.getTime();

    const progressChildren = [];
    let numberOfDaysLeft = days;
    for (let i = 1; i <= days; i++) {
      const time = startTimeEpoch + i * dayEpoch;
      const nextTime = startTimeEpoch + (i + 1) * dayEpoch;
      if (now > time) {
        numberOfDaysLeft--;
      }
      const className = classNames({ completed: now > time, last: now < nextTime || i === days });
      progressChildren.push(<span key={i} className={className} />);
    }

    if (numberOfDaysLeft >= 3) {
      return null;
    }
    return (
      <SiteWideNotification
        onDismiss={this.dismiss}
        notificationAction={
          <Button type="primary" medium onClick={upgradeViaChat}>
            Upgrade
          </Button>
        }
      >
        {numberOfDaysLeft < 4 && (
          <Typography variant="h6">
            {numberOfDaysLeft <= 1
              ? "This is your last day of free trial"
              : `Your free trial is almost over. You have ${Math.ceil(numberOfDaysLeft)} days remaining`}
          </Typography>
        )}
      </SiteWideNotification>
    );
  }
}
