import * as React from "react";
import { useContext } from "react";
import { ValidationError } from "../core/api/contracts/FeatureDefinition";
import { observer } from "mobx-react";
import { AppContext } from "../routes/AppContext";
import { integrationStates } from "./integrations-store";
import { IntegrationLaunch } from "./aws-views";
import { Button, FlatLinkButton } from "../core/views/Button";
import tail from "lodash/tail";
import { capitalizeFirstLetter } from "../core/Utils";
import { integrationLauncher } from "./IntegrationLauncher";

type GenericIntegrationParams = {
  payload?: { clazz: string };
  title?: React.ReactNode;
  name?: string;
  blurb?: string;
  children?: any;
  onLaunch?: Function;
  onClose?: () => void;
  onValidationError?: (error: ValidationError) => void;
  methods: any[];
  showTitle?: boolean;
  onDone?: () => void;
};
export const GenericIntegration: React.FC<GenericIntegrationParams> = observer((props) => {
  const context = useContext(AppContext);
  const { name, title, showTitle, blurb, payload, children, onLaunch, onClose, methods, onValidationError } = props;
  const { integrations } = context;
  const checking = integrations.state.current === integrationStates.STARTED;
  const completed = integrations.state.current === integrationStates.COMPLETED;
  const closeCallback = onClose || (() => integrations.done());
  return (
    <IntegrationLaunch
      title={showTitle && (title || <h1>{`${name} Integration`}</h1>)}
      blurb={blurb}
      checking={checking}
      completed={completed}
      actions={
        <>
          <Button
            type="primary"
            onClick={
              completed
                ? props.onDone || closeCallback
                : onLaunch
                ? (e) => onLaunch(e, methods[0])
                : () => integrationLauncher(integrations, payload, onValidationError, methods[0])
            }
            overlay={
              !completed &&
              methods.length > 1 && (
                <div className="popover">
                  <div className="popover-content">
                    <ul>
                      {tail(methods).map((method) => (
                        <li key={method}>
                          <FlatLinkButton
                            onClick={
                              onLaunch
                                ? (e) => onLaunch(e, method)
                                : () => integrationLauncher(integrations, payload, onValidationError, method)
                            }
                          >
                            {capitalizeFirstLetter(method)}
                          </FlatLinkButton>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )
            }
          >
            {completed ? "Done" : `${checking ? "Relaunch" : "Launch"} Integration`}
          </Button>
          <Button type="cancel" disabled={completed} onClick={closeCallback}>
            Cancel
          </Button>
        </>
      }
    >
      {children}
    </IntegrationLaunch>
  );
});

GenericIntegration.defaultProps = {
  showTitle: true,
};
