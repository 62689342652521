export const globalApiAddress = "api.upsolver.com";
export function getApiHost(envType: string = process.env.REACT_APP_ENV_TYPE): string {
  switch (envType) {
    case "prod":
    case "demo":
    case "daily-staging":
      return globalApiAddress;
    case "prod-gateway":
      return "api-gateway-prod.upsolver.com";
    case "integ":
      return "api-integ.upsolver.com";
    case "integ-gateway":
      return "api-gateway-integ.upsolver.com";
    case "e2e":
    case "integ-e2e":
      return "api-integ-e2e.upsolver.com";
    case "local-gateway":
    case "local":
    default:
      return window.location.hostname;
    // return "https://api-" + window.location.hostname;
  }
}

export type ApiSchema = "http" | "ws";

export function getApiSchema(envType: string = process.env.REACT_APP_ENV_TYPE, schema: ApiSchema = "http"): string {
  switch (envType) {
    case "prod":
    case "demo":
    case "daily-staging":
    case "prod-gateway":
    case "integ":
    case "integ-gateway":
    case "e2e":
    case "integ-e2e":
      return `${schema}s`;
    case "local-gateway":
    case "local":
    default:
      return `${schema}`;
  }
}
export const localApiPort = 8080;
export const localApiGateWayPort = 8081;

export function getApiPort(envType: string = process.env.REACT_APP_ENV_TYPE): number | null {
  if (envType === "local") {
    return localApiPort;
  }
  if (envType === "local-gateway") {
    return localApiGateWayPort;
  }

  return null;
}

export function globalApiLocation(schema: ApiSchema = "http") {
  const envType = process.env.REACT_APP_ENV_TYPE;
  const port = getApiPort(envType);
  const secureSchema = getApiSchema(envType, schema);
  const host = getApiHost(envType);
  const url = `${secureSchema}://${host}`;
  return port == null ? url : `${url}:${port}`;
}

export function globalWebSocketsApiLocation() {
  return globalApiLocation("ws");
}

function apiLocation(apiHost: string, schema: ApiSchema) {
  const envType = process.env.REACT_APP_ENV_TYPE;
  const port = getApiPort(envType);
  const secureSchema = getApiSchema(envType, schema);
  const url = `${secureSchema}://${apiHost}`;
  // the host we get from the local dev api is localhost:8080
  return port == null || apiHost.includes(":") ? url : `${url}:${port}`;
}

export function webSocketsApiLocation(apiHost: string) {
  return apiLocation(apiHost, "ws");
}

export function httpApiLocation(apiHost: string) {
  return apiLocation(apiHost, "http");
}

export function getGlobalApiFallback(envType: string = process.env.REACT_APP_ENV_TYPE): string {
  const getHost = () => {
    switch (envType) {
      case "prod":
        return "cl-mt-api-prod.upsolver.com";
      case "e2e":
      case "integ-e2e":
        return "cl-mt-api-integ-e2e.upsolver.com";
      case "local":
      default:
        return window.location.hostname;
      // return "https://api-" + window.location.hostname;
    }
  }
  return apiLocation(getHost(), "http");
}

export default globalApiLocation;
