import SocialLogin from "react-social-login";
import React from "react";

export const OrSeparator = () => (
  <div className="or-separator">
    <span />
    <p>Or</p>
    <span />
  </div>
);

class WrappedComponent extends React.Component<any> {
  render() {
    const { children, triggerLogin, ...rest } = this.props;
    return (
      <div onClick={triggerLogin} {...rest} role="button">
        {children}
      </div>
    );
  }
}
const SocialButton = SocialLogin(WrappedComponent);

export type GoogleLoginButtonProps = {
  onSuccess: (result: any) => void;
  onFailure?: (result: any) => void;
  text?: string;
};

export const googleAuthenticationPayload = (obj: any) =>
  Object.assign({}, obj.token, {
    tokenId: obj.token.idToken,
    profileObj: Object.assign({}, obj.profile, {
      googleId: obj.profile.id,
      imageUrl: obj.profile.profilePicURL,
    }),
  });

export class GoogleLoginButton extends React.Component<GoogleLoginButtonProps, { remount: boolean }> {
  //hack to re-mount on failure until there's a better fix for popup closed error from googleAttach's window
  state = { remount: false };

  fail = (e: any) => {
    if (this.props.onFailure) {
      this.props.onFailure(e);
    }
    this.setState({ remount: true }, () => this.setState({ remount: false }));
  };

  render() {
    const { text, onSuccess } = this.props;
    if (this.state.remount) {
      return null;
    }
    return (
      <SocialButton
        className="button-google"
        provider="google"
        scope={["email", "profile"] as any}
        appId="89754441866-b76mt1p19ju8vs4aac7otqb0kgc007oq.apps.googleusercontent.com"
        onLoginSuccess={onSuccess}
        onLoginFailure={this.fail}
      >
        <div>
          <div className="google-logo" />
        </div>
        <div className="text">{text || "Log in with Google"}</div>
      </SocialButton>
    );
  }
}
