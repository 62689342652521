import { useCallback, useContext } from "react";
import { GlobalContext } from "../GlobalContext";

/**
 * Send user event to tracking
 * @param {string} eventName
 * @returns {(properties?: any) => void}
 */
function useUserTrackingEvent(eventName: string) {
  const { analytics } = useContext(GlobalContext);

  return useCallback(
    (properties: any = {}) => {
      analytics.trackEvent(eventName, properties);
    },
    [eventName]
  );
}

export default useUserTrackingEvent;
