import React from "react";
import { action } from "mobx";
import { observer } from "mobx-react";
import classNames from "classnames";
import Checkbox from "@mui/material/Checkbox";
import { EntryData } from "./Tree2";

export type Key = string;

type SelfType<T, U> = T & U;
export type TreeEntry<TValue = object, TKey = Key> = SelfType<
  {
    readonly key: TKey;
    readonly children?: SelfType<TreeEntry<TValue, TKey>, TValue>[];
  },
  TValue
>;

export type TreeProps<TEntry> = {
  entries: TreeEntry<TEntry>[];
  onExpand: (entry: TreeEntry, expanded: boolean) => void;
  onSelect: (entry: TreeEntry) => void;
  onDoubleSelect: (payload: any) => void;
  onCheck: (entry: TreeEntry<TEntry>, checked?: boolean) => void;

  checkedKeys: Key[];
  selectedKey?: Key;
  expandedKeys: Key[];

  filteredEntries: Set<Key>;

  renderEntry: any;

  checkable: boolean;

  className?: string;
  loadData?: boolean;
  showLine?: boolean;
  style?: any;
};

const arrayToMap = (keys: Key[]) => {
  const retVal: Record<Key, boolean> = {};
  keys.forEach((key) => (retVal[key] = true));
  return retVal;
};

@observer
export default class Tree extends React.Component<TreeProps<any>> {
  @action.bound
  _clickEntry(entry: TreeEntry<any>, e: React.MouseEvent<HTMLInputElement>) {
    const clickedOnCheckbox = e.currentTarget.type === "checkbox";
    e.stopPropagation();
    if (!clickedOnCheckbox) {
      if (entry.children && entry.children.length) {
        this.props.onExpand(entry, !this.props.expandedKeys.includes(entry.key));
      } else {
        this.props.onSelect(entry);
      }
    }
  }

  @action.bound
  _toggleExpand(entry: TreeEntry<any>, e: React.MouseEvent<HTMLSpanElement>) {
    e.stopPropagation();
    this.props.onExpand(entry, !this.props.expandedKeys.includes(entry.key));
  }

  @action.bound
  _toggleCheck(entry: TreeEntry<any>, e: React.ChangeEvent<HTMLInputElement>) {
    e.stopPropagation();
    if (this.props.onCheck && this.props.checkedKeys) {
      this.props.onCheck(entry, !this.props.checkedKeys.includes(entry.key));
    }
  }

  _descedantsChecked(entry: TreeEntry<any>, checkedKeysMap: any, func: Function): boolean {
    if (entry.children) {
      return func.call(entry.children, (e: TreeEntry<any>) => this._descedantsChecked(e, checkedKeysMap, func));
    } else {
      return checkedKeysMap[entry.key] === true;
    }
  }

  //TODO: ts-convert what type is filteredEntries?
  _renderTree(nodes: TreeEntry<any>[], expandedKeysMap: any, checkedKeysMap: any, filteredEntries: any) {
    const selectedKey = this.props.selectedKey;
    const checkable = this.props.checkable === true;
    const doubleClickProps = this.props.onDoubleSelect ? { onDoubleClick: this.props.onDoubleSelect } : {};
    return (
      <ul style={this.props.style}>
        {nodes.map((entry) => {
          const hasChildren = entry.children ? entry.children.length > 0 : false;
          const key = entry.key;
          const selected = key === selectedKey;
          const allDescendantsChecked = this._descedantsChecked(entry, checkedKeysMap, Array.prototype.every);
          const anyDescendantsChecked = this._descedantsChecked(entry, checkedKeysMap, Array.prototype.some);
          const markedAsChecked = checkedKeysMap[key] === true;
          return (
            <li
              key={key}
              className={classNames({
                submenu: hasChildren,
                close: !(expandedKeysMap[key] === true),
                selected: !hasChildren && selected,
                "selected-parent": hasChildren && selected,
                hidden: !filteredEntries.has(entry),
              })}
            >
              <span>
                {hasChildren && <span className="icon-arrow-down" onClick={this._toggleExpand.bind(this, entry)} />}
                {this.props.renderEntry(
                  entry,
                  this._clickEntry.bind(this, entry),
                  doubleClickProps,
                  checkable && (
                    <Checkbox
                      color="primary"
                      indeterminate={(markedAsChecked || anyDescendantsChecked) && !allDescendantsChecked}
                      checked={markedAsChecked || allDescendantsChecked}
                      onChange={this._toggleCheck.bind(this, entry)}
                    />
                  )
                )}
              </span>
              {hasChildren && this._renderTree(entry.children, expandedKeysMap, checkedKeysMap, filteredEntries)}
            </li>
          );
        })}
      </ul>
    );
  }

  render() {
    const { entries, expandedKeys, filteredEntries, checkedKeys } = this.props;
    const expandedKeysMap = arrayToMap(expandedKeys || []);
    const checkedKeysMap = arrayToMap(checkedKeys || []);

    return this._renderTree(entries, expandedKeysMap, checkedKeysMap, filteredEntries);
  }
}
