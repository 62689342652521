export interface UserProperties {
  role: string;
  impostor: boolean;
  name: string;
  phone: string;
  envType: string;
  company?: string;
}

export interface OrganizationProperties {
  name: string;
  plan: string;
  envType: string;
}

export const EVENT_IDS = {
  CREATE_OUTPUT: "CREATE_OUTPUT",
};

export interface Analytics {
  kind: string;
  identifyUser: (id: string, properties: UserProperties) => void;

  organization: (id: string, properties: OrganizationProperties) => void;

  trackEvent: (id: string, properties?: any) => void;

  page: (category: string, name: string, properties?: any) => void;
}

export class SegmentIOAnalytics implements Analytics {
  public readonly kind: string = "SegmentIOAnalytics";
  identifyUser(id: string, properties: UserProperties) {
    window.analytics.identify(id, properties);
  }

  organization(id: string, properties: OrganizationProperties) {
    window.analytics.group(id, properties);
  }

  page(category: string, name: string, properties?: any) {
    window.analytics.page(category, name, properties);
  }

  trackEvent(id: string, properties?: any) {
    window.analytics.track(id, properties);
  }
}

export class ConsoleAnalytics implements Analytics {
  public readonly kind: string = "ConsoleAnalytics";
  identifyUser(id: string, properties: UserProperties) {
    console.log("identify", id, properties);
  }

  organization(id: string, properties: OrganizationProperties) {
    console.log("org", id, properties);
  }

  page(category: string, name: string, properties?: any) {
    console.log("page", name, properties);
  }

  trackEvent(id: string, properties?: any) {
    console.log("trackEvent", id, properties);
  }
}

export class ApiAnalyticsProxy implements Analytics {
  public readonly kind: string = "ApiAnalyticsProxy";
  constructor(
    private post: (properties: unknown, eventDetails: unknown) => Promise<void>,
    private extraProperties: Record<string, unknown> = {}
  ) {}
  identifyUser(id: string, properties: UserProperties): void {}

  organization(id: string, properties: OrganizationProperties): void {}

  page(category: string, name: string, properties: any): void {}

  trackEvent(event: string, properties: any): void {
    void this.post(
      { ...properties, ...this.extraProperties },
      {
        event,
        originalTime: new Date().toISOString(),
      }
    );
  }
}

export class AnalyticsPropagator implements Analytics {
  public readonly kind: string = "AnalyticsPropagator";
  constructor(private analyticsServices: Analytics[] = []) {}

  /**
   * Sets the tracker for this tracker.kind
   * @param {Analytics} tracker
   */
  setTracker(tracker: Analytics) {
    this.analyticsServices = this.analyticsServices.filter((x) => x.kind !== tracker.kind).concat(tracker);
    return () => {
      this.analyticsServices = this.analyticsServices.filter((x) => x !== tracker);
    };
  }

  identifyUser(id: string, properties: UserProperties): void {
    this.analyticsServices.forEach((x) => x.identifyUser(id, properties));
  }

  organization(id: string, properties: OrganizationProperties): void {
    this.analyticsServices.forEach((x) => x.organization(id, properties));
  }

  page(category: string, name: string, properties: any): void {
    this.analyticsServices.forEach((x) => x.page(category, name, properties));
  }

  trackEvent(id: string, properties: any): void {
    this.analyticsServices.forEach((x) => x.trackEvent(id, properties));
  }
}

async function informUserGuiding(email?: string) {
  if (window.userGuiding?.identify && email) {
    const encoder = new TextEncoder();
    const data = encoder.encode(email);
    const hash = await crypto.subtle.digest("SHA-256", data);
    const payloadText = Array.from(new Uint8Array(hash))
      .map((b) => b.toString(16).padStart(2, "0"))
      .join("");
    window.userGuiding.identify(payloadText);
  }
}

export class UserGuiding implements Analytics {
  public readonly kind: string = "UserGuiding";

  identifyUser(id: string, properties: UserProperties): void {
    informUserGuiding(id);
  }

  organization(id: string, properties: OrganizationProperties): void {}

  page(category: string, name: string, properties: any): void {}

  trackEvent(id: string, properties: any): void {}
}

export class HotJar implements Analytics {
  public readonly kind: string = "HotJar";
  identifyUser(id: string, properties: UserProperties): void {
    if (window.hj) {
      window.hj("identify", id, {});
    }
  }

  organization(id: string, properties: OrganizationProperties): void {}

  page(category: string, name: string, properties: any): void {}

  trackEvent(id: string, properties: any): void {}
}
