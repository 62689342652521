import { Theme } from "@mui/material/styles";
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import ToggleButton from '@mui/material/ToggleButton';

export const NotebookToggleButton = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 51,
    },
    label: {
      fontWeight: "bold",
      color: `${theme.palette.primary.main}`,
    },
    selected: {
      backgroundColor: `${theme.palette.primary.main} !important`,
      "& $label": {
        color: `${theme.palette.common.white} !important`,
      },
      fontWeight: "bold",
    },
  })
)(ToggleButton);
