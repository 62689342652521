import { DisplayData } from "./api/contracts/Core";
import { Api } from "./api/Api";
import { MetadataStore } from "./Metadata";
import { action, computed, IObservableArray, observable } from "mobx";

export interface Workspace {
  readonly id: string;
  readonly displayData: DisplayData;
  readonly organizationId: string;
  readonly usageCount: number | null;
}

export interface WorkspaceDashboard {
  readonly workspace: Workspace;
  readonly inputIds: string[];
  readonly templateIds: string[];
}

export class WorkspacesReplyWrapper implements Workspace, WorkspaceDashboard {
  value: Workspace & WorkspaceDashboard;
  isDashboard: boolean;

  constructor(value: any) {
    this.value = value;
    this.isDashboard = value.hasOwnProperty("workspace");
  }

  get id(): string {
    return this.isDashboard ? this.value.workspace.id : this.value.id;
  }

  get displayData(): DisplayData {
    return this.isDashboard ? this.value.workspace.displayData : this.value.displayData;
  }

  get organizationId(): string {
    return this.isDashboard ? this.value.workspace.organizationId : this.value.organizationId;
  }

  get workspace(): Workspace {
    return this.isDashboard ? this.value.workspace : this.value;
  }

  get inputIds(): string[] {
    return this.value.inputIds || [];
  }

  get templateIds(): string[] {
    return this.value.templateIds || [];
  }

  get usageCount(): number {
    return this.isDashboard ? this.value.inputIds.length + this.value.templateIds.length : -1;
  }
}

export class WorkspacesStore {
  _api: Api;
  _op: string = "workspaces/";
  _metadata: MetadataStore;

  constructor(api: Api, metadataStore: MetadataStore) {
    this._api = api;
    this._metadata = metadataStore;
  }

  get canAttachMulti(): boolean {
    return this._metadata.Metadata().supportsAttachManyWorkspaces;
  }

  get supportsDashboards(): boolean {
    return this._metadata.Metadata().supports("workspaceDashboard");
  }

  itemsLoaded = false;
  @observable.shallow items: WorkspacesReplyWrapper[] = [];

  @computed
  get options(): any[] {
    return this.items.map((i) => ({
      key: i.id,
      title: i.displayData.name,
    }));
  }

  onListReply = action((x: Array<Workspace | WorkspaceDashboard>) => {
    const wrappers = x.map((y) => new WorkspacesReplyWrapper(y));
    (this.items as IObservableArray).replace(wrappers);
    this.itemsLoaded = true;
    return wrappers;
  });

  wrappedList = () => this._api.get(this._op).then((x) => this.onListReply(x));

  list(): Promise<Workspace[]> {
    return this.wrappedList();
  }

  get(id: string, errHandlers: any): Promise<Workspace> {
    if (!id) {
      throw new Error("get most have an id");
    }
    return this._api.get(this._op + id, null, null, null, errHandlers);
  }

  create(payload: any): Promise<Workspace> {
    return this._api.post(this._op, payload);
  }

  remove(id: string): Promise<any> {
    return this._api.remove(`${this._op}${id}`);
  }

  attachMulti(workspaces: string[], templateIds: string[], inputIds: string[]): Promise<void> {
    return this._api.post(this._op + "multi-attach", { workspaces, templateIds, inputIds });
  }

  dashboards(): Promise<WorkspaceDashboard[]> {
    return this.supportsDashboards
      ? this._api.get(`${this._op}dashboards`).then((x) => this.onListReply(x))
      : this.wrappedList();
  }
}
