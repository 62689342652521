import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { action, get, keys, runInAction } from "mobx";
import { Button } from "../Button";
import { Observer, observer } from "mobx-react-lite";
import TextField from "@mui/material/TextField";
import classNames from "classnames";
import { FormInputProps, getInputId, inputOnChange, secretClassName } from "../FormInputTypes";
import { FormFieldRegistry } from "../../FormFieldRegistry";
import FormItemDecorator from "./FormItemDecorator";
import { Field as MField } from "../../type-definitions/mobx-react-form";

export const FormTextInput: React.FC<Partial<FormInputProps>> = observer(({ input, onChange, placeholder }) => {
  const onBlur = function () {
    input.onBlur();
    if (onChange) {
      onChange.apply(null, arguments as any);
    }
  };

  let type = input.metadata.type || "text";
  if (type === "static") {
    type = "text";
  }

  const disabled = input.metadata.disabled || input.metadata.readonly || input.loading;

  return disabled ? (
    <div className="readonly-input disabled">
      {input.$value}
      {input.loading && (
        <span className="Select-loading-zone">
          <span className="Select-loading" />
        </span>
      )}
    </div>
  ) : (
    <TextField
      variant="outlined"
      fullWidth
      type={type}
      className={input.metadata.secret && secretClassName}
      name={input.name}
      id={getInputId(input)}
      value={input.$value}
      placeholder={placeholder || input.label}
      onChange={inputOnChange(input)}
      onFocus={input.onFocus}
      onBlur={onBlur}
    />
  );
});
const formTextInputComponentFactory = (props: FormInputProps) => (
  <FormTextInput {...props} placeholder={props.input.metadata.placeholder} />
);

export const MultiTextInput: React.FC<FormInputProps> = observer(({ input, hideLabel, hideDescription }) => {
  useEffect(() => {
    if (!(input.$value && input.$value.length)) {
      input.$value = [""];
    }

    if (input.$value && input.$value.length && input.fields.size === 0) {
      input.$value.forEach((x: unknown) => {
        input.add();
        input.$(input.fields.size - 1).set(x);
      });
    }
  }, [input]);

  const onAdd = action((e?: React.MouseEvent) => {
    if (e) {
      e.stopPropagation();
    }
    input.add({});
    const keys = Object.keys(input.get());
    const last = keys[keys.length - 1];
    input.select(last).set(null);
  });

  const onDel = action((value: string, idx: string | number) => {
    input.del(idx);
    const currentIndex = input.$value.findIndex((x: unknown) => x === value);
    input.$value[currentIndex] = null;
  });

  const required = input.$rules && input.$rules.includes("required");
  return (
    <div className="fields-autocomplete-array">
      {keys<string>(input.fields).map((key: string) => {
        const childInput = get(input.fields, key) as MField;
        if (key === "0" && required) {
          childInput.set("rules", "required");
        }

        const childInputKey = childInput.name;
        const setValue = action((v: unknown) => {
          inputOnChange(childInput)(v);
          if (input.metadata.changeHandler) {
            input.metadata.changeHandler(input.values());
          }
        });

        return (
          <div key={key} className="flex-two-columns mapped-column">
            <div className="properties">
              <FormTextInput input={childInput} onChange={setValue} />
              {childInput.error && hideLabel && <span className="description error-text">{childInput.error}</span>}
            </div>
            <div className="buttons">
              {Number(key) > 0 && (
                <span
                  className="icon-trash clickable"
                  onClick={action(() => onDel(childInput.$value, childInputKey))}
                />
              )}
            </div>
          </div>
        );
      })}
      <Button type="borderless" automationName="nary-add-input" small onClick={onAdd}>
        <span className="icon-plus" /> Add
      </Button>
      {hideLabel && !hideDescription && <div className="description">{input.metadata.description}</div>}
    </div>
  );
});

export class FormTextAreaInput extends React.Component<FormInputProps> {
  onBlur = () => {
    this.props.input.onBlur();
    if (this.props.onChange) {
      this.props.onChange(this.props.input.$value);
    }
  };

  render(): React.ReactNode {
    const { input, style } = this.props;
    const { placeholder, disabled } = input;
    return (
      <Observer>
        {() => (
          <textarea
            style={style}
            className={input.metadata.secret && secretClassName}
            name={input.name}
            disabled={disabled || input.metadata.disabled}
            value={input.$value}
            placeholder={placeholder || input.label}
            onChange={inputOnChange(input)}
            onFocus={input.onFocus}
            onBlur={this.onBlur}
          />
        )}
      </Observer>
    );
  }
}

export const FormPasswordInput: React.FC<FormInputProps> = observer(({ autocomplete, input, onChange }) => {
  const [type, setType] = useState(autocomplete === false ? "text" : "password");
  const el = useRef();
  useLayoutEffect(() => {
    if (el.current) {
      const style: any = window.getComputedStyle(el.current);
      if (!style.webkitTextSecurity) {
        runInAction(() => setType("password"));
      }
    }
  });

  return (
    <TextField
      variant="outlined"
      fullWidth
      type={type}
      name={input.name}
      disabled={input.metadata.disabled}
      value={input.$value}
      placeholder={input.label}
      onChange={input.sync}
      onBlur={onChange || (() => {})}
      autoComplete={autocomplete === false ? "off" : null}
      ref={el}
      inputProps={{ className: classNames("no-mouseflow", "password") }}
    />
  );
});
export const register = (formFieldRegistry: FormFieldRegistry) => {
  formFieldRegistry.register("text", formTextInputComponentFactory);
  formFieldRegistry.register("static", (props) =>
    formTextInputComponentFactory(Object.assign(props, { disabled: true }))
  );
  formFieldRegistry.register(
    "multi-text",
    (props) => {
      const v = <MultiTextInput {...props} hideLabel={false} placeholder={props.input.metadata.placeholder} />;
      return (
        <FormItemDecorator
          inputRef={props.scrollRef}
          vertical={props.vertical}
          compact={props.compact}
          hideLabel={false}
          input={props.input}
        >
          {v}
        </FormItemDecorator>
      );
    },
    { decorate: false }
  );
  formFieldRegistry.register("textarea", (props) => <FormTextAreaInput {...props} />);
  formFieldRegistry.register("password", (props) => <FormPasswordInput {...props} />);
  formFieldRegistry.register("password-no-autocomplete", (props) => (
    <FormPasswordInput {...props} autocomplete={false} />
  ));
};
