import { observer } from "mobx-react";
import React from "react";
import { FormSelectInputProps } from "../FormInputTypes";
import { values } from "mobx";
import FormCheckboxInput from "./FormCheckboxInput";
import { FormFieldRegistry } from "../../FormFieldRegistry";
import { AppContext } from "../../../routes/AppContext";

@observer
export class RequiredCredentialsInput extends React.Component<FormSelectInputProps> {
  render() {
    const childFields = values(this.props.input.fields);
    return (
      <div className="form-inupt-group" ref={this.props.scrollRef}>
        {childFields.map((c) => (
          <label key={c.key} className="clickable centered-flex-row">
            <FormCheckboxInput input={c} />
            {c.label}
          </label>
        ))}
      </div>
    );
  }
}
export const register = (formFieldRegistry: FormFieldRegistry) =>
  formFieldRegistry.register(
    "required-credentials",
    (props) => <RequiredCredentialsInput {...props} options={props.input.metadata.options} />,
    { decorate: false }
  );
