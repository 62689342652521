import React from "react";
import { observer } from "mobx-react";

type SwitchProps = {
  checked?: boolean;
  disabled?: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  className?: string;
};
export const Switch: React.FunctionComponent<SwitchProps> = observer(({ checked, disabled, onChange, className }) => (
  <label className="switch">
    <div className="icon-x" />
    <div className="icon-check" />
    <input type="checkbox" checked={checked} className={className} disabled={disabled} onChange={onChange} />
    <div className="uslider round" />
  </label>
));
