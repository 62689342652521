import { OptionalOrganizationFlag } from "./OptionalOrganizationFlag";

export type Documentation = {
  blurb: string;
  url: string;
};

export interface ContextMetadata {
  clazz: string;
  context: string[];
  entityContext?: string[];
  getContext?: () => Record<string, any>;
  parameters: string[];
}

export function buildMetadataContextRequest(contextMetadata: ContextMetadata) {
  const { clazz, parameters, getContext } = contextMetadata;
  const context = (getContext && getContext()) ?? {};

  return Object.assign({}, { clazz, parameters }, context);
}

export interface AutoCompleteMetadata extends ContextMetadata {
  required: boolean;
  sequence?: boolean;
  allowMissingContext?: boolean;
  openContainer?: boolean;
}

export type ServerSideValidationStatus = "VALID" | "INVALID" | null;
export interface ServerSideValidation extends ContextMetadata {
  subFields?: boolean;
  validationCallToAction?: string;
}

export interface StepMetadata {
  name: string;
  description?: string;
}

export interface DefaultValueProviderMetadata {
  clazz: string;
  parameters: any[];
  entityContextFieldNames: string[];
}

export interface DisplayIf {
  path: string;
  value: string;
}

export interface DynamicDescriptionMetadata extends ContextMetadata {
  clazz: string;
}

/**
 * Override the displayName of child properties, property name -> display name override value
 */
type DisplayNameOverride = Record<string, string>;

export interface SimpleMetadataProperty extends OptionalOrganizationFlag {
  displayNameOverride?: DisplayNameOverride;
  name: string;
  displayName: string;
  description: string;
  schemaType: string;
  defaultValue?: any;
  defaultValueProvider?: DefaultValueProviderMetadata;
  group?: string;
  editor?: string;
  editorInformation: Record<string, string>;
  typeArguments: string[];
  hidden: boolean;
  showInProperties: boolean;
  secret: boolean;
  mandatory: boolean;
  immutable: boolean;
  sequence: boolean;
  generated: boolean;
  autoComplete?: AutoCompleteMetadata;
  validators: Record<string, object>;
  changeset?: string;
  isStringEnum: boolean;
  example?: object;
  unique: boolean;
  valueField?: string;
  displayIf?: DisplayIf;
  traits?: string[];
  serverSideValidation?: ServerSideValidation;
  step?: StepMetadata;
  dynamicDescription?: DynamicDescriptionMetadata;
  defaultSubFieldsSelectorOption?: string;
  displayValueSubFieldPath?: string;
}

export interface SimpleMetadata {
  clazz: string;
  displayName: string;
  description: string;
  properties?: SimpleMetadataProperty[];
  editorInformation?: { [key: string]: string };
  documentation?: Documentation;
  extendedDescription?: string;
  traits?: string[];
  request?: string;
  dynamicMetadataClazz?: string;
  hidden?: boolean;
  organizationFlag?: string;
  iconOverride?: string;
  immutable?: boolean;
  actionName?: string;
  dynamicDescription?: DynamicDescriptionMetadata;
}
