import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { RouteComponentProps, withRouter } from "react-router-dom";
import MuiButton from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { MoreVert } from "@mui/icons-material";
import { lighten, Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { EllipsisTooltip } from "./EllipsisTooltip";

export type ButtonType =
  | "submit"
  | "primary"
  | "secondary"
  | "cancel"
  | "borderless"
  | "danger"
  | "warning"
  | "restore"
  | "beta";

export type ButtonProps = {
  type: ButtonType;
  onClick?: React.MouseEventHandler;
  disabled?: boolean;
  submit?: boolean;
  title?: string;
  children?: any;
  small?: boolean;
  medium?: boolean;
  xsmall?: boolean;
  xxsmall?: boolean;
  overlay?: any;
  to?: string;
  loading?: boolean;
  "data-disabled"?: boolean;
  automationName?: string;
  tooltip?: string;
  className?: string;
  margins?: boolean;
  icon?: React.ReactNode;
  endIcon?: React.ReactNode;
  toggle?: boolean;
  selected?: boolean;
  square?: boolean;
  lowercase?: boolean;
  narrow?: boolean;
  role?: string;
};

const useRoundedButtonStyle: any = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: "22.5px",
    },
    square: {
      borderRadius: 0,
    },
    single: {
      "&:not(:last-child)": {
        marginRight: theme.spacing(1),
      },
    },
    margins: {
      marginRight: theme.spacing(1),
    },
    danger: {
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main,
      "&:hover": {
        backgroundColor: lighten(theme.palette.error.main, 0.95),
        borderColor: theme.palette.error.main,
      },
    },
    beta: {
      color: theme.palette.common.white,
      fontWeight: theme.typography.fontWeightBold,
      backgroundColor: theme.palette.primary.main,
    },
    selected: {
      backgroundColor: lighten(theme.palette.primary.light, 0.9),
    },
    progress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
    lowercase: {
      textTransform: "inherit",
      fontSize: theme.typography.subtitle1.fontSize,
    },
  })
);

const RoutedButton: React.FC<ButtonProps & RouteComponentProps> = observer((props) => {
  const {
    small,
    medium,
    xsmall,
    xxsmall,
    disabled,
    to,
    loading,
    automationName,
    className,
    type,
    onClick,
    tooltip,
    submit,
    title,
    children,
    overlay,
    history,
    margins,
    icon,
    endIcon,
    selected,
    toggle,
    square,
    lowercase,
    narrow,
  } = props;
  const classes = useRoundedButtonStyle();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [container, setContainer] = useState();
  const handleToggle: React.MouseEventHandler = (e) => {
    e.stopPropagation();
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = () => setOpen(false);
  const onLinkClick = () => history.push(to);

  useEffect(() => {
    if (!anchorRef.current) {
      setContainer(null);
    } else {
      let dialog = null;
      let parent = anchorRef.current.parentElement;
      while (parent && !dialog) {
        const ariaLabel = parent.attributes.getNamedItem("aria-labelledby");
        if (ariaLabel && ariaLabel.value === "dialog") {
          dialog = parent;
        } else {
          parent = parent.parentElement;
        }
      }
      setContainer(dialog || parent);
    }
  }, [setContainer]);

  const clickAction = to ? onLinkClick : onClick;
  let size: any = "large";
  if (medium) {
    size = "medium";
  } else if (small || xxsmall || xsmall) {
    size = "small";
  }
  const buttonHtmlType = submit || type === "submit" ? "submit" : "button";
  const buttonColor = type === "cancel" ? "secondary" : "primary";
  const buttonVariant =
    (type === "borderless" && "text") || ((["primary", "submit"].includes(type) ? "contained" : "outlined") as any);
  const buttonDisabled = !!(disabled || loading);
  const buttonProps = {
    size: size,
    className: classNames(
      classes.root,
      square && classes.square,
      !overlay && classes.single,
      margins && classes.margins,
      type === "danger" && classes.danger,
      className,
      toggle && selected && classes.selected,
      lowercase && classes.lowercase,
      type === "beta" && classes.beta
    ),
    "data-automation-name": automationName,
    "data-testid": automationName,
    title: tooltip,
    onClick: clickAction,
    disabled: buttonDisabled,
    "aria-disabled": buttonDisabled,
    children: (
      <>
        {loading && (
          <CircularProgress
            color={type === "secondary" ? "primary" : "secondary"}
            size={24}
            className={classes.progress}
          />
        )}
        {icon}
        <Box pl={icon ? 0.25 : 0} pr={endIcon ? 0.25 : 0}>
          {title ? title : children}
        </Box>
        {endIcon}
      </>
    ),
    role: props.role,
  };
  const ButtonComponent = narrow ? ShortNarrowMuiButton : MuiButton;
  const retVal = toggle ? (
    <ButtonComponent {...buttonProps} type={buttonHtmlType} color={buttonColor} variant={buttonVariant || "outlined"} />
  ) : (
    <ButtonComponent {...buttonProps} type={buttonHtmlType} color={buttonColor} variant={buttonVariant} />
  );

  if (!overlay) {
    return disabled && tooltip ? (
      <EllipsisTooltip title={tooltip} button>
        {retVal}
      </EllipsisTooltip>
    ) : (
      retVal
    );
  }

  return (
    <React.Fragment>
      <ButtonGroup className={classes.root} variant="contained" color="primary" aria-label="split button">
        {retVal}
        <MuiButton
          className={classes.root}
          data-automation-name="open-button-more"
          data-testid="open-button-more"
          color="primary"
          size={size}
          aria-owns={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <MoreVert ref={anchorRef} />
        </MuiButton>
      </ButtonGroup>
      <Popper
        open={open}
        placement="bottom"
        disablePortal={!Boolean(container)}
        container={container}
        anchorEl={anchorRef.current}
        modifiers={
          {
            flip: {
              enabled: true,
            },
            preventOverflow: {
              enabled: true,
              boundariesElement: "undefined",
            },
          } as any
        }
      >
        <Paper>
          <ClickAwayListener onClickAway={handleClose}>{overlay}</ClickAwayListener>
        </Paper>
      </Popper>
    </React.Fragment>
  );
});

RoutedButton.defaultProps = {
  small: false,
  medium: false,
  submit: false,
};

export const Button = withRouter(RoutedButton);

export const FlatLinkButton: React.FC<React.HTMLProps<HTMLButtonElement>> = ({
  children,
  className,
  type,
  ...rest
}) => (
  <button className={classNames("flat-button", className)} type={(type as any) || "button"} {...rest}>
    {children}
  </button>
);

export const FlatButton: React.FC<React.HTMLProps<HTMLButtonElement>> = ({ className, ...rest }) => (
  <FlatLinkButton className={classNames("no-width", className)} {...rest} />
);

export const ShortNarrowMuiButton = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "15px",
      display: "inline-flex",
      paddingRight: "2px",
      paddingLeft: "2px",
      minWidth: 0,
    },
  })
)(MuiButton);
