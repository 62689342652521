/* eslint-disable */
import React from "react";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import Snackbar from "@mui/material/Snackbar";
import { Button } from "./core/views/Button";
import SnackbarContent from "@mui/material/SnackbarContent";

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      border: `1px solid ${theme.palette.grey["300"]}`,
      color: theme.palette.text.primary,
    },
  })
);

export const SiteWideNotification: React.FC<
  React.HTMLProps<HTMLDivElement> & { onDismiss?: React.EventHandler<any>; notificationAction?: React.ReactNode }
> = ({ className, onDismiss, notificationAction, children, ...rest }) => {
  const classes = useStyles();
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open
      onClose={onDismiss}
    >
      <SnackbarContent
        classes={classes}
        message={
          <Box ml={1} mr={2} flexGrow={1}>
            {children}
          </Box>
        }
        action={
          <>
            {notificationAction}
            {onDismiss && (
              <Button medium type="secondary" icon={<CloseIcon />} aria-label="close" onClick={onDismiss}>
                Close
              </Button>
            )}
          </>
        }
      />
    </Snackbar>
  );
};
