import React from "react";
import { FormInputProps, FormSelectInputProps } from "./views/FormInputTypes";

type Props = FormInputProps | FormSelectInputProps;

type FormFieldFactory = (props: Props) => any;

interface RegisterOptions {
  decorate: boolean;
}

const defaultOptions: RegisterOptions = { decorate: true };

interface FormFieldFactoryResult {
  decorate: boolean;
  component: any;
}

class DefaultFormFieldRegistry implements FormFieldRegistry {
  private registry = new Map<string, { decorate: boolean; factory: FormFieldFactory }>();
  private patternRegistry = new Map<string, { decorate: boolean; factory: FormFieldFactory; expression: RegExp }>();

  clear = () => {
    this.registry.clear();
    this.patternRegistry.clear();
  };

  tryCreate = (type: string, props: Props): FormFieldFactoryResult | null => {
    if (this.registry.has(type)) {
      const item = this.registry.get(type);
      return { decorate: item.decorate, component: item.factory(props) };
    }
    for (let pattern of this.patternRegistry.values()) {
      if (pattern.expression.exec(type)) {
        return { decorate: pattern.decorate, component: pattern.factory(props) };
      }
    }
    return null;
  };

  register = (
    type: RegExp | string,
    componentFactory: FormFieldFactory,
    options: RegisterOptions = defaultOptions
  ): void => {
    if (type instanceof RegExp) {
      this.registerRegex(type, componentFactory, options);
    } else {
      this.registerString(type, componentFactory, options);
    }
  };

  private registerString = (
    type: string,
    componentFactory: FormFieldFactory,
    options: RegisterOptions = defaultOptions
  ) => {
    if (this.registry.has(type)) {
      throw new Error(`Attempted to register form field ${type} that is already registered`);
    }
    this.registry.set(type, { decorate: options.decorate, factory: componentFactory });
  };

  private registerRegex = (expression: RegExp, componentFactory: FormFieldFactory, options?: RegisterOptions): void => {
    const pattern = expression.toString();
    if (this.patternRegistry.has(pattern)) {
      throw new Error(`Attempted to register form field ${pattern} that is already registered`);
    }
    this.patternRegistry.set(pattern, {
      decorate: options.decorate,
      factory: componentFactory,
      expression,
    });
  };
}

export interface FormFieldRegistry {
  register(type: string, componentFactory: FormFieldFactory, options?: RegisterOptions): void;
  register(expression: RegExp, componentFactory: FormFieldFactory, options?: RegisterOptions): void;
  tryCreate(type: string, props: Props): FormFieldFactoryResult | null;
  clear(): void;
}

const formFieldRegistry = new DefaultFormFieldRegistry();
export default formFieldRegistry;
