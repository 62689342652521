import React, { Suspense } from "react";
import { ButtonFileUpload } from "../FileUpload";
import { observer, Observer } from "mobx-react";
import { action, computed, IObservableArray, observable, set, toJS, values } from "mobx";
import { ActionMetadata } from "../../Metadata";
import groupBy from "lodash/groupBy";
import Tree, { TreeEntry } from "../Tree";
import { Field as MField } from "../../type-definitions/mobx-react-form";
import SyntaxHighlighter from "react-syntax-highlighter/dist/esm/light";
import jsonLang from "react-syntax-highlighter/dist/cjs/languages/hljs/json";
import uniq from "lodash/uniq";
import keyBy from "lodash/keyBy";
import { Spin } from "../Spin";
import { Button } from "../Button";
import { PolicyStatement } from "../../../iam/IAMPage";
import "../../../styles/inputs/policy-editor.scss";
import { Main, Sidebar } from "../layout/layouts";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Toggle } from "../Toggle";
import classNames from "classnames";
import identity from "lodash/identity";
import "../../../styles/fields-menu.scss";
import { FormInputProps, inputOnChange } from "../FormInputTypes";
import FormItemDecorator from "./FormItemDecorator";
import FormSelectInput from "./FormSelectInput";
import { FormAutoCompleteMultiSelectInputWithFormContext } from "./Autocomplete";
import { traverseItemTree } from "../../TreeEntityUtils";
import { EditTemplateContext } from "../../../templates/common/EditTemplateContext";
import { FormFieldRegistry } from "../../FormFieldRegistry";
import { AppContext } from "../../../routes/AppContext";

SyntaxHighlighter.registerLanguage("json", jsonLang);

const AsyncLoader = () => (
  <div className="page" style={{ height: 215 }}>
    <p style={{ verticalAlign: "middle", textAlign: "center", width: "100%", marginTop: "25%" }}>
      Loading the Policy Editor
    </p>
  </div>
);

const AsyncJSONEditorReact = React.lazy(() => import("./JSONEditorReact"));

@observer
class JsonEditor extends React.Component<FormInputProps> {
  setFile = (files: File[]) => {
    const fileReader = new FileReader();
    fileReader.onloadend = action(() => {
      const statements = JSON.parse(String(fileReader.result));
      if (Array.isArray(statements)) {
        this.props.input.clear();
        statements.forEach((x) => {
          this.props.input.add(x);
        });
        inputOnChange(this.props.input)(this.props.input.value);
      }
    });
    fileReader.readAsText(files[0]);
  };

  setValue = action((value: string) => {
    const parsedValue = JSON.parse(value);
    this.props.input.set(parsedValue);
    inputOnChange(this.props.input)(parsedValue);
    this.props.input.metadata = Object.assign(this.props.input.metadata || {}, { value: parsedValue });
  });

  componentDidMount(): void {
    this.props.input.metadata.value = this.props.input.values();
  }

  @computed
  get policy(): any {
    return JSON.stringify((this.props.input.metadata && this.props.input.metadata.value) || "", null, 2);
  }

  render(): React.ReactNode {
    return (
      <FormItemDecorator input={this.props.input} hideLabel>
        <ButtonFileUpload onFiles={this.setFile} fireOnSameFile />
        <Suspense fallback={<AsyncLoader />}>
          <AsyncJSONEditorReact mode={"text"} text={this.policy} onChangeText={this.setValue} />
        </Suspense>
      </FormItemDecorator>
    );
  }
}

class TreeState {
  @observable expandedKeys: IObservableArray<string> = observable.array();
  @observable checkedKeys: IObservableArray<string> = observable.array();
  @observable selectedKey: string;

  @observable private readonly actions: ActionMetadata[];
  @observable.deep input: MField<PolicyStatement>;

  constructor(actions: ActionMetadata[], input: MField) {
    this.actions = actions;
    this.input = input;
    const namespace = input.$("namespace").$value + ":";
    const actionValues = this.input.$<string[]>("actions").value.map((x) => x.replace(namespace, "").split(":"));

    if (actionValues.length === 1 && actionValues[0][0] === "*") {
      this.all();
    } else {
      const endsWithStar = actionValues.filter((y) => y.length > 1 && y[1] === "*");
      if (actionValues.length > 0) {
        traverseItemTree(this.entries, (x) => {
          if (endsWithStar.some((y) => y[0] === x.key) && x.children && x.children.length > 0) {
            this.onCheck(x, true);
          } else if (actionValues.some((y) => y.length === 2 && y[0] + "." + y[1] === x.key)) {
            this.onCheck(x, true);
          }
        });
      }
    }
  }

  onCheck = action((entry: TreeEntry, checked?: boolean) => {
    const actionsInputs = this.input.$("actions");
    traverseItemTree([entry], (x) => {
      if (checked && !this.checkedKeys.includes(x.key)) {
        this.checkedKeys.push(x.key);
      } else if (!checked) {
        this.checkedKeys.remove(x.key);
        const parent = x.key.split(".")[0];
        const subKeys = this.checkedKeys.filter((x) => x.startsWith(parent));
        if (subKeys.length === 1 && subKeys[0] === parent) {
          this.checkedKeys.remove(parent);
        }
      }
    });

    let hasPartialEntry = false;
    const byParent: { [key: string]: string[] } = groupBy(this.checkedKeys, (x) => x.split(".")[0]) as any;
    const inputValues: string[] = Object.entries(byParent).flatMap(([type, actions]) => {
      const sanitizedActions = actions.filter((x) => x !== type);

      if ((actions.length === 1 && actions[0] === type) || this.entriesCounts[type].size === sanitizedActions.length) {
        return [`${this.namespace}:${type}:*`];
      } else {
        hasPartialEntry = true;
        return sanitizedActions.map((x) => `${this.namespace}:${x.replace(".", ":")}`);
      }
    });

    if (Object.entries(byParent).length === this.entries.length && !hasPartialEntry) {
      inputOnChange(actionsInputs)([`${this.namespace}:*`]);
    } else {
      inputOnChange(actionsInputs)(inputValues);
    }
  });

  onExpand = action((entry: TreeEntry, shouldExpand: boolean) => {
    this.expandedKeys.remove(entry.key);
    if (shouldExpand) {
      this.expandedKeys.push(entry.key);
    }
  });

  clear = action(() => {
    this.checkedKeys.clear();
    inputOnChange(this.input.$("actions"))([]);
  });

  all = action(() => {
    const keys: string[] = [];
    traverseItemTree(this.entries, (x) => keys.push(x.key));
    this.checkedKeys.replace(keys);
    inputOnChange(this.input.$("actions"))([`${this.namespace}:*`]);
  });

  @computed
  get namespace(): string {
    return this.input.$("namespace").$value;
  }

  @computed
  get entriesCounts(): { [key: string]: { size: number } } {
    return keyBy(
      Object.entries(this.groupedItems).map(([type, actions]) => ({
        type,
        size: actions.filter((x) => !x.hidden).length,
      })),
      "type"
    ) as any;
  }

  @computed
  get groupedItems(): Record<string, ActionMetadata[]> {
    return groupBy(this.actions, "actionType") as any;
  }

  @computed
  get entries(): TreeEntry[] {
    return Object.entries(this.groupedItems)
      .map(([type, actions]) => {
        const children = actions
          .filter((x) => !x.hidden)
          .map((a) => ({ label: a.action, key: `${type}.${a.action}`, description: a.description }));
        return (
          children.length && {
            label: type,
            key: type,
            children: children,
          }
        );
      })
      .filter(identity) as any;
  }

  @computed
  get filteredEntries(): Set<any> {
    const allSet = new Set();
    this.entries.forEach((x) => {
      allSet.add(x);
      if (x.children) {
        x.children.forEach((y) => allSet.add(y));
      }
    });
    return allSet;
  }
}

@observer
class PolicyActionsFormInput extends React.Component<{ treeState: TreeState }> {
  render(): React.ReactNode {
    const { treeState } = this.props;

    return (
      <div>
        <div className="fields-menu">
          <div className="menu">
            <div className="centered-flex-row">
              <Button type="borderless" onClick={treeState.all}>
                Select All
              </Button>
              <Button type="borderless" onClick={treeState.clear}>
                Clear Selection
              </Button>
            </div>
            <Tree
              className="fields-tree"
              checkable={true}
              {...treeState}
              entries={treeState.entries}
              onSelect={() => ({})}
              onDoubleSelect={() => ({})}
              filteredEntries={treeState.filteredEntries}
              renderEntry={(
                item: TreeEntry<{ label: string; description: string }>,
                onClick: React.MouseEventHandler,
                doubleClickProps: unknown,
                checkbox: React.ReactNode
              ) => {
                if (item.children) {
                  return (
                    <span className="fields-container parent">
                      <span className="field">
                        {checkbox}
                        <span className="label">
                          {item.label}({item.children.length})
                        </span>
                      </span>
                    </span>
                  );
                } else {
                  return (
                    <span className="fields-container">
                      <div className="field">
                        {checkbox}
                        <span className="label">{item.label}</span>
                      </div>
                      {item.description && <p className="description">{item.description}</p>}
                    </span>
                  );
                }
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

function buildRegex(values: string[]): string {
  return `(${values.join("|")})`;
}

function getRegexValues(regex: string) {
  return regex.replace(/\(|\)/g, "").split("|");
}

@observer
export class FormEditor extends React.Component<FormInputProps<PolicyStatement[]>> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  namespaces: string[];

  @observable currentId = "";
  @observable loading = true;

  componentDidMount(): void {
    this.namespaces = [...uniq(this.context.metadataStore.Metadata().actionNames.map((x) => x.namespace)), "*"];
    const current = toJS(this.props.input.value, {
      exportMapsAsObjects: true,
      recurseEverything: true,
    });
    if (!current || !current.length) {
      this.add();
    } else {
      this.props.input.clear(true);
      this.props.input.fields.clear();
      current.map(
        action((x: any, index: number) => {
          const actionsHead = x.actions.length ? x.actions[0] : "";
          const namespace = actionsHead.split(":")[0];
          x["namespace"] = namespace;
          this.add(x);
          this.setNamespace(namespace, this.props.input.$(index.toString()), x);
        })
      );
      this.currentId = "0";
    }
    this.loading = false;
  }

  add = action((values: any = { resources: [], namespace: "", effect: "", actions: [], conditions: [] }) => {
    const { input } = this.props;
    const statement: any = {
      namespace: values.namespace,
      effect: values.effect,
      actions: [],
      resources: [],
      conditions: [],
    };
    const addedInput = input.add(statement);
    addedInput.$("effect").set("rules", "required");
    addedInput.$("namespace").set("rules", "required");
    addedInput.metadata = { selectedIndex: 0 };
    this.currentId = (input.fields.size - 1).toString();
  });

  @computed
  get hasItems(): boolean {
    return !this.loading && this.props.input.fields.size > 0;
  }

  setNamespace = action(
    (
      namespace: any,
      input: MField<any>,
      initValues: Partial<PolicyStatement> = {
        resources: ["*"],
        conditions: [],
      }
    ) => {
      input.reset(true);
      input.$("namespace").set("value", namespace);
      const metadata = this.context.metadataStore.Metadata().actionNames.find((x) => x.namespace === namespace);
      const resourcesInput = input.$("resources");
      resourcesInput.metadata = {};
      set(
        resourcesInput.metadata,
        metadata &&
          metadata.autoCompleteProvider && {
            autocomplete: {
              clazz: metadata.autoCompleteProvider,
              required: true,
              context: [],
              parameters: [],
              sequence: true,
              getContext: () => ({}),
            },
          }
      );

      const conditionsInput = input.$("conditions");
      resourcesInput.set(observable.array(initValues.resources));
      resourcesInput.metadata.changeHandler = (value: string[], input: MField) => {
        const noAllValue = value.filter((x) => x !== "*");
        input.value = noAllValue.length === 0 ? ["*"] : noAllValue;
      };

      if (initValues.actions) {
        input.$("actions").set(observable.array(initValues.actions));
      } else {
        input.$("actions").set(observable.array([`${namespace}:*`]));
      }

      conditionsInput.set(observable.array(initValues.conditions));
      conditionsInput.metadata = {
        autocomplete: {
          clazz: "WorkspaceAutoCompleteProvider",
          required: false,
          context: [],
          parameters: [],
          sequence: true,
          getContext: () => ({}),
        },

        isRunningValue: "None",
      };

      input.metadata.treeState = metadata && new TreeState(metadata.actions, input);
    }
  );

  @computed
  get currentInput(): MField {
    return this.hasItems && this.props.input.has(this.currentId.toString()) && this.props.input.$(this.currentId);
  }

  sideBarText = (input: MField, index: number): string => {
    const namespace = input.$("namespace").$value;
    const effect = input.value.effect;
    const actions = input.value.actions.length > 0;
    const complete = namespace && effect && actions;
    const text = namespace ? `${effect} ${namespace}` : `#${index + 1}`;
    return `${text}${!complete ? " *" : ""}`;
  };

  remove = action((index: string) => {
    this.props.input.del(index);
    if (this.props.input.values().length === 0) {
      this.add();
    }
    this.currentId = "0";
  });

  render(): React.ReactNode {
    const input = this.currentInput;
    const hasNamespace = input && input.$("namespace").$value;
    const hasActions = input && input.value.effect && input.value.actions.length > 0;

    return (
      <Observer>
        {() => (
          <FormItemDecorator input={this.props.input} hideLabel>
            <span className="policy-editor">
              <Sidebar>
                <ul className="no-style-list">
                  {this.hasItems &&
                    values(this.props.input.fields).map((input, index) => (
                      <li
                        className={classNames({ selected: this.currentId === input.name })}
                        key={input.name}
                        onClick={action(() => (this.currentId = input.name))}
                      >
                        <span>
                          <span>{this.sideBarText(input, index)}</span>
                          <span className="icon-trash clickable" onClick={() => this.remove(input.name)} />
                        </span>
                      </li>
                    ))}
                  <li key={"add-statement"}>
                    <Button type={"secondary"} onClick={() => this.add()} small>
                      Add Statement
                    </Button>
                  </li>
                </ul>
                <div />
              </Sidebar>
              <Main>
                <Spin spinning={!this.hasItems}>
                  <span className={"main-container"}>
                    {this.currentInput && (
                      <Tabs
                        selectedIndex={input.metadata.selectedIndex}
                        onSelect={action((index) => {
                          input.resetValidation();
                          input.metadata.selectedIndex = index;
                        })}
                      >
                        <TabList>
                          <Tab>
                            <span className={classNames({ "error-text": input.$("namespace").error })}>Namespace</span>
                          </Tab>
                          <Tab disabled={!hasNamespace}>
                            <span className={classNames({ "error-text": input.$("effect").error })}>Actions</span>
                          </Tab>
                          <Tab disabled={!hasActions}>Resources</Tab>
                        </TabList>
                        <TabPanel>
                          <FormItemDecorator input={input.$("namespace")} required label={"namespace"}>
                            <FormSelectInput
                              input={input.$("namespace")}
                              options={this.namespaces}
                              onChange={(v) => this.setNamespace(v, input)}
                            />
                          </FormItemDecorator>
                          <Button
                            disabled={!hasNamespace}
                            type="primary"
                            className="next-button"
                            small
                            onClick={action(() => (input.metadata.selectedIndex = 1))}
                          >
                            Next
                          </Button>
                        </TabPanel>
                        <TabPanel>
                          <FormItemDecorator input={input.$("effect")} label="Policy Type" required>
                            <Toggle
                              style={{ width: 120 }}
                              medium
                              options={["Allow", "Deny"]}
                              onChange={inputOnChange(input.$("effect"))}
                              value={input.$("effect").value}
                            />
                          </FormItemDecorator>
                          <FormItemDecorator input={input.$("actions")} label="Actions" required>
                            {input.metadata.treeState && (
                              <PolicyActionsFormInput treeState={input.metadata.treeState} />
                            )}
                          </FormItemDecorator>
                          <Button
                            disabled={!hasActions}
                            type="primary"
                            className="next-button"
                            small
                            onClick={action(() => (input.metadata.selectedIndex = 2))}
                          >
                            Next
                          </Button>
                        </TabPanel>
                        <TabPanel>
                          {input.value.effect &&
                            input.metadata.treeState &&
                            input.$("resources").metadata.autocomplete && (
                              <FormItemDecorator input={input.$("resources")} label="Resources" required>
                                <FormAutoCompleteMultiSelectInputWithFormContext input={input.$("resources")} />
                              </FormItemDecorator>
                            )}
                          <label>Additional Conditions</label>
                          <h1>{""}</h1>
                          <FormItemDecorator input={input.$("conditions")} label={"Workspaces"}>
                            <FormAutoCompleteMultiSelectInputWithFormContext
                              input={input.$("conditions")}
                              checked={(workspace: string) =>
                                input
                                  .$("conditions")
                                  .value.some((x: any) => x.field === "workspace" && x.value.includes(workspace))
                              }
                              onRemove={action((value: string) => {
                                const conditions = input.$("conditions");
                                const workspaceCondition = conditions.value.find((x: any) => x.field === "workspace");

                                if (workspaceCondition?.conditionType === "Regex") {
                                  const newWorkspaces = getRegexValues(workspaceCondition.value).filter(
                                    (workspace: any) => workspace !== value
                                  );

                                  const newValue = conditions.value.filter((x: any) => x.field !== "workspace");

                                  if (newWorkspaces.length > 1) {
                                    newValue.push({
                                      conditionType: "Regex",
                                      field: "workspace",
                                      value: buildRegex(newWorkspaces),
                                    });
                                  } else if (newWorkspaces.length === 1) {
                                    newValue.push({
                                      conditionType: "Equals",
                                      field: "workspace",
                                      value: newWorkspaces[0],
                                    });
                                  }

                                  inputOnChange(conditions)(newValue);
                                } else {
                                  inputOnChange(conditions)(
                                    conditions.value.filter((x: any) => !(x.field === "workspace" && x.value === value))
                                  );
                                }
                              })}
                              onAdd={action((value: string) => {
                                const conditions = input.$("conditions");
                                const workspaceCondition = conditions.value.find((x: any) => x.field === "workspace");

                                if (workspaceCondition) {
                                  const oldValue = getRegexValues(workspaceCondition.value);

                                  const newValue = conditions.value.filter((x: any) => x.field !== "workspace");
                                  newValue.push({
                                    conditionType: "Regex",
                                    field: "workspace",
                                    value: buildRegex([...oldValue, value]),
                                  });

                                  inputOnChange(conditions)(newValue);
                                } else {
                                  conditions.value.push({
                                    conditionType: "Equals",
                                    field: "workspace",
                                    value,
                                  });

                                  inputOnChange(conditions)(conditions.value);
                                }
                              })}
                            />
                          </FormItemDecorator>
                          <FormItemDecorator input={input.$("conditions")} label={"Is Running"}>
                            <Toggle
                              style={{ width: 165 }}
                              medium
                              options={["True", "False", "None"]}
                              onChange={(value) => {
                                const conditions = input.$("conditions");

                                const newValue = [...conditions.value].filter((x: any) => x.field !== "is-running");

                                if (value !== "None") {
                                  newValue.push({
                                    conditionType: "Equals",
                                    field: "is-running",
                                    value: (value === "True").toString(),
                                  });
                                }

                                conditions.metadata.isRunningValue = value;
                                inputOnChange(conditions)(newValue);
                              }}
                              value={input.$("conditions").metadata.isRunningValue}
                            />
                          </FormItemDecorator>
                        </TabPanel>
                      </Tabs>
                    )}
                  </span>
                </Spin>
                <div className="code-wrapper">
                  <h2>Preview</h2>
                  <SyntaxHighlighter
                    language="json"
                    showLineNumbers={true}
                    useInlineStyles={false}
                    lineNumberContainerStyle={{
                      paddingRight: "32px",
                      float: "left",
                      color: "#6a6a6a",
                    }}
                  >
                    {JSON.stringify(
                      toJS(this.props.input.values(), {
                        exportMapsAsObjects: true,
                        recurseEverything: true,
                      }).map((x: any) => {
                        delete x.namespace;
                        return x;
                      }),
                      null,
                      2
                    )}
                  </SyntaxHighlighter>
                </div>
              </Main>
            </span>
          </FormItemDecorator>
        )}
      </Observer>
    );
  }
}

@observer
export class PolicyEditorInput extends React.Component<FormInputProps<PolicyStatement[]>> {
  @observable onlyJSON: boolean = null;
  @observable mode: "editor" | "json";

  componentDidMount(): void {
    if (!this.props.input.values()) {
      this.props.input.$value = [];
    }
    const currentValues = this.props.input.values();

    this.onlyJSON =
      currentValues.length &&
      currentValues
        .map((s: any) => new Set(s.actions.map((x: string) => x.split(":")[0])))
        .some((x: any) => x.size > 1);
    this.mode = this.onlyJSON ? "json" : "editor";
  }

  render(): React.ReactNode {
    if (!this.mode) {
      return <Spin />;
    }
    return (
      <div>
        <h2>
          Statements
          {this.props.input.error && <span className="error-text">{this.props.input.error}</span>}
          <Toggle
            className="pull-right"
            medium
            disabled={this.onlyJSON}
            options={["Form", "JSON"]}
            onChange={action(() => (this.mode = this.mode === "editor" ? "json" : "editor"))}
            value={this.mode === "editor" ? "Form" : "JSON"}
          />
        </h2>
        <br />
        {this.mode === "editor" ? <FormEditor {...this.props} /> : <JsonEditor {...this.props} />}
      </div>
    );
  }
}

export const register = (formFieldRegistry: FormFieldRegistry) => {
  formFieldRegistry.register("policy-editor", (props) => <PolicyEditorInput {...props} />);
};
