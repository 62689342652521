import React from "react";

export const RunningBadge = () => (
  <span className="badge green" data-automation-name="running-badge">
    Running
  </span>
);
export const StoppedBadge = ({ word }: { word?: string }) => (
  <span className="badge gray" data-automation-name={`${word ? word.toLowerCase() : "stopped"}-badge`}>
    {word || "Paused"}
  </span>
);
export const DraftBadge = () => (
  <span className="badge draft" data-automation-name="draft-badge">
    Draft
  </span>
);
export const NewBadge = () => (
  <span className="badge info" data-automation-name="new-badge">
    New
  </span>
);

export const DeletingBadge = () => (
  <span className="badge deleting" data-automation-name="deleting-badge">
    Deleting
  </span>
);

export const DeletedBadge = () => <StoppedBadge word={"Deleted"} data-automation-name="archived-badge" />;
export const QueryableBadge = () => (
  <span className="badge green" data-automation-name="running-badge">
    Queryable
  </span>
);
export const CompletedBadge = () => (
  <span className="badge gray" data-automation-name="completed-badge">
    Completed
  </span>
);
export const CorrectionBadge = () => (
  <span className="badge correction" data-automation-name="correction-badge">
    Correction
  </span>
);
