import React from "react";
import classNames from "classnames";
import { appBarHeight } from "../../styles/sizes";
import Box from "@mui/material/Box";
import { observer } from "mobx-react";
import { SystemPath } from "../SystemPath";
import Typography from "@mui/material/Typography";
import "../../styles/template-title.scss";

export const TopBarContent: React.FC<React.HTMLProps<HTMLDivElement>> = ({ className, ...rest }) => (
  <div
    {...rest}
    className={classNames("flex-two-columns", className)}
    style={{ height: appBarHeight, paddingRight: 8, paddingLeft: 4 }}
  />
);

function TitleLogo(props: { logo?: React.ReactNode }) {
  return <>{props.logo || <div style={{ width: 4 }} />}</>;
}

export const TopBarTitle = observer(
  (props: { logo?: React.ReactNode; title?: React.ReactNode; subtitle?: React.ReactNode }) => {
    return (
      <div className="template-title">
        <TitleLogo logo={props.logo} />
        <SystemPath>
          <div className="name">{props.title}</div>
        </SystemPath>
        {props.subtitle && <Typography variant="subtitle2">{props.subtitle}</Typography>}
      </div>
    );
  }
);

export const TopBarActions = observer((props: { children?: React.ReactNode }) => {
  return <div className="template-details">{props.children}</div>;
});

//TODO: either use div or use Box props
export const TopBarNameContainer: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ className, ...rest }) => (
  <Box {...(rest as any)} display="flex" alignItems="center" />
);
export const TopBarStandaloneTitle = observer(
  (props: { logo?: React.ReactNode; title?: React.ReactNode; subtitle?: React.ReactNode }) => {
    return (
      <div className="template-title">
        <TitleLogo logo={props.logo} />
        <div>
          <div className="name">
            <Typography variant="h4">{props.title}</Typography>
          </div>
          {props.subtitle && <Typography variant="subtitle2">{props.subtitle}</Typography>}
        </div>
      </div>
    );
  }
);
