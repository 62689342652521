import debounce from "lodash/debounce";
import { RecentItem } from "./views/RecentItem";
import { UpsolverRemoteStorage } from "./UpsolverRemoteStorage";
import { UpsolverLocalStorage } from "./UpsolverLocalStorage";
import { OnboardingData } from "./views/home-page/onboarding/model";

export type DefaultTimeZone = "UTC" | "LOCAL";

export type UserPreferences = Partial<{
  defaultTimeZone: DefaultTimeZone;
  impersonate: string;
  showSparseFields: Record<string, boolean>;
  showTemplateSparseFields: Record<string, boolean>;
  collapsedSidebar: boolean;
  fontSizes: Record<string, number>;
  recentItems: RecentItem[];
  onboarding: OnboardingData;
  defaultWorkspace: string;
}> &
  Record<string, any>;

export interface IPreferencesStorage<T = UserPreferences> {
  get(): T;

  upsertKey(key: keyof T | string, value: any): T;

  removeKey(key: keyof T | string): T;

  set(data: T): T;

  load(): Promise<T>;
}

export class UserPreferencesStorage implements IPreferencesStorage<UserPreferences> {
  private readonly update = (func: Function, args: any) => func.apply(this, args);

  constructor(private remote: UpsolverRemoteStorage<UserPreferences>) {
    this.update = debounce(this.update, 200);
  }

  get(): UserPreferences {
    return UpsolverLocalStorage.get();
  }

  removeKey(key: string): UserPreferences {
    const local = UpsolverLocalStorage.removeKey(key);
    this.update(this.remote.removeKey, [key]);
    return local;
  }

  set(data: any): UserPreferences {
    const local = UpsolverLocalStorage.set(data);
    this.update(this.remote.set, [local]);
    return local;
  }

  upsertKey(key: string, value: any): UserPreferences {
    const local = UpsolverLocalStorage.upsertKey(key, value);
    this.update(this.remote.upsertKey, [key, local[key]]);
    return local;
  }

  load(): Promise<UserPreferences> {
    return this.remote.get().then((x) => UpsolverLocalStorage.set(Object.assign(UpsolverLocalStorage.get(), x)));
  }
}
