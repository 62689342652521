import React from "react";
import { FormInputProps, getInputId } from "../FormInputTypes";
import classNames from "classnames";
import ReactMarkdown from "react-markdown";
import { markdownProps } from "../MarkdownRenderers";
import Box from "@mui/material/Box";
import { observer, useObserver } from "mobx-react-lite";

type FromItemDecoratorProps = Partial<FormInputProps & { inputRef: any }>;

/**
 * Contains the footer of a form input including:
 *  - optional description in markdown using the description from the input's metadata
 *  - error using props.input.error
 * @param {FromItemDecoratorProps} props
 * @returns {JSX.Element}
 * @constructor
 */
export function FormItemDecoratorFooter(props: FromItemDecoratorProps) {
  return useObserver(() => (
    <Box width="100%" className="description-container" hidden={props.hidden || props.input?.metadata?.hidden}>
      {props.input.error && <ReactMarkdown className="description error">{props.input.error}</ReactMarkdown>}
    </Box>
  ));
}

/**
 * Displays the description of a form input
 * @param {FromItemDecoratorProps} props
 * @returns {JSX.Element}
 * @constructor
 */
export function FormItemDescription(props: FromItemDecoratorProps) {
  return useObserver(() => (
    <Box width="100%" className="description-container" hidden={props.hidden || props.input?.metadata?.hidden}>
      {props.compact ? (
        <div className="description" />
      ) : (
        props.input.metadata.description && (
          <ReactMarkdown className="description" {...markdownProps}>
            {props.input.metadata.description}
          </ReactMarkdown>
        )
      )}
    </Box>
  ));
}

/**
 * Displays a label of a form input
 * @type {React.FunctionComponent<FromItemDecoratorProps>}
 */
export const FormInputLabel = observer((props: FromItemDecoratorProps) => {
  const { input, labelPrefix, required } = props;
  const label = props.label || input.$label;
  const rules = input.$rules || "";
  const optional = !required && rules.indexOf("required") === -1;
  return (
    <label htmlFor={getInputId(input)}>
      {labelPrefix}
      {label}&nbsp;<em className="optional">{optional && "(optional)"}</em>
    </label>
  );
});
FormInputLabel.displayName = "FormInputLabel";

/**
 * Container for form inputs. Adds:
 *  - label above
 *  - description & error below
 *  - optional field indicator
 *  - validating/hidden classes using the input state
 *  also supports child inputs (e.g., no label or description in repeated array inputs)
 * @param {FromItemDecoratorProps} props
 * @returns {JSX.Element}
 * @constructor
 */
const FormItemDecorator: React.FC<FromItemDecoratorProps> = observer((props: FromItemDecoratorProps) => {
  const { input, children, hideLabel, className } = props;
  if (!input) {
    console.log(`Got FormItemDecorator with no input, ${props.label}`);
  }
  const metadata = input.metadata;

  const full = input.incremental === false || metadata.simple || hideLabel === false;
  if (full) {
    return (
      <div
        ref={props.inputRef}
        data-automation-name={`${metadata.type}-${input.name}`}
        className={classNames(
          "form-input",
          {
            validating: input.validating,
            hidden: metadata.hidden && !metadata.everHadErrors,
          },
          metadata.type,
          className
        )}
      >
        {!(hideLabel === true) && <FormInputLabel input={input} label={props.label} />}
        <FormItemDescription input={input} />
        {children}
        <FormItemDecoratorFooter input={input} />
      </div>
    );
  } else {
    return (
      <div
        ref={props.inputRef}
        className={classNames(
          "form-input",
          {
            validating: input.validating,
            hidden: metadata.hidden && !input.error,
          },
          metadata.type,
          className
        )}
      >
        {children}
      </div>
    );
  }
});
FormItemDecorator.displayName = "FormItemDecorator";

export default FormItemDecorator;
