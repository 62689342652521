import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { values } from "mobx";
import { observer } from "mobx-react";
import ReactMarkdown from "react-markdown";

import { Button } from "./Button";
import { UFormField } from "./UFormField";
import { PopconfirmButton } from "./General";
import { Disabler } from "./FormInputs/Disabler";
import { GlobalContext } from "../../GlobalContext";
import { BaseFormWithArrayFields } from "../BaseForm";
import { Field as MField } from "../type-definitions/mobx-react-form";
import { HideFormFieldProvider } from "../forms/Hide";
import StepView, { DEFAULT_STEP, Steps } from "./Step";
import { UField } from "../api/contracts/UField";

interface SimpleFormViewProps<T> {
  form: BaseFormWithArrayFields;
  onSuccess: (values: T, success: () => void) => any;
  onClose?: () => any;
  submitText?: string;
  children?: any;
  stateless?: boolean;
  onError?: (error: any) => void;
  disabled?: boolean;
  className?: string;
}

interface FormViewProps<T> {
  form: BaseFormWithArrayFields;
  disabled?: boolean;
  onSuccess?: (values: T, callback?: () => any) => void;
  submitText?: string;
  compact?: boolean;
  submitDisabled?: boolean;
  extraActions?: any[];
  children?: any;
  successAfterActions?: boolean;
  cancellable?: boolean;
  onClose?: (event?: any) => void;
  closeText?: string;
  onRemove?: () => void;
  stateless?: boolean;
  loading?: boolean;
  hideActions?: boolean;
  vertical?: boolean;
}

export type FormContextValue = { formState: any; onValidationError: (error: any) => boolean };
export type WithFormContextProps = { formContext: FormContextValue };

export const FormContext = React.createContext<FormContextValue>(null);

export function withFormContext(component: React.ComponentType<any>) {
  return (props: any) => (
    <FormContext.Consumer>
      {(formContext) => React.createElement(component, Object.assign({ formContext }, props))}
    </FormContext.Consumer>
  );
}

export function SimpleFormView<T = any>(props: SimpleFormViewProps<T>) {
  const { formStore } = useContext(GlobalContext);
  const history = useHistory();
  const location = useLocation();
  const disposers = useRef<Array<() => void>>([]);
  const [formState, setFormState] = useState();

  useEffect(() => {
    let state: any = null;
    const stateType = typeof location.state;
    if (stateType !== "object") {
      location.state = {};
      const newState = formStore.getNew();
      state = newState.state;
      location.state.id = newState.id;
      history.replace(location);
    } else if (stateType === "object" && location?.state) {
      if (location.state.id) {
        state = formStore.get(location.state.id);
      } else {
        const newState = formStore.getNew();
        state = newState.state;
        location.state.id = newState.id;
        history.replace(location);
      }
    }

    setFormState(state);

    if (!props.stateless && state) {
      props.form.each((c: MField) => {
        if (c.size === 0) {
          const value = state[c.path];
          if (typeof value !== "undefined") {
            c.set(value);
          }

          props.form.observe({
            path: c.path,
            key: "value",
            call: ({ change }: any) => {
              state[c.path] = change.newValue;
            },
          });
        }
      });
    }
    return () => {
      if (disposers.current) {
        disposers.current.forEach((d) => d());
      }
    };
  }, [props.form]);

  const success = () => {
    const result = props.onSuccess(props.form.values(), () => {
      formStore.remove(history.location.state.id);
    });
    if (result instanceof Promise) {
      result.catch((err) => {
        if (process.env.REACT_APP_ENV_TYPE !== "prod") {
          console.log("Error submitting form", err);
        }
        if (err && err.response) {
          props.form.onValidateError(err.response.body, { override: false });
        }
        if (props.onError) {
          props.onError(props.form.errors());
        }
      });
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    props.form.onSubmit(e, {
      onSuccess: success,
      onError: () => {
        const errors = props.form.errors();
        if (errors && process.env.REACT_APP_ENV_TYPE !== "prod") {
          console.log("Error submitting form", errors);
        }
        if (props.onError) {
          props.onError(errors);
        }
      },
    });
  };

  return (
    <FormContext.Provider value={{ formState, onValidationError: props.form.onValidationError }}>
      <form onSubmit={handleSubmit} className={props.className}>
        {props.children}
      </form>
    </FormContext.Provider>
  );
}

function getErrorFieldKey(errors: any, parentKeys: string[] = []): any {
  return Object.entries(errors).find(([k, v]) => {
    if (v !== null && typeof v === "object") {
      return getErrorFieldKey(v, parentKeys.concat([k]));
    } else {
      if (typeof v === "string" && v.length > 0) {
        return parentKeys;
      } else {
        return null;
      }
    }
  });
}

// Group form fields in steps
function toSteps(fields: readonly any[]): Steps {
  return fields.reduce((acc, curr) => {
    const name = curr.metadata.step?.name ?? DEFAULT_STEP;
    if (!acc[name]) {
      acc[name] = {};
    }

    if (!acc[name].fields) {
      acc[name].fields = [];
    }

    acc[name].fields = acc[name].fields.concat(curr);

    if (!acc[name].description) {
      acc[name].description = curr.metadata.step?.description;
    }

    return acc;
  }, {});
}

// export const FormView = observer(<T extends {} = any>(props: FormViewProps<T>) => {
function FormView<T = any>(props: FormViewProps<T>) {
  const { formStore } = useContext(GlobalContext);
  const location = useLocation();
  const history = useHistory();
  const scrollRef = useRef<Record<string, HTMLElement>>({});

  const scrollToError = (errors: any) => {
    if (errors) {
      const fieldKeys = getErrorFieldKey(errors);
      if (fieldKeys?.length > 0 && scrollRef.current.hasOwnProperty(fieldKeys[0])) {
        if (fieldKeys[0] === "Advanced") {
          props.form.$("Advanced").metadata.openContainer = true;
        }
        const scrollRefElement = scrollRef.current[fieldKeys[0]];
        if (scrollRefElement?.scrollIntoView) {
          scrollRefElement.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
        }
      }
    }
  };

  const handleClose = () => {
    const { id = undefined } = location.state || {};
    if (id) {
      formStore.remove(id);
    }

    if (props.onClose) {
      props.onClose();
    } else {
      history.goBack();
    }
  };

  const { form, disabled, onSuccess, hideActions } = props;
  const submitText = props.submitText || "Submit";
  const compact = props.compact;

  const steps: Steps = toSteps(values(form.fields));
  const extra = props.extraActions && props.extraActions.map((v, i) => <span key={i}>{v}</span>);
  const submitBtn = props.submitText && (
    <Button key="submit" type="submit" loading={props.loading} disabled={props.submitDisabled || disabled}>
      {submitText}
    </Button>
  );

  return (
    <HideFormFieldProvider>
      <div className="create-feature-form">
        <SimpleFormView form={form} onSuccess={onSuccess} onError={scrollToError} stateless={props.stateless}>
          <Disabler disabled={disabled}>
            {/* Render All Steps */}
            <span>
              {Object.entries(steps)
                .filter(([name, step]) => name !== DEFAULT_STEP)
                .map(([name, step]) => (
                  <StepView name={name} description={step.description} key={name}>
                    {step.fields.map((field) => (
                      <UFormField
                        scrollRef={(el) => (scrollRef.current[field.key] = el)}
                        compact={compact}
                        vertical={props.vertical}
                        key={field.key}
                        input={field}
                      />
                    ))}
                  </StepView>
                ))}
            </span>

            {/* Render All non-step fields in default step */}
            {steps.DEFAULT_STEP && (
              <>
                {steps.DEFAULT_STEP.fields.map((field) => (
                  <UFormField
                    scrollRef={(el) => (scrollRef.current[field.key] = el)}
                    compact={compact}
                    vertical={props.vertical}
                    key={field.key}
                    input={field}
                  />
                ))}
              </>
            )}

            {props.children}
          </Disabler>

          {form.error && <ReactMarkdown className="form description error">{form.error}</ReactMarkdown>}

          {!hideActions && (
            <div className="actions">
              {props.successAfterActions ? [extra, submitBtn] : [submitBtn, extra]}
              {(props.cancellable || props.onClose) && (
                <Button type="cancel" disabled={disabled} onClick={handleClose}>
                  {props.closeText || "Cancel"}
                </Button>
              )}

              {props.onRemove && (
                <PopconfirmButton
                  action={props.onRemove}
                  title="Are you sure?"
                  buttonProps={{ className: "pipeline-op-remove", icon: "delete" }}
                  use={true}
                >
                  Remove
                </PopconfirmButton>
              )}
            </div>
          )}
        </SimpleFormView>
      </div>
    </HideFormFieldProvider>
  );
}

(FormView as React.FC).defaultProps = {
  loading: false,
};

const observableFormView = observer(FormView);
export { observableFormView as FormView };
