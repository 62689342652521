import { action, observable, reaction } from "mobx";
import { MissingIntegrationException } from "./MissingIntegrationException";
import { setTimeoutVisible } from "../core/PageVisibilityStore";
import { BasicApi } from "../core/api/Api";
import { ExternalIntegrationState, IntegrationState, integrationStates, integrationViews } from "./integrations-store";

class CloudIntegrationState {
  clearTimeout: Function;
  resolve: () => void;
  reject: () => void;
  @observable canceled: boolean = false;
  @observable current: IntegrationState = integrationStates.INITIAL;
  @observable view: any | null;

  @action
  start() {
    this.clear();
    this.current = integrationStates.STARTED;
  }

  @action
  complete() {
    this._stopCheck();
    this.current = integrationStates.COMPLETED;
  }

  require(exception: MissingIntegrationException): Promise<void> {
    return new Promise(
      action("promise created", (resolve, reject) => {
        this.current = integrationStates.REQUIRED;
        this.view = integrationViews(exception);
        this.resolve = resolve;
        this.reject = reject;
      })
    );
  }

  check(payload: { clazz: string }, callback: ({ clazz }: { clazz: string }) => any) {
    if (this.clearTimeout) {
      this.clearTimeout();
    }
    this.clearTimeout = setTimeoutVisible(() => {
      callback(payload);
    }, 1000);
  }

  cancel = () => {
    if (this.current === integrationStates.COMPLETED) {
      this.resolve && this.resolve();
    } else {
      this.reject && this.reject();
    }

    this.canceled = true;
    this.view = null;
    this.current = integrationStates.REQUIRED;
    this._stopCheck();
  };

  _stopCheck() {
    if (this.clearTimeout) {
      this.clearTimeout();
    }
  }

  clear() {
    this.current = integrationStates.INITIAL;
    this.canceled = false;
    this._stopCheck();
  }
}

export class CloudIntegrationStore {
  _api: BasicApi;
  _op: string = "cloud-integration";

  state: CloudIntegrationState;

  _completionCallbacks: { [key: string]: Function } = {};

  constructor(api: BasicApi) {
    this._api = api;
    this.state = new CloudIntegrationState();
    reaction(
      () => this.state.current === integrationStates.COMPLETED,
      (completed) => {
        if (completed) {
          Object.values(this._completionCallbacks).forEach((f: Function) => f());
        }
      }
    );
  }

  @action.bound
  async pendingIntegration(): Promise<ExternalIntegrationState | null> {
    return this._api.get(`${this._op}/`).then((pendingIntegrations: any[]) => {
      let theIntegration = pendingIntegrations.length > 0 ? pendingIntegrations[pendingIntegrations.length - 1] : null;
      if (theIntegration) {
        this.state.start();
        this.checkIntegrationCompleted(theIntegration.externalIntegration);
      }
      return theIntegration;
    });
  }

  @action.bound
  validateIntegration(payload: any, method?: string): Promise<{ integration: any; url: string }> {
    return this._api.post(`${this._op}/validate/`, payload, { method: method });
  }

  @action.bound
  startIntegration(payload?: { clazz: string }, method?: string): Promise<string> {
    return this._api.post(`${this._op}/`, payload, { method: method }).then((url) => {
      this.state.start();
      this.checkIntegrationCompleted(payload);
      return this._api.redirectUrl(url);
    });
  }

  @action.bound
  checkIntegrationCompleted(payload: any): Promise<boolean> {
    return this._api
      .post(`${this._op}/check`, payload, null, null, { 0: () => false })
      .then((completed) => {
        if (completed) {
          this.state.complete();
          this._api.setLocation();
        } else if (!this.state.canceled) {
          this.state.check(payload, this.checkIntegrationCompleted);
        } else {
          this.state.cancel();
        }
        return completed;
      })
      .catch((err) => {
        if (err && err.response && err.response.body) {
          throw err;
        } else {
          this.state.check(payload, this.checkIntegrationCompleted);
        }
      });
  }

  @action.bound
  private async cancelPendingIntegration(
    payload: ExternalIntegrationState,
    integrationClazz: string
  ): Promise<boolean> {
    await this._api.post(`${this._op}/delete`, payload.externalIntegration || payload);
    // check if the integration is still needed in case the user canceled after the integration actually completed
    const required = await this.getRequiredIntegrations(integrationClazz);
    if (required.length > 0 || integrationClazz === "AzureMainIntegrationRequest") {
      this.state.cancel();
      return true;
    } else {
      return false;
    }
  }

  @action.bound
  setRequired(exception: MissingIntegrationException, isOnboarding: boolean = true): Promise<void> {
    return this.state.require(exception);
  }

  private getRequiredIntegrations = (clazz: string): Promise<MissingIntegrationException[]> =>
    this._api.get(`${this._op}/required-integrations/${clazz}`);

  @action.bound
  getRequired(clazz: string): Promise<MissingIntegrationException[]> {
    return this.getRequiredIntegrations(clazz).then((integs) => {
      if (integs.length) {
        this.state.require(integs[0]);
      }
      return integs;
    });
  }

  @action.bound
  done(): void {
    this.state.cancel();
  }

  @action.bound
  async cancel(integration: ExternalIntegrationState, integrationClazz: string): Promise<boolean> {
    return await this.cancelPendingIntegration(integration, integrationClazz);
  }

  @action.bound
  clear(): void {
    this.state.clear();
  }

  generatePolicy(payload: { clazz: string }): Promise<string> {
    return this._api.post(`${this._op}/generate-policy`, payload);
  }

  onCompleted(key: string, callback: Function): Function {
    this._completionCallbacks[key] = callback;
    return () => delete this._completionCallbacks[key];
  }

  componentWillUnmount() {
    this.state.cancel();
  }
}
