import React from "react";
import { observer } from "mobx-react-lite";
import { FlatButton } from "./core/views/Button";

export const MainLoadingPlaceholder = observer(() => {
  const api = window.globalStores?.api;

  return (
    <React.Fragment>
      <div className="initial-header">
        <div className="initial-logo">
          <svg width="150" height="49" viewBox="0 0 150 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M22.8415 2.70273C21.6512 1.46098 19.3274 0.219238 17.1169 0.219238C16.4368 0.219238 15.8133 0.332124 15.1899 0.557896C13.7729 1.12233 12.0725 2.64629 12.0725 6.5973V22.4578C6.63139 21.8934 2.43718 17.2086 2.43718 11.7901C2.43718 8.29059 4.13753 5.01689 7.02814 2.98495C7.59493 2.58984 7.70828 1.85609 7.31153 1.29165C6.91478 0.783668 6.17796 0.614339 5.61118 1.00944C2.09711 3.49293 -4.32423e-07 7.50039 -4.32423e-07 11.7901C-4.32423e-07 18.5632 5.27111 24.264 12.0159 24.8848V32.3353C12.0159 33.0126 12.5826 33.5206 13.2061 33.5206C13.8862 33.5206 14.3964 32.9562 14.3964 32.3353V24.8848C21.1978 24.264 26.4689 18.5632 26.4689 11.7901C26.4689 8.40347 25.1653 5.12978 22.8415 2.70273ZM24.0884 11.7901C24.0884 17.2086 19.8942 21.8934 14.5097 22.4578V6.65374C14.5097 5.18622 14.7931 3.3236 16.1534 2.81562C16.4368 2.70273 16.7769 2.64629 17.1736 2.64629C18.6472 2.64629 20.3476 3.54938 21.1411 4.39602C23.0115 6.37153 24.0884 9.02435 24.0884 11.7901Z"
              fill="#FAFAFA"
            />
            <path
              d="M51.1807 24.7155H48.8569V22.9093H48.8002C47.7233 24.6026 45.7963 25.1671 44.0392 25.1671C41.092 25.1671 38.9382 23.9253 38.9382 20.8774V12.6367H41.262V20.4259C41.262 22.232 42.3956 23.248 44.8327 23.248C46.9298 23.248 48.8002 22.0063 48.8002 19.6357V12.6367H51.1241V24.7155H51.1807Z"
              fill="#FAFAFA"
            />
            <path
              d="M71.9818 20.934C71.9818 22.0064 72.8886 23.361 75.8359 23.361C78.8399 23.361 79.5767 22.345 79.5767 21.442C79.5767 20.5953 79.1233 20.0873 77.2529 19.8051L73.3987 19.2971C71.6417 19.0713 70.1114 17.9425 70.1114 16.0799C70.1114 13.6528 72.2651 12.1853 75.6659 12.1853C79.6334 12.1853 81.3904 13.8786 81.6171 16.2492H79.2933C79.2366 15.0075 78.0464 13.9915 75.6659 13.9915C74.1922 13.9915 72.4352 14.3866 72.4352 15.8541C72.4352 16.6443 72.8319 17.1523 74.4756 17.3781L77.763 17.8296C80.8236 18.2247 82.0139 19.3536 82.0139 21.0469C82.0139 23.869 79.6334 25.1672 75.8926 25.1672C73.1153 25.1672 69.9413 24.4334 69.6579 20.934H71.9818Z"
              fill="#FAFAFA"
            />
            <path
              d="M90.9124 12.1853C95.6734 12.1853 97.9406 15.1768 97.9406 18.6762C97.9406 22.1757 95.6167 25.1672 90.9124 25.1672C86.1514 25.1672 83.8843 22.1757 83.8843 18.6762C83.8276 15.1768 86.1514 12.1853 90.9124 12.1853ZM90.9124 14.1608C87.5684 14.1608 86.3215 16.475 86.3215 18.6762C86.3215 20.8775 87.5684 23.1917 90.9124 23.1917C94.2565 23.1917 95.5034 20.8775 95.5034 18.6762C95.5034 16.475 94.2565 14.1608 90.9124 14.1608Z"
              fill="#FAFAFA"
            />
            <path d="M100.491 8.0647H102.815V24.7154H100.491V8.0647Z" fill="#FAFAFA" />
            <path
              d="M115.284 12.6367H117.891L112.394 24.772H109.843L104.345 12.6367H107.009L111.203 22.232L115.284 12.6367Z"
              fill="#FAFAFA"
            />
            <path
              d="M121.349 19.3536C121.519 21.8371 123.786 23.1917 125.826 23.1917C127.13 23.1917 128.944 22.7401 129.737 21.0469H132.174C131.041 23.9819 128.32 25.1672 125.883 25.1672C121.122 25.1672 118.798 22.1757 118.798 18.6762C118.798 15.1768 121.122 12.1853 125.883 12.1853C129.114 12.1853 132.458 14.0479 132.458 19.0713V19.3536H121.349ZM130.077 17.5474C129.737 15.3461 128.037 14.1044 125.826 14.1044C123.673 14.1044 121.746 15.3461 121.405 17.5474H130.077Z"
              fill="#FAFAFA"
            />
            <path
              d="M135.122 12.6368H137.445V14.8381H137.502C138.579 12.9191 140.166 12.1853 141.696 12.1853C142.263 12.1853 142.603 12.1853 143 12.2982V14.7817C142.433 14.6688 141.98 14.5559 141.356 14.5559C139.033 14.5559 137.445 15.9105 137.445 18.5069V24.6592H135.122V12.6368Z"
              fill="#FAFAFA"
            />
            <path
              d="M61.2129 12.3542C59.3991 12.3542 57.4721 13.0316 56.5652 14.3298H56.5085V12.6365H54.2414V29.4H56.5652L56.4519 19.071C56.4519 18.9581 56.4519 18.8452 56.4519 18.7323C56.4519 16.4181 57.8121 14.2733 60.9861 14.2733C64.1035 14.2733 65.4637 16.4181 65.4637 18.7323C65.4637 21.498 63.7067 23.1913 60.9861 23.1913C60.2493 23.1913 59.5692 23.0784 59.0024 22.8526V24.7717C59.7392 24.9975 60.476 25.1104 61.2695 25.1104C64.9536 25.1104 67.9009 22.7962 67.9009 18.7323C67.9009 14.6684 64.897 12.3542 61.2129 12.3542Z"
              fill="#FAFAFA"
            />
          </svg>
        </div>
        <ul className="initial-navigation" />
      </div>

      {api?.impersonateOrganizationId && (
        <div style={{ display: "flex", gap: 10, padding: 10 }}>
          <FlatButton
            type="secondary"
            onClick={() => {
              api.forceGlobalApi();
              window.location.reload();
            }}
          >
            Use Global API
          </FlatButton>

          <FlatButton
            type="secondary"
            onClick={() => {
              api.clearImpersonate();
              window.location.reload();
            }}
          >
            Clear Impersonation
          </FlatButton>
        </div>
      )}

      <span className="loading-logo-container">
        <div className="logo-spinner" />
      </span>
    </React.Fragment>
  );
});
