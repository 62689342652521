import { action, computed, observable } from "mobx";
import React, { useContext } from "react";
import request, { Request, Response } from "superagent";
import { setTimeoutVisible } from "../PageVisibilityStore";
import * as Mousetrap from "mousetrap";
import ErrorStore from "./ErrorStore";
import { NotificationListener } from "../../AppNotification";
import { History } from "history";
import { NewUserRequest, User } from "./contracts/User";
import { RequestError } from "./RequestError";
import { MissingIntegrationException } from "../../cloud-integrations/MissingIntegrationException";
import { StepsLocalAPIStatusResult } from "./contracts/NoLocalApi";
import { NewOrganizationRequest, Organization, organizationExpired, UserSignupRequest } from "./contracts/Organization";
import { UpsolverLocalStorage } from "../UpsolverLocalStorage";
import { GlobalContext } from "../../GlobalContext";
import globalApiLocation, {
  getGlobalApiFallback,
  globalWebSocketsApiLocation,
  webSocketsApiLocation
} from "./globalApiLocation";
import { LoginReply } from "./contracts/login";
import { v4 as uuidGenerator } from "uuid";
// import { NIL as testUuid } from "uuid";
import { KnownNoLocalApiReasons, NoLocalApiPath, noLocalApiPathBase } from "./noLocalApi";
import { UnsupportedOperationError } from "./UnsupportedOperationError";
import { captureException } from "@sentry/react";

export type ApiLoggingLevel = "Info" | "Warn" | "Error" | "Debug";
export const ApiLoggingLevels: ApiLoggingLevel[] = ["Info", "Warn", "Error", "Debug"];
export type ErrorHandlers = Record<number, (errors: RequestError) => void> & {
  connectionError?: (error?: any) => void;
  ignoreNoLocalApi?: boolean;
  errorMessage?: string;
};
type Workspace = "ALL" | string;

const getWorkspace = (workspace: Workspace) => (workspace !== "ALL" ? workspace : "");

const serviceUnavailableErrorCode = 503;

export const logMiddleware = (req: Request) => {
  // req.on("response", function(res) {
  //   console.groupCollapsed(req);
  //   console.log(res);
  //   console.groupEnd();
  // });

  req.on("error", function (error: unknown) {
    console.groupCollapsed(req);
    console.log(arguments);
    console.groupEnd();
  });

  return req;
};

function generateRequestId() {
  // return testUuid;
  return uuidGenerator();
}

const logoutOp = "users/logout";
const badLocalApiResponseError = "Got local-api with no dns address";

const initialSearchParams = new URLSearchParams(window.location.search);
const localApiParam = "local.api.address";

export type HttpMethod = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

const userHeaderNames = ["name", "email", "organization", "role", "phone", "company"];
const valid_status_file_types = ["text/markdown", "binary/octet-stream"];

class ServerErrorStatusChecker {
  clearServerStatusChecker: Function;
  serverStatusDown: boolean = false;
  serverDownError: string;

  constructor(private history: History) {
    this.startServerStatusChecker();
  }

  startServerStatusChecker() {
    if (this.clearServerStatusChecker) {
      this.clearServerStatusChecker();
    }
    this.clearServerStatusChecker = setTimeoutVisible(this.startServerStatusChecker.bind(this), 15000);
    request("get", "/server-status/status-down.md")
      .type("text/markdown")
      .use(logMiddleware.bind(this))
      .use(preventCacheByAddingTime)
      .then((res) => {
        if (valid_status_file_types.includes(res.type)) {
          if (this.history.location.pathname !== "/server-error") {
            this.serverDownError = res.text;
            this.history.push("/server-error");
          }
          this.serverStatusDown = true;
        } else if (this.serverStatusDown) {
          this.serverStatusDown = false;
          this.history.goBack();
        }
      })
      .catch(() => {
        if (this.serverStatusDown) {
          this.serverStatusDown = false;
          this.history.goBack();
        }
      });
  }
}

export function hasOrganizations(reply: any): reply is { organizations: Organization[] } {
  return Array.isArray(reply?.organizations);
}

export const apiImpersonateOrganizationHeader = "X-Api-Impersonate-Organization";
type SetRequired = (exception: MissingIntegrationException) => Promise<void>;

export interface BasicApi {
  localApiAddr?: string;

  setLocation(): Promise<string>;

  shortGet<T = any>(op: string, errHandlers: any): any;

  get<T = any>(
    op: string,
    body?: any,
    query?: any,
    headers?: any,
    errorHandlers?: ErrorHandlers,
    ignoreWorkspace?: boolean,
    shortTimeout?: boolean
  ): Promise<T>;

  post<T = any>(
    op: string,
    body?: any,
    query?: any,
    headers?: any,
    errorHandlers?: ErrorHandlers,
    contentType?: string
  ): Promise<T>;

  put<T = any>(op: string, body?: any, query?: any, headers?: any, errorHandlers?: ErrorHandlers): Promise<T>;

  patch<T = any>(op: string, body?: any, query?: any, headers?: any, errorHandlers?: ErrorHandlers): Promise<T>;

  download(op: string, contentType?: string, query?: any, header?: any, errorHandlers?: ErrorHandlers): Promise<any>;

  remove(op: string, body?: any): Promise<any>;

  upload(op: string, file: File, fields?: Record<string, string>): Promise<any>;

  redirectUrl(url: string): Promise<string>;

  postUIError(payload: { error: string; info: { componentStack: string } }): void;
}

export function useBasicApi() {
  return useContext(GlobalContext).api as BasicApi;
}

export function useFullApi() {
  return useContext(GlobalContext).api;
}

const disallowNoLocalApiOrgs = Object.freeze([
  "0914e3be-d02e-494b-8ff2-f47f5706f912",
  "d1f114fa-84db-4825-9e99-7a1013d90364",
]);

export class Api implements BasicApi {
  @observable outOfDate: boolean = false;
  @observable impersonateOrganizationId: string = "";
  @observable workspace: Workspace = "";
  @observable omniSearch = false;
  _location: Promise<string>;
  _authenticationErrorHandlers: Array<(error: RequestError) => void> = [];
  _onUserHandlers: Array<(user: User) => void> = [];
  _lastSeenEmail: string | null;
  _revision = (process.env.REACT_APP_REVISION || "").trim();
  _notificationListener: NotificationListener<any>;
  _lastConnectionError: number = 0;
  _storeProvider: (api: Api) => { setRequired: SetRequired };
  _envType = process.env.REACT_APP_ENV_TYPE;
  initialLocation: string;
  serverErrorStatusChecker: ServerErrorStatusChecker;
  userData: any;

  _history: History;

  loggingLevel: ApiLoggingLevel | null = "Info";

  @observable localApiWebSocketAddress: string = null;
  @observable localApiAddr: string;
  @observable.shallow multiOrgs: Organization[] = [];
  @observable.ref authenticationResult: any;

  @computed
  get deployEnvironment(): "staging" | "prod" | "local" | null {
    try {
      const host = window.location.hostname;
      if (host === "app.upsolver.com") {
        return "prod";
      } else if (host.endsWith(".upsolver.com")) {
        return "staging";
      } else if (host.match(/localhost:\d+/g)) {
        return "local";
      } else {
        return null;
      }
    } catch {
      return null;
    }
  }

  get location(): string {
    return this.localApiAddr || this.initialLocation;
  }

  _poster(type: string) {
    return (event: any) => {
      captureException({ type, event });
    };
  }

  _getLogPoster() {
    const envType = process.env.REACT_APP_ENV_TYPE;
    /* eslint-disable no-fallthrough */
    switch (envType) {
      case "demo":
      case "prod":
      case "prod-gateway":
        return this._poster("error-log");
      case "integ":
      case "office":
      // return poster('http://10.0.0.161:28082/ingest/?apitoken=xFLwu6MBvH8H1w6JcTwB5RYWbGBaJlo3');
      case "local-gateway":
      case "local":
      default:
        return (p: any) => console.log("There was an error: ", p);
    }
    /* eslint-enable no-fallthrough */
  }

  _clearRevisionChecker: Function;
  _errorStore: ErrorStore;

  _startRevisionChecker() {
    if (this._clearRevisionChecker) {
      this._clearRevisionChecker();
    }
    this._clearRevisionChecker = setTimeoutVisible(this._startRevisionChecker.bind(this), 60000);
    request("get", "/revision")
      .use(logMiddleware.bind(this))
      .use(preventCacheByAddingTime)
      .then((x) => (this.outOfDate = x.text.trim() !== this._revision))
      .catch(() => {});
  }

  constructor(
    notificationListener: NotificationListener<any>,
    storesProvider: (api: Api) => { setRequired: SetRequired },
    public browserHistory: History,
    errorStore: ErrorStore
  ) {
    this.post = this.post.bind(this);
    this.postGlobal = this.postGlobal.bind(this);
    Mousetrap.bind(["command+shift+k", "ctrl+shift+k"], this._showOmniSearch);
    this._history = browserHistory;
    this._errorStore = errorStore;
    console.log(process.env.REACT_APP_ENV_TYPE);
    if (process.env.REACT_APP_ENV_TYPE !== "local") {
      this._startRevisionChecker();
      this.serverErrorStatusChecker = new ServerErrorStatusChecker(browserHistory);
    }

    this._notificationListener = notificationListener;
    this._storeProvider = storesProvider;
    this._authenticationErrorHandlers = [];
    const impersonateOrgId = new URLSearchParams(window.location.search).get("impersonate");
    this.impersonateOrganizationId = impersonateOrgId || UpsolverLocalStorage.get()["impersonate"];
    this.workspace = getWorkspace(UpsolverLocalStorage.get()["workspace"]);
  }

  _showOmniSearch = action((e: KeyboardEvent) => {
    e.stopPropagation();
    e.preventDefault();
    this.omniSearch = true;
  });

  private getUserData = () => ({
    ...this.userData,
    envType: process.env.REACT_APP_ENV_TYPE,
    revision: process.env.REACT_APP_REVISION,
  });

  public setUserData = (data: any) => {
    this.userData = data;
  };

  private redirectToNoLocalApi(originalRequestId: KnownNoLocalApiReasons = "") {
    const path: NoLocalApiPath = `/no-local-api/${originalRequestId}`;
    this._history.push(path);
  }

  setLocation(): Promise<string> {
    this.initialLocation = globalApiLocation();
    let dnsLocation = false;

    if (UpsolverLocalStorage.get()["forceGlobalApi"] !== "true") {
      const locationPromise = initialSearchParams.has(localApiParam)
        ? Promise.resolve(initialSearchParams.get(localApiParam))
        : this._requestByLocationObj({
            location: this.initialLocation,
            method: "GET",
            op: "environments/local-api",
            shortTimeout: true,
          });

      this._location = locationPromise
        .then((response) => {
          const dns = response.dnsInfo;
          dnsLocation = !!dns;
          if (response.environment && !dns) {
            const pathname = this._history.location.pathname;
            if (response.organization && organizationExpired(response.organization)) {
              this._history.push("/trial-over");
              return Promise.resolve(this.initialLocation);
            } else if (!pathname.startsWith(noLocalApiPathBase)) {
              const reason: KnownNoLocalApiReasons = "ui-initialize-request";
              this._getLogPoster()({
                type: "no-local-api",
                event: {
                  request: "environments/local-api",
                  userData: this.getUserData(),
                  reason,
                  url: window.location.href,
                },
              });
              this.redirectToNoLocalApi("ui-initialize-request" as KnownNoLocalApiReasons);
            }
            return Promise.reject(badLocalApiResponseError);
          } else {
            this.localApiWebSocketAddress = dns?.name ? webSocketsApiLocation(dns.name) : globalWebSocketsApiLocation();
            this.localApiAddr = dns ? `${dns.urlSchema || "https"}://${dns.name}` : null;
            return Promise.resolve(dns ? this.localApiAddr : this.initialLocation);
          }
        })
        .catch((err: any) => {
          //TODO: ts-convert superagent reseponse type
          if (err?.status === 405 || err?.status === 401) {
            return this.initialLocation;
          } else {
            throw err;
          }
        });
    } else {
      this.localApiAddr = null;
      this._location = Promise.resolve(getGlobalApiFallback());
      // this._location = this._requestByLocationObj({
      //   location: this.initialLocation,
      //   method: "GET",
      //   op: "environments/multi-tenant-api",
      //   shortTimeout: true,
      // })
      //   .then((response) => {
      //     const dns = response.dnsInfo;
      //
      //     dnsLocation = !!dns;
      //
      //     this.localApiWebSocketAddress = dns?.name ? webSocketsApiLocation(dns.name) : globalWebSocketsApiLocation();
      //     this.localApiAddr = dns ? `${dns.urlSchema || "https"}://${dns.name}` : null;
      //
      //     return Promise.resolve(dns ? this.localApiAddr : this.initialLocation);
      //   })
      //   .catch((err: any) => {
      //     captureException(err);
      //
      //     return this.initialLocation;
      //   });
    }

    return this._location;
  }

  forceGlobalApi() {
    UpsolverLocalStorage.upsertKey("forceGlobalApi", "true");
  }

  _workspacesMiddleware(req: any) {
    if (this.workspace) {
      req.set("X-Api-Workspace", this.workspace);
    }
  }

  _apiLoggingMiddleware(req: any) {
    if (this.loggingLevel != null) {
      req.set("X-Api-Logging-Level", this.loggingLevel);
    }
  }

  _authenticationMiddleware(req: Request) {
    if (this.impersonateOrganizationId) {
      req.set(apiImpersonateOrganizationHeader, this.impersonateOrganizationId);
    }
    //
    req.on("response", (res: any) => {
      if (res.statusCode === 401) {
        this._authenticationErrorHandlers.forEach((handler) => {
          handler(res?.body);
        });
      }
    });
    return req;
  }

  /**
   * Try to use error handler to handle to request error if one exists.
   * @param err
   * @param {ErrorHandlers} errorHandlers
   * @returns {boolean} True if matching handler succeeded. False if not match or handler failed.
   */
  _tryHandleErrorWithExternalHandler(err: RequestError, errorHandlers: ErrorHandlers = {}) {
    const handlers = errorHandlers || {};
    const hasSpecificHandler = handlers[err.status] || handlers[0];
    if (typeof hasSpecificHandler === "function") {
      try {
        hasSpecificHandler(err);
        return true;
      } catch {}
    }

    return false;
  }

  _handleError(
    errorHandlers?: any,
    original?: {
      method: HttpMethod;
      op: string;
      body: any;
      query: { uiRequestId: string } & any;
      headers: any;
      contentType?: string;
      errorHandlers?: ErrorHandlers;
      location: string;
      responseType?: string;
      ignoreWorkspace?: boolean;
    }
  ) {
    return (err: RequestError) => {
      console.error("Error while calling API", err, err.status, err.message, err.response);
      if((err as any)?.code === "ABORTED") {
        return;
      }
      if (
        (typeof err.status === "undefined" || err.status === serviceUnavailableErrorCode) &&
        original &&
        original.location !== globalApiLocation() &&
        err?.toString()?.startsWith("SyntaxError") === false
      ) {
        if (errorHandlers?.ignoreNoLocalApi === true) {
          return Promise.reject(err);
        }
        this.postRequestError(err);
        let userData;
        try {
          userData = this.getUserData();
        } catch {
          userData = {};
        }
        this._getLogPoster()({
          type: "no-local-api",
          event: {
            localTime: new Date().toISOString(),
            request: original && {
              reason: original.query?.uiRequestId,
              method: original.method,
              op: original.op,
              header: original.headers,
              location: original.location,
            },
            status: err.status ?? "undefined",
            userData,
            userAgent: navigator.userAgent,
            url: window.location.href,
          },
        });

        if (original.op === "metadata") {
          if (!this._history.location.pathname.startsWith(noLocalApiPathBase)) {
            this.redirectToNoLocalApi(original.query?.uiRequestId);
          }
        } else {
          const defaultMessage =
            original.method === "GET" ? "Try reloading the application." : "Wait for a minute and try again.";
          const errorMessage = errorHandlers?.errorMessage ?? defaultMessage;

          this._addNotification({
            level: "warning",
            title: "The client couldn't connect to the API cluster.",
            message: (
              <span>
                {errorMessage}
                <br />
                If the issue continues,&nbsp;
                <a href={`/no-local-api/${original.query?.uiRequestId}`}>click here to troubleshoot the connection.</a>
              </span>
            ),
            autoDismiss: 0,
          });
        }
        throw err;
      } else {
        const errorType = err.status && Math.floor(err.status / 100);
        const isInternalError = errorType && errorType === 5;
        const isRequestError = errorType && errorType === 4;
        const isConnectionError = typeof err.status === "undefined";

        const exception = err?.response?.body;
        const requestException = isRequestError && err.status === 400 && exception;
        const isMissingIntegrationException = requestException && exception.clazz === "MissingIntegration";

        let message = null;
        let messageType = null;
        let duration = 10;

        if (this._tryHandleErrorWithExternalHandler(err, errorHandlers)) {
          return;
        }

        if (requestException && exception.clazz === "UserSession$MustRunInOrganizationContextException") {
          this._history.push("/bad-org");
          return;
        }

        if (exception && exception.showInUI === true) {
          if (exception.showInModal) {
            return this._errorStore.push(exception).then((modalResult) => {
              if (modalResult.okay && modalResult.okay.shouldRetry && exception.forceInformation && original) {
                const queryWithForce = { ...(original.query || {}), force: true };
                return this._requestByLocation(
                  original.location,
                  original.method,
                  original.op,
                  original.body,
                  queryWithForce,
                  original.headers,
                  original.contentType,
                  original.errorHandlers,
                  original.responseType,
                  original.ignoreWorkspace
                );
              } else if (modalResult.cancel) {
                if (modalResult.cancel.goBack) {
                  this._history.goBack();
                }
                return Promise.reject(err);
              } else {
                return Promise.reject(err);
              }
            });
          } else if (exception.showRichNotification) {
            message = "Server returned: " + err.message + ", please try again.";
            messageType = "error";
            this._showRichNotification(err, message, messageType, duration, isConnectionError);
          } else {
            this._addNotification({
              level: "warning",
              title: "Whoops!",
              message: exception.detailMessage,
              messageText: exception.detailMessage,
              autoDismiss: 0,
            });
          }
        } else {
          if (isConnectionError && errorHandlers && errorHandlers.connectionError) {
            if (errorHandlers && errorHandlers.connectionError) {
              errorHandlers.connectionError(err);
            }
          } else if (isConnectionError) {
            messageType = "error";
            message =
              "Error while connecting to Upsolver Streams, please check your internet connection or try again in few minutes.";

            const now = new Date().getTime();
            if (now - this._lastConnectionError < duration * 1000) {
              throw err;
            }
            this._lastConnectionError = now;
          } else if (isInternalError) {
            messageType = "error";
            message = err.message + ", please try again.";
          } else if (isRequestError && err.status !== 401 && err.status != 400) {
            messageType = "warning";
            message = "Server returned: " + err.message + ", please check your request and try again.";
            duration = 5;
          } else if (isMissingIntegrationException && exception.payload) {
            const promise = this._storeProvider(this).setRequired(exception);
            if (original) {
              return promise.then(() => this._requestObj(original));
            } else {
              throw err;
            }
          }

          if(message) {
            this._showRichNotification(err, message, messageType, duration, isConnectionError);
          }
        }

        // Let the client handle the error
        throw err;
      }
    };
  }

  _showRichNotification(
    err: RequestError,
    message: string,
    messageType: string,
    duration: number,
    isConnectionError: boolean
  ) {
    if((err as any)?.code === "ABORTED") {
      return;
    }
    let traceData = "";
    let requestId = "";
    if (err.response && err.response.header["x-api-requestid"]) {
      requestId = err.response.header["x-api-requestid"];
      traceData = " (request id: " + requestId + ")";
    }
    const error = `${message} If the error persists please contact us ${traceData}.`;
    this._addNotification({
      level: messageType,
      title: "An Error Occurred",
      message: <span>{error}</span>,
      messageText: error,
      autoDismiss: duration,
      action: {
        label: "Contact Us",
        callback: function () {
          const message = isConnectionError
            ? "I keep getting error while connecting to Upsolver Streams"
            : `I keep getting error message while running the same operation over and over. ${
                requestId ? "The request id is " + requestId : ""
              }`;
          window.supportChat.sendMessage(`Hi, ${message}.`);
        },
      },
      id: requestId,
    });
  }

  _addNotification(notification: {
    level: string;
    title: string;
    message: React.ReactNode;
    messageText?: string;
    autoDismiss?: number;
    action?: any;
    id?: string;
  }) {
    if (this._notificationListener == null) {
      return;
    }
    this._notificationListener.publish(notification);
    if (notification?.level === "error") {
      this._getLogPoster()({
        type: "notification",
        event: {
          level: notification.level,
          id: notification.id,
          message: notification.messageText,
          userData: this.getUserData(),
          url: window.location.href,
        },
      });
    }
  }

  _requestObj(args: {
    method: HttpMethod;
    op: string;
    body?: any;
    query?: any;
    headers?: any;
    contentType?: string;
    errorHandlers?: ErrorHandlers;
    responseType?: string;
    ignoreWorkspace?: boolean;
    shortTimeout?: boolean;
  }): Promise<any> {
    const {
      method,
      op,
      body,
      query,
      headers,
      contentType,
      errorHandlers,
      responseType,
      ignoreWorkspace,
      shortTimeout,
    } = args;
    return this._request(
      method,
      op,
      body,
      query,
      headers,
      contentType,
      errorHandlers,
      responseType,
      ignoreWorkspace,
      shortTimeout
    );
  }

  _requestByLocationObj(args: {
    location: string;
    method: HttpMethod;
    op: string;
    body?: any;
    query?: any;
    headers?: any;
    contentType?: string;
    errorHandlers?: ErrorHandlers;
    responseType?: string;
    ignoreWorkspace?: boolean;
    shortTimeout?: boolean;
    timeoutOverride?: number;
  }): Promise<any> {
    const {
      location,
      method,
      op,
      body,
      query,
      headers,
      contentType,
      errorHandlers,
      responseType,
      ignoreWorkspace,
      shortTimeout,
      timeoutOverride,
    } = args;
    return this._requestByLocation(
      location,
      method,
      op,
      body,
      query,
      headers,
      contentType,
      errorHandlers,
      responseType,
      ignoreWorkspace,
      shortTimeout,
      timeoutOverride
    );
  }

  _requestByLocation(
    location: string,
    method: HttpMethod,
    op: string,
    body?: any,
    query?: any,
    headers?: any,
    contentType?: string,
    errorHandlers?: ErrorHandlers,
    responseType?: string,
    ignoreWorkspace?: boolean,
    shortTimeout?: boolean,
    timeoutOverride?: number,
    abort?: AbortSignal
  ): Promise<any> {
    const queryWithReqId = { ...query, uiRequestId: generateRequestId() };
    const initialReq = request(method, `${location}/${op}`)
      .withCredentials()
      .use(logMiddleware.bind(this))
      .use(this._authenticationMiddleware.bind(this))
      .use(this._apiLoggingMiddleware.bind(this))
      .query(queryWithReqId)
      .set("Content-Type", contentType || "application/json")
      .set(Object.assign({ "x-ui-revision": this._revision }, headers || {}));

    const req = ignoreWorkspace ? initialReq : initialReq.use(this._workspacesMiddleware.bind(this));

    if (responseType) {
      req.responseType(responseType);
    }

    if (shortTimeout) {
      req.timeout(15000);
    }

    if (timeoutOverride) {
      req.timeout(timeoutOverride);
    }

    const handleError = this._handleError(errorHandlers, {
      location,
      method,
      op,
      body,
      query: queryWithReqId,
      headers,
      contentType,
      errorHandlers,
      responseType,
      ignoreWorkspace,
    });

    if(abort) {
      abort.onabort = () => {
        req.abort();
      }
    }

    return req
      .send(body)
      .then((result: Response) => {
        const fixedResult = typeof result.body !== "undefined" && result.body !== null ? result.body : result.text;

        if (fixedResult.clazz == "Redirect") {
          window.location.assign(fixedResult.location);
          throw new Error("Redirected");
        }

        return fixedResult;
      })
      .catch(handleError);
  }

  _request(
    method: HttpMethod,
    op: string,
    body: any,
    query?: any,
    headers?: any,
    contentType?: string,
    errorHandlers?: ErrorHandlers,
    responseType?: string,
    ignoreWorkspace?: boolean,
    shortTimeout?: boolean,
    abort?: AbortSignal
  ): Promise<any> {
    return this._location.then((location) => {
      return this._requestByLocation(
        location,
        method,
        op,
        body,
        query,
        headers,
        contentType,
        errorHandlers,
        responseType,
        ignoreWorkspace,
        shortTimeout,
        null,
        abort
      );
    });
  }

  shortGet<T = any>(op: string, errorHandlers: any = null) {
    return this.get<T>(op, null, null, null, errorHandlers, null, true);
  }

  shortGetGlobal<T = any>(op: string, errorHandlers: any = null) {
    return this._requestByLocationObj({
      method: "GET",
      op,
      errorHandlers,
      shortTimeout: true,
      location: globalApiLocation(),
    });
  }

  getGlobal<T = any>(op: string, errorHandlers: any = null, query: any = null, headers: any = null) {
    return this._requestByLocationObj({
      method: "GET",
      op,
      errorHandlers,
      location: globalApiLocation(),
      query,
      headers,
    }) as Promise<T>;
  }

  get<T = any>(
    op: string,
    body?: any,
    query?: any,
    headers?: any,
    errorHandlers: ErrorHandlers = {},
    ignoreWorkspace?: boolean,
    shortTimeout?: boolean,
    abort?: AbortSignal
  ): Promise<T> {
    return this._request(
      "GET",
      op,
      body,
      query,
      headers,
      null,
      errorHandlers || {},
      null,
      ignoreWorkspace,
      shortTimeout,
      abort
    ) as Promise<T>;
  }

  post<T = any>(
    op: string,
    body?: any,
    query?: any,
    headers?: any,
    errorHandlers: ErrorHandlers = {},
    contentType: string = null,
    ignoreWorkspace?: boolean
  ): Promise<T> {
    return this._request("POST", op, body, query, headers, contentType, errorHandlers, null, ignoreWorkspace);
  }

  postGlobal<T = any>(
    op: string,
    body?: any,
    query?: any,
    headers?: any,
    errorHandlers: ErrorHandlers = {},
    contentType: string = null
  ): Promise<T> {
    return this._requestByLocationObj({
      method: "POST",
      op,
      body,
      query,
      headers,
      contentType,
      errorHandlers,
      shortTimeout: true,
      location: globalApiLocation(),
    });
  }

  put<T = any>(op: string, body?: any, query?: any, headers?: any, errorHandlers: ErrorHandlers = {}): Promise<T> {
    return this._request("PUT", op, body, query, headers, null, errorHandlers);
  }

  putGlobal<T = any>(
    op: string,
    body?: any,
    query?: any,
    headers?: any,
    errorHandlers: ErrorHandlers = {}
  ): Promise<T> {
    return this._requestByLocationObj({
      method: "PUT",
      op,
      body,
      query,
      headers,
      errorHandlers,
      shortTimeout: true,
      location: globalApiLocation(),
    });
  }

  patch<T = any>(op: string, body?: any, query?: any, headers?: any, errorHandlers: ErrorHandlers = {}): Promise<T> {
    return this._request("PATCH", op, body, query, headers, null, errorHandlers);
  }

  patchGlobal<T = any>(
    op: string,
    body?: any,
    query?: any,
    headers?: any,
    errorHandlers: ErrorHandlers = {}
  ): Promise<T> {
    return this._requestByLocationObj({
      method: "PATCH",
      op,
      body,
      query,
      headers,
      errorHandlers,
      location: globalApiLocation(),
    });
  }

  download(
    op: string,
    contentType?: string,
    query?: any,
    header?: any,
    errorHandlers: ErrorHandlers = {}
  ): Promise<any> {
    return this._request("GET", op, null, query, header, contentType, errorHandlers, "blob");
  }

  // Deletes, we can't use the reserved word delete.
  remove(op: string, body?: any): Promise<any> {
    return this._request("DELETE", op, body);
  }

  // Deletes, we can't use the reserved word delete.
  removeGlobal(op: string, body?: any): Promise<any> {
    return this._requestByLocationObj({
      op,
      body,
      method: "DELETE",
      location: globalApiLocation(),
    });
  }

  upload(op: string, file: File, fields: Record<string, string> = {}): Promise<any> {
    return this._location.then((location) => {
      const req = request("POST", location + "/" + op)
        .withCredentials()
        .use(logMiddleware.bind(this))
        .use(this._workspacesMiddleware.bind(this))
        .use(this._authenticationMiddleware.bind(this))
        .use(this._apiLoggingMiddleware.bind(this))
        .set({ "x-ui-revision": this._revision });
      Object.entries(fields).forEach(([k, v]) => {
        req.field(k, v);
      });
      return req.attach("file", file).then((result) => result.body || result.text, this._handleError({}));
    });
  }

  onAuthenticationError(handler: (error: RequestError) => void): () => void {
    this._authenticationErrorHandlers.push(handler);
    return () => (this._authenticationErrorHandlers = this._authenticationErrorHandlers.filter((x) => x === handler));
  }

  onUser(handler: (user: User) => void): void {
    this._onUserHandlers.push(handler);
  }

  impersonate(organizationId: string): Promise<Organization> {
    UpsolverLocalStorage.removeKey("forceGlobalApi");
    this.clearWorkspace();
    this.impersonateOrganizationId = organizationId;
    UpsolverLocalStorage.upsertKey("impersonate", this.impersonateOrganizationId);
    return this.setLocation().then(() => this.shortGet("organizations/"));
  }

  clearImpersonate = () => {
    this.clearWorkspace();
    UpsolverLocalStorage.removeKey("impersonate");
    this.impersonateOrganizationId = "";
  };

  setWorkspace = action((workspace: string) => {
    this.workspace = getWorkspace(workspace);
    UpsolverLocalStorage.upsertKey("workspace", workspace);
  });

  clearWorkspace = () => {
    UpsolverLocalStorage.removeKey("workspace");
    this.workspace = "";
  };

  redirectUrl(url: string): Promise<string> {
    return this._location.then((location) => `${location}/redirect/?url=${encodeURIComponent(url)}`);
  }

  clearMultiOrgs = () => {
    this.multiOrgs = [];
  };

  logout(): Promise<void> {
    this.clearImpersonate();
    return this._requestByLocation(globalApiLocation(), "GET", logoutOp).then(this.clearMultiOrgs);
  }

  login(
    email: string,
    password: string,
    onForbidden: (err: RequestError) => void,
    onConnectionError: (err: RequestError) => void,
    onUnAuthorized: (err: RequestError) => void
  ): Promise<Organization[]> {
    return this._requestByLocation(
      globalApiLocation(),
      "POST",
      "signin",
      {
        "X-Api-Email": email,
        "X-Api-Password": password,
      },
      null,
      null,
      "application/x-www-form-urlencoded",
      {
        403: onForbidden,
        0: onConnectionError,
        401: onUnAuthorized,
      }
    ).then(this.handleLoginResponse);
  }

  @action.bound
  setMultiOrgs(orgs: Organization[]) {
    this.multiOrgs = orgs.length === 1 ? null : orgs;
  }

  signup(req: NewOrganizationRequest): Promise<any> {
    return this._requestByLocation(this.initialLocation, "POST", "signup/", req);
  }

  signupUser(req: UserSignupRequest): Promise<any> {
    return this._requestByLocation(this.initialLocation, "POST", "signup/v2/", req);
  }

  signupUserV3(req: UserSignupRequest): Promise<LoginReply> {
    return this._requestByLocation(globalApiLocation(), "POST", "signup/v3/", req);
  }

  testLocalApi(): Promise<any> {
    return this.setLocation().then((location) =>
      this._requestByLocationObj({
        location,
        method: "GET",
        op: "organizations",
        timeoutOverride: 5000,
        errorHandlers: { ignoreNoLocalApi: true },
      })
    );
  }

  diagnoseLocalApi(reason: string): Promise<StepsLocalAPIStatusResult> {
    return this._requestByLocation(this.initialLocation, "GET", "environments/local-api/diagnose/ui/", null, {
      reason,
    });
  }

  postUIError(payload: { error: unknown; info }) {
    try {
      captureException(payload.error);
    } catch (err) {
      console.log(err);
    }
  }

  postError(error: string, exception: any = {}) {
    try {
      captureException(exception, { extra: { error: error } });
    } catch (err) {
      console.log(err);
    }
  }

  postRequestError(err: RequestError) {
    try {
      captureException(err);
    } catch (err) {
      console.log(err);
    }
  }

  dismissUpgrade = () => {
    this.outOfDate = false;

    if (this._clearRevisionChecker) {
      this._clearRevisionChecker();
    }
  };

  private setLoginOrgRequest = (org: Organization, location: string) =>
    this._requestByLocation(location, "PUT", `users/organizations/${org.id}`, null, null, null, null, {
      405: () => {
        throw new UnsupportedOperationError("cannot set user org");
      },
    });

  // set user's organizations from the global api so they aren't filtered by the local-api
  // handling 405 for backwards compatibility
  // we're calling set org twice to resolve a caching issue - make sure the current api, whatever server it is, also uses the new org
  setLoginOrg = async (org: Organization) => {
    if (this.impersonateOrganizationId) {
      return await this.setLocation();
    } else {
      await this.setLoginOrgRequest(org, globalApiLocation());
      const location = await this.setLocation();
      return await this.setLoginOrgRequest(org, location);
    }
  };

  /// get user's organizations from the global api so they aren't filtered by the local-api
  // handling 405 for backwards compatibility
  getUserOrganizations = (unsupportedHandler: () => void = () => {}): Promise<Organization[]> =>
    this._requestByLocation(globalApiLocation(), "GET", "users/organizations", null, null, null, null, {
      405: unsupportedHandler,
    }).then((orgs) => {
      this.setMultiOrgs(orgs);
      return orgs;
    });

  handleLoginResponse = async (orgs: Organization | Organization[] | any) => {
    if (hasOrganizations(orgs)) {
      this.setMultiOrgs(orgs.organizations);
      return this.setLocation().then(() => orgs.organizations);
    } else {
      if (Array.isArray(orgs)) {
        this.setMultiOrgs(orgs);
        return this.setLocation().then(() => orgs as Organization[]);
      } else {
        const orgs = await this.getUserOrganizations(this.clearMultiOrgs);
        await this.setMultiOrgs(orgs);
        await this.setLocation();
        return orgs;
      }
    }
  };

  authenticate(authenticationMethod: string, body: any, errorHandlers?: ErrorHandlers): Promise<LoginReply> {
    return this._requestByLocation(
      globalApiLocation(),
      "POST",
      "authenticate/" + authenticationMethod,
      body,
      null,
      null,
      null,
      errorHandlers
    );
  }

  inviteUser(request: NewUserRequest & { organizationId: string }) {
    return this._requestByLocation(globalApiLocation(), "POST", "users/invite", request);
  }

  zendeskToken(): Promise<string> {
    return this._requestByLocation(globalApiLocation(), "GET", "zendesk/jwt/token", request);
  }

  forgotPassword(email: string) {
    return this._requestByLocation(globalApiLocation(), "POST", `signup/forgot-password/${email}`);
  }

  verify(guid: string, onError: () => void) {
    return this._requestByLocationObj({
      location: globalApiLocation(),
      method: "GET",
      op: `signup/verify/${guid}`,
      errorHandlers: { 400: onError },
    });
  }
}

export const preventCacheByAddingTime = (req: any) => {
  req.url += `?time=${Date.now()}`;
  return req;
};
