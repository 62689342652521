import { TypeName } from "./api/contracts/TypeName";
import { UField } from "./api/contracts/UField";
import { SimpleMetadataProperty } from "./SimpleMetadata";

export type FeatureProperty = SimpleMetadataProperty;
export type FeatureInput = {
  name: string;
  description: string;
  schemaType: TypeName;
  secondary?: boolean;
  sequence: boolean;
  minimumInputs?: number;
  typeParamName?: string;
};

export interface DslSynonym {
  name: string;
  showInUI: boolean;
}

export function isDslSynonym(x: string | DslSynonym): x is DslSynonym {
  return x.hasOwnProperty("name");
}

export interface FeatureMetadata {
  name: string;
  group: string;
  description: string;
  properties: FeatureProperty[];
  inputs: FeatureInput[];
  returnType: TypeName;
  field: any;
  writeable: boolean;
  notSampleable: boolean;
  filter: boolean;
  hidden: boolean;
  clazz: string;
  deprecation?: { message: string; since: string };
  documentation?: { markdown: string; location: string };
  dslName: string;
  determinateOutputType?: TypeName;
  isMap?: boolean;
  id?: string;
  organizationFlag?: string;
  dslSynonyms: (string | DslSynonym)[];
}

export interface LookupFeatureMetadata extends FeatureMetadata {
  indexId?: string;
  timeOffset?: number;
  timeFrame?: { from: number; to: number };
  referenceDataId?: string;
  valueFields?: any[];
  keyFields?: any[];
  joinFields?: any[];
  aggregations?: any[];
  timeField?: { field: UField };
}
