import { FormInputProps, inputOnChange } from "../../FormInputTypes";
import React, { useState } from "react";
import { DurationEditor } from "../OptionalPositiveDurationEditor";
import { FormInputChoice } from "../../FormInputs/FormChoicesInput";
import classNames from "classnames";
import { TimeUnits } from "../../TimeUnits";

type Checked = "None" | "Infinite" | "Custom";
function getChecked(value: number | null): Checked {
  if (!value) {
    return "None";
  } else if (value >= TimeUnits.InfiniteMinutes) {
    return "Infinite";
  } else {
    return "Custom";
  }
}

/**
 * View for the windowSizeOverride editor
 * @type {React.FunctionComponent<FormInputProps<any>>}
 */
export function WindowSizeOverrideInput(props: FormInputProps) {
  const [checked, setChecked] = useState<Checked>(getChecked(props.input.$value));

  return (
    <>
      <div className={classNames("form-input-group FormChoices windowOverrideChoices")}>
        <FormInputChoice
          checked={checked === "None"}
          onClick={() => {
            setChecked("None");
            inputOnChange(props.input)(null);
          }}
          option={{ title: "None", description: "Same as output interval" }}
        />
        <FormInputChoice
          checked={checked === "Infinite"}
          onClick={() => {
            inputOnChange(props.input)(TimeUnits.InfiniteMinutes);
            setChecked("Infinite");
          }}
          option={{ title: "Infinite", description: "All historical data" }}
        />
        <FormInputChoice
          checked={checked === "Custom"}
          onClick={() => setChecked("Custom")}
          extraField={checked === "Custom" && <DurationEditor {...props} hasInfinite hideLabel hideDescription />}
          option={{ title: "Custom", description: `Enter your own ${props.input.label.toLowerCase()}` }}
        />
      </div>
    </>
  );
}
