import { SimpleMetadata } from "./SimpleMetadata";
import { Organization } from "./api/contracts/Organization";
import { OptionalOrganizationFlag } from "./OptionalOrganizationFlag";

function isSimpleMetadata(x: any): x is SimpleMetadata {
  return x && Array.isArray(x.properties) && Reflect.has(x, "displayName");
}

export function filterMetadataByOrganizationFlags<A extends OptionalOrganizationFlag>(
  organization: Organization,
  values: Array<A>
): Array<A> {
  const flags = organization.flags.metadataFlags;

  const filtered = values.filter(function (i: OptionalOrganizationFlag) {
    return i.organizationFlag
      ? flags.includes(i.organizationFlag) || Reflect.get(organization.flags, i.organizationFlag) === true
      : true;
  });
  return filtered.map((x) => {
    if (!isSimpleMetadata(x)) {
      return x;
    }
    return {
      ...x,
      properties: filterMetadataByOrganizationFlags(organization, x.properties),
    };
  });
}
