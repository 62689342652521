import Fuse from "fuse.js";

export function fuzzyFilter<A>(
  items: A[],
  keys: Array<any>,
  value: string,
  options?: Partial<Fuse.FuseOptions<A>>
): A[] {
  if (!value) {
    return items;
  }

  const searchOptions: Fuse.FuseOptions<A> = {
    keys: keys,
    shouldSort: true,
    maxPatternLength: 32,
    distance: 500,
    threshold: 0.2,
    ...options,
  };

  const filter = new Fuse(items, searchOptions);
  return filter.search(value.toString().trim());
}
