import { FormFieldRegistry } from "../../FormFieldRegistry";
import React from "react";
import { register as Autocomplete } from "./Autocomplete";
import { register as ClusterDeploymentMethod } from "./ClusterDeploymentMethod";
import { register as ConnectionsSelect } from "./ConnectionsSelect";
import { register as CSVEditor } from "./CSVEditor";
import { register as EnvironmentSelectInput } from "./EnvironmentSelectInput";
import { register as FormCheckboxInput } from "./FormCheckboxInput";
import { register as FormChoicesInput } from "./FormChoicesInput";
import { register as FormDateTimeInput } from "./FormDateTimeInput";
import { register as FormDateTimeWithDefaults } from "./FormDateTimeWithDefaults";
import { register as FormFieldsSelectInput } from "./FormFieldsSelectInput";
import { register as FormNumberInput } from "./FormNumberInput";
import { register as FormSelectInput } from "./FormSelectInput";
import { register as Intervals } from "./Intervals";
import { register as MultiSelectComponent } from "./MultiSelectComponent";
import { register as PhoneFormInput } from "./PhoneFormInput";
import { register as ProtobufSchema } from "./ProtobufSchema";
import { register as PythonEditor } from "./PythonEditor";
import { register as RequiredCredentials } from "./RequiredCredentials";
import { register as SelectOutputType } from "./SelectOutputType";
import { register as SourceSelect } from "./SourceSelect";
import { register as TextInputs } from "./TextInputs";
import { register as HiveMetastore } from "./HiveMetastore";
import { register as FunctionAutoComplete } from "./FunctionAutoComplete";
import { register as EnvSizeInput } from "./EnvironmentSizeInput";
import { register as FormInputsFormSwitchInput } from "./FormInputsFormSwitchInput";
import { register as MultiChoiceSelectInput } from "./MultiChoiceSelectInput";
import { register as OptionalPositiveDurationEditor } from "../editors/OptionalPositiveDurationEditor";
import { register as Udsl } from "./udsl";
import { register as WindowSizeOverride } from "../editors/window-size-override/register";
import { register as FileNameMatcher } from "./FileNameMatcher";
import { register as PolicyInputEditor } from "./PolicyEditorInput";
import { register as FileUpload } from "./FormFileUploadInput";
import { register as PreviewList } from "./PreviewList";
import { register as Radio } from "./radio/FormRadioInput";

export default function registerAll(registry: FormFieldRegistry) {
  registry.clear();
  Autocomplete(registry);
  ClusterDeploymentMethod(registry);
  ConnectionsSelect(registry);
  CSVEditor(registry);
  EnvironmentSelectInput(registry);
  FormCheckboxInput(registry);
  FormChoicesInput(registry);
  FormDateTimeInput(registry);
  FormDateTimeWithDefaults(registry);
  FormFieldsSelectInput(registry);
  FormNumberInput(registry);
  FormSelectInput(registry);
  Intervals(registry);
  MultiSelectComponent(registry);
  PhoneFormInput(registry);
  ProtobufSchema(registry);
  PythonEditor(registry);
  RequiredCredentials(registry);
  SelectOutputType(registry);
  SourceSelect(registry);
  TextInputs(registry);
  HiveMetastore(registry);
  FunctionAutoComplete(registry);
  EnvSizeInput(registry);
  FormInputsFormSwitchInput(registry);
  MultiChoiceSelectInput(registry);
  OptionalPositiveDurationEditor(registry);
  Udsl(registry);
  WindowSizeOverride(registry);
  FileNameMatcher(registry);
  PolicyInputEditor(registry);
  FileUpload(registry);
  PreviewList(registry);
  Radio(registry);
}
