import { Environment } from "../../../environments/EnvironmentsStore";
import { StoppedBadge } from "../Badges";
import { Definition, DefinitionList } from "../definition-list/DefinitionList";
import { ProcessingUnits } from "./EnvironmentSizeInput";
import { DefaultItemView } from "../../../templates/index/views/DefatultItemView";
import React from "react";

export const EnvironmentItemView = ({ env }: { env: Environment }) => (
  <span className="item-view inline">
    <strong className="title">
      {env.displayData.name}
      {!env.running && <StoppedBadge />}
    </strong>
    <DefinitionList>
      <Definition title="Units" value={<ProcessingUnits {...env.size} />} />
      {env.environmentType === "compute" && (
        <Definition
          title="Replay Units"
          value={env.maxReplayClusterSize ? <ProcessingUnits min={env.maxReplayClusterSize} /> : "-"}
        />
      )}
    </DefinitionList>
  </span>
);

export const EnvironmentView = ({ item }: { item: any }) => {
  if (Array.isArray(item)) {
    return (
      <span className="item-view inline">
        <span className="title">
          {item.length > 0 ? item.map((x) => x.displayData.name).join(", ") : "Placeholder"}
        </span>
      </span>
    );
  }

  if (item.type === "create" || item.type === "warning") {
    return <DefaultItemView item={item} />;
  } else {
    return <EnvironmentItemView env={item.subtitle} />;
  }
};
