import React from "react";
import { FormSelectInputProps, getInputId } from "../FormInputTypes";
import { DataSourceWord } from "../../Language";
import { observer } from "mobx-react";
import { action, IObservableArray, observable } from "mobx";
import { MultiSelectComponent } from "./MultiSelectComponent";
import SelectOrAddSomething from "./SelectOrAdd";
import { FormFieldRegistry } from "../../FormFieldRegistry";
import { AppContext } from "../../../routes/AppContext";
import { useObserver } from "mobx-react-lite";
import { FormInputLabel } from "./FormItemDecorator";

class SimpleSourceSelect extends React.Component<FormSelectInputProps> {
  render() {
    return (
      <SelectOrAddSomething
        {...this.props}
        placeholder={`Please select a ${DataSourceWord.single()}`}
        createOptions={[
          {
            url: "/inputs/create",
            word: DataSourceWord.single(),
            state: {},
          },
        ]}
      />
    );
  }
}

@observer
export class SourceSelect extends React.Component<FormSelectInputProps> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;
  @observable.shallow _options: IObservableArray<any> = observable.array();

  componentDidMount() {
    if (this.props.options && this.props.options.length) {
      this._options.replace(this.props.options);
    } else if (this.context.inputsStore.options.length) {
      this._options.replace(this.context.inputsStore.options);
    } else {
      this.props.input.metadata.loading = true;
      this.context.inputsStore.reloadApi().then(
        action(() => {
          this._options.replace(this.context.inputsStore.options);
          this.props.input.metadata.loading = false;
        })
      );
    }
  }

  render() {
    return <SimpleSourceSelect {...this.props} options={this._options} />;
  }
}

function MultiSourceSelect(props: FormSelectInputProps) {
  return useObserver(() => (
    <>
      {props.input.label && <FormInputLabel {...props} />}
      <MultiSelectComponent {...props}>
        {(props: any) => {
          const { childInput, filteredOptions, setValue } = props;
          return <SimpleSourceSelect {...props} options={filteredOptions} input={childInput} onChange={setValue} />;
        }}
      </MultiSelectComponent>
    </>
  ));
}

export const register = (formFieldRegistry: FormFieldRegistry) => {
  formFieldRegistry.register("select-source", (props) => (
    <SourceSelect options={props.input.metadata.options} {...props} />
  ));
  formFieldRegistry.register("select-source-multi", (props) => (
    <MultiSourceSelect options={props.input.metadata.options} {...props} />
  ));
};
