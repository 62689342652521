import React, { useState } from "react";
import Popover from "@mui/material/Popover";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";

export const FieldZoomPopup: React.FC<{ direction?: any; popupViewProvider: any }> = ({
  popupViewProvider,
  direction,
}) => {
  const [el, setEl] = useState(null);
  return (
    <React.Fragment>
      <span
        className="clickable icon-details field-details"
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
          setEl(event.currentTarget);
        }}
      />
      <Popover
        open={Boolean(el)}
        onClose={() => setEl(null)}
        style={{ zIndex: 1301 }}
        anchorEl={el}
        onDoubleClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <ClickAwayListener onClickAway={() => setEl(null)}>
          <Paper elevation={5}>
            <IconButton size="small" className="pull-right" onClick={() => setEl(null)}>
              <CloseIcon />
            </IconButton>
            <Box p={2} className="popup-modal" width={600}>
              {popupViewProvider()}
            </Box>
          </Paper>
        </ClickAwayListener>
      </Popover>
    </React.Fragment>
  );
};
