import { action, observable } from "mobx";

class PageVisibilityStore {
  @observable visible: boolean;

  constructor() {
    document.addEventListener("visibilitychange", this._onVisibilityChange, false);
    this.visible = !document.hidden;
  }

  @action.bound
  _onVisibilityChange() {
    this.visible = !document.hidden;
  }
}

export const setTimeoutVisible = (f: () => void, timeout: number): (() => void) => {
  let hidden = document.hidden;
  let timeoutPassed = false;
  const onVisibilityChange = function () {
    hidden = document.hidden;

    if (!hidden && timeoutPassed) {
      document.removeEventListener("visibilitychange", onVisibilityChange, false);
      f();
    }
  };

  document.addEventListener("visibilitychange", onVisibilityChange, false);

  const timeoutId = setTimeout(() => {
    timeoutPassed = true;
    if (!hidden) {
      document.removeEventListener("visibilitychange", onVisibilityChange, false);
      f();
    }
  }, timeout);

  return () => {
    clearTimeout(timeoutId);
    document.removeEventListener("visibilitychange", onVisibilityChange, false);
  };
};

const pageVisibilityStore = new PageVisibilityStore();
export default pageVisibilityStore;
