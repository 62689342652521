import React, { useMemo } from "react";
import { FormInputProps, inputOnChange, secretClassName } from "../FormInputTypes";
import TextField from "@mui/material/TextField";
import { FormFieldRegistry } from "../../FormFieldRegistry";
import { observer } from "mobx-react-lite";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import { useOptionalField } from "./useOptionalField";

export const FormNumberInput = observer((props: FormInputProps) => {
  const input = props.input;

  const onChangeValue = (value: string | number) => {
    inputOnChange(input)(value === "" ? value : Number(value));
  };

  const initialRequired = useMemo(() => props.required || props.input?.rules?.includes("required"), [
    props.required,
    props.input,
  ]);

  const optionalField = useOptionalField(props.input, initialRequired, onChangeValue);
  const disabled = input.metadata.disable || input.metadata.loading || input.disabled || !optionalField.enabled;
  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!disabled) {
      const value = e.currentTarget.value;
      onChangeValue(value);
    }
  };

  return (
    <div>
      <Box display="flex" alignItems="center">
        {!initialRequired && (
          <Box pr={1}>
            <Checkbox data-automation-name={`enable-${props.input.name}`} {...optionalField.checkBoxProps} />
          </Box>
        )}
        <div>
          <TextField
            placeholder={optionalField.placeholder || null}
            variant="outlined"
            disabled={disabled}
            value={input.$value}
            type={optionalField.placeholder ? "text" : "number"}
            className={input.metadata.secret && secretClassName}
            onChange={changeHandler}
            inputProps={{ "data-testid": "number-input" }}
          />
        </div>
      </Box>
    </div>
  );
});

export const register = (formFieldRegistry: FormFieldRegistry) =>
  formFieldRegistry.register("number", (props) => <FormNumberInput {...props} />);
