import { observer } from "mobx-react";
import React from "react";
import { FormInputProps } from "../FormInputTypes";
import FormItemDecorator from "./FormItemDecorator";
import { ButtonFileUpload } from "../FileUpload";
import FormSelectInput from "./FormSelectInput";
import { FormTextInput } from "./TextInputs";
import { FormFieldRegistry } from "../../FormFieldRegistry";

@observer
export class ProtobufSchemaInput extends React.Component<FormInputProps> {
  render() {
    const input = this.props.input;
    const schemaFilesField = input.fields.get("schemaFiles");
    const mainFileField = input.fields.get("mainFile");
    const messageTypeField = input.fields.get("messageType");
    return (
      <div>
        <FormItemDecorator input={schemaFilesField}>
          <ButtonFileUpload
            fireOnSameFile
            multi
            onFiles={(files) => {
              input.metadata.loading = true;
              const payload: any = [];
              files.forEach((f: any) => {
                const reader = new FileReader();
                reader.onload = () => {
                  payload.push({
                    clazz: "SchemaFile",
                    fileName: f.name,
                    content: reader.result,
                  });
                  if (payload.length === files.length) {
                    schemaFilesField.sync(payload);
                    input.metadata.loading = false;
                  }
                };
                reader.readAsText(f);
              });
            }}
          />
        </FormItemDecorator>
        <FormItemDecorator input={mainFileField}>
          <FormSelectInput
            input={mainFileField}
            options={
              schemaFilesField.value
                ? schemaFilesField.value.map((x: any) => ({
                    id: x.fileName,
                    label: x.fileName,
                  }))
                : []
            }
          />
        </FormItemDecorator>
        <FormItemDecorator input={messageTypeField}>
          <FormTextInput input={messageTypeField} />
        </FormItemDecorator>
      </div>
    );
  }
}
export const register = (formFieldRegistry: FormFieldRegistry) =>
  formFieldRegistry.register("protobuf", (props) => <ProtobufSchemaInput {...props} />);
