import React from "react";
import classNames from "classnames";

export const Page: React.FC<{
  reversed?: boolean;
  content?: boolean;
  padded?: boolean;
  flat?: boolean;
  style?: React.CSSProperties;
  className?: string;
}> = ({ children, reversed, content, padded, flat, className, ...rest }) => (
  <div {...rest} className={classNames("page-layout", className, { reversed, content, padded, flat })}>
    {children}
  </div>
);

export const ContentPage: React.FC = ({ children }) => <Page content>{children}</Page>;

export const Header: React.FC<React.HTMLProps<HTMLHeadElement>> = ({ className, ...rest }) => (
  <header {...rest} className={classNames("header-layout", className)} />
);

export const Footer: React.FC = ({ children }) => <footer className="footer-layout">{children}</footer>;

export const Main: React.FC<{ narrow?: boolean; compact?: boolean } & React.HTMLProps<HTMLDivElement>> = ({
  narrow,
  compact,
  className,
  ...rest
}) => <article {...rest} className={classNames("main-layout", { narrow, compact }, className)} />;

export const Sidebar: React.FC = ({ children }) => <aside className="sidebar-layout">{children}</aside>;
