import { action, computed, observable } from "mobx";
import { BasicApi } from "./api/Api";
import { FeatureDefinition, NewFeatureInspection } from "./api/contracts/FeatureDefinition";
import { OutputFormat } from "./api/contracts/PipelineOperation";
import keyBy from "lodash/keyBy";
import uniqBy from "lodash/uniqBy";
import { InputChangesets } from "../inputs/InputChangesets";
import { Auth } from "../auth/Auth";
import { UField } from "./api/contracts/UField";
import { UDFMetadata, UserDefinedFunctionsStore } from "../udf/Store";
import { SimpleMetadata } from "./SimpleMetadata";
import { FormOptionsItem } from "./FormOptionsItem";
import { FeatureInput, FeatureMetadata, isDslSynonym } from "./FeatureMetadata";
import { PlanMetadata } from "./PlanMetadata";
import { SupportedFeatures } from "./api/contracts/SupportedFeatures";
import { filterMetadataByOrganizationFlags } from "./filterMetadataByOrganizationFlags";
import { CreateFormFieldOptions } from "./views/CreateFormFieldOptions";

export interface ActionMetadata {
  namespace: string;
  actionType: string;
  action: string;
  hidden: boolean;
  description: string;
}

export interface ActionNameMetadata {
  namespace: string;
  actions: ActionMetadata[];
  autoCompleteProvider: string;
}

export type EnumDefinition = {
  schemaType: string;
  values: string[];
};

export type CompressionFormat = {
  clazz: string;
  displayName: string;
};

export interface ContentType {
  clazz: string;
  autoDetectTypes: boolean;
}

export type EnvironmentSizeDescription = {
  processingUnit: number;
  price: number;
};

export type EnvironmentSizes = { [key: string]: EnvironmentSizeDescription };

const supportedFeaturesForOrganizations: { [key in SupportedFeatures]?: Array<string> } = {
  "multi-unmap-outputfield": ["IronSource"],
};

export interface MetadataArray<T> extends Array<T> {
  readonly forDisplay: T[];
  readonly byClazz: { [key: string]: T };
}

const cachedForDisplay = Symbol.for("cachedForDisplay");
const cachedByClazz = Symbol.for("cachedByClazz");

type Overrides<T> = {
  forDisplay: (arr: Array<T>) => T[];
  byClazz: (arr: Array<T>) => { [key: string]: T };
};

export type Clazz = string;

const defaultOverrides = {
  forDisplay: (arr: SimpleMetadata[]) => arr.filter((x) => !x.hidden),
  byClazz: (arr: SimpleMetadata[]) =>
    arr.reduce<Record<Clazz, SimpleMetadata>>((a, c) => {
      a[c.clazz] = c;
      return a;
    }, {}),
};

type MetadataCache<T extends SimpleMetadata | FeatureMetadata> = T[] & {
  [cachedForDisplay]?: T[];
  [cachedByClazz]?: Record<Clazz, T>;
};

export function createMetadataArray<T extends SimpleMetadata | FeatureMetadata>(
  arr: MetadataCache<T> = [],
  overrides: Partial<Overrides<T>> = {}
): MetadataArray<T> {
  const ret: MetadataCache<T> = arr.slice().map((m) => {
    if (m.properties) {
      m.properties.forEach((p) => {
        Object.assign(p, {
          get schemaType() {
            return p.schemaType === "Either" ? p.typeArguments[0] : p.schemaType;
          },
        });
      });
    }
    return m;
  });
  const methods = Object.assign({}, defaultOverrides, overrides);
  const t = {
    get forDisplay() {
      if (!ret[cachedForDisplay]) {
        ret[cachedForDisplay] = methods.forDisplay(arr);
      }
      return ret[cachedForDisplay];
    },
    get byClazz() {
      if (!ret[cachedByClazz]) {
        ret[cachedByClazz] = methods.byClazz(arr);
      }
      return ret[cachedByClazz];
    },
  };

  return Object.assign(ret, t);
}

export type MetadataKey = keyof SimpleMetadataCollections;
export abstract class SimpleMetadataCollections {
  contentTypes: MetadataArray<SimpleMetadata>;
  inputs: MetadataArray<SimpleMetadata>;
  connections: MetadataArray<SimpleMetadata>;
  outputParameters: MetadataArray<SimpleMetadata>;
  templateDeployParameters: MetadataArray<SimpleMetadata>;
  customerEnvironments: MetadataArray<SimpleMetadata>;
  environments: MetadataArray<SimpleMetadata>;
  environments2: MetadataArray<SimpleMetadata>;
  integrations: MetadataArray<SimpleMetadata>;
  general: MetadataArray<SimpleMetadata>;
  requests: MetadataArray<SimpleMetadata>;
  constantTimeRanges: MetadataArray<SimpleMetadata>;
  clusterDeploymentMethods: MetadataArray<SimpleMetadata>;
  inputChangesets: MetadataArray<SimpleMetadata>;
  templateChangesets: MetadataArray<SimpleMetadata>;
  udfChangesets: MetadataArray<SimpleMetadata>;
  connectionChangesets: MetadataArray<SimpleMetadata>;
  templates: MetadataArray<SimpleMetadata>;
  credits: MetadataArray<SimpleMetadata>;
  discounts: MetadataArray<SimpleMetadata>;
  hiveMetastores: MetadataArray<SimpleMetadata>;
  mandatoryOutputParameters: MetadataArray<SimpleMetadata>;
  templateRequests: MetadataArray<SimpleMetadata>;
  environmentMonitors: MetadataArray<SimpleMetadata>;
  environmentMetrics: MetadataArray<SimpleMetadata>;
  iam: MetadataArray<SimpleMetadata>;
  iamChangesets: MetadataArray<SimpleMetadata>;
  environmentChangesets: MetadataArray<SimpleMetadata>;
  outputTemplateFields: MetadataArray<SimpleMetadata>;
  tableChangesets: MetadataArray<SimpleMetadata>;
}

//TODO: ts-convert
export const TemplateChangesets: { [key: string]: (payload?: any) => any } = {};

class Metadata extends SimpleMetadataCollections {
  aggregations: MetadataArray<FeatureMetadata>;
  singleInputAggregations: FeatureMetadata[];
  features: MetadataArray<FeatureMetadata>;
  featuresMap: { [key: string]: FeatureMetadata };
  enums: EnumDefinition[];
  outputFormats: OutputFormat[];
  compressions: CompressionFormat[];
  operationReferenceTypes: { [key: string]: string[] };
  environmentSizes: EnvironmentSizes;
  plans: PlanMetadata[];
  credentials: { [key: string]: MetadataArray<SimpleMetadata> };
  mapAggregations: FeatureMetadata[];
  simpleAggregations: FeatureMetadata[];
  allAggregations: FeatureMetadata[];
  displayData: SimpleMetadata;
  displayDataByType: { [key: string]: SimpleMetadata };
  supportedFeatures: SupportedFeatures[];
  outputCompressions: any[];
  actionNames: ActionNameMetadata[];
  jdbcOutputParametersClazzes: string[];
  columnarOutputParameterClazzes: string[];
  schemaBasedOutputParameterClazzes: string[];
  jdbcConnectionProperty: Record<string, string>;
  hiveParamsClazzes: string[];
  get supportsWorkspaces(): boolean {
    return this.templateRequests && this.templateRequests.length
      ? this.templateRequests[0].properties.findIndex((x) => x.name === "workspaces") > -1
      : false;
  }

  get supportsAttachManyWorkspaces(): boolean {
    return this.templateChangesets.byClazz.hasOwnProperty("AttachManyWorkspaces");
  }

  supports = (featureName: SupportedFeatures) => {
    const supportedOrganizations = supportedFeaturesForOrganizations[featureName];
    const currentUser = this.auth.user;
    const currentOrganization = currentUser && currentUser.organization;
    if (supportedOrganizations && (!currentUser || !supportedOrganizations.includes(currentOrganization))) {
      return false;
    }

    return this.supportedFeatures ? this.supportedFeatures.includes(featureName) : false;
  };

  originalFeatures: FeatureMetadata[];

  private buildChangesetsFunctions(changesetsMetadata: SimpleMetadata[], changesetObject: any) {
    changesetsMetadata.forEach((x) => {
      if (!changesetObject.hasOwnProperty(x.clazz)) {
        changesetObject[x.clazz] = function () {
          const value: any = { clazz: x.clazz };
          const a = arguments;
          x.properties.forEach((p, i) => {
            const argsValue = i < a.length && a[i];
            if (p.mandatory || argsValue) {
              value[p.name] = argsValue;
            }
          });
          return value;
        };
      }
    });
  }

  private udfFeatures: FeatureMetadata[] = [];

  get udfMetadata(): FeatureMetadata[] {
    return this.udfFeatures;
  }

  set udfMetadata(value: FeatureMetadata[]) {
    this.udfFeatures = value;
    this.setFeaturesMetadata();
  }

  private setFeaturesMetadata() {
    this.featuresMap = keyBy(this.originalFeatures.concat(this.udfMetadata), "clazz");
    const featureMetadata = this.originalFeatures.concat(this.udfMetadata).filter((f) => !f.deprecation);
    featureMetadata.unshift(Object.assign({}, fakeDslFeatureMetadata, { filter: true }) as any);
    featureMetadata.unshift(fakeDslFeatureMetadata as any);
    this.features = createMetadataArray(featureMetadata);
  }

  constructor(json: any, private auth: Auth) {
    super();
    Object.assign(this, json);
    const safeTypes = (arr: SimpleMetadata[] = []) => {
      const supported = arr.filter((x) => !auth.unsupportedClazzes.includes(x.clazz));
      return this.auth.currentOrganization.id
        ? filterMetadataByOrganizationFlags(this.auth.currentOrganization, supported)
        : supported;
    };
    const createSafeMetadataArray = (
      arr: Array<SimpleMetadata>,
      overrides: Partial<Overrides<SimpleMetadata>> = {}
    ): MetadataArray<SimpleMetadata> => createMetadataArray(safeTypes(arr), overrides);
    this.contentTypes = createSafeMetadataArray(json.contentTypes);
    this.inputs = createSafeMetadataArray(json.inputs);
    this.customerEnvironments = createSafeMetadataArray(json.environments);
    this.environments = createSafeMetadataArray(json.environments2);
    this.environments2 = createSafeMetadataArray(json.environments2, {
      byClazz: (arr) =>
        arr.reduce<Record<string, SimpleMetadata>>((a, c) => {
          return { ...a, [c.clazz.toLowerCase()]: c, [c.clazz]: c };
        }, {}),
    });

    // Removes SubFieldsSelector from metadata until this file will be deleted.
    this.connections = createSafeMetadataArray(json.connections);
    this.outputParameters = createSafeMetadataArray(json.outputParameters);
    this.templateDeployParameters = createSafeMetadataArray(json.templateDeployParameters);
    this.integrations = createSafeMetadataArray(json.integrations);
    this.general = createSafeMetadataArray(json.general);
    this.requests = createSafeMetadataArray(json.requests);
    this.constantTimeRanges = createSafeMetadataArray(json.constantTimeRanges);
    this.clusterDeploymentMethods = createSafeMetadataArray(json.clusterDeploymentMethods);
    this.inputChangesets = createSafeMetadataArray(json.inputChangesets);
    this.templateChangesets = createSafeMetadataArray(json.templateChangesets);
    this.templates = createSafeMetadataArray(json.templates);
    this.credits = createSafeMetadataArray(json.credits);
    this.discounts = createSafeMetadataArray(json.discounts);
    this.hiveMetastores = createSafeMetadataArray(json.hiveMetastores);
    this.mandatoryOutputParameters = createSafeMetadataArray(json.mandatoryOutputParameters);
    this.templateRequests = createSafeMetadataArray(json.templateRequests);
    this.environmentMonitors = createSafeMetadataArray(json.environmentMonitors);
    this.environmentMetrics = createSafeMetadataArray(json.environmentMetrics);
    this.iamChangesets = createSafeMetadataArray(json.iamChangesets);
    this.aggregations = createMetadataArray(json.aggregations);
    this.udfChangesets = this.supports("udf-patches") && createSafeMetadataArray(json.udfChangesets);
    this.connectionChangesets = createMetadataArray(json.connectionChangesets || []);
    this.environmentChangesets = createSafeMetadataArray(json.environmentChangesets);
    this.tableChangesets = createSafeMetadataArray(json.tableChangesets || []);
    this.outputTemplateFields = createSafeMetadataArray(json.outputTemplateFields || []);
    Object.entries(json)
      .filter(([k]) => k.endsWith("ColumnTypes"))
      .forEach(([k, v]) => {
        Object.assign(this, { [k]: createSafeMetadataArray(v as SimpleMetadata[]) });
      });
    this.originalFeatures = (this.features || createMetadataArray<FeatureMetadata>([])).flatMap((x) => [
      x,
      ...x.dslSynonyms
        .filter(isDslSynonym)
        .filter((x) => x.showInUI)
        .map((synonym) => Object.assign({}, x, { name: synonym.name, dslName: synonym.name })),
    ]);
    this.singleInputAggregations = this.aggregations.filter(
      (x) => x.inputs.length < 2 && x.properties.length === 0 && !x.hidden && !x.deprecation
    );

    this.credentials = Object.entries(this.credentials || {}).reduce<any>((a, c) => {
      a[c[0]] = c[1].filter((x) => !this.auth.unsupportedClazzes.includes(x.clazz));
      return a;
    }, {});

    if (!this.allAggregations) {
      const aggs = uniqBy(
        this.aggregations.forDisplay
          .filter((x) => !x.deprecation)
          .map((a) => Object.assign({}, a, { isMap: a.group === "Map" })),
        "dslName"
      );
      this.simpleAggregations = aggs.filter((a) => !a.isMap);
      this.mapAggregations = aggs.filter((a) => a.isMap);
      this.allAggregations = aggs;
    }

    const jdbcOutputParameters: SimpleMetadata[] = json.outputParameters.filter((x: SimpleMetadata) =>
      x.traits?.includes("JdbcOutputParameters")
    );
    this.columnarOutputParameterClazzes = json.outputParameters
      .filter((x: SimpleMetadata) => x.traits?.includes("ColumnarOutputParameters"))
      .map((x: SimpleMetadata) => x.clazz);
    this.jdbcOutputParametersClazzes = jdbcOutputParameters.map((x) => x.clazz);
    this.schemaBasedOutputParameterClazzes = this.jdbcOutputParametersClazzes.concat(
      this.columnarOutputParameterClazzes
    );
    if (json.supportedFeatures.includes("jdbc-schema-provider")) {
      this.jdbcConnectionProperty = jdbcOutputParameters.reduce<Record<Clazz, string>>((a, c) => {
        a[c.clazz] = c.properties.find((p) => p.editorInformation["schemaProvider"])?.name;
        return a;
      }, {});
    } else {
      this.jdbcConnectionProperty = {
        RedshiftOutputParameters: "redshiftConnection",
        MySqlOutputParameters: "mysqlConnection",
        MicrosoftSqlServerOutputParameters: "microsoftSqlServerConnection",
        PostgreSqlOutputParameters: "postgresqlConnection",
        SnowflakeOutputParameters: "snowflakeConnection",
      };
    }

    this.hiveParamsClazzes = json.outputParameters
      .concat(json.mandatoryOutputParameters)
      .filter(
        (x: SimpleMetadata) =>
          x.traits?.includes("BaseHiveMetastoreOutputParameters") ||
          x.traits?.includes("BaseMandatoryHiveMetastoreOutputParameters") ||
          x.traits?.includes("BaseCompositeHiveMetastoreMandatory")
      )
      .map((x: SimpleMetadata) => x.clazz);
    this.buildChangesetsFunctions(this.templateChangesets, TemplateChangesets);
    this.buildChangesetsFunctions(this.inputChangesets, InputChangesets);

    this.setFeaturesMetadata();
  }

  isJdbcOutput(clazz: string) {
    return this.jdbcOutputParametersClazzes.includes(clazz);
  }

  availableOutputTypesFormOptions(): FormOptionsItem {
    const options = this.outputParameters.forDisplay
      .map((x) => {
        return {
          title: x.displayName,
          label: x.displayName,
          id: x.clazz,
          key: x.clazz,
          editorInformation: x.editorInformation,
          connectionType: x.clazz,
          logo: x.clazz,
        };
      })
      .slice();
    return options.sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
  }
}

export type MetadataFormOptionLists = {
  storage?: any[];
  fields?: any[];
  environments?: any[];
  operations?: any[];
  connections?: any[];
  contextProvider?: (value: string) => any;
} & { [key: string]: any };

export type FormMetadata = {
  outputCompressions: FormOptionsItem[];
  compressions: FormOptionsItem[];
  enums: { [key: string]: FormOptionsItem[] };
  outputFormats: FormOptionsItem[];
  contentTypes: FormOptionsItem[];
  operationReferenceTypes: { [key: string]: string[] };
  credentials: { [key: string]: SimpleMetadata[] };
  general: { [key: string]: SimpleMetadata };
  lists?: MetadataFormOptionLists;
  clusterDeploymentMethods: SimpleMetadata[];
};

export const NopFormMetadata: FormMetadata = {
  outputCompressions: [],
  compressions: [],
  enums: {},
  outputFormats: [],
  contentTypes: [],
  operationReferenceTypes: {},
  credentials: {},
  general: {},
  clusterDeploymentMethods: [],
};

export const fakeDslFeatureMetadata: any = {
  name: "CODE",
  group: "Simple",
  description: "",
  properties: [],
  inputs: [],
  writeable: true,
  filter: false,
  hidden: false,
  clazz: "DSLFeature",
  dslName: "CODE",
};

export type NewFeature = { feature: FeatureMetadata; inputs: FeatureInput[] } | { expression: string };

class MetadataStore {
  @observable.ref private _metadata: Metadata;
  private originalMetadata: any;

  private filterByOrgId: string = "";

  _formMetadata: FormMetadata;

  _clazzToDslNames: { [key: string]: string };

  constructor(private api: BasicApi, private auth: Auth, private udfStore: UserDefinedFunctionsStore) {}

  load(force: boolean = false, loadUdf = true): Promise<Metadata> {
    if (this._metadata && !force) {
      return Promise.resolve(this._metadata);
    } else {
      return this.api
        .get("metadata", null, null, null, {
          0: () => {},
        })
        .then(
          action((d) => {
            this._formMetadata = null;
            this.originalMetadata = d;
            this._metadata = new Metadata(d, this.auth);
            if (loadUdf) {
              this.reloadUdfFeatures();
            }
            return this._metadata;
          })
        );
    }
  }

  convertToFeatureDefinition(
    definition: { field: UField; expression: string },
    context: any
  ): Promise<FeatureDefinition> {
    const featureDefinition = Object.assign({}, definition);
    featureDefinition.field = featureDefinition.field || ({ name: "" } as any);
    if (this.Metadata().supports("new-dsl-code-mode")) {
      const body = { definition: featureDefinition, inspectionContext: context };
      return this.api.post("inspection/feature/convert2", body).then((x: FeatureDefinition) => {
        x.field.name = featureDefinition.field.name;
        return x;
      });
    } else {
      return this.api.post("inspection/feature/convert", { ...featureDefinition });
    }
  }

  convertToDsl(definition: FeatureDefinition, context?: any): Promise<string> {
    const featureDefinition = Object.assign({}, definition);
    const body = { definition: featureDefinition, inspectionContext: context };
    return this.api.post("inspection/feature/convert-to-dsl2", body);
  }

  newFeatureInspection(payload: NewFeature): Promise<NewFeatureInspection> {
    return this.api.post("inspection/feature/inspect", payload);
  }

  canReplaceFeature(previous: FeatureDefinition, newFeature: NewFeature): Promise<any> {
    return this.api.post("inspection/feature/replace", { previous, newFeature });
  }

  Metadata(): Metadata {
    if (this.auth.currentOrganization?.id !== this.filterByOrgId && this.originalMetadata) {
      this.filterByOrgId = this.auth.currentOrganization.id;
      this._metadata = new Metadata(this.originalMetadata, this.auth);
    }
    return this._metadata;
  }

  getDslName(definition: FeatureDefinition): string {
    const clazz = definition.feature.clazz;
    if (clazz !== "UserDefinedFunctionFeature") {
      return this.getDslNameForClazz(clazz);
    }
    const udfMetadata = this._metadata.udfMetadata.find((x) => x.id === definition.feature.id);
    return udfMetadata ? udfMetadata.name : "UDF";
  }

  getDslNameForClazz(clazz: string): string {
    if (!this._clazzToDslNames) {
      const meta = this.Metadata();
      this._clazzToDslNames = {};
      meta.aggregations.forEach((agg) => (this._clazzToDslNames[agg.clazz] = agg.dslName));
      this._metadata.originalFeatures.forEach((f) => (this._clazzToDslNames[f.clazz] = f.dslName));
    }
    return this._clazzToDslNames[clazz] || "";
  }

  getFormMetadata(): FormMetadata {
    if (!this._formMetadata) {
      const compressionToOption = (c: any) => ({ id: c.clazz, label: c.displayName });
      const meta = this.Metadata();
      this._formMetadata = {
        outputCompressions: meta.outputCompressions.map(compressionToOption),
        compressions: meta.compressions.map(compressionToOption),
        enums: meta.enums.reduce((a: Record<string, FormOptionsItem[]>, e: EnumDefinition) => {
          a[e.schemaType] = e.values as any;
          return a;
        }, {}),
        outputFormats: meta.outputFormats.map((o) => ({ id: o.clazz, label: o.formatName })),
        contentTypes: meta.contentTypes.map((c) => ({ id: c.clazz, label: c.displayName || c.description })),
        operationReferenceTypes: meta.operationReferenceTypes,
        credentials: meta.credentials,
        general: keyBy(meta.general, "clazz"),
        lists: {},
        clusterDeploymentMethods: meta.clusterDeploymentMethods.forDisplay,
      };
    }
    return this._formMetadata;
  }

  setUdfFeatures = (features: FeatureMetadata[]) => {
    this._metadata.udfMetadata = features;
  };

  reloadUdfFeatures: () => Promise<{ features: Array<FeatureMetadata>; udfs: UDFMetadata[] }> = () => {
    return Promise.all([this.udfStore.features(), this.udfStore.list()]).then(([features, udfs]) => {
      const udfNameLookup = udfs.reduce((a: Record<string, UDFMetadata>, c) => {
        a[c.displayData.name] = c;
        return a;
      }, {});

      this.setUdfFeatures(
        features.map((x) => {
          x.id = udfNameLookup[x.name].id;
          return x;
        })
      );
      return {
        features,
        udfs,
      };
    });
  };

  defaultValue = (payload: any): any => {
    return this.api.post("metadata/defaultvalue", payload);
  };

  autocomplete = (expression: string, position: any, context: any, version: string = ""): Promise<Suggestion> => {
    return this.api.post(`dsl/autocomplete${version}/`, { expression, position, context }, null, null, {
      ignoreNoLocalApi: true,
    });
  };

  signatureAutocomplete = (expression: string, position: any, version: string = ""): Promise<SignatureSuggestion> => {
    return this.api.post(`dsl/signature${version}/`, { expression, position }, null, null, {
      ignoreNoLocalApi: true,
    });
  };

  fetchDynamicMetadata(clazz: string, payload: any): Promise<SimpleMetadata> {
    return this.api.post("dynamic-metadata", { clazz, payload });
  }

  dynamicMetadata = (metadata: SimpleMetadata, payload: any): Promise<SimpleMetadata> => {
    return metadata.dynamicMetadataClazz
      ? this.fetchDynamicMetadata(metadata.dynamicMetadataClazz, payload)
      : Promise.resolve(metadata);
  };

  formOptions = (): CreateFormFieldOptions => {
    return {
      metadataStore: this,
      formMetadata: this.getFormMetadata(),
    };
  };

  @computed
  get defaultJoinClazz() {
    return this._metadata.features
      .find((x) => x.clazz === "SqlImplicitLookup")
      ?.properties.find((x) => x.name === "withStatementName")?.generated === true
      ? "ImplicitLookup"
      : "SqlImplicitLookup";
  }
}

export type Suggestion = {
  autocomplete: Array<FeatureAutoCompletion | FieldAutoCompletion>;
  range: { start: number; end: number };
};
export const AutoCompletionItemClazzes = {
  Feature: "FeatureAutoCompletion",
  Field: "FieldAutoCompletion",
};
export type FeatureAutoCompletion = { clazz: string; feature: FeatureMetadata; insertText: { value: string } };
export type FieldAutoCompletion = { clazz: string; field: UField; insertText: { value: string } };
export type SignatureSuggestion = {
  activeSignature: number;
  activeParameter: number;
  signatures: any[];
};

export { MetadataStore, Metadata };
