import { Field } from "../../type-definitions/mobx-react-form";
import React, { useEffect, useRef, useState } from "react";
import { mergeRules } from "../mergeRules";

export function useOptionalField(field: Field, required: boolean, onChangeValue: (value: any) => void) {
  const [enabled, setEnabled] = useState(required || !!field?.$value);
  const previousValue = useRef(null);
  useEffect(() => {
    if (field) {
      if (!enabled && !field.isPristine) {
        previousValue.current = field.$value;
        field.reset(true);
      } else if (enabled && previousValue.current !== null) {
        field.set(previousValue.current);
        onChangeValue(previousValue.current);
      }
      if (enabled) {
        // I tried to use REQUIRED_ENABLED_OPTIONAL but it looks like custom validators don't work well if added after creation to numeric fields
        field.set("rules", mergeRules(field.rules, "required"));
      } else if (!required && field.rules) {
        field.set("rules", field.rules === "required" ? "" : field.rules.replace("|required", ""));
      }
    }
  }, [enabled, field]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEnabled(e.currentTarget.checked);
  };
  return {
    enabled,
    setEnabled,
    placeholder: !required && !enabled && "Check to enable",
    checkBoxProps: { onChange, checked: enabled },
  };
}
