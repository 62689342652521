import React, { PropsWithChildren, useEffect } from "react";
import useUserTrackingEvent from "./useUserTrackingEvent";

/**
 * A component that sends tracking event on initialization
 * @param {string} event
 * @param {any} properties
 * @param {React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined} children
 * @returns {JSX.Element}
 * @constructor
 */
function UserTrackingEvent({ event, properties, children }: PropsWithChildren<{ event: string; properties?: any }>) {
  const send = useUserTrackingEvent(event);
  useEffect(() => send(properties), [send, properties]);
  return <>{children}</>;
}

export default UserTrackingEvent;
