import React, { Component } from "react";
import { action, computed, observable } from "mobx";
import { observer } from "mobx-react";
import { fakeDslFeatureMetadata } from "../core/Metadata";
import { Modal } from "../core/views/Modal";
import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";
import intersection from "lodash/intersection";
import union from "lodash/union";
import uniq from "lodash/uniq";
import { fuzzyFilter } from "../core/Fuzzy";
import { FeatureWord } from "../core/Language";
import { Spin } from "../core/views/Spin";
import classNames from "classnames";
import DocsIllustration from "../upsolver-images/docs-illustration.svg";
import ReactMarkdown from "react-markdown";
import { markdownProps } from "../core/views/MarkdownRenderers";
import { scrollIntoView } from "../core/Utils";
import { AppContext } from "../routes/AppContext";
import { Button } from "../core/views/Button";
import "../styles/custom-fields/gallery.scss";
import Box from "@mui/material/Box";
import { ItemsListFilter } from "../templates/common/InputBasedItemFilter";
import { lightBorderColor } from "../styles/colors";
import Typography from "@mui/material/Typography";
import { TypeName } from "../core/api/contracts/TypeName";
import { FeatureMetadata, FeatureProperty } from "../core/FeatureMetadata";
import { filterMetadataByOrganizationFlags } from "../core/filterMetadataByOrganizationFlags";

interface ComputedFieldGalleryModalProps {
  onSelect: (family: string, source?: string) => void;
  onClose: () => void;
  typesFilter?: (metadata: FeatureMetadata) => boolean;
  source?: string;
  hideDsl?: boolean;
}

export const getFeatureGroupKey = (f: FeatureMetadata): string => {
  return (
    f.dslName +
    "(" +
    f.inputs
      .map((i) => i.name)
      .concat(f.properties.map((x) => x.displayName))
      .join(",") +
    ")"
  );
};

const numericTypes = ["float", "double", "int", "long", "number"];

export const getInputIndexes = (group: FeatureMetadata[]): { numeric: boolean[]; other: boolean[] } => {
  const numberOfInputs = group[0].inputs.length;
  const retVal: any = { numeric: [], other: [] };
  for (let i = 0; i < numberOfInputs; i++) {
    const types = group.map((x) => x.inputs[i].schemaType.name);
    const nonNumeric = union(numericTypes, types).length > 5;
    const numeric = intersection(numericTypes, types).length > 0;
    retVal.numeric.push(numeric);
    retVal.other.push(nonNumeric);
  }
  return retVal;
};

const TypeView: React.FC<{ type: string | TypeName; isArray?: boolean }> = ({ type, isArray }) => {
  if (!type) {
    return null;
  }

  const text = typeof type === "string" ? type : type.name;
  const postfix = isArray || (type as TypeName)?.isArray ? "[]" : "";

  // nary
  if (typeof type === "object" && isArray) {
    return (
      <span className="type">
        {text} 1, {text} 2, ... {text} n
      </span>
    );
  }

  return <span className="type">{text + postfix}</span>;
};

const FeatureSignatureView = ({ feature, inputIndexes }: any) => {
  const f: FeatureMetadata = feature;

  const typeName = (input: FeatureProperty, i: number): string => {
    const numeric = inputIndexes.numeric[i];
    const other = inputIndexes.other[i];
    return numeric && other ? "any" : numeric ? "numeric" : input.schemaType;
  };

  return (
    <span className="feature-signature">
      <span className="dsl-name">{f.dslName}</span>({" "}
      <span className="signature">
        {f.properties
          .filter((p) => !p.generated)
          .map((property, index) => (
            <span key={index} className="property">
              {property.name}: <TypeView type={property.schemaType} />
            </span>
          ))}
        {f.inputs.map((input, index) => {
          return (
            <span key={index} className="input">
              {input.name}: <TypeView type={typeName(input as any, index)} isArray={input.sequence} />
            </span>
          );
        })}
      </span>
      )
    </span>
  );
};

export const rotateIndex = (oldIndex: number, coefficient: number, length: number): number => {
  const newIndex = (oldIndex + coefficient) % length;
  return newIndex < 0 ? length + newIndex : newIndex;
};

@observer
export class ComputedFieldGalleryModal extends Component<ComputedFieldGalleryModalProps> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;
  @observable.ref _focusedFeature?: FeatureMetadata;
  @observable _groupFilter: string = "";
  @observable _filter: string = "";
  @observable.shallow _featuresMetadata: FeatureMetadata[] = [];
  @observable _gotUdfs: boolean;

  _inputElement: HTMLInputElement;

  componentDidMount(): void {
    const getFeaturesMetadata = () => {
      const meta = filterMetadataByOrganizationFlags(
        this.context.auth.currentOrganization,
        this.context.metadataStore.Metadata().features.forDisplay.filter((x) => !x.deprecation)
      );
      return this.props.hideDsl ? meta.filter((x) => x.clazz !== fakeDslFeatureMetadata.clazz) : meta;
    };

    this._featuresMetadata = getFeaturesMetadata();
    this.context.metadataStore.reloadUdfFeatures().then(() => {
      this._featuresMetadata = getFeaturesMetadata();
      this._gotUdfs = true;
    });
  }

  _setFocusedFeature(feature: FeatureMetadata | null, e?: React.MouseEvent) {
    this._focusedFeature = feature;

    if (e) {
      e.preventDefault();
      if (this._inputElement) {
        this._inputElement.focus();
      }
    }
  }

  _close = () => {
    this.props.onClose();
  };

  @action.bound
  _updateGroupFilter(e: React.ChangeEvent<HTMLSelectElement>) {
    this._groupFilter = e.target.value;
    this._updateFocusedFeature();
  }

  @action.bound
  _updateFilter(value: string) {
    this._groupFilter = "";
    this._filter = value;
    this._updateFocusedFeature();
  }

  @action.bound
  _onKeyDown(e: React.KeyboardEvent) {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      const coefficient = e.key === "ArrowUp" ? -1 : 1;
      const currentFocused = this._focusedFeature;
      const groups = this.featuresToShowGrouped;
      const currentIndex = groups.findIndex((f) => f.value.indexOf(currentFocused) !== -1);
      const newIndex = rotateIndex(currentIndex, coefficient, groups.length);
      this._focusedFeature = groups[newIndex].value[0];
    }
  }

  @action.bound
  _updateFocusedFeature() {
    const featuresToShow = this.featuresToShow;
    const groups = this.featuresToShowGrouped;
    if (!this._focusedFeature || featuresToShow.indexOf(this._focusedFeature) === -1) {
      this._setFocusedFeature(groups.length > 0 ? groups[0].value[0] : null);
    }
  }

  @action.bound
  _onSubmit(e: React.FormEvent) {
    e.preventDefault();

    if (this._focusedFeature) {
      this.props.onSelect(getFeatureGroupKey(this._focusedFeature), this.props.source);
    }
  }

  _select = (group: FeatureMetadata[], e: React.SyntheticEvent<any> | null) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    this._close();
    this.props.onSelect(getFeatureGroupKey(group[0]), this.props.source);
  };

  @computed
  get allFeatures(): FeatureMetadata[] {
    const extra = this.props.typesFilter ? this.props.typesFilter : () => true;
    return this._featuresMetadata.filter((f) => extra(f));
  }

  @computed
  get featuresToShow(): FeatureMetadata[] {
    const featuresByCategory = this.allFeatures.filter(
      (f) => this._groupFilter === "" || f.group === this._groupFilter
    );
    return fuzzyFilter(featuresByCategory, ["dslName", "name", "description"], this._filter);
  }

  @computed
  get featuresToShowGrouped(): [{ key: string; value: FeatureMetadata[] }] {
    const grouped = groupBy(this.featuresToShow, getFeatureGroupKey);

    const keys = Object.keys(grouped);
    const values = Object.values(grouped);
    const array = [];
    for (let i = 0; i < keys.length; i++) {
      array.push({ key: keys[i], value: values[i] });
    }
    //@ts-ignore
    return sortBy(array, (x) => Math.min(...x.value.map((x) => this.featuresToShow.indexOf(x)))) as any[];
  }

  render() {
    const groupedByInputKind = this.featuresToShowGrouped;
    const categories = uniq(this.allFeatures.map((x) => x.group));
    const title =
      this.featuresToShow.length === 0 ||
      this.featuresToShow.findIndex((f: any) => !f.filter && !f.filterParameters) > -1 //TODO: ts-convert is this supposed to be an array of FeatureMetadata
        ? ` ${FeatureWord.single()}`
        : " Filter";

    return (
      <Modal isOpen={true} className="calculated-fields-gallery" onRequestClose={this._close} wide>
        <Box display="flex">
          <div className="main">
            <Typography variant="h5">Add {title}</Typography>
            <Typography variant="subtitle1">Please select the function to use.</Typography>
            <Box border={1} borderColor={lightBorderColor} py={2} px={1} width="100%">
              <form onSubmit={this._onSubmit}>
                <ItemsListFilter
                  filterValue={this._filter}
                  onUpdateFilter={this._updateFilter}
                  options={categories.map((c) => ({ id: c, label: c }))}
                  onSelect={this._updateGroupFilter}
                  word={FeatureWord.plural()}
                  inputProps={{
                    autoFocus: true,
                    onKeyDown: this._onKeyDown,
                    onSubmit: this._onSubmit,
                  }}
                />
              </form>
            </Box>
            <ul className="features">
              {this._groupFilter.toLowerCase() === "udf" && !this._gotUdfs ? (
                <Spin />
              ) : (
                <React.Fragment>
                  {groupedByInputKind.map(({ key, value }) => {
                    const f: FeatureMetadata = value[0];
                    const inputIndexes = getInputIndexes(value);
                    return (
                      <li
                        key={key}
                        onClick={this._setFocusedFeature.bind(this, f)}
                        onDoubleClick={this._select.bind(this, value)}
                        ref={value.includes(this._focusedFeature) ? scrollIntoView : null}
                        className={classNames({ selected: value.includes(this._focusedFeature) })}
                      >
                        <div className="flex-two-columns">
                          {f.clazz === "DSLFeature" ? (
                            <span>
                              Create a <FeatureWord.component single /> using a SQL snippet
                            </span>
                          ) : (
                            <FeatureSignatureView feature={f} inputIndexes={inputIndexes} />
                          )}
                          <Button
                            type="primary"
                            automationName={`select-feature-${f.dslName}`}
                            onClick={this._select.bind(this, value)}
                          >
                            Select
                          </Button>
                        </div>
                      </li>
                    );
                  })}
                </React.Fragment>
              )}
            </ul>
            <br />
            <Button onClick={this._close} type="cancel">
              Cancel
            </Button>
          </div>

          <Box p={1} flexGrow={0} flexShrink={1} flexBasis={500}>
            {this._focusedFeature ? (
              <FeatureDocumentationView clazz={this._focusedFeature.clazz} />
            ) : (
              <Box className="docs">
                <img src={DocsIllustration} alt="Documentation" />
                <p>Click on a function to view function documentation</p>
                <p>
                  <strong>Tip:</strong> Double click to quick select
                </p>
              </Box>
            )}
          </Box>
        </Box>
      </Modal>
    );
  }
}

export type FeatureDocumentationViewProps = { clazz: string };

const descriptionView = (description: string) => (
  <ReactMarkdown source={description} skipHtml={false} escapeHtml={false} {...markdownProps} />
);

export class FeatureDocumentationView extends React.Component<FeatureDocumentationViewProps> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  render() {
    const { clazz } = this.props;
    const feature =
      clazz === "DSLFeature" ? fakeDslFeatureMetadata : this.context.metadataStore.Metadata().featuresMap[clazz];

    return (
      <div className="feature-docs">
        {feature.documentation ? (
          <React.Fragment>
            <a
              href={
                "https://docs.upsolver.com/upsolver-1/getting-started/glossary/language-guide/functions/calculated-functions/" +
                feature.documentation.location
              }
              target="_blank"
              ref="external"
              className="external"
            >
              <span className="icon-external" /> Open in new window
            </a>
            {descriptionView(feature.documentation.markdown)}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <h2>{feature.dslName}</h2>
            {descriptionView(feature.description)}
          </React.Fragment>
        )}
      </div>
    );
  }
}
