import { action, computed, observable } from "mobx";
import { TimeUnits } from "../TimeUnits";
import isNil from "lodash/isNil";
import { FormInput, FormSelectInputProps, inputOnChange } from "../FormInputTypes";

export type DurationEditorProps = Partial<FormSelectInputProps> & {
  defaultMinutes?: number;
  optional?: boolean;
  mutateAmount?: (number: number, minutes: number) => number;
  positiveView?: boolean;
  options?: any;
  hasInfinite?: any;
};

export class DurationEditorState {
  constructor(
    private readonly input: FormInput,
    private readonly mutateAmount: (number: number, minutes: number) => number,
    private readonly positiveView: boolean = false
  ) {}

  lastNonInfiniteAmount: number;
  @observable checked: boolean = false;
  @observable amount: number = 1;
  @observable unit: string;
  @observable infinite: boolean = false;

  getAmount = (amount: number, minutes: number) => (this.mutateAmount ? this.mutateAmount(amount, minutes) : amount);

  init = action((props: DurationEditorProps) => {
    this.checked = props.optional ? Boolean(props.input.$value) : true;
    const { unit, amount } = TimeUnits.FitToWindow(props.input.$value || props.defaultMinutes);
    const minutes = TimeUnits.TimeWindowToMinutes(amount, unit);
    this.amount = this.getAmount(amount, minutes);
    this.lastNonInfiniteAmount = amount;
    this.unit = !isNil(amount) ? unit : isNil(props.defaultMinutes) ? "Minutes" : "Hour";
    this.updateInfinite(minutes >= TimeUnits.InfiniteMinutes);
  });

  changeEnabled = action((checked: boolean) => {
    this.checked = checked;
    this.sync();
  });

  changeUnit = action((value: string) => {
    this.updateInfinite(false);
    this.unit = value;
    this.sync();
  });

  changeAmount = action((e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const value = e.currentTarget.value;
    const amount = Number(value);
    const minutes = TimeUnits.TimeWindowToMinutes(amount, this.unit);
    this.amount = this.getAmount(amount, minutes);
    this.lastNonInfiniteAmount = amount;
    this.sync(value === "" ? "" : null);
  });

  setInfinite = action(() => {
    this.updateInfinite(true);
  });

  @action.bound
  private updateInfinite(infinite: boolean) {
    if (infinite) {
      this.lastNonInfiniteAmount = this.amount;
      const mutatedAmount = this.getAmount(TimeUnits.InfiniteMinutes, TimeUnits.InfiniteMinutes);
      const { amount, unit } = TimeUnits.FitToWindow(TimeUnits.InfiniteMinutes);
      this.amount = mutatedAmount < 0 ? -amount : amount;
      this.unit = unit;
    } else {
      this.amount = this.lastNonInfiniteAmount === 10000 ? 1 : this.lastNonInfiniteAmount;
    }
    this.sync();
    this.infinite = infinite;
  }

  sync = (syncValue?: any) => {
    const value = this.checked ? TimeUnits.TimeWindowToMinutes(this.amount, this.unit) : null;
    inputOnChange(this.input)(isNil(syncValue) ? value : syncValue);
  };

  @computed
  get viewAmount(): number {
    return this.positiveView ? Math.abs(this.amount) : this.amount;
  }
}
