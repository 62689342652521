import React from "react";

type WordProps = {
  plural?: boolean;
  lowercase?: boolean;
};

export type Word = {
  func: (props: WordProps) => string;
  component: any;
  single: (lowercase?: boolean) => string;
  plural: (lowercase?: boolean) => string;
};

function getWord(singleForm: string, pluralForm: string): Word {
  function getWord(props: WordProps): string {
    const current = props.plural ? pluralForm : singleForm;
    return props.lowercase ? current.toLowerCase() : current;
  }

  return {
    func: getWord,
    single: (lowercase = false) => getWord({ plural: false, lowercase }),
    plural: (lowercase = false) => getWord({ plural: true, lowercase }),
    component: class extends React.Component<WordProps> {
      render() {
        return <span>{getWord(this.props)}</span>;
      }
    },
  };
}

export const WorkspaceWord = getWord("Workspace", "Workspaces");
export const DataSourceWord = getWord("Data Source", "Data Sources");
export const IndexWord = getWord("Lookup Table", "Lookup Tables");
export const EnvironmentWord = getWord("Cluster", "Clusters");
export const OutputWord = getWord("Output", "Outputs");
export const ConnectionWord = getWord("Connection", "Connections");
export const GroupByWord = getWord("Aggregation", "Aggregations");
export const FeatureWord = getWord("Calculated Field", "Calculated Fields");
export const DashboardWord = getWord("Dashboard", "Dashboards");
export const PermissionGroupWord = getWord("Group", "Groups");
export const PermissionPolicyWord = getWord("Policy", "Policies");
export const UserWord = getWord("User", "Users");
export const BucketWord = getWord("Bucket", "Buckets");
export const StreamWord = getWord("Stream", "Streams");
export const UdfWord = getWord("User Defined Function", "User Defined Functions");
export const TableWord = getWord("Table", "Tables");
export const OpenLakeWord = getWord("Query", "Query");
export const WorksheetWord = getWord("Worksheet", "Worksheets");
export const JobWord = getWord("Job", "Jobs");
export const AccountWord = getWord("Account", "Account");

export const DuplicateVerb = getWord("Duplicate", "Duplicate");
export const DeployVerb = getWord("Run", "Run");
export const StopVerb = getWord("Stop", "Stop");
export const StartVerb = getWord("Start", "Start");
