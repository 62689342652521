import { TypeName } from "./TypeName";
import flatten from "lodash/flatten";
import { FeatureDefinition } from "./FeatureDefinition";
import { lastLabel } from "../../Utils";
import { observable, toJS } from "mobx";

export type FieldStates = "FoundInData" | "FoundInSchema" | "NotFound";
export type FieldState = { clazz: FieldStates };
export type FieldMetadata = { id: string; state: FieldState };

export class UField {
  label: string;
  name: string;
  nativeType: TypeName;
  key: string;
  lastLabel: string;

  operationId: string = "";
  featureField: boolean;
  feature: FeatureDefinition;
  parentName?: string;
  customFieldId?: string;
  preview?: boolean;

  @observable inputs?: FieldMetadata[];
  @observable output?: FieldMetadata;

  constructor(
    name: string,
    nativeType: TypeName,
    inputs?: FieldMetadata[] | null,
    feature?: any,
    output?: FieldMetadata
  ) {
    const featureClone = feature && toJS(feature, { recurseEverything: true });
    if (featureClone) {
      delete featureClone.field;
    }
    this.name = name;
    this.nativeType = nativeType;
    this.feature = featureClone;
    this.featureField = Boolean(featureClone);
    this.key = UField.key(this);
    this.inputs = inputs;
    this.output = output;
    if (featureClone && featureClone.inline && featureClone.dsl) {
      this.label = featureClone.dsl;
      this.lastLabel = this.label;
    } else {
      this.label = UField.fieldName(this);
      this.lastLabel = lastLabel(this.label);
    }
  }

  static getFullName(field: any) {
    return field.label || UField.fieldName(field);
  }

  static getFullNameWithType(field: any) {
    return `${UField.getFullName(field)}: ${field?.nativeType?.name}`;
  }

  private static fieldName(field: any) {
    return field.name + (field.nativeType.isArray ? "[]" : "");
  }

  static fromJSON(field: UField, feature?: any): UField {
    if (field.isPrototypeOf(UField)) {
      return field;
    } else {
      return new UField(field.name, TypeName.fromJSON(field.nativeType), field.inputs, feature, field.output);
    }
  }

  static key(field: { key?: string; name: string; nativeType: { name: string; isArray?: boolean } } | UField): string {
    if (!field) {
      return "";
    }

    if (field.key) {
      return field.key;
    }

    if (field.nativeType.isArray) {
      return `${field.name}[]-${field.nativeType.name}`;
    } else {
      return `${field.name}-${field.nativeType.name}`;
    }
  }

  static _fakeDescendantField(parent: UField, child: UField): UField {
    const nativeType = child.nativeType;
    const fakeField = new UField(
      parent.name + (parent.nativeType.isArray ? "[]" : "") + "." + child.name,
      new TypeName(nativeType.name, nativeType.isArray, nativeType.subFields),
      parent.inputs,
      parent.feature,
      parent.output
    );
    fakeField.operationId = parent.operationId;
    fakeField.parentName = parent.name;
    fakeField.featureField = parent.featureField;
    return fakeField;
  }

  static splitBySubFields(f: UField): UField[] {
    if (f.nativeType.subFields && f.nativeType.subFields.length) {
      return flatten(
        f.nativeType.subFields.map((sf) => {
          if (sf.nativeType.subFields.length > 0) {
            return flatten(UField.splitBySubFields(UField._fakeDescendantField(f, sf)));
          } else {
            return UField._fakeDescendantField(f, sf);
          }
        })
      );
    } else {
      return [f];
    }
  }

  static clone(f: UField, feature?: any) {
    const clone = new UField(f.name, TypeName.fromJSON(f.nativeType), f.inputs, feature || f.feature, f.output);
    clone.operationId = f.operationId;
    return clone;
  }

  static sourceFeature(f?: UField): FeatureDefinition[] {
    return f && f.feature && f.feature.source ? [f.feature] : [];
  }

  static lastLabel(field: { name: string; lastLabel: string } | UField): string {
    if (!field) {
      return "";
    }

    if (field.lastLabel) {
      return field.lastLabel;
    } else {
      return lastLabel(field.name);
    }
  }

  keyEquals(that: UField) {
    return UField.key(this) === UField.key(that);
  }
}
