import React from "react";
import "../../styles/modal.scss";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import MuiDialogTitle from "@mui/material/DialogTitle";
import { observer } from "mobx-react-lite";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import classNames from "classnames";
import DialogActions from "@mui/material/DialogActions";
import Box from "@mui/material/Box";

export type ModalProps = {
  isOpen: boolean;
  onRequestClose?: React.MouseEventHandler;
  className?: string;
  closeView?: React.ReactNode;
  children: React.ReactNode;
  fullScreen?: boolean;
  closeTimeoutMS?: number;
  wide?: boolean;
  title?: React.ReactNode;
  actions?: React.ReactNode;
  disableContentPadding?: React.ReactNode;
};

const useDialogStyles: any = makeStyles((theme?: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    content: {
      minHeight: 200,
      width: 800,
      [theme.breakpoints.down("lg")]: {
        width: 700,
      },
    },
  })
);

export interface DialogTitleProps {
  onClose: React.MouseEventHandler;
}

const DialogTitle: React.FC<DialogTitleProps> = ({ children, onClose }) => {
  const classes = useDialogStyles();
  return (
    <MuiDialogTitle className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={(e) => {
            if (e) {
              e.stopPropagation();
            }
            onClose(e);
          }}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

export const Modal: React.FC<ModalProps> = observer(
  ({
    className,
    onRequestClose,
    fullScreen,
    wide,
    isOpen,
    children,
    closeView,
    title,
    actions,
    disableContentPadding,
  }) => {
    const classes = useDialogStyles();
    const smallWidth = useMediaQuery("(max-width:900px)");
    return (
      <Dialog
        disableEnforceFocus
        aria-labelledby="dialog"
        className={classNames("dialog", className)}
        maxWidth={wide ? "lg" : "md"}
        scroll="body"
        fullWidth={Boolean(wide)}
        fullScreen={Boolean(fullScreen) || smallWidth}
        open={Boolean(isOpen)}
        onClose={onRequestClose}
      >
        <DialogTitle onClose={onRequestClose}>
          <Box p={1}>{title}</Box>
        </DialogTitle>
        <DialogContent
          style={disableContentPadding && { padding: 0 }}
          dividers={Boolean(title || actions)}
          className={!fullScreen && !wide && !smallWidth && classes.content}
        >
          {children}
        </DialogContent>
        {actions && <DialogActions>{actions}</DialogActions>}
      </Dialog>
    );
  }
);

Modal.displayName = "UModal";
export default Modal;
