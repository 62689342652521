import { AllFields } from "../../core/api/contracts/templates";
import { UField } from "../../core/api/contracts/UField";

export const deserializeAllFieldsPart = (name: string, payload: AllFields): any => ({
  fields: payload.fields.map((f) => {
    const df = UField.fromJSON(f);
    df.operationId = name;
    return df;
  }),
  features: payload.features
    .filter((x) => !x.inline)
    .map((x) => {
      x.field = UField.fromJSON(x.field, x);
      x.field.operationId = name;
      return x;
    }),
});
