import { observer } from "mobx-react";
import React from "react";
import { action, computed, observable, observe, reaction } from "mobx";
import { BaseFormWithArrayFields } from "../../BaseForm";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button } from "../Button";
import MaskedInput from "react-maskedinput";
import DefaultMomentProvider, { ZonedDateTime } from "../../MomentProvider";
import { Moment } from "moment";
import { dateTimeFormat, dateTimeFormatPicker } from "../../dateFormats";
import { inputOnChange, inputOnChangeHandler } from "../FormInputTypes";
import { FormFieldRegistry } from "../../FormFieldRegistry";
import { add, sub } from "date-fns";

const moment = (v: moment.MomentInput, format?: string): Moment => DefaultMomentProvider.moment()(v, format);

class Input extends React.Component<{ onClick?: React.MouseEventHandler }> {
  render() {
    return <span className="icon-calendar" onClick={this.props.onClick} />;
  }
}

@observer
export class DateTimeMaskedInput extends React.Component<{ input: { $value: Moment } } & any> {
  static dateTimeMask: string = "1111-11-11 11:11";
  @observable _innerValue: string;
  _dispose: () => void;
  _disabled: boolean;
  _inputOnChange: (date: moment.MomentInput) => void;
  _inputOnBlur: React.FocusEventHandler;
  _onFocus: React.FocusEventHandler;

  componentDidMount() {
    const input = this.props.input;
    this._dispose = observe(input, (x: any) => {
      if (x.name === "$value") {
        this._innerValue = this._formattedValue(this._inputCurrent);
      }
    });
    this._inputOnChange = this.props.onChange || inputOnChange(this.props.input);
    this._inputOnBlur = this.props.onBlur || this.props.input.onBlur;
    this._onFocus = this.props.onFocus || this.props.input.onFocus;
    this._disabled = this.props.disabled || this.props.input.disabled;
  }

  componentWillUnmount() {
    if (this._dispose) {
      this._dispose();
    }
  }

  _formattedValue = (value: string) => (value ? moment(value).format(dateTimeFormat) : "");

  @computed
  get _inputCurrent() {
    return this.props.input.$value && this.props.input.$value.current;
  }

  @computed
  get _displayValue(): string {
    return this._innerValue || this._formattedValue(this._inputCurrent);
  }

  _toggle = (e: React.MouseEvent) => {
    const currentMoment = this._innerValue ? moment(this._innerValue, dateTimeFormat) : moment(this._inputCurrent);
    if (currentMoment.isValid()) {
      DefaultMomentProvider.toggle();
    }
  };

  _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.currentTarget.value.includes("_")) {
      const momentValue = e.currentTarget.value && moment(e.currentTarget.value, dateTimeFormat);
      this._innerValue = e.target.value;
      this._inputOnChange(momentValue);
    }
  };

  _onBlur = (e: React.FormEvent<HTMLInputElement>) => {
    const momentValue = e.currentTarget.value && moment(e.currentTarget.value, dateTimeFormat);
    if (!momentValue || momentValue.isValid()) {
      this._innerValue = e.currentTarget.value;
      this._inputOnBlur(momentValue as any);
    } else {
      this._inputOnBlur(this._inputCurrent);
    }
    this._innerValue = null;
  };

  render() {
    const { input } = this.props;
    const selectedDate = new ZonedDateTime(input.$value && DefaultMomentProvider.moment()(input.$value).toISOString());

    return (
      <span className="datetime-masked-input">
        <MaskedInput
          style={{ height: 50 }}
          mask={DateTimeMaskedInput.dateTimeMask}
          disabled={this._disabled}
          placeholder={dateTimeFormatPicker}
          onChange={this._onChange}
          onBlur={this._onBlur}
          value={selectedDate.fakeFromRaw && this._formattedValue(selectedDate.current)}
        />
        <span className="buttons">
          <DatePicker
            showTimeSelect
            customInput={<Input />}
            selected={selectedDate.fakeFromRaw}
            onChange={(v) => {
              const m = moment(ZonedDateTime.fakeToRaw(v));

              this._inputOnChange(m.toISOString());
            }}
            disabled={this._disabled}
            timeFormat="HH:mm"
            timeIntervals={15}
            dateFormat={dateTimeFormatPicker}
            placeholderText={"Select time"}
            onFocus={this._onFocus}
            onBlur={this._inputOnBlur}
          />
          <Button type="secondary" className="zone-toggle" small onClick={this._toggle}>
            {DefaultMomentProvider.defaultTimeZone}
          </Button>
        </span>
      </span>
    );
  }
}

type InnerMethod = { label?: string; empty?: boolean; getTime?: () => any };

@observer
export class FormDateTimeInputWithDefaults extends React.Component<{ input: any }> {
  static customMethod: { label: string; empty: boolean } = { label: "custom", empty: false };
  _form: BaseFormWithArrayFields;
  _defaultOption: any;
  _dispose: () => void;

  @observable.ref _method: InnerMethod = FormDateTimeInputWithDefaults.customMethod;

  @action.bound
  _changeMethod(method: InnerMethod) {
    this._method = method;
    if (method.getTime) {
      this._setValue(method.getTime());
    }
  }

  componentDidMount() {
    const input = this.props.input;
    const options = input.metadata.options;
    this._defaultOption = options.find((p: any) => p.default === true);
    if (this._defaultOption) {
      this._changeMethod(this._defaultOption);
    } else {
      this._updateDateFromDatePicker(input.$value);
    }

    this._dispose = observe(input, (x: any) => {
      if (x.name === "$value") {
        const currentMoment = input.$value && moment(input.$value);
        const matchingMethod =
          currentMoment &&
          input.metadata.options.find(
            (x: any) => x.getTime && moment(x.getTime()).startOf("minute").isSame(currentMoment.startOf("minute"))
          );
        if (matchingMethod) {
          this._method = matchingMethod;
        } else {
          this._method = input.$value ? FormDateTimeInputWithDefaults.customMethod : this._defaultOption;
        }
      }
    });
  }

  componentWillUnmount() {
    if (this._dispose) {
      this._dispose();
    }
  }

  _setValue = (value: Moment) => {
    if (!value || value.isValid()) {
      this.props.input.resetValidation();
      inputOnChange(this.props.input)(value?.toISOString());
    } else {
      this.props.input.invalidate("Invalid date");
    }
  };

  _updateDateFromDatePicker = (value: string) => {
    this._setValue(moment(value));
    this._updateMethod(moment(value));
  };

  _updateMethod(value?: Moment) {
    if (value) {
      this._changeMethod(FormDateTimeInputWithDefaults.customMethod);
    } else if (this._defaultOption) {
      this._changeMethod(this._defaultOption);
    }
  }

  render() {
    const { input } = this.props;
    const method = this._method;
    const pickerDisabled = method !== FormDateTimeInputWithDefaults.customMethod;
    return (
      <div className="datepicker-with-defaults">
        {input.metadata.options.map((o: { label: string; getTime: () => any }, i: number) => (
          <label className="radio-option" key={i}>
            <input type="radio" checked={method === o} onClick={this._changeMethod.bind(this, o)} /> {o.label}
          </label>
        ))}

        <label className="radio-option">
          <input
            type="radio"
            checked={method === FormDateTimeInputWithDefaults.customMethod}
            onChange={this._changeMethod.bind(this, FormDateTimeInputWithDefaults.customMethod)}
          />
          Custom
        </label>

        <DateTimeMaskedInput
          disable={pickerDisabled}
          input={input}
          onChange={this._updateDateFromDatePicker}
          onFocus={input.onFocus}
          onBlur={input.onBlur}
        />
      </div>
    );
  }
}

export const register = (formFieldRegistry: FormFieldRegistry) => {
  formFieldRegistry.register("zoned-datetime", (props) => <DateTimeMaskedInput {...props} />);
  formFieldRegistry.register("datetime-with-defaults", (props) => <FormDateTimeInputWithDefaults {...props} />);
};
