import { observer } from "mobx-react";
import React, { Component } from "react";
import { action, computed, observable } from "mobx";
import { modifiedKeyPressed } from "../Utils";
import TextField from "@mui/material/TextField";

type ComboBoxProps<A> = {
  items: Array<A>;
  filterItems: (items: Array<A>, filter: string) => Array<A>;
  children: (item: A, focused: boolean) => React.ReactNode;
  itemSelected: (item: A) => void;
  placeholder: string;
  showInput?: boolean;
};

@observer
export class ComboBox<A> extends Component<ComboBoxProps<A>> {
  @observable.ref _focusedItem: A = null;
  @observable.ref _filter = "";

  static defaultProps = {
    showInput: true,
  };

  _inputElement: HTMLInputElement;

  @action.bound
  _onFilterChanged(e: React.ChangeEvent<HTMLInputElement>) {
    this._filter = e.currentTarget.value;
  }

  @computed get itemsToShow() {
    const { filterItems, items } = this.props;
    return filterItems(items, this._filter.trim());
  }

  @action.bound
  _onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    const itemsToShow = this.itemsToShow;
    const isArrowDown = e.key === "ArrowDown";
    if ((isArrowDown || e.key === "ArrowUp") && itemsToShow.length > 0) {
      const coefficient = isArrowDown ? 1 : -1;
      if (!itemsToShow.includes(this._focusedItem)) {
        if (coefficient === 1) {
          this._focusedItem = itemsToShow[0];
        } else {
          this._focusedItem = itemsToShow[itemsToShow.length - 1];
        }
      } else {
        const index = itemsToShow.indexOf(this._focusedItem);
        let newIndex = (index + coefficient) % itemsToShow.length;
        if (newIndex < 0) {
          newIndex = itemsToShow.length + newIndex;
        }
        this._focusedItem = itemsToShow[newIndex];
      }
    } else if (e.key === "Enter" && !modifiedKeyPressed(e)) {
      e.preventDefault();
      e.stopPropagation();

      if (this._focusedItem) {
        this.props.itemSelected(this._focusedItem);
      }
    }
  }

  _focus = (el: HTMLInputElement) => {
    if (el) {
      el.focus();
    }

    this._inputElement = el;
  };

  render() {
    if (this._inputElement) {
      this._inputElement.focus();
    }

    const { placeholder, children, showInput } = this.props;

    return (
      <React.Fragment>
        {showInput && (
          <TextField
            className="search"
            placeholder={placeholder}
            value={this._filter}
            onChange={this._onFilterChanged}
            onKeyDown={this._onKeyDown}
            ref={this._focus}
          />
        )}
        <ul>{this.itemsToShow.map((i) => children(i, i === this._focusedItem))}</ul>
      </React.Fragment>
    );
  }
}
