export function isTimeUnit(unit: any): unit is TimeUnit {
  if (typeof unit !== "object") {
    return false;
  }
  const maybeUnit = Reflect.get(unit, "unit");
  const maybeAmount = Reflect.get(unit, "amount");
  return typeof maybeUnit === "string" && typeof maybeAmount === "number";
}

export interface TimeUnit {
  unit: string;
  amount: number;
}
