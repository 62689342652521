import { MissingIntegrationException } from "./MissingIntegrationException";
import * as React from "react";
import { useContext } from "react";
import { AppContext } from "../routes/AppContext";
import { useObserver } from "mobx-react-lite";
import Modal from "../core/views/Modal";
import Typography from "@mui/material/Typography";
import { methodsOrDefault } from "./integrations-store";
import { GenericIntegration } from "./GenericIntegration";

export function MissingIntegrationModal(props: MissingIntegrationException) {
  const { integrations } = useContext(AppContext);
  const handleCancel = integrations.state.cancel;
  return useObserver(() => (
    <Modal
      disableContentPadding
      isOpen={true}
      onRequestClose={handleCancel}
      title={<Typography variant="h5">{props.title} Integration</Typography>}
    >
      <GenericIntegration
        showTitle={false}
        payload={props.payload}
        methods={methodsOrDefault(props.methods)}
        blurb={props.description}
        onClose={handleCancel}
      />
    </Modal>
  ));
}
