import React, { useEffect, useMemo, useState } from "react";
import { TimeUnits } from "../../TimeUnits";
import { FormInputProps } from "../../FormInputTypes";
import { autorun, values } from "mobx";
import isNil from "lodash/isNil";
import { TimeUnit } from "../../../TimeUnit";
import { windowSizeDisplay } from "../../../../templates/notebook/windowSizeDisplay";

function TimeUnitDisplay(props: { value: TimeUnit }) {
  return <>{windowSizeDisplay(props.value).toLowerCase()}</>;
}

function OutputIntervalExplanation(props: { outputInterval: number }) {
  const time = useMemo(() => TimeUnits.FitToWindow(props.outputInterval), [props.outputInterval]);
  return (
    <>
      Every{" "}
      <strong>
        <TimeUnitDisplay value={time} />
      </strong>{" "}
      write an output
    </>
  );
}

function WindowSizeExplanation(props: { windowSize?: number }) {
  const time = useMemo(() => !isNil(props.windowSize) && TimeUnits.FitToWindow(props.windowSize), [props.windowSize]);
  if (!time || time.amount === 0) {
    return null;
  }
  if (props.windowSize === TimeUnits.InfiniteMinutes) {
    return (
      <>
        &nbsp;using <strong>all available</strong> historical data
      </>
    );
  }
  return (
    <>
      {" using data from the last "}
      <strong>
        <TimeUnitDisplay value={time} />
      </strong>
    </>
  );
}

interface ExplainWindowSizeOverride {
  outputInterval: number;
  windowSize?: number;
}

/**
 * Attempts to explain what the values of the UpdateOutputIntervalAndWindowSizeOverride form mean (the explanation
 * assumes the changeset form also contains outputInterval). This done as an attempt to prevent users from setting
 * window override to a value that would cause the Output not to function.
 * This assumes the changeset form contains outputInterval and windowSize.
 * @param {FormInputProps} props
 * @returns {JSX.Element | null}
 * @constructor
 */
export function ExplainUpdateOutputIntervalAndWindowSizeOverride(props: FormInputProps) {
  const [explain, setExplain] = useState<ExplainWindowSizeOverride>();

  // track changes to any value in the containing form and possibly update the explanation
  useEffect(
    () =>
      autorun(() => {
        const fromEntries: object = Object.fromEntries(
          values(props.input.container().fields).map((x) => [x.name, x.$value])
        );
        setExplain(fromEntries.hasOwnProperty("outputInterval") ? (fromEntries as ExplainWindowSizeOverride) : null);
      }),
    [props.input]
  );
  if (!explain) {
    return null;
  }
  return (
    <div className="form-input">
      <label>What does this mean?</label>
      <span>
        <OutputIntervalExplanation outputInterval={explain.outputInterval} />
        <WindowSizeExplanation windowSize={explain.windowSize} />.
      </span>
    </div>
  );
}
