import { useCallback, useLayoutEffect, useMemo, useState } from "react";
import ResizeObserver from "resize-observer-polyfill";

interface Options {
  widthOffset: number;
  heightOffset: number;
  subtractOtherChildrenHeight: boolean;
  includePaddingDiff: boolean;
}

function getPadding(el: HTMLElement, dirs: ("top" | "bottom" | "left" | "right")[]) {
  const cssProps = window.getComputedStyle(el);
  return Math.ceil(
    dirs
      .map((d) => Number.parseFloat(cssProps.getPropertyValue(`padding-${d}`).replace("px", "")))
      .filter((x) => !Number.isNaN(x))
      .reduce((x) => x + x)
  );
}

function getSize(el: HTMLElement = null, options: Options) {
  if (!el) {
    return {
      width: 0,
      height: 0,
    };
  }
  const paddingH = options.includePaddingDiff ? getPadding(el, ["top", "bottom"]) : 0;
  const paddingW = options.includePaddingDiff ? getPadding(el, ["left", "right"]) : 0;
  return {
    height: el.offsetHeight - options.heightOffset - paddingH,
    width: el.offsetWidth - options.widthOffset - paddingW,
  };
}

const defaultOptions: Options = {
  widthOffset: 0,
  heightOffset: 0,
  subtractOtherChildrenHeight: false,
  includePaddingDiff: false,
};

export function useContainerSize(el: HTMLElement, options?: Partial<Options>) {
  const fullOptions = useMemo(() => ({ ...defaultOptions, ...options }), [options]);
  const [ComponentSize, setComponentSize] = useState({ height: Number.NaN, width: Number.NaN });

  const handleResize = useCallback(() => {
    if (el?.parentElement != null) {
      const size = getSize(el.parentElement, fullOptions);
      const initialValue = { height: 0, width: 0 };
      const totalChildSize = fullOptions.subtractOtherChildrenHeight
        ? Array.from(el.parentElement.children)
            .filter((x) => x !== el)
            .map((el) => getSize(el as HTMLElement, defaultOptions))
            .reduce(
              (a, c) => ({
                height: a.height + c.height,
                width: a.width + c.width,
              }),
              initialValue
            )
        : initialValue;

      requestAnimationFrame(() => {
        setComponentSize({ height: size.height - totalChildSize.height, width: size.width - totalChildSize.width });
      });
    }
  }, [el, fullOptions]);

  useLayoutEffect(() => {
    if (el?.parentElement == null) {
      return;
    }

    handleResize();

    const observer = new ResizeObserver(handleResize);
    observer.observe(el.parentElement);

    return () => {
      observer.disconnect();
    };
  }, [el]);

  return ComponentSize;
}
