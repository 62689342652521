import { AuthenticationResult } from "@azure/msal-browser";

export interface AzureAuthenticationObject {
  accessToken: string;
  homeAccountId: string;
  username: string;
  name?: string;
}

export function authenticationResultToObject(authenticationResult: AuthenticationResult): AzureAuthenticationObject {
  return {
    accessToken: authenticationResult.accessToken,
    homeAccountId: authenticationResult.account.homeAccountId,
    username: authenticationResult.account.username,
    name: authenticationResult.account.name,
  };
}
