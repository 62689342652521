import * as React from "react";
import { observer } from "mobx-react";
import { action, computed, observable } from "mobx";
import classNames from "classnames";
import AnimateHeight from "react-animate-height";
import "../../styles/advanced.scss";

type AdvancedCollapseProps = {
  open: boolean;
  toggle: () => void;
  children?: React.ReactNode;
  toggleText?: (toggle: boolean) => string;
};

export class AdvancedCollapse extends React.Component<AdvancedCollapseProps> {
  @action.bound
  _toggle(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    this.props.toggle();
  }

  @computed
  get _toggleText(): string {
    const open = this.props.open;
    return this.props.toggleText ? this.props.toggleText(open) : `${open ? "Hide" : "Show"} Advanced Options`;
  }

  render() {
    const { open, children } = this.props;
    return (
      <div className={classNames("advanced-collapse", { children: children })}>
        <button onClick={this._toggle}>
          <span className="icon-gear" /> {this._toggleText}
        </button>

        {children && (
          <AnimateHeight height={open ? "auto" : "0"} duration={200}>
            {children}
          </AnimateHeight>
        )}
      </div>
    );
  }
}

@observer
export class UncontrolledAdvancedCollapse extends React.Component<{ children?: any }> {
  @observable _open: boolean = false;

  @action.bound
  _toggle() {
    this._open = !this._open;
  }

  render() {
    return (
      <AdvancedCollapse open={this._open} toggle={this._toggle}>
        {this.props.children}
      </AdvancedCollapse>
    );
  }
}
